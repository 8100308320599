import { Button, Table } from 'antd';
import { IAccountColumnsProps, IProjectDatas } from './type';
import React, { useEffect, useState } from 'react';

import DesktopAdminAccountTableModal from './desktop-admin-project-table-modal';
import { IAdminMenuPermissionProps } from '@/common/type';
import { getToday } from '@/common/util';
import styled from 'styled-components';
import defaultInfo from '@/common/site-info';
import CustomButton from "@/components/button";
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopAdminProjectTableProps {
  projectList: IProjectDatas[];
  setProjectList: React.Dispatch<React.SetStateAction<IProjectDatas[]>>;
  getProjectAPI: (project_name: string) => any;
  pageInfo: IAdminMenuPermissionProps;
}

const AddBtnArea = styled.div`
  text-align: right;
  padding-bottom: 20px;
`;

const Container = styled.div`
  box-sizing: border-box;
`;

const DesktopAdminProjectTable: React.FunctionComponent<
  IDesktopAdminProjectTableProps
> = ({ projectList, setProjectList, getProjectAPI, pageInfo }) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectData, setSelectData] = useState<IProjectDatas | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProjectAPI('').then(data => {
      setProjectList(data.map(project => ({ ...project, key: project.id })));
      setIsLoading(false);
    });
  }, [getProjectAPI, isModalVisible, setProjectList]);

  const columns: IAccountColumnsProps[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: (_, record) => <p>{record.id}</p>,
    },
    {
      title: t('프로젝트명'),
      dataIndex: 'project_name',
      key: 'project_name',
      align: 'center',
    },
    {
      title: t('라이센스키'),
      dataIndex: 'license_key',
      key: 'license_key',
      align: 'center',
      render: value => value,
    },
    {
      title: t('서비스기간'),
      dataIndex: 'service_date',
      key: 'service_date',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: (_, record) => (
        <p>
          {record.service_start} ~ {record.service_end}
        </p>
      ),
    },
    {
      title: t('서비스유형'),
      dataIndex: 'service_type',
      key: 'service_type',
      align: 'center',
      render: value => {
        if (value === '1' || value === '2' || value === '') return 'ISMS';
        if (value === '5') return 'ISO';
        if (value === '7' || value === 'A' || value === 'B' || value === 'C' || value === 'D' || value === 'E' || value === 'F') return 'CSAP';
      },
    },
  ];

  const projectAdd = () => {
    setSelectData({
      id: null,
      project_name: '',
      license_key: '',
      service_start: getToday(),
      service_end: getToday(),
      service_level: '',
      service_type: '',
    });
    setIsModalVisible(true);
  };

  const columnsButtons: IAccountColumnsProps[] = [
    {
      title: t('수정'),
      dataIndex: 'edit',
      key: 'edit',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: (_, record) => (
        <CustomButton
          data-no={record.no}
          onClick={() => {
            setSelectData(record);
            setIsModalVisible(true);
          }}
        >
          {t("수정")}
        </CustomButton>
      ),
    },
  ];

  return (
    <Container>
      {pageInfo.perm_write && (
        <AddBtnArea>
          <Button onClick={projectAdd}>{t("프로젝트추가")}</Button>
        </AddBtnArea>
      )}
      <Table
        columns={
          pageInfo.perm_write ? [...columns, ...columnsButtons] : [...columns]
        }
        size={defaultInfo.table_size}
        dataSource={projectList}
        pagination={false}
        loading={isLoading}
      />
      <DesktopAdminAccountTableModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        selectData={selectData}
        setProjectList={setProjectList}
      />
    </Container>
  );
};

export default DesktopAdminProjectTable;
