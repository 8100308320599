import { ICertificationTableData } from '../type';
import React from 'react';
import { Select } from 'antd';
import styled from 'styled-components';

const { Option } = Select;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopCertificationSpecTableOperationProps {
  datas: ICertificationTableData;
  setEditCertData: React.Dispatch<
    React.SetStateAction<ICertificationTableData[]>
  >;
  dataSync: (data) => void;
}

const StyledSelect = styled(Select)`
  width: 70px;
  font-size: 13px;
`;

const DesktopCertificationSpecTableOperation: React.FunctionComponent<
  IDesktopCertificationSpecTableOperationProps
> = ({ datas, setEditCertData, dataSync }) => {
  function handleChange(value) {
    const paramsDatas = { ...datas, state: value };

    setEditCertData(prev => [
      ...prev.filter(item => item.category_id !== paramsDatas.category_id),
      paramsDatas,
    ]);
    dataSync([paramsDatas]);
  }

  return (
    <StyledSelect defaultValue={datas.state || 'Y'} onChange={handleChange}>
      <Option value="Y">Y</Option>
      <Option value="N">N</Option>
      <Option value="N/A">N/A</Option>
    </StyledSelect>
  );
};

export default DesktopCertificationSpecTableOperation;
