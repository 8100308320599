import { Button, Input, Select, Space, Table } from 'antd';
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
    useRef, 
} from 'react';

import { nl2br } from 'react-js-nl2br';
import { IContentProps, IDiagnosisTechnicalDataProps } from './type';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import { openNotification } from '@/common/util';
import styled from 'styled-components';
import { IPaginationProps } from '@/panel/Assets/Evaluation/type';
import defaultInfo from '@/common/site-info';
import Modal from '@/components/modal';
import { Excel } from "antd-table-saveas-excel";
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const { TextArea } = Input;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopDiagnosisTechnicalTableModalProps {
    setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    isModalVisible: boolean;
    selectAssetData: IContentProps;
    selectGroupName: string;
    setAssetList: React.Dispatch<
        React.SetStateAction<IDiagnosisTechnicalDataProps>
    >;
    selectGroupId: number;
    selectsRecord: IContentProps[];
    isBundle: boolean;
    pagination: IPaginationProps;
    fetch: (
        pagination: IPaginationProps,
        keyword: string,
        cb: () => void
    ) => void;
    searchKeyword: string;
    setIsTableLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IColumnsProps {
    title: string;
    label: string;
    dataIndex: string;
    sorter?: boolean;
    key: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    render?: (text: any, record: IDiagnosisTechnicalDataProps) => JSX.Element;
    width: string;
    align: 'left' | 'center' | 'right';
}

const Container = styled.div`
    position: relative;
    padding: 24px 0 24px 0;
    .ant-table-cell {
        vertical-align: top;
    }
`;
const StyledTextArea = styled(TextArea)`
    font-size: 11px;
`;
const ButtonArea = styled.div`
    text-align: right;
`;
const ScoreNum = styled.div`
    font-size: 20px;
`;
const Text = styled.p`
    text-align: left;
    font-size: 14px;
`;

const getTechManual = async ({
    projectId,
    groupName,
    os,
    assetId,
    selectGroupId,
}) => {
    const result = await api.getTechManual({
        project_id: projectId,
        group_name: groupName,
        os,
        asset_id: assetId,
        group_id: selectGroupId,
    });

    return result.data;
};

const setTechManualStep1 = async ({
    projectId,
    groupName,
    os,
    assetId,
    datas,
    selectGroupId,
}) => {
    const result = await api.setTechManualStep1({
        project_id: projectId,
        group_name: groupName,
        os,
        asset_id: assetId,
        datas,
        group_id: selectGroupId,
    });

    return result.data;
};

const DesktopDiagnosisTechnicalTableModal: React.FunctionComponent<
    IDesktopDiagnosisTechnicalTableModalProps
> = ({
    setIsModalVisible,
    isModalVisible,
    selectAssetData,
    selectGroupName,
    selectGroupId,
    selectsRecord,
    isBundle,
    pagination,
    fetch,
    searchKeyword,
    setIsTableLoading,
}) => {
    const { t } = useTranslation();
    const [manualData, setManualData] = useState([]);
    const { projectId } = useContext(ProjectContext);

    const bundleIdArray = useMemo(
        () => selectsRecord.map(item => item.id).sort(),
        [selectsRecord]
    );

    const isReadOnly = useMemo(() => {
        return selectAssetData?.status === t('진단완료');
    }, [selectAssetData, t]);

    useEffect(() => {
        if (
            typeof selectAssetData !== 'undefined' ||
            selectsRecord.length > 0
        ) {
            const { os, id } = selectAssetData;

            setManualData([]);
            getTechManual({
                projectId,
                groupName: selectGroupName,
                os,
                assetId: id,
                selectGroupId,
            }).then(datas => {
                setManualData(datas.map(item => ({ ...item, key: item.id })));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        projectId,
        // selectAssetData,
        selectGroupName,
        selectGroupId,
        // selectsRecord,
        isBundle,
        isModalVisible,
        t,
    ]);

    const formHandleChange = useCallback(
        (value, record, target) => {
            const datas = manualData.map(data => {
                if (data.check_code === record.check_code) {
                    return { ...data, [target]: value };
                }
                return data;
            });
            setManualData(datas);
        },
        [manualData]
    );

    const apiCall = useCallback(
        (api, text) => {
            const { os, id } = selectAssetData;

            const apiFunc = api;

            apiFunc({
                projectId,
                groupName: selectGroupName,
                os,
                assetId: isBundle ? bundleIdArray : id,
                datas: manualData,
                selectGroupId,
            }).then(() => {
                setIsModalVisible(false);
                setIsTableLoading(true);
                fetch(pagination, searchKeyword, () => {
                    const notiText = {
                        description:
                            text === t("완료"),
                    };
                    openNotification(notiText);
                });
            });
        },
        [
            bundleIdArray,
            fetch,
            isBundle,
            manualData,
            pagination,
            projectId,
            searchKeyword,
            selectAssetData,
            selectGroupId,
            selectGroupName,
            setIsModalVisible,
            setIsTableLoading,
            t,
        ]
    );

    const score = useCallback(record => {
        const { weight, result1 } = record;

        const num1 = weight === '상' ? 3 : weight === '중' ? 2 : 1;
        const num2 = result1 === '양호' ? 1 : result1 === '미흡' ? 0.5 : 0;

        return num1 * num2;
    }, []);


    const columns: IColumnsProps[] = [
        {
            title: t('구분'),
            label: t('구분'),
            dataIndex: 'category_name',
            key: 'category_name',
            align: 'center',
            width: '100px',
        },
        {
            title: t('항목코드'),
            label: t('항목코드'),
            dataIndex: 'check_code',
            key: 'check_code',
            align: 'center',
            width: '100px',
        },
        {
            title: t('항목명'),
            label: t('항목명'),
            dataIndex: 'check_desc',
            key: 'check_desc',
            align: 'center',
            width: '200px',
        },
        {
            title: t('중요도'),
            label: t('중요도'),
            dataIndex: 'weight',
            key: 'weight',
            align: 'center',
            width: '100px',
            render: (text, record) => {
                return isReadOnly ? (
                    <Text>{text}</Text>
                ) : (
                    <Select
                        defaultValue={text}
                        style={{ width: 70 }}
                        onChange={value => {
                            formHandleChange(value, record, 'weight');
                        }}
                    >
                        <Option value="상">{t("상")}</Option>
                        <Option value="중">{t("중")}</Option>
                        <Option value="하">{t("하")}</Option>
                    </Select>
                );
            },
        },

        {
            title: t('진단현황'),
            label: t('진단현황'),
            dataIndex: 'result1_status',
            key: 'result1_status',
            align: 'center',
            width: 'auto',
            // eslint-disable-next-line react/display-name
            render: (text, record) => {
                return isReadOnly ? (
                    <Text>{nl2br(text)}</Text>
                ) : (
                    <StyledTextArea
                        allowClear
                        autoSize={{ minRows: 3, maxRows: 8 }}
                        defaultValue={text}
                        onBlur={event => {
                            formHandleChange(
                                event.target.value,
                                record,
                                'result1_status'
                            );
                        }}
                    />
                );
            },
        },
        {
            title: t('진단결과'),
            label: t('진단결과'),
            dataIndex: 'result1',
            key: 'result1',
            align: 'center',
            width: '120px',
            // eslint-disable-next-line react/display-name
            render: (text, record) => {
                return isReadOnly ? (
                    <Text>{text}</Text>
                ) : (
                    <Select
                        defaultValue="N/A"
                        value={text}
                        style={{ width: 100, zIndex: 1 }}
                        onChange={value => {
                            formHandleChange(value, record, 'result1');
                        }}
                    >
                        <Option value="양호">{t("양호")}</Option>
                        <Option value="미흡">{t("미흡")}</Option>
                        <Option value="취약">{t("취약")}</Option>
                        <Option value="N/A">N/A</Option>
                    </Select>
                );
            },
        },
        {
            title: t('진단점수'),
            label: t('진단점수'),
            dataIndex: 'score',
            key: 'score',
            align: 'center',
            width: '100px',
            render: (_, record) => {
                return <ScoreNum>{score(record)}점</ScoreNum>;
            },
        },
        {
            title: t('비고'),
            label: t('비고'),
            dataIndex: 'remark1',
            key: 'remark1',
            align: 'center',
            width: '200px',
            // eslint-disable-next-line react/display-name
            render: (text, record) => {
                return isReadOnly ? (
                    <Text>{text}</Text>
                ) : (
                    <StyledTextArea
                        allowClear
                        autoSize={{ minRows: 3, maxRows: 8 }}
                        defaultValue={text}
                        onBlur={event => {
                            formHandleChange(
                                event.target.value,
                                record,
                                'remark1'
                            );
                        }}
                    />
                );
            },
        },
    ];

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setManualData([]);
        setIsModalVisible(false);
    };
    
    const tableRef = useRef(null);


    const excelColumns = [
        {
            title: t('구분'),
            label: t('구분'),
            dataIndex: 'category_name',
            key: 'category_name',

        },
        {
            title: t('항목코드'),
            label: t('항목코드'),
            dataIndex: 'check_code',
            key: 'check_code',
            width: 100,

        },
        {
            title: t('항목명'),
            label: t('항목명'),
            dataIndex: 'check_desc',
            key: 'check_desc',
            width: 200,
        },
        {
            title: t('중요도'),
            label: t('중요도'),
            dataIndex: 'weight',
            key: 'weight',
            width: 100,
        },

        {
            title: t('진단현황'),
            label: t('진단현황'),
            dataIndex: 'result1_status',
            key: 'result1_status',
            width: 500,
        },
        {
            title: t('진단결과'),
            label: t('진단결과'),
            dataIndex: 'result1',
            key: 'result1',
            
        },
        {
            title: t('진단점수'),
            label: t('진단점수'),
            dataIndex: 'score',
            key: 'score'
            
        },
        {
            title: t('비고'),
            label: t('비고'),
            dataIndex: 'remark1',
            key: 'remark1',
        },
    ];

    const handleExcelDownload = () => {
        const excel = new Excel();
        excel
          .addSheet("Sheet1")
          .addColumns(excelColumns)
          .addDataSource(manualData, {
            str2Percent: true
          })
          .saveAs(`${selectAssetData?.asset_name}_result.xlsx`);
      };

    return (
        <Modal
            width="90%"
            title={t("수동진단")}
            open={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            maskClosable={false}
            keyboard={false}
            footer={false}
        >
            <ButtonArea>
                <Space size="small">
                    <Button type="default" onClick={handleExcelDownload}>
                    {t("엑셀 다운로드")}
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => apiCall(setTechManualStep1, t('진단저장'))}
                    >
                        {t("진단저장")}
                    </Button>
                </Space>
            </ButtonArea>
            <Container>
                <Table
                    ref={tableRef}
                    columns={columns}
                    dataSource={manualData}
                    pagination={false}
                    size={defaultInfo.table_size}
                    scroll={{ y: '60vh' }}
                    // loading={loading}
                />
            </Container>
        </Modal>
    );
};

export default DesktopDiagnosisTechnicalTableModal;
