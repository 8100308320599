import { Breadcrumb } from 'antd';
import { IBreadCrumbProps } from './type';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { HomeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { ThemeContext } from '@/common/theme-provider';

import { useTranslation } from 'react-i18next';

const StyledBreadcrumb = styled(Breadcrumb)`
  span {
    color: ${props => props.theme.font.color};
  }
  li:last-child {
    font-weight: 700;
  }
`;

const DesktopBreadcrumb: React.FunctionComponent<IBreadCrumbProps> = ({
  breadCrumb,
}) => {
  const { t } = useTranslation();

  const { isDarkMode } = useContext(ThemeContext);

  return (
    <StyledBreadcrumb>
      <Breadcrumb.Item>
        <Link to="/">
          <HomeOutlined style={{ color: isDarkMode ? '#CCCCCC' : '#292a2d' }} />
        </Link>
      </Breadcrumb.Item>
      {breadCrumb &&
        breadCrumb.map(item => {
          if (item !== '') {
            return (
              <Breadcrumb.Item key={item}>
                <span>{t(item)}</span>
              </Breadcrumb.Item>
            );
          }
          return <React.Fragment key={item}></React.Fragment>;
        })}
    </StyledBreadcrumb>
  );
};

export default DesktopBreadcrumb;
