import { Button, Col, Form, Input, Row, Select } from 'antd';
import { IOrgMemberData, IRoleData } from '../type';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopOrgMembersManagerModalTableProps {
  id: number;
  datas: IOrgMemberData;
  orgArrList: IRoleData[];
}

const StyledFormItem = styled(Form.Item)`
  &.ant-form-item {
    margin-bottom: 5px !important;
    padding-right: 10% !important;
  }
`;

const DesktopOrgMembersManagerModalTable: React.FunctionComponent<
  IDesktopOrgMembersManagerModalTableProps
> = ({ id, orgArrList }) => {
  const { t } = useTranslation();
  
  return (
    <>
      <Form.List name="role">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => {
              return (
                <Row key={index}>
                  <Col span={10}>
                    <StyledFormItem
                      name={[name, 'organ_id']}
                      {...restField}
                      rules={[
                        { required: true, message: t('조직명을 선택해 주세요') },
                      ]}
                    >
                      <Select style={{ width: '100%' }} placeholder={t("조직명")}>
                        {orgArrList.map(orgItem => (
                          <Option value={orgItem.id} key={orgItem.id}>
                            {orgItem.organ_name}
                          </Option>
                        ))}
                      </Select>
                    </StyledFormItem>
                  </Col>
                  <Col span={10}>
                    <StyledFormItem name={[name, 'roll_desc']} {...restField}>
                      <Input placeholder={t("역할")} />
                    </StyledFormItem>
                  </Col>
                  {index > 0 && (
                    <Col span={2}>
                      <Button
                        type="text"
                        icon={<MinusCircleOutlined />}
                        onClick={() => remove(name)}
                      />
                    </Col>
                  )}
                  {index === fields.length - 1 && (
                    <Col span={2}>
                      <Button
                        type="text"
                        icon={<PlusCircleOutlined />}
                        onClick={() => add({ id: null, member_id: id })}
                      />
                    </Col>
                  )}
                </Row>
              );
            })}
          </>
        )}
      </Form.List>
    </>
  );
};

export default DesktopOrgMembersManagerModalTable;
