import { Drawer, Space } from 'antd';
import React, { useCallback, useState } from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { CloseOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { IMenuFoldingProps } from './type';
import MenuFolding from './menu-folding';
import MenuList from './menu/menu-list';
import Profile from '@/common/components/profile';
import ThemeSwitch from '@/common/components/theme';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { defaultColor } from '@/styles/color';
import Modal from '@/components/modal';

type IMobileSiderProps = IMenuFoldingProps & WrappedComponentProps;

const StyledDrawer = styled(Drawer)`
    .ant-drawer-content {
        background: ${defaultColor.white};
        .ant-drawer-header {
            background: transparent;
            padding: 16px 10px;
            border-color: ${defaultColor.lightGray};
        }
        .ant-drawer-body {
            padding: 10px 0;
            overflow-x: hidden;
        }
        .ant-drawer-close {
            top: 3px;
            font-size: 20px;
            color: ${defaultColor.gray};
        }
        .ant-drawer-footer {
            text-align: right;
            border-color: ${defaultColor.lightGray};
        }
    }
`;

const FooterContainer = styled.div`
    position: relative;
`;

const UserArea = styled.div`
    position: absolute;
    left: 0;
    top: 3px;
    font-size: 12px;
`;

const AncherElement = styled.a`
    color: ${defaultColor.darkGray};
`;

const MobileSider: React.FunctionComponent<IMobileSiderProps> = ({
    collapsed,
    HeaderToggle,
    intl,
}) => {
    const [visible, setVisible] = useState(false);
    const navigate = useNavigate();

    const pageMove = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (event: any) => {
            event.preventDefault();
            const href = event.target.getAttribute('href');

            navigate(href);
        },
        [navigate]
    );

    const logOutHandler = useCallback(() => {
        setVisible(false);
    }, []);

    const footerElement = (
        <FooterContainer>
            <ThemeSwitch />
            <UserArea>
                <Space size={10}>
                    <AncherElement href="/Mypage" onClick={pageMove}>
                        <FormattedMessage id="myPage" />
                    </AncherElement>
                    <AncherElement onClick={() => setVisible(true)}>
                        <FormattedMessage id="logout" />
                    </AncherElement>
                </Space>
            </UserArea>
        </FooterContainer>
    );

    return (
        <>
            <MenuFolding collapsed={collapsed} HeaderToggle={HeaderToggle} />
            <StyledDrawer
                title={<Profile />}
                footer={footerElement}
                placement="left"
                closable={true}
                onClose={HeaderToggle}
                visible={!collapsed}
                closeIcon={<CloseOutlined />}
            >
                <MenuList />
            </StyledDrawer>
            <Modal
                open={visible}
                onOk={logOutHandler}
                onCancel={() => setVisible(false)}
                okText={intl.formatMessage({ id: 'confirm' })}
                cancelText={intl.formatMessage({ id: 'cancle' })}
                width={300}
                centered
            >
                {intl.formatMessage({ id: 'logoutMsg' })}
            </Modal>
        </>
    );
};

export default injectIntl(MobileSider);
