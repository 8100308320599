import React, { useContext } from 'react';

import { Navigate } from 'react-router-dom';
import { ProjectContext } from './project-provider';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IViewProviderProps {
  id: string;
}

const ViewProvider: React.FunctionComponent<IViewProviderProps> = ({
  children,
  id,
}) => {
  const { getPageInfo } = useContext(ProjectContext);
  const { perm_view } = getPageInfo(id);

  if (!perm_view) {
    alert('접근 불가능한 페이지입니다. 담당자에게 문의하세요. ');
    return <Navigate to="/" />;
  }

  // return <>{children}</>;
  return React.cloneElement(children as React.ReactElement, { id });
};

export default ViewProvider;
