import { Button, Form, Input, Select, Space } from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";

import styled from "styled-components";
import {
  IPaginationProps,
  ITechPlanParamsProps,
  ITechProtectionDataSource,
} from "./type";
import { DownloadOutlined } from "@ant-design/icons";
import { IAdminMenuPermissionProps } from "@/common/type";

import { openNotification } from "@/common/util";
import api from "@/api/api";
import { ProjectContext } from "@/common/project-provider";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const { Option } = Select;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopDangersTechProtectionHeaderProps {
  standardDateList: string[];
  setSelectDate: React.Dispatch<React.SetStateAction<string>>;
  riskTechApi: (
    page: IPaginationProps,
    ym: string,
    value: ITechProtectionDataSource
  ) => void;
  pagination: IPaginationProps;
  pageInfo: IAdminMenuPermissionProps;
  isEdit: boolean;
  dataSource: ITechProtectionDataSource | null;
  selectDate: string;
  searchParam: ITechPlanParamsProps;
  setSearchParam: React.Dispatch<React.SetStateAction<ITechPlanParamsProps>>;
}

const Container = styled.div`
  text-align: left;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid ${(props) => props.theme.layout.searchBorderColor};
  border-radius: 10px;
  overflow-x: auto;
  word-break: keep-all;
`;
const Label = styled.label`
  font-size: 15px;
  margin-left: 15px;
`;
const StyledSelect = styled(Select)`
  text-align: left;
`;
const StyledInput = styled(Input)`
  width: 130px;
`;
const ButtonArea = styled.div`
  position: sticky;
  top: 70px;
  text-align: right;
  z-index: 6;
  /* display: inline-block; */
  float: right;
  margin-bottom: 20px;
  &.fixed {
  }
`;
const DownloadButton = styled(Button)`
  a {
    color: black;
    display: inline-block;
    padding-left: 10px;
  }
`;

const ResetButton = styled(Form.Item)`
  padding-right: 20px;
`;

const setRiskTechPlan = async (props) => {
  const result = await api.setRiskTechPlan({
    ...props,
  });

  return result.data;
};

const getTechProtectionExcelDownload = async (props) => {
  const result = await api.getTechProtectionExcelDownload({
    ...props,
  });

  return result;
};

const DesktopDangersTechProtectionHeader: React.FunctionComponent<
  IDesktopDangersTechProtectionHeaderProps
> = ({
  standardDateList,
  setSelectDate,
  riskTechApi,
  pagination,
  pageInfo,
  isEdit,
  dataSource,
  selectDate,
  searchParam,
  setSearchParam,
}) => {
  const { t } = useTranslation();
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const { projectId } = useContext(ProjectContext);
  const [form] = Form.useForm();

  useEffect(() => {
    if (selectDate !== "") {
      setSearchParam((prev) => ({ ...prev, ym: selectDate }));
    }
    form.resetFields();
  }, [selectDate, setSearchParam, form]);

  const onFinish = useCallback(
    (value) => {
      setSelectDate(value.ym);
      riskTechApi({ ...pagination, current: 1 }, value.ym, value);
      setSearchParam({ ...value });
    },
    [pagination, riskTechApi, setSearchParam, setSelectDate]
  );

  const resetHandler = useCallback(() => {
    form.resetFields();
  }, [form]);

  const handlerProtectionSave = useCallback(() => {
    if (dataSource.content !== null) {
      setIsSaveLoading(true);
      setRiskTechPlan({
        project_id: projectId,
        ym: selectDate,
        size: pagination.pageSize,
        page: pagination.current,
        datas: dataSource.content,
      }).then(() => {
        const notiText = {
          description: t("완료"),
        };

        openNotification(notiText);
        setIsSaveLoading(false);
      });
    }
  }, [dataSource, pagination, projectId, selectDate, t]);

  const excelDownload = useCallback(() => {
    getTechProtectionExcelDownload({
      project_id: projectId,
      ...searchParam,
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `risk_implementation_action_plan.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
  }, [projectId, searchParam]);

  return (
    <>
      <Container>
        <Form
          name="basic"
          onFinish={onFinish}
          autoComplete="off"
          form={form}
          initialValues={{
            ym: standardDateList[0],
          }}
        >
          <Space>
            {standardDateList.length > 0 ? (
              <>
                <Label>{t("기준년월")}</Label>
                <Form.Item name="ym">
                  <Select
                    defaultValue={standardDateList[0]}
                    style={{ width: "170px" }}
                    placeholder={t("기준년월")}
                    // onChange={selectHandler}
                  >
                    {standardDateList.map((item) => (
                      <Option value={item} key={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            ) : (
              <Link to="/Dangers/Risk">
                <Button>{t("기준년월 설정")}</Button>
              </Link>
            )}
            <Label>{t("그룹")}</Label>
            <Form.Item name="asset_group">
              <StyledInput placeholder={t("그룹")} style={{ width: "110px" }} />
            </Form.Item>
            <Label>{t("자산")}</Label>
            <Form.Item name="keyword">
              <StyledInput placeholder={t("자산")} style={{ width: "150px" }} />
            </Form.Item>
            <Label>{t("항목코드")}</Label>
            <Form.Item name="check_code">
              <StyledInput placeholder={t("항목코드")} style={{ width: "100px" }} />
            </Form.Item>
            <Label>{t("위험값")}</Label>
            <Form.Item name="risk_value">
              <StyledSelect
                placeholder={t("위험값")}
                defaultValue=""
                style={{ width: "80px" }}
              >
                <Option value="3" key="risk_3">
                  3
                </Option>
                <Option value="4" key="risk_4">
                  4
                </Option>
                <Option value="5" key="risk_5">
                  5
                </Option>
                <Option value="6" key="risk_6">
                  6
                </Option>
                <Option value="7" key="risk_7">
                  7
                </Option>
                <Option value="8" key="risk_8">
                  8
                </Option>
                <Option value="9" key="risk_9">
                  9
                </Option>
              </StyledSelect>
            </Form.Item>
            <Label>{t("조치현황")}</Label>
            <Form.Item name="status">
              <StyledSelect
                placeholder={t("조치현황")}
                defaultValue=""
                style={{ width: "110px" }}
              >
                <Option value={t("미조치")} key="status_1">
                {t("미조치")}
                </Option>
                <Option value={t("위험수용")} key="status_2">
                  {t("위험수용")}
                </Option>
                <Option value={t("조치예정")} key="status_3">
                  {t("조치예정")}
                </Option>
                <Option value={t("조치완료")} key="status_4">
                  {t("조치완료")}
                </Option>
              </StyledSelect>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
              {t("조회")}
              </Button>
            </Form.Item>
            <ResetButton>
              <Button onClick={resetHandler}>{t("초기화")}</Button>
            </ResetButton>
          </Space>
        </Form>
      </Container>
      {pageInfo.perm_write && (
        <ButtonArea>
          <Space size="small">
            <Button
              type="primary"
              onClick={() => handlerProtectionSave()}
              disabled={!isEdit}
              loading={isSaveLoading}
            >
              {t("저장")}
            </Button>
            <DownloadButton
              icon={<DownloadOutlined />}
              disabled={dataSource?.content.length === 0}
              onClick={() => excelDownload()}
            >
              {t("엑셀 다운로드")}
            </DownloadButton>
          </Space>
        </ButtonArea>
      )}
    </>
  );
};

export default DesktopDangersTechProtectionHeader;
