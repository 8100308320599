import { Button, Spin, UploadProps } from 'antd';
import { ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { IContentProps } from './type';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import { openNotification } from '@/common/util';
import styled from 'styled-components';
import Dragger from 'antd/lib/upload/Dragger';
import Modal from '@/components/modal';
import { IPaginationProps } from '@/panel/Assets/Evaluation/type';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopDiagnosisTechnicalUploadModalProps {
    isUploadModalVisible: boolean;
    setIsUploadModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    selectRecord?: IContentProps;
    selectsRecord: IContentProps[];
    selectGroupId: number;
    selectGroupName: string;
    isBundle: boolean;
    pagination: IPaginationProps;
    fetch: (
        pagination: IPaginationProps,
        keyword: string,
        cb: () => void
    ) => void;
    searchKeyword: string;
    setIsTableLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledUploadArea = styled.div`
    padding-bottom: 20px;
    .ant-upload-list {
        display: none;
    }
    .ant-upload-text {
        color: ${props => props.theme.font.color}!important;
    }
`;

const Title = styled.h4`
    font-size: 18px;
    margin: 0 0 10px 0;
`;
const Caution = styled.p`
    color: #983333;
    margin-top: -10px;
    margin-bottom: 15px;
`;
const CloseBtnArea = styled.div`
    text-align: right;
`;
const InfoArea = styled.div`
    padding-left: 23px;
    margin-bottom: 20px;
`;
const InfoText = styled.p`
    margin-bottom: 5px;
`;

const setCertTechUpload = async ({
    formData,
    project_id,
    group_name,
    group_id,
    asset_id,
}) => {
    const result = await api.setCertTechUpload({
        formData,
        project_id,
        group_name,
        group_id,
        asset_id,
    });

    return result.data;
};

const DesktopDiagnosisTechnicalUploadModal: React.FunctionComponent<
    IDesktopDiagnosisTechnicalUploadModalProps
> = ({
    setIsUploadModalVisible,
    isUploadModalVisible,
    selectRecord = null,
    selectsRecord = [],
    isBundle,
    selectGroupId,
    selectGroupName,
    fetch,
    pagination,
    searchKeyword,
    setIsTableLoading,
}) => {
    const { t } = useTranslation();
    const { projectId } = useContext(ProjectContext);
    const [loading, setLoading] = useState(false);

    const bundleIdArray = useMemo(
        () => selectsRecord.map(item => item.id).sort(),
        [selectsRecord]
    );

    const handleChange = useCallback(
        info => {
            if (info.file.status === 'uploading') {
                return;
            }
            setLoading(true);
            const formData = new FormData();

            formData.append('file', info.file.originFileObj);

            setCertTechUpload({
                formData,
                project_id: projectId,
                group_name: selectGroupName,
                group_id: selectGroupId,
                asset_id: isBundle
                    ? bundleIdArray
                    : selectRecord !== null
                    ? selectRecord.id
                    : 0,
            }).then(() => {
                setLoading(false);
                onCancelHandler();
                // message.info('셀별로 key 값을 설정해 주세요	');
                // setFieldsData(data);
                const notiText = {
                    description:
                        t('완료'),
                };

                openNotification(notiText);
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            projectId,
            selectGroupName,
            selectGroupId,
            isBundle,
            bundleIdArray,
            selectRecord,
            t,
        ]
    );

    const props: UploadProps = {
        name: 'file',
        multiple: true,
        onChange(info) {
            // const { status } = info.file;
            handleChange(info);
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const onCancelHandler = useCallback(() => {
        setIsUploadModalVisible(false);
        setIsTableLoading(true);
        fetch(pagination, searchKeyword, () => {
            setIsTableLoading(false);
        });
    }, [
        fetch,
        pagination,
        searchKeyword,
        setIsTableLoading,
        setIsUploadModalVisible,
    ]);

    return (
        <Modal
            title={t("진단결과 업로드")}
            open={isUploadModalVisible}
            maskClosable={false}
            keyboard={false}
            footer={false}
            onCancel={() => setIsUploadModalVisible(false)}
        >
            <Spin spinning={loading} delay={500}>
                {selectRecord !== null && !isBundle && (
                    <>
                        <Title>
                            <ExclamationCircleOutlined /> {t("진단 정보")}
                        </Title>
                        <InfoArea>
                            <InfoText>{t("구분")} : {selectRecord.os}</InfoText>
                            <InfoText>
                                {t("자산코드")} : {selectRecord.asset_code}
                            </InfoText>
                            <InfoText>
                                {t("자산명")} : {selectRecord.asset_name}
                            </InfoText>
                        </InfoArea>
                    </>
                )}
                <Title>
                    <ExclamationCircleOutlined /> {t("진단결과 업로드")}
                </Title>
                <Caution>
                    ({t("데이터 양에 따라 많은 시간이 소요될 수 있습니다.")})
                </Caution>
                <StyledUploadArea>
                    {/* <Button icon={<UploadOutlined />}>엑셀 업로드</Button> */}
                    <Dragger {...props}>
                        <p className="ant-upload-drag-icon">
                            <UploadOutlined />
                        </p>
                        <p className="ant-upload-text">
                        {t("클릭 또는 마우스로 파일을 끌어오세요.")}
                        </p>
                    </Dragger>
                </StyledUploadArea>
                <CloseBtnArea>
                    <Button onClick={() => setIsUploadModalVisible(false)}>
                    {t("닫기")}
                    </Button>
                </CloseBtnArea>
            </Spin>
        </Modal>
    );
};

export default DesktopDiagnosisTechnicalUploadModal;
