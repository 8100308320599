import api from '@/api/api';
import { ProjectContext } from '@/common/project-provider';
import { Button, Form, Input, Select, Space } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import styled from 'styled-components';
import {
  IPaginationProps,
  ITechEvaluationDataSource,
  ITechPageParamsProps,
} from './type';
import { DownloadOutlined } from '@ant-design/icons';
import { IAdminMenuPermissionProps } from '@/common/type';
import Modal from '@/components/modal';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopDangersTechEvaluationContentHeaderProps {
  groupId: number;
  groupName: string;
  dataSource:ITechEvaluationDataSource;
  setDataSource: React.Dispatch<
    React.SetStateAction<ITechEvaluationDataSource | null>
  >;
  pagination: IPaginationProps;
  setPagination: React.Dispatch<React.SetStateAction<IPaginationProps>>;
  pageInfo: IAdminMenuPermissionProps;
  riskTechAPI: any;
  searchParam: ITechPageParamsProps;
  setSearchParam: React.Dispatch<React.SetStateAction<ITechPageParamsProps>>;
}

const Container = styled.div`
  text-align: left;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid ${props => props.theme.layout.searchBorderColor};
  margin-top: 20px;
  border-radius: 10px;
  overflow-x: auto;
  word-break: keep-all;
`;
const Label = styled.label`
  font-size: 15px;
  margin-left: 15px;
`;
const StyledSelect = styled(Select)`
  text-align: left;
`;
const StyledInput = styled(Input)`
  width: 130px;
`;
const ButtonArea = styled.div`
  position: sticky;
  top: 70px;
  text-align: right;
  z-index: 6;
  /* display: inline-block; */
  float: right;
  margin-bottom: 20px;
  &.fixed {
  }
`;
const DownloadButton = styled(Button)`
  a {
    color: black;
    display: inline-block;
    padding-left: 10px;
  }
`;
const ResetButton = styled(Form.Item)`
  padding-right: 20px;
`;

const getAssetGroupFields = async ({ projectId, id }) => {
  const result = await api.getAssetGroupFields({ project_id: projectId, id });

  return result.data;
};

const getTechEvaluationExcelDownload = async props => {
  const result = await api.getTechEvaluationExcelDownload({ ...props });

  return result;
};

const DesktopDangersTechEvaluationContentHeader: React.FunctionComponent<
  IDesktopDangersTechEvaluationContentHeaderProps
> = ({
  groupId,
  groupName,
  pagination,
  setPagination,
  setDataSource,
  dataSource,
  pageInfo,
  riskTechAPI,
  searchParam,
  setSearchParam,
}) => {
  const { t } = useTranslation();
  const { projectId } = useContext(ProjectContext);
  const [form] = Form.useForm();
  const [filterColumnsData, setFilterColumnsData] = useState([]);
  const [open, setOpen] = useState(false);

  const iframePrivacyPart = () => {
    return {
      __html:
      '<iframe src="/static/document/tech.html" width="100%" height="700px"></iframe>,'
    };
  };

  const onFinish = useCallback(
    ({ col, keyword, asset_group, check_code, check_desc, risk_value }) => {
      riskTechAPI({
        project_id: projectId,
        group_id: groupId,
        col,
        keyword,
        asset_group,
        check_code,
        check_desc,
        risk_value,
        size: pagination.pageSize,
        page: 1,
      }).then(datas => {
        setSearchParam({
          col,
          keyword,
          asset_group,
          check_code,
          check_desc,
          risk_value,
        });
        setDataSource({
          ...datas,
          content: [
            ...datas.content.map((item, index) => ({ ...item, key: index })),
          ],
        });

        setPagination(prev => {
          return {
            ...prev,
            total: datas.total_elements,
            current: 1,
          };
        });
      });
    },
    [
      groupId,
      pagination,
      projectId,
      riskTechAPI,
      setDataSource,
      setPagination,
      setSearchParam,
    ]
  );

  const resetHandler = useCallback(() => {
    form.resetFields();
  }, [form]);

  useEffect(() => {
    getAssetGroupFields({ projectId, id: groupId }).then(({ fields }) => {
      setFilterColumnsData(fields.filter(column => column.id !== null));
    });
  }, [groupId, projectId]);

  const excelDownload = useCallback(
    (id, name) => {
      console.log(id, name);

      getTechEvaluationExcelDownload({
        project_id: projectId,
        group_id: id,
        ...searchParam,
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${name}_risk_analysis_result.xlsx`);
        document.body.appendChild(link);
        link.click();
      });
    },
    [projectId, searchParam]
  );

  return (
    <>
      <Container>
        <Form name="basic" onFinish={onFinish} autoComplete="off" form={form}>
          <Space>
            {filterColumnsData.length > 0 ? (
              <>
                <Label>{t("구분")}</Label>
                <Form.Item name="col" initialValue={filterColumnsData[0].id}>
                  <StyledSelect style={{ width: 150 }}>
                    {filterColumnsData.map(column => (
                      <Option value={column.id} key={column.object_name}>
                        {column.field_name}
                      </Option>
                    ))}
                  </StyledSelect>
                </Form.Item>
                <Form.Item name="keyword">
                  <StyledInput placeholder="" />
                </Form.Item>
              </>
            ) : (
              <Label>{t("데이터가 없습니다.")}</Label>
            )}
            <Label>{t("항목코드")}</Label>
            <Form.Item name="check_code">
              <StyledInput placeholder={t("항목코드")} />
            </Form.Item>
            <Label>{t("세부항목")}</Label>
            <Form.Item name="check_desc">
              <StyledInput placeholder="세부항목" />
            </Form.Item>
            <Label>{t("위험값")}</Label>
            <Form.Item name="risk_value">
              <StyledSelect
                placeholder={t("위험값")}
                defaultValue=""
                style={{ width: 150 }}
              >
                <Option value="3" key="risk_3">
                  3
                </Option>
                <Option value="4" key="risk_4">
                  4
                </Option>
                <Option value="5" key="risk_5">
                  5
                </Option>
                <Option value="6" key="risk_6">
                  6
                </Option>
                <Option value="7" key="risk_7">
                  7
                </Option>
                <Option value="8" key="risk_8">
                  8
                </Option>
                <Option value="9" key="risk_9">
                  9
                </Option>
              </StyledSelect>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
              {t("조회")}
              </Button>
            </Form.Item>
            <ResetButton>
              <Button onClick={resetHandler}>{t("초기화")}</Button>
            </ResetButton>
          </Space>
        </Form>
      </Container>
      <ButtonArea>
          <Space size="small">
          <Button type="primary" onClick={() => setOpen(true)}>{t("산출기준")}</Button>
      {pageInfo.perm_write && (
            <DownloadButton
              icon={<DownloadOutlined />}
              onClick={() => excelDownload(groupId, groupName)}
              disabled={dataSource?.content.length===0}
            >
              {t("엑셀 다운로드")}
            </DownloadButton>
      )}
      </Space>
        </ButtonArea>
      <Modal
        title={t("기술위험평가 산출기준")}
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={800}
        footer={<Button onClick={() => setOpen(false)}>{t("닫기")}</Button>}
      >
        <div dangerouslySetInnerHTML={iframePrivacyPart()} />
      </Modal>
    </>
  );
};

export default DesktopDangersTechEvaluationContentHeader;