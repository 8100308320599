import { Button, Form, Input, Popconfirm, Radio, Select, Space } from "antd";
import React, { useContext, useMemo } from "react";

import { IAccountDatas } from "./type";
import { ICodeData } from "@/common/type";
import IMask from "imask";
import { MaskedInput } from "antd-mask-input";
import { ProjectContext } from "@/common/project-provider";
import api from "@/api/api";
import { openNotification } from "@/common/util";
import styled from "styled-components";
import Modal from "@/components/modal";
import { useTranslation } from 'react-i18next';

const { Option } = Select;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopAdminAccountTableModalProps {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  selectData: IAccountDatas;
  codeList: ICodeData[];
  organList: ICodeData[];
  isAccountPage: boolean;
}
const Line = styled.div`
  background: ${(props) => props.theme.layout.searchBorderColor};
  height: 1px;
  margin-bottom: 20px;
`;
const FormContainer = styled.div`
  position: relative;
  #basic_userhp.ant-input {
    background: ${(props) => props.theme.layout.tabBg};
    color: ${(props) => props.theme.font.color};
    border: 1px solid ${(props) => props.theme.layout.tabBorderColor};
  }
`;
const TextGuide = styled.p`
  position: absolute;
  left: 160px;
  top: 34px;
  font-size: 12px;
  color: #949494;
  margin: 0;
  padding: 0;
`;

const StyledMaskedInput = styled(MaskedInput)`
  border-radius: 3px;
  &:focus,
  &:active {
    border: 1px solid #6887ad !important;
    box-shadow: 0 0 0 2px rgb(11 55 83 / 18%);
    border-inline-end-width: 1px;
    outline: 0;
  }
  &:hover {
    border: 1px solid #6887ad !important;
    border-inline-end-width: 1px;
  }
`;

const setUsers = async (userData) => {
  const result = await api.setUsers(userData);

  return result.data;
};
const setUserProfile = async (userData) => {
  const result = await api.setUserProfile(userData);

  return result.data;
};

const deleteUser = async (username) => {
  const result = await api.deleteUser(username);

  return result.data;
};

const resetPassword = async ({ userData }) => {
  const result = await api.resetPassword(userData);

  return result.data;
};

const setTOTPkey = async ({ userData }) => {
  const result = await api.resetTOTPKey(userData);

  return result.data;
};

const DesktopAdminAccountTableModal: React.FunctionComponent<
  IDesktopAdminAccountTableModalProps
> = ({
  isModalVisible,
  setIsModalVisible,
  selectData,
  codeList,
  organList,
  isAccountPage,
}) => {
  const { t } = useTranslation();
  
  const { setUserInfo } = useContext(ProjectContext);
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const fields = useMemo(() => {
    const fieldDatas = [];

    if (selectData !== null) {
      for (const [key, value] of Object.entries(selectData)) {
        // console.log(`${key} : ${value}`);
        fieldDatas.push({
          name: [key],
          value: value,
        });
      }
    }

    return fieldDatas;
  }, [selectData]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = (value) => {
    if (isAccountPage) {
      setUsers(value).then(() => {
        const notiText = {
          description: t("완료"),
        };

        openNotification(notiText);
        setIsModalVisible(false);
      });
    } else {
      setUserProfile(value).then((profile) => {
        const notiText = {
          description: t("완료"),
        };

        setUserInfo((prev) => ({
          ...prev,
          profile: { ...prev.profile, ...profile },
        }));
        openNotification(notiText);
        setIsModalVisible(false);
      });
    }
  };

  const resetTOTPKey = () => {
    setTOTPkey({ userData: selectData }).then(() => {
      const notiText = {
        description: t("생성된 TOTP Key 정보가 이메일로 발송되었습니다."),
      };

      openNotification(notiText);
      setIsModalVisible(false);
    });
  };

  const resetUserPassword = () => {
    resetPassword({ userData: selectData }).then(() => {
      const notiText = {
        description: t("새로운 패스워드가 이메일로 발송되었습니다."),
      };

      openNotification(notiText);
      setIsModalVisible(false);
    });
  };

  const deleteAccount = () => {
    deleteUser(selectData.username).then(() => {
      const notiText = {
        description: t("계정 삭제 완료"),
      };

      openNotification(notiText);
      setIsModalVisible(false);
    });
  };

  return (
    <Modal
      title={t("계정 관리")}
      open={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      width={600}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        fields={fields}
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item label={t('아이디')} name="username">
          <Input readOnly disabled style={{ width: 220 }} />
        </Form.Item>

        {!isAccountPage && (
          <>
            <Form.Item
              label={t('패스워드')}
              name="password"
              rules={[
                { required: true, message: t('패스워드msg') },
                { min: 8, message: t('패스워드는 최소 8자 이상이어야 합니다.') },
                ({ getFieldValue }) => {
                  return {
                    validator(_, value) {
                      var num = value.search(/[0-9]/g);
                      var eng = value.search(/[a-z]/gi);
                      var spe = value.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi);

                      if (getFieldValue("username") === value) {
                        return Promise.reject(
                          new Error(t('아이디를 패스워드로 사용하실 수 없습니다.'))
                        );
                      }
                      if (value.search(/\s/) !== -1) {
                        return Promise.reject(
                          new Error(t('패스워드는 공백 없이 입력해주세요.'))
                        );
                      }
                      if (num < 0 || eng < 0 || spe < 0) {
                        return Promise.reject(
                          new Error(
                            t('영문, 숫자, 특수문자를 혼합하여 입력해주세요.')
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  };
                },
              ]}
            >
              <Input.Password style={{ width: 220 }} />
            </Form.Item>

            <Form.Item
              name="confirm"
              label={t('패스워드확인')}
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t('패스워드를 확인해주세요.'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t('패스워드가 일치하지 않습니다.'))
                    );
                  },
                }),
              ]}
            >
              <Input.Password style={{ width: 220 }} />
            </Form.Item>
          </>
        )}

        <Line></Line>

        <Form.Item
          label={t("이름")}
          name="fullname"
          rules={[{ required: true, message: t('이름을 입력해주세요.') }]}
        >
          <Input readOnly={!isAccountPage} disabled={!isAccountPage} style={{ width: 220 }} />
        </Form.Item>

        <Form.Item
          label={t("이메일")}
          name="email"
          rules={[{ required: true, message: t('이메일을 입력해주세요.') }]}
        >
          <Input style={{ width: 220 }} />
        </Form.Item>
        <FormContainer>
          <Form.Item
            label={t("연락처")}
            name="userhp"
            rules={[{ required: true, message: t('연락처를 입력해주세요.') }]}
          >
            <StyledMaskedInput
             style={{ width: 220 }}
              mask={[
                {
                  mask: "000-0000-0000",
                  blocks: {
                    RGB: {
                      mask: IMask.MaskedRange,
                      from: 0,
                      to: 9999,
                    },
                  },
                },
              ]}
            />
          </Form.Item>
          <TextGuide>{t("숫자만 입력해주세요.")}</TextGuide>
        </FormContainer>

        {organList.length > 0 && (
          <Form.Item
            label={t("부서")}
            name="dept_cd"
            rules={[{ required: false, message: t('부서를 선택해주세요.') }]}
          >
            <Select style={{ width: 220 }} onChange={() => false}>
              {organList.map((item) => (
                <Option key={item.item_cd} value={item.item_cd}>
                  {item.item_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {codeList.length > 0 && (
          <Form.Item
            label={t("직급")}
            name="rank_cd"
            rules={[{ required: false, message: t('직급을 선택해주세요.') }]}
          >
            <Select style={{ width: 220 }} onChange={() => false}>
              {codeList.map((item) => (
                <Option key={item.item_cd} value={item.item_cd}>
                  {item.item_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {isAccountPage && (
          <Form.Item label={t('승인여부')} name="appr_yn">
            <Radio.Group>
              <Space direction="horizontal">
                <Radio value={true}>{t('승인')}</Radio>
                <Radio value={false}>{t('미승인')}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        )}

        <div style={{ textAlign: "center", paddingTop: "30px" }}>
          <Space>
            {isAccountPage && (
              <>
                <Popconfirm
                  title={t("계정을 삭제 하시겠습니까?")}
                  onConfirm={deleteAccount}
                  // onCancel={cancel}
                  okText={t("삭제")}
                  cancelText={t("취소")}
                >
                  <Button danger>{t("계정삭제")}</Button>
                </Popconfirm>
                <Popconfirm
                  title={t("TOTP Key를 생성 하시겠습니까?")}
                  onConfirm={resetTOTPKey}
                  // onCancel={cancel}
                  okText="TOTP Key"
                  cancelText={t("취소")}
                >
                  <Button>TOTP Key {t("생성")}</Button>
                </Popconfirm>
                <Popconfirm
                  title={t("패스워드를 초기화 하시겠습니까?")}
                  onConfirm={resetUserPassword}
                  // onCancel={cancel}
                  okText={t("초기화")}
                  cancelText={t("취소")}
                >
                  <Button>{t("패스워드 초기화")}</Button>
                </Popconfirm>
              </>
            )}
            <Button type="primary" htmlType="submit">
            {t("저장")}
            </Button>
          </Space>
          <div style={{ padding: "10px" }}>
            * {t("2단계 인증을 사용하는 경우 TOPT Key를 생성해야 합니다.")}
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default DesktopAdminAccountTableModal;
