import React, { useMemo, useState } from 'react';

import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import en from '@/locale/en';
import enUS from 'antd/lib/locale/en_US';
import ko from '@/locale/ko';
import koKR from 'antd/lib/locale/ko_KR';
import jp from '@/locale/jp';
import jaJP from 'antd/lib/locale/ja_JP';
import moment from 'moment';

interface ILocaleProviderProps {
  children: React.ReactNode;
}

moment.locale('ko');

interface ILocaleContextProps {
  setLocale: React.Dispatch<React.SetStateAction<string>>;
}

export const LocaleContext = React.createContext<ILocaleContextProps>({
  setLocale: () => ''
});

const LocaleProvider: React.FunctionComponent<ILocaleProviderProps> = ({
  children
}) => {
  const [locale, setLocale] = useState('en');
  const value = useMemo(() => ({ setLocale }), [setLocale]);

  return (
    <LocaleContext.Provider value={value}>
      <ConfigProvider locale={locale === 'ko' ? koKR : locale === 'jp' ? jaJP : enUS}>
        <IntlProvider locale={locale} messages={locale === 'ko' ? ko : locale === 'ko' ? jp : en}>
          {children}
        </IntlProvider>
      </ConfigProvider>
    </LocaleContext.Provider>
  );
};

export default LocaleProvider;
