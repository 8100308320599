import AdminAccount from '@/pages/Admin/Account';
import AdminAssetsGroup from '@/pages/Admin/AssetsGroup';
import AdminCategory from '@/pages/Admin/Category';
import AdminCode from '@/pages/Admin/Code';
import AdminLog from '@/pages/Admin/Log';
import AdminProject from '@/pages/Admin/Project';
import AdminSystem from '@/pages/Admin/System';
import AssetsEvaluation from '@/pages/Assets/Evaluation';
import AssetsManagement from '@/pages/Assets/Management';
import CertificationCertAdmin from '@/pages/Certification/CertAdmin';
import CertificationRecord from '@/pages/Certification/Record';
import CertificationSpec from '@/pages/Certification/Spec';
import DangersAnalysis from '@/pages/Dangers/Analysis';
import DangersRiskAnalysis from '@/pages/Dangers/RiskAnalysis';
import DangersEvaluation from '@/pages/Dangers/Evaluation';
import DangersTechEvaluation from '@/pages/Dangers/TechEvaluation';
import DangersProtection from '@/pages/Dangers/Protection';
import DangersTechProtection from '@/pages/Dangers/TechProtection';
import DangersRisk from '@/pages/Dangers/Risk';
import DashBoard from '@/pages';
import DiagnosisManagement from '@/pages/Diagnosis/Management';
import DiagnosisStatus from '@/pages/Diagnosis/Status';
import DiagnosisTechnical from '@/pages/Diagnosis/Technical';
import License from '@/pages/License';
import Login from '@/pages/Login';
import NotFound from '@/pages/404';
import OrgChart from '@/pages/Org/Chart';
import OrgManager from '@/pages/Org/Manager';
import OrgMember from '@/pages/Org/Member';
import PageViewProvider from '@/common/view-provider';
import PolicyProtection from '@/pages/Policy/Protection';
import React from 'react';
import { useRoutes } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IRouterProps {}

const Router: React.FunctionComponent<IRouterProps> = () => {
  const isLogin = sessionStorage.getItem('accessToken');

  const element = useRoutes([
    {
      path: '/',
      element: isLogin !== null ? <DashBoard id="dashboard" /> : <Login />,
    },
    { path: 'Login', element: <Login /> },
    { path: 'License', element: <License /> },
    {
      path: 'Org',
      children: [
        {
          path: 'Manager',
          element: (
            <PageViewProvider id="Org/Manager">
              <OrgManager />
            </PageViewProvider>
          ),
        },
        {
          path: 'Member',
          element: (
            <PageViewProvider id="Org/Member">
              <OrgMember />
            </PageViewProvider>
          ),
        },
        {
          path: 'Chart',
          element: (
            <PageViewProvider id="Org/Chart">
              <OrgChart />
            </PageViewProvider>
          ),
        },
      ],
    },
    {
      path: 'Assets',
      children: [
        {
          path: 'Management',
          element: (
            <PageViewProvider id="Assets/Management">
              <AssetsManagement />
            </PageViewProvider>
          ),
        },
        {
          path: 'Evaluation',
          element: (
            <PageViewProvider id="Assets/Evaluation">
              <AssetsEvaluation />
            </PageViewProvider>
          ),
        },
      ],
    },
    {
      path: 'Policy',
      children: [
        {
          path: 'Protection',
          element: (
            <PageViewProvider id="Policy/Protection">
              <PolicyProtection />
            </PageViewProvider>
          ),
        },
      ],
    },
    {
      path: 'Diagnosis',
      children: [
        {
          path: 'Status',
          element: (
            <PageViewProvider id="Diagnosis/Status">
              <DiagnosisStatus />
            </PageViewProvider>
          ),
        },
        {
          path: 'Management',
          element: (
            <PageViewProvider id="Diagnosis/Management">
              <DiagnosisManagement />
            </PageViewProvider>
          ),
        },
        {
          path: 'Technical',
          element: (
            <PageViewProvider id="Diagnosis/Technical">
              <DiagnosisTechnical />
            </PageViewProvider>
          ),
        },
      ],
    },
    {
      path: 'Dangers',
      children: [
        {
          path: 'Analysis',
          element: (
            <PageViewProvider id="Dangers/Analysis">
              <DangersAnalysis />
            </PageViewProvider>
          ),
        },
        {
          path: 'RiskAnalysis',
          element: (
            <PageViewProvider id="Dangers/RiskAnalysis">
              <DangersRiskAnalysis />
            </PageViewProvider>
          ),
        },
        {
          path: 'Evaluation',
          element: (
            <PageViewProvider id="Dangers/Evaluation">
              <DangersEvaluation />
            </PageViewProvider>
          ),
        },
        {
          path: 'TechEvaluation',
          element: (
            <PageViewProvider id="Dangers/TechEvaluation">
              <DangersTechEvaluation />
            </PageViewProvider>
          ),
        },
        {
          path: 'Risk',
          element: (
            <PageViewProvider id="Dangers/Risk">
              <DangersRisk />
            </PageViewProvider>
          ),
        },
        {
          path: 'Protection',
          element: (
            <PageViewProvider id="Dangers/Protection">
              <DangersProtection />
            </PageViewProvider>
          ),
        },
        {
          path: 'TechProtection',
          element: (
            <PageViewProvider id="Dangers/TechProtection">
              <DangersTechProtection />
            </PageViewProvider>
          ),
        },
      ],
    },
    {
      path: 'Certification',
      children: [
        {
          path: 'Record',
          element: (
            <PageViewProvider id="Certification/Record">
              <CertificationRecord />
            </PageViewProvider>
          ),
        },
        {
          path: 'CertAdmin',
          element: (
            <PageViewProvider id="Certification/CertAdmin">
              <CertificationCertAdmin />
            </PageViewProvider>
          ),
        },
        {
          path: 'Spec',
          element: (
            <PageViewProvider id="Spec">
              <CertificationSpec />
            </PageViewProvider>
          ),
        },
      ],
    },
    {
      path: 'Spec',
      element: (
        <PageViewProvider id="Spec">
          <CertificationSpec />
        </PageViewProvider>
      ),
    },
    {
      path: 'Admin',
      children: [
        {
          path: 'AssetsGroup',
          element: (
            <PageViewProvider id="Admin/AssetsGroup">
              <AdminAssetsGroup />
            </PageViewProvider>
          ),
        },
        {
          path: 'Account',
          element: (
            <PageViewProvider id="Admin/Account">
              <AdminAccount />
            </PageViewProvider>
          ),
        },
        {
          path: 'Project',
          element: (
            <PageViewProvider id="Admin/Project">
              <AdminProject />
            </PageViewProvider>
          ),
        },
        {
          path: 'Code',
          element: (
            <PageViewProvider id="Admin/Code">
              <AdminCode />
            </PageViewProvider>
          ),
        },

        {
          path: 'Log',
          element: (
            <PageViewProvider id="Admin/Log">
              <AdminLog />
            </PageViewProvider>
          ),
        },

        {
          path: 'Category',
          element: (
            <PageViewProvider id="Admin/Category">
              <AdminCategory />
            </PageViewProvider>
          ),
        },

        {
          path: 'System',
          element: (
            <PageViewProvider id="Admin/System">
              <AdminSystem />
            </PageViewProvider>
          ),
        },
      ],
    },
    // {
    //   path: 'Viewer',
    //   element: <Viewer />,
    // },
    {
      path: '*',
      element: <NotFound />,
    },
  ]);

  const returnLogin = useRoutes([{ path: '*', element: <Login /> }]);

  return isLogin ? element : returnLogin;
};

export default Router;
