import { Column, Radar, RingProgress } from '@ant-design/plots';
import { IDiagnosticDataType, IDiagnosticTechDataType } from './type';
import React, { useContext, useEffect, useState } from 'react';

import Loading from '@/components/loading';
import { ProjectContext } from '@/common/project-provider';
import { SwiperSlide } from 'swiper/react';
import api from '@/api/api';
import styled from 'styled-components';
import { ThemeContext } from '@/common/theme-provider';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopDiagnosisStatusProps {}

const Container = styled.div``;
const StatusList = styled.ul`
  .swiper-slide {
    padding-bottom: 20px;
  }
  .swiper-horizontal > .swiper-scrollbar {
    height: 1px;
  }
`;
const ListItem = styled.li`
  width: 100%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
`;
const Title = styled.div`
  font-size: 20px;
  position: absolute;
  left: 20px;
  top: 20px;
`;
const ChartContainer = styled.div`
  display: inline-block;
  vertical-align: center;
  &.circleChart {
    padding: 60px;
  }
`;

const getDashboardDiagnosticSub = async ({ projectId }) => {
  const result = await api.getDashboardDiagnosticSub({ project_id: projectId });

  return result.data;
};

const getDashboardDiagnosticSubTech = async ({ projectId }) => {
  const result = await api.getDashboardDiagnosticSubTech({
    project_id: projectId,
  });

  return result.data;
};

const DesktopDiagnosisStatus: React.FunctionComponent<
  IDesktopDiagnosisStatusProps
> = () => {
  const { t } = useTranslation();
  const { isDarkMode } = useContext(ThemeContext);
  const { projectId } = useContext(ProjectContext);
  const [diagnosticData, setDiagnosticData] = useState<IDiagnosticDataType[]>(
    []
  );
  const [diagnosticTechData, setDiagnosticTechData] = useState<
    IDiagnosticTechDataType[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [swiperIns, setSwiperIns] = useState<any>();

  useEffect(() => {
    Promise.all([
      getDashboardDiagnosticSub({ projectId }),
      getDashboardDiagnosticSubTech({ projectId }),
    ]).then(totalData => {
      setDiagnosticData(totalData[0]);
      setDiagnosticTechData(totalData[1]);
      setIsLoading(false);
    });
  }, [projectId]);

  if (isLoading) return <Loading />;

  return (
    <Container>
      <StatusList>
        {diagnosticData.map(item => {
          const value = Number((item.ranges / 100).toFixed(2));
          const value2 = Number((item.ranges ).toFixed(2));
          const strRate = t('달성률');
         

          const config_type1 = {
            autoFit: false,
            height: 400,
            percent: value,
            color: ['#F4664A', '#E8EDF3'],
            innerRadius: 0.85,
            radius: 0.98,
            statistic: {
              title: {
                style: {
                  color: isDarkMode ? '#CCCCCC' : '#363636',
                  fontSize: '17px',
                  lineHeight: '18px',
                },
                formatter: () => `${item.title} ${strRate}(%)`,
              },
              content: {
                style: {
                  fontSize: '24px',
                  lineHeight: '44px',
                  color: isDarkMode ? '#CCCCCC' : '#4B535E',
                },
                formatter: () => `${value2}`,
              },
            },
          };

          const config_type2 = {
            data: item.data.map(dataItem => {
              return {
                item: dataItem.title,
                user: t('달성률'),
                score: dataItem.value,
              };
            }),
            theme: isDarkMode ? 'dark' : 'default',
            width: 600,
            height: 350,
            xField: 'item',
            yField: 'score',
            seriesField: 'user',
            meta: {
              score: {
                alias: t('달성률'),
                min: 0,
                max: 100,
              },
            },
            xAxis: {
              line: null,
              tickLine: null,
              grid: {
                line: {
                  style: {
                    lineDash: null,
                  },
                },
              },
            },
            yAxis: {
              line: null,
              tickLine: null,
              grid: {
                line: {
                  type: 'line',
                  style: {
                    lineDash: null,
                  },
                },
              },
            },
            point: {
              size: 2,
            },
          };

          return (
            <SwiperSlide key={item.id}>
              <ListItem>
                <Title>{item.title}</Title>
                {/* 차트삽입 */}
                <ChartContainer className="circleChart">
                  <RingProgress {...config_type1} />
                </ChartContainer>
                <ChartContainer>
                  <Radar {...config_type2} />
                </ChartContainer>
              </ListItem>
            </SwiperSlide>
          );
        })}
        {diagnosticTechData.map(item => {
          const value = Number((item.ranges / 100).toFixed(2));
          const value2 = Number((item.ranges ).toFixed(2));

          const config_type3 = {
            autoFit: false,
            height: 350,
            percent: value,
            color: ['#F4664A', '#E8EDF3'],
            innerRadius: 0.85,
            radius: 0.98,
            statistic: {
              title: {
                style: {
                  color: isDarkMode ? '#CCCCCC' : '#363636',
                  fontSize: '18px',
                  lineHeight: '18px',
                },
                formatter: () => `${t("기술적 진단 실행률")}(%)`,
              },
              content: {
                style: {
                  fontSize: '24px',
                  lineHeight: '44px',
                  color: isDarkMode ? '#CCCCCC' : '#363636',
                },
                formatter: () => `${value2}`,
              },
            },
          };

          const config_type4: any = {
            data: item.data.map(dataItem => {
              return {
                type: t(dataItem.title),
                sales: dataItem.value,
              };
            }),
            color: '#476CA0',
            height: 300,
            xField: 'type',
            yField: 'sales',
            theme: isDarkMode ? 'dark' : 'default',
            label: {
              position: 'middle',
              style: {
                fill: '#FFFFFF',
                opacity: 0.6,
              },
            },
            xAxis: {
              label: {
                autoHide: true,
                autoRotate: false,
              },
            },
            meta: {
              type: {
                alias: t('진단율'),
              },
              sales: {
                alias: t('진단율'),
              },
            },
          };

          return (
            <SwiperSlide key={item.id}>
              <ListItem>
                <Title>{t(item.title)}</Title>
                {/* 차트삽입 */}
                <ChartContainer className="circleChart">
                  <RingProgress {...config_type3} />
                </ChartContainer>
                <ChartContainer>
                  <Column {...config_type4} />
                </ChartContainer>
              </ListItem>
            </SwiperSlide>
          );
        })}
      </StatusList>
    </Container>
  );
};

export default DesktopDiagnosisStatus;
