import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { ViewTypeEnum } from './type';
import api from '../api/api';
import { message } from 'antd';
import moment from 'moment';
import { removeSessionStorage } from './util';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext(null);

const setLogOut = async () => {
  const result = await api.setLogOut({
    username: '',
  });

  return result;
};

const printScreenDisable = e => {
  var keyCode = e.keyCode ? e.keyCode : e.which;
  if (keyCode === 44) {
    var aux = document.createElement('input');
    aux.setAttribute('value', 'print screen disabled!');
    document.body.appendChild(aux);
    aux.select();
    document.execCommand('copy');
    // Remove it from the body
    //document.body.removeChild(aux);
    //alert('Print screen disabled!');
  }
};

let intv = null;

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))?.userLevel ===
      ViewTypeEnum.admin
  );
  const isLogin = sessionStorage.getItem('accessToken');

  const login = level => {
    setUser(level === 3);
  };

  const logout = () => {
    setUser(null);
  };

  const logOutHandler = useCallback(() => {
    setLogOut().then(() => {
      removeSessionStorage();
      navigate('/Login', { replace: true });
    });
  }, [navigate]);

  const countDown = useCallback(() => {
    if (intv !== null) clearInterval(intv);

    const targetTime = moment().add(30, 'minute');
    const getCurrentTime = moment();
    const getCurrentTimeUnix = getCurrentTime.unix();
    const targetTimeUnix = targetTime.unix();
    const leftTime = targetTimeUnix - getCurrentTimeUnix;
    let duration = moment.duration(leftTime, 'seconds');

    intv = setInterval(function () {
      if (duration.asSeconds() <= 1 || getCurrentTimeUnix >= targetTimeUnix) {
        alert('응답시간이 초과 되어 로그인 페이지로 이동합니다.');
        logOutHandler();
        clearInterval(intv);
      } else {
        duration = moment.duration(duration.asSeconds() - 1, 'seconds');
        var timer = {
          hours:
            duration.hours() < 10 ? '0' + duration.hours() : duration.hours(),
          minutes:
            duration.minutes() < 10
              ? '0' + duration.minutes()
              : duration.minutes(),
          seconds:
            duration.seconds() < 10
              ? '0' + duration.seconds()
              : duration.seconds(),
        };

        if (String(timer.minutes) === '05' && String(timer.seconds) === '01') {
          message.warning({
            content: '5분간 사용이 없을 시 자동 로그아웃됩니다.',
            duration: '10',
          });
        }

        // console.log(
        //   timer.hours + ' : ' + timer.minutes + ' : ' + timer.seconds
        // );
      }
    }, 1000);
  }, [logOutHandler]);

  useEffect(() => {
    const setCountdown = () => {
      setTimeout(() => {
        if (intv !== null) clearInterval(intv);
        if (isLogin !== null) countDown();
      }, 300);
    };

    setCountdown();

    document.addEventListener('click', setCountdown);
    document.addEventListener('keydown', setCountdown);
    document.addEventListener('keyup', printScreenDisable);

    return () => {
      document.removeEventListener('click', setCountdown);
      document.removeEventListener('keydown', setCountdown);
      document.removeEventListener('keyup', printScreenDisable);
    };
  }, [countDown, isLogin]);

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
