import React from 'react';

import { IAdminMenuPermissionProps } from '@/common/type';
import styled from 'styled-components';
import DesktopDangersTechEvaluationGroup from './desktop-dangers-techEvaluation-group';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopDangersTechEvaluationProps {
  pageInfo: IAdminMenuPermissionProps;
}

const Container = styled.div`
  .ant-form-item {
    margin: 0;
  }
`;

const DesktopDangersTechEvaluation: React.FunctionComponent<
  IDesktopDangersTechEvaluationProps
> = ({ pageInfo }) => {
  return (
    <Container>
      <DesktopDangersTechEvaluationGroup pageInfo={pageInfo} />
    </Container>
  );
};

export default DesktopDangersTechEvaluation;
