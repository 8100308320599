import { EditableCell, EditableRow } from './assets-editable-table';
import React, { useCallback, useMemo } from 'react';

import { IAnalysisDataProps } from './type';
import { Table } from 'antd';
import defaultInfo from '@/common/site-info';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopDangersAnalysisTableProps {
  isLoading: boolean;
  analysisData: IAnalysisDataProps[];
  setAnalysisData: React.Dispatch<React.SetStateAction<IAnalysisDataProps[]>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: any;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

const DesktopDangersAnalysisTable: React.FunctionComponent<
  IDesktopDangersAnalysisTableProps
> = ({ isLoading, columns, analysisData, setAnalysisData, setIsEdit }) => {
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const inColumns = useMemo(() => {
    return columns.map(col => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave,
        }),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, analysisData]);

  const handleSave = useCallback(
    row => {
      const newData = [...analysisData];
      const index = newData.findIndex(item => row.key === item.key);
      const item = newData[index];
      newData.splice(index, 1, { ...item, ...row });

      setAnalysisData(newData);
      setIsEdit(true);
    },
    [analysisData, setAnalysisData, setIsEdit]
  );

  return (
    <Table
      dataSource={analysisData}
      columns={inColumns}
      loading={isLoading}
      pagination={false}
      size={defaultInfo.table_size}
      components={components}
      rowClassName={() => 'editable-row'}
    />
  );
};

export default DesktopDangersAnalysisTable;
