import React, { useMemo, useContext } from 'react';

import DesktopDashboardCard from './components/desktop-dashboard-card';
import { Empty } from 'antd';
import { IAssetsType } from './type';
import { Pie } from '@ant-design/plots';
import styled from 'styled-components';
import { graphColor } from '@/common/util';
import { ThemeContext } from '@/common/theme-provider';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopDashboardAssetsProps {
  assetsData: IAssetsType[];
  isLoading: boolean;
}

const Inner = styled.div`
  /* padding: 20px; */
  height: 399px;
  position: relative;
`;

const StyledEmpty = styled(Empty)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const DesktopDashboardAssets: React.FunctionComponent<
  IDesktopDashboardAssetsProps
> = ({ assetsData, isLoading }) => {
  
  const { t } = useTranslation();

  const { isDarkMode } = useContext(ThemeContext);

  const dataSource = useMemo(() => {
    return assetsData.map(item => {
      return {
        type: item.type,
        value: item.value,
      };
    });
  }, [assetsData]);

  const config: any = {
    appendPadding: 10,
    data: dataSource,
    color: graphColor,
    angleField: 'value',
    colorField: 'type',
    radius: 0.7,
    theme: isDarkMode ? 'dark' : 'default',
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 15,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
    legend: {
      layout: 'vertical',
      position: 'right',
      offsetX: -30,
    },
  };

  return (
    <DesktopDashboardCard
      cardTitle={t("자산현황")}
      moreLink="/Assets/Management"
      isLoading={isLoading}
    >
      <Inner>
        {assetsData.length > 0 ? <Pie {...config} /> : <StyledEmpty />}
      </Inner>
    </DesktopDashboardCard>
  );
};

export default DesktopDashboardAssets;
