import React, { useContext, useEffect, useState } from 'react';

import DesktopAdminCategoryHeader from './desktop-admin-category-header';
import DesktopAdminCategoryTable from './desktop-admin-category-table';
import { IAdminMenuPermissionProps } from '@/common/type';
import { IDataSourceProps } from './type';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopAdminCategoryProps {
  pageInfo: IAdminMenuPermissionProps;
}

const getAdminCertCategory = async ({ projectId }) => {
  const result = await api.getAdminCertCategory({ project_id: projectId });

  return result.data;
};

const DesktopAdminCategory: React.FunctionComponent<
  IDesktopAdminCategoryProps
> = ({ pageInfo }) => {
  const { projectId } = useContext(ProjectContext);

  const [categoryData, setCategoryData] = useState<IDataSourceProps[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAdminCertCategory({ projectId }).then(datas => {
      setCategoryData(datas.map(data => ({ ...data, key: data.id })));
      setIsLoading(false);
    });
  }, [projectId]);

  return (
    <>
      <DesktopAdminCategoryHeader />
      <DesktopAdminCategoryTable
        categoryData={categoryData}
        setCategoryData={setCategoryData}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        pageInfo={pageInfo}
      />
    </>
  );
};

export default DesktopAdminCategory;
