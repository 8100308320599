import { Button, Table } from "antd";
import { IAccountColumnsProps, IAccountDatas } from "./type";
import { IAdminMenuPermissionProps, ICodeData } from "@/common/type";
import React, { useContext, useEffect, useState } from "react";

import DesktopAdminAccountTableMenuModal from "./desktop-admin-account-table-menu-modal";
import DesktopAdminAccountTableProjectModal from "./desktop-admin-account-table-project-modal";
import DesktopAdminRoleTableModal from "../Role/desktop-admin-role-table-modal";
import IMask from "imask";
import { MaskedInput } from "antd-mask-input";
import { ProjectContext } from "@/common/project-provider";
import { formatDt } from "@/common/util";
import styled from "styled-components";
import defaultInfo from "@/common/site-info";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopAdminAccountTableProjectProps {
  userList: IAccountDatas[];
  setUserList: React.Dispatch<React.SetStateAction<IAccountDatas[]>>;
  getUsersAPI: ({ projectId, datas }) => any;
  codeList: ICodeData[];
  organList: ICodeData[];
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  pageInfo: IAdminMenuPermissionProps;
}

const Container = styled.div`
  box-sizing: border-box;
  border-radius: 10px;
`;
const Text = styled.div`
  input {
    text-align: center;
  }
`;

const StyledMaskedInput = styled(MaskedInput)`
  border-radius: 3px;
  &:focus {
    border: 0;
  }
  &:active {
    border: 0;

    outline: 0;
  }
  &:hover {
    border: 0;
  }
`;

const DesktopAdminAccountTableProject: React.FunctionComponent<
  IDesktopAdminAccountTableProjectProps
> = ({
  userList,
  setUserList,
  getUsersAPI,
  organList,
  codeList,
  isLoading,
  setIsLoading,
  pageInfo,
}) => {
  const { t } = useTranslation();
  const { projectId } = useContext(ProjectContext);
  const [isRoleModalVisible, setIsRoleModalVisible] = useState(false);
  const [isAccountProjectModalVisible, setIsAccountProjectModalVisible] =
    useState(false);
  const [isAccountMenuModalVisible, setIsAccountMenuModalVisible] =
    useState(false);
  const [selectUsername, setSelectUsername] = useState(null);
  const [selectData, setSelectData] = useState<IAccountDatas | null>(null);
  const [userId, setUserId] = useState<string | null>(null);

  useEffect(() => {
    getUsersAPI({
      projectId,
      datas: {
        user_name: "",
        user_id: "",
        appr_yn: "",
      },
    }).then((datas) => {
      setUserList(
        datas.map((item, index) => ({
          ...item,
          no: index + 1,
          key: index + 1,
        }))
      );
      setIsLoading(false);
    });
  }, [
    getUsersAPI,
    projectId,
    setIsLoading,
    setUserList,
    isAccountProjectModalVisible,
  ]);

  const columns: IAccountColumnsProps[] = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      align: "center",
      // render: text => <a>{text}</a>
    },
    {
      title: t("아이디"),
      dataIndex: "username",
      key: "username",
      align: "center",
    },
    {
      title: t("이름"),
      dataIndex: "fullname",
      key: "fullname",
      align: "center",
    },
    {
      title: t("연락처"),
      dataIndex: "userhp",
      key: "userhp",
      align: "center",
      render: (text) => {
        return (
          <Text>
            <StyledMaskedInput
              value={text}
              type="text"
              bordered={false}
              readOnly
              mask={[
                {
                  mask: "000-0000-0000",
                  blocks: {
                    RGB: {
                      mask: IMask.MaskedRange,
                      from: 0,
                      to: 9999,
                    },
                  },
                },
              ]}
            />
          </Text>
        );
      },
    },
    {
      title: t("이메일"),
      dataIndex: "email",
      key: "email",
      align: "center",
    },
    {
      title: t("부서"),
      dataIndex: "dept",
      key: "dept_cd",
      align: "center",
    },
    {
      title: t("직급"),
      dataIndex: "rank",
      key: "rank_cd",
      align: "center",
    },
    {
      title: t("등록일"),
      dataIndex: "created_date",
      key: "created_date",
      align: "center",
      render: (text) => formatDt(text),
    },
    {
      title: t("승인여부"),
      dataIndex: "appr_yn",
      key: "appr_yn",
      align: "center",
      render: (bool) => (bool ? t("승인") : t("미승인")),
    },
  ];

  const columnsButtons: IAccountColumnsProps[] = [
    {
      title: t("메뉴별 권한"),
      dataIndex: "menu_edit",
      key: "menu_edit",
      align: "center",
      render: (_, record) => {
        return (
          <Button
            onClick={() => {
              setUserId(record.username);
              setIsAccountMenuModalVisible(true);
            }}
          >
            {t("메뉴별 권한")}
          </Button>
        );
      },
    },
    {
      title: t("프로젝트 권한 관리"),
      dataIndex: "project_edit",
      key: "project_edit",
      align: "center",
      // eslint-disable-next-line react/display-name
      render: (_, record) => (
        <Button
          onClick={() => {
            setSelectUsername(record.username);
            setIsRoleModalVisible(true);
          }}
        >
          {t("프로젝트 권한 관리")}
        </Button>
      ),
    },
    {
      title: t("계정관리"),
      dataIndex: "user_edit",
      key: "user_edit",
      align: "center",
      // eslint-disable-next-line react/display-name
      render: (_, record) => (
        <Button
          data-no={record.no}
          onClick={() => {
            setSelectData(record);
            setIsAccountProjectModalVisible(true);
          }}
        >
          {t("계정관리")}
        </Button>
      ),
    },
  ];

  return (
    <Container>
      <Table
        columns={
          pageInfo.perm_write ? [...columns, ...columnsButtons] : [...columns]
        }
        size={defaultInfo.table_size}
        dataSource={userList}
        pagination={false}
        loading={isLoading}
      />
      <DesktopAdminAccountTableProjectModal
        isModalVisible={isAccountProjectModalVisible}
        setIsModalVisible={setIsAccountProjectModalVisible}
        selectData={selectData}
        organList={organList}
        codeList={codeList}
        isAccountPage={true}
      />
      <DesktopAdminRoleTableModal
        isModalVisible={isRoleModalVisible}
        setIsModalVisible={setIsRoleModalVisible}
        selectUsername={selectUsername}
      />
      <DesktopAdminAccountTableMenuModal
        isAccountMenuModalVisible={isAccountMenuModalVisible}
        setIsAccountMenuModalVisible={setIsAccountMenuModalVisible}
        userId={userId}
      />
    </Container>
  );
};

export default DesktopAdminAccountTableProject;
