import { Button, Form, Input, InputNumber, Space } from 'antd';
import { IFieldProps, IGroupProps } from './type';
import React, { useCallback, useContext, useMemo } from 'react';

import { IAdminMenuPermissionProps } from '@/common/type';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import { openNotification } from '@/common/util';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface IDesktopAdminAssetsGroupContentCategoryCell {
  fieldsData: IGroupProps;
  selectIndex: number;
  setFieldsData: React.Dispatch<React.SetStateAction<IGroupProps>>;
  setIsNewDataSave: React.Dispatch<React.SetStateAction<boolean>>;
  isNewDataSave: boolean;
  setActiveKey: React.Dispatch<React.SetStateAction<string>>;
  pageInfo: IAdminMenuPermissionProps;
}

const Container = styled.div`
  border: 1px solid ${props => props.theme.layout.searchBorderColor};
  padding: 40px 30px 10px 30px;
  margin-top: -1px;
  border-radius: 10px;
  box-shadow: 2px 0 8px 0 rgb(29 35 41 / 5%);
`;

const setAssetGroupFields = async ({ projectId, datas }) => {
  const result = await api.setAssetGroupFields({
    project_id: projectId,
    datas,
  });

  return result.data;
};

const DesktopAdminAssetsGroupContentCategoryCell: React.FunctionComponent<
  IDesktopAdminAssetsGroupContentCategoryCell
> = ({
  fieldsData,
  selectIndex,
  setFieldsData,
  setIsNewDataSave,
  isNewDataSave,
  setActiveKey,
  pageInfo,
}) => {
  const { t } = useTranslation();
  const { projectId } = useContext(ProjectContext);

  const values = useMemo(() => {
    return Object.keys(fieldsData?.fields[selectIndex]).map(item => {
      return {
        name: [item],
        value: fieldsData.fields[selectIndex][item],
      };
    });
  }, [fieldsData.fields, selectIndex]);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
  };

  const onFinish = useCallback(
    (values: IFieldProps) => {
      const newData = fieldsData.fields.map(field => {
        return field.id === values.id
          ? values.id === 'new_data'
            ? { ...values, id: null }
            : values
          : field;
      });

      setAssetGroupFields({
        projectId,
        datas: { ...fieldsData, fields: newData },
      }).then(data => {
        const notiText = {
          description: t("완료"),
        };
        setIsNewDataSave(false);
        openNotification(notiText);
        setFieldsData(data);

        if (isNewDataSave)
          setActiveKey(data.fields[data.fields.length - 1].id.toString());
      });
    },
    [
      fieldsData,
      isNewDataSave,
      projectId,
      setActiveKey,
      setFieldsData,
      setIsNewDataSave,
      t,
    ]
  );

  const removeCell = useCallback(() => {
    if (isNewDataSave) {
      setFieldsData(prev => ({
        ...prev,
        fields: [...prev.fields.slice(0, -1)],
      }));
    } else {
      const newData = fieldsData.fields.map((fields, index) =>
        index === selectIndex ? { ...fields, del_yn: true } : fields
      );
      const selectCellName = fieldsData.fields[selectIndex].field_name;

      if (window.confirm(`${selectCellName} ${t("삭제 하시겠습니까?")}`)) {
        setAssetGroupFields({
          projectId,
          datas: { ...fieldsData, fields: newData },
        }).then(data => {
          const notiText = {
            description: t("완료"),
          };

          openNotification(notiText);
          setFieldsData(data);
        });
      }
    }
    setIsNewDataSave(false);
  }, [
    fieldsData,
    isNewDataSave,
    projectId,
    selectIndex,
    setFieldsData,
    setIsNewDataSave,
    t,
  ]);

  return (
    <Container>
      <Form
        {...layout}
        name="nest-messages"
        onFinish={onFinish}
        fields={values}
      >
        <Form.Item name="id" label="id" hidden={true}>
          <Input />
        </Form.Item>
        <Form.Item
          name="field_name"
          label={t("필드명")}
          rules={[{ required: true }]}
        >
          <Input
            placeholder={t("필드명을 입력해주세요.")}
            readOnly={!pageInfo.perm_write}
          />
        </Form.Item>
        <Form.Item name="key" label={t("키값")} rules={[{ required: false }]}>
          <Input readOnly={!pageInfo.perm_write} />
        </Form.Item>
        <Form.Item
          name="field_sort"
          label={t("순서")}
          rules={[{ required: true, type: 'number', min: 0, max: 100 }]}
          hidden={true}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item name="field_type" label={t("타입")} hidden={true}>
          <Input />
        </Form.Item>
        <Form.Item name="field_option" label={t("옵션")} hidden={true}>
          <Input />
        </Form.Item>
        <Form.Item name="del_yn" label={t("삭제여부")} hidden={true}>
          <Input />
        </Form.Item>
        <Form.Item name="group_id" label="ID" hidden={true}>
          <Input />
        </Form.Item>
        {pageInfo.perm_write && (
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Space size={10}>
              <Button type="primary" htmlType="submit">
                {t("저장")}
              </Button>
              <Button onClick={removeCell}>{t("삭제")}</Button>
            </Space>
          </Form.Item>
        )}
      </Form>
    </Container>
  );
};

export default DesktopAdminAssetsGroupContentCategoryCell;
