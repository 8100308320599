import { IEvaluationColumsProps, IEvaluationDataProps } from './type';
import React, { useContext, useEffect, useState } from 'react';

import DesktopDangersEvaluationHeader from './desktop-dangers-evaluation-header';
import DesktopDangersEvaluationTable from './desktop-dangers-evaluation-table';
import { IAdminMenuPermissionProps } from '@/common/type';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopDangersEvaluationProps {
  pageInfo: IAdminMenuPermissionProps;
}

const getRiskResult = async props => {
  const result = await api.getRiskResult({ ...props });

  return result.data;
};

interface ITableText {
  align: string;
}
const Text = styled.div<ITableText>`
  text-align: ${props => props.align || 'left'};
`;

const DesktopDangersEvaluation: React.FunctionComponent<
  IDesktopDangersEvaluationProps
> = ({ pageInfo }) => {
  const { t } = useTranslation();
  const { projectId } = useContext(ProjectContext);
  const [isLoading, setIsLoading] = useState(true);
  const [riskData, setRiskData] = useState<IEvaluationDataProps[]>([]);

  useEffect(() => {
    getRiskResult({ project_id: projectId }).then(datas => {
      setIsLoading(false);
      setRiskData(datas.map((item, index) => ({ ...item, key: index })));
    });
  }, [projectId]);

  const columns: IEvaluationColumsProps[] = [
    {
      title: t('통제번호'),
      label: t('통제번호'),
      dataIndex: 'category_num',
      key: 'category_num',
      align: 'center',
      width: '5%',
    },
    {
      title: t('구분'),
      label: t('구분'),
      dataIndex: 'category_name',
      key: 'category_name',
      align: 'center',
      width: '8%',
    },
    {
      title: t('점검항목'),
      label: t('점검항목'),
      dataIndex: 'check_list_desc',
      key: 'check_list_desc',
      align: 'center',
      width: '15%',
      render: text => {
        return <Text>{text}</Text>;
      }
    },
    {
      title: t('현황'),
      label: t('현황'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: '15%',
      render: text => {
        return <Text>{text}</Text>;
      }
    },
    {
      title: t('위협코드'),
      label: t('위협코드'),
      dataIndex: 'threat_code',
      key: 'threat_code',
      align: 'center',
      width: '5%',
    },
    {
      title: t('위협내용'),
      label: t('위협내용'),
      dataIndex: 'threat_desc',
      key: 'threat_desc',
      align: 'center',
      render: text => {
        return <Text>{text}</Text>;
      }
    },
    {
      title: t('취약점'),
      label: t('취약점'),
      dataIndex: 'vulner_desc',
      key: 'vulner_desc',
      align: 'center',
      render: text => {
        return <Text>{text}</Text>;
      }
    },
    {
      title: t('개선방향'),
      label: t('개선방향'),
      dataIndex: 'action_desc',
      key: 'action_desc',
      align: 'center',
      render: text => {
        return <Text>{text}</Text>;
      }
    },
    {
      title: t('발생가능성'),
      label: t('발생가능성'),
      dataIndex: 's_value',
      key: 's_value',
      align: 'center',
      width: '6%',
    },
    {
      title: t('유출위험도'),
      label: t('유출위험도'),
      dataIndex: 'leak_risk_value',
      key: 'leak_risk_value',
      align: 'center',
      width: '6%',
    },
    {
      title: t('위험도'),
      label: t('위험도'),
      dataIndex: 'risk_value',
      key: 'risk_value',
      align: 'center',
      width: '5%',
    },
  ];

  return (
    <>
      <DesktopDangersEvaluationHeader
        getRiskResult={getRiskResult}
        setIsLoading={setIsLoading}
        setRiskData={setRiskData}
      />
      <DesktopDangersEvaluationTable
        riskData={riskData}
        columns={columns}
        isLoading={isLoading}
        pageInfo={pageInfo}
      />
    </>
  );
};

export default DesktopDangersEvaluation;
