import { AppConsumer } from '@/common/app-provider';
import DesktopHeader from './desktop-header';
import { IHeaderProps } from './type';
import MobileHeader from './mobile-header';
import React from 'react';

const Index: React.FunctionComponent<IHeaderProps> = props => {
  return (
    <AppConsumer>
      {({ config }) => {
        if (config.isMobile) {
          return <MobileHeader {...props} config={config} />;
        }
        return <DesktopHeader {...props} config={config} />;
      }}
    </AppConsumer>
  );
};

export default Index;
