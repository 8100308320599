import { Button, Radio, Space, Spin, Tooltip, UploadProps } from 'antd';
import { DownloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import React, { useCallback, useContext, useState } from 'react';
import { IGroupProps } from '@/panel/admin/AssetsGroup/type';
import { ProjectContext } from '@/common/project-provider';
import { UploadOutlined } from '@ant-design/icons';
import api from '@/api/api';
import styled from 'styled-components';
import Dragger from 'antd/lib/upload/Dragger';
import Modal from '@/components/modal';
import { useTranslation } from 'react-i18next';

interface IDesktopAssetsManagementGroupContentExcelProps {
  pane: IGroupProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setAssetField: any;
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isAddMode: boolean;
  dataSource: IGroupProps[];
}
const StyledUploadArea = styled.div`
  padding-bottom: 20px;
  .ant-upload-list {
    display: none;
  }
  .ant-upload-text {
    color: ${props => props.theme.font.color}!important;
  }
`;

const CloseBtnArea = styled.div`
  text-align: right;
`;
const UpdateTypeContainer = styled.div`
  padding: 15px 0 10px;
`;
const Title = styled.h4`
  font-size: 18px;
  margin: 0 0 10px 0;
`;
const Caution = styled.p`
  color: #983333;
  margin-top: -10px;
`;
const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${props => props.theme.layout.searchBorderColor};
  margin: 30px 0;
`;

const getAssetExcelSampleDownload = async ({ projectId, id }) => {
  const result = await api.getAssetExcelSampleDownload({
    project_id: projectId,
    id,
  });

  return result;
};

const getAssetExcelDownload = async ({ projectId, id }) => {
  const result = await api.getAssetExcelDownload({
    project_id: projectId,
    id,
  });

  return result;
};

const setAssetCellDataExcelUpload = async ({
  projectId,
  id,
  formData,
  upDateType,
}) => {
  const result = await api.setAssetCellDataExcelUpload({
    project_id: projectId,
    id,
    formData,
    upDateType,
  });

  return result.data;
};

const DesktopAssetsManagementGroupContentExcel: React.FunctionComponent<
  IDesktopAssetsManagementGroupContentExcelProps
> = ({ pane, isModalVisible, setIsModalVisible, isAddMode, dataSource }) => {
  const { t } = useTranslation();
  
  const { projectId } = useContext(ProjectContext);

  const [loading, setLoading] = useState(false);
  const [upDateType, setUpDateType] = React.useState(1);

  const sampleDownload = () => {
    getAssetExcelSampleDownload({ projectId, id: pane.id }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${pane.group_name}_template.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
  };

  const handleChange = useCallback(
    info => {
      if (info.file.status === 'uploading') {
        return;
      }
      const formData = new FormData();

      formData.append('file', info.file.originFileObj);
      setLoading(true);
      setAssetCellDataExcelUpload({
        projectId,
        id: pane.id,
        formData,
        upDateType,
      })
        .then(() => {
          setLoading(false);
          setIsModalVisible(false);
        })
        .catch(() => {
          setLoading(false);
          setIsModalVisible(false);
        });
    },
    [pane.id, projectId, setIsModalVisible, upDateType]
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const excelDownload = () => {
    getAssetExcelDownload({ projectId, id: pane.id }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `asset_${pane.group_name}_table.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
  };

  const onChange = e => {
    setUpDateType(e.target.value);
  };

  const props: UploadProps = {
    name: 'file',
    multiple: true,
    onChange(info) {
      // const { status } = info.file;
      handleChange(info);
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  return (
    <Space
      size="small"
      style={{
        marginBottom: 16,
        marginLeft: 10,
      }}
    >
      <Tooltip title={<span>{t("엑셀을 이용하여 데이터 일괄 추가")}</span>}>
        <Button onClick={showModal} disabled={isAddMode}>
        {t("자산 데이터 일괄 등록")}
        </Button>
      </Tooltip>

      <Button
        onClick={excelDownload}
        icon={<DownloadOutlined />}
        disabled={isAddMode || dataSource.length===0}
      >
        {t("엑셀 다운로드")}
      </Button>

      <Modal
        title={t("데이터 일괄 등록")}
        open={isModalVisible}
        maskClosable={false}
        keyboard={false}
        footer={false}
        onCancel={handleCancel}
      >
        <Spin spinning={loading} delay={500}>
          <Title>
            <ExclamationCircleOutlined /> {t("엑셀 업로드 양식 다운로드")}
          </Title>
          <Button onClick={sampleDownload}>
            <DownloadOutlined /> {t("업로드 양식 다운로드")}
          </Button>
          <Line />
          <Title>
            <ExclamationCircleOutlined /> {t("기존 데이터 하단에 일괄 추가")}
          </Title>
          <Caution>({t("데이터 양에 따라 많은 시간이 소요될 수 있습니다.")})</Caution>

          <UpdateTypeContainer>
            <Radio.Group onChange={onChange} value={upDateType}>
              <Radio value={1}>{t("기본양식")}</Radio>
              <Radio value={2}>{t("필드자동매칭")}</Radio>
              <Radio value={3}>{t("필드자동생성 및 자동매칭")}</Radio>
            </Radio.Group>
          </UpdateTypeContainer>
          <StyledUploadArea>
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <UploadOutlined />
              </p>
              <p className="ant-upload-text">
              {t("클릭 또는 마우스로 파일을 끌어오세요.")}
              </p>
            </Dragger>
          </StyledUploadArea>
          <CloseBtnArea>
            <Button onClick={handleCancel}>{t("닫기")}</Button>
          </CloseBtnArea>
        </Spin>
      </Modal>
    </Space>
  );
};

export default DesktopAssetsManagementGroupContentExcel;
