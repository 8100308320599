import i18n from "i18next";
import { initReactI18next } from "react-i18next";

//JSON 파일 import
import translationKo from "./ko.json";
import translationJp from "./jp.json";

const resource = {
//국가 코드와 JSON 파일 match
  ko: {
    translation: translationKo,
  },
  jp: {
    translation: translationJp,
  },
};

i18n.use(initReactI18next).init({
  resources: resource,
  lng: "kr", //default 국가 코드
  debug: true,
  keySeparator: false,
  interpolation: { escapeValue: false },
});

//브라우저 DB에 기록된 언어가 있으면 해당 언어를 불러온다.
let language = localStorage.getItem("language");
if(language!==null)
i18n.changeLanguage(language);

//국가 코드를 변경하는 함수
export function changeLanguage() {
  i18n.changeLanguage(i18n.language === "ko" ? "jp" : "ko");
  //브라우저 DB에 기록
  //localStorage.setItem("language", i18n.language);
}

export default i18n;