import { Button, Form, Input, Select, Space } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { ICertificationTableRecord } from '../type';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopCertificationSearchProps {
  setCategoryId: React.Dispatch<React.SetStateAction<string>>;
  categoryId: number;
  setRecordDatas: React.Dispatch<
    React.SetStateAction<ICertificationTableRecord[]>
  >;
  type?: string;
}

const Container = styled.div`
  text-align: left;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid ${props => props.theme.layout.searchBorderColor};
  box-sizing: border-box;
  border-radius: 10px;
  .ant-form-item {
    margin: 0;
  }
`;

const CertificationSelect = styled.div`
  position: relative;
  float: left;
  margin-right: 20px;
`;
const Label = styled.label`
  font-size: 15px;
  margin-left: 15px;
`;

const getCertificationSearch = async ({ projectId, categoryId, params }) => {
  const result = await api.getCertificationSearch({
    project_id: projectId,
    id: categoryId,
    params: {
      keyword: params.keyword,
      content: params.content,
    },
  });

  return result.data;
};

const getCertificationCategory = async ({ projectId }) => {
  const result = await api.getCertificationCategory({ project_id: projectId });

  return result.data;
};

const DesktopCertificationSearch: React.FunctionComponent<
  IDesktopCertificationSearchProps
> = ({ categoryId, setRecordDatas, setCategoryId, type }) => {
  const { t } = useTranslation();
  const { projectId } = useContext(ProjectContext);
  const [category, setCategory] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    getCertificationCategory({ projectId }).then(data => {
      setCategory(data);
    });
  }, [projectId]);

  const onFinish = useCallback(
    (values: any) => {
      getCertificationSearch({ projectId, categoryId, params: values }).then(
        data => {
          setRecordDatas(() => data.map(item => ({ ...item, key: item.id })));
        }
      );
    },
    [categoryId, projectId, setRecordDatas]
  );

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const resetHandler = () => {
    form.resetFields();
  };

  const handleChange = value => {
    console.log(`selected ${value}`);
    setCategoryId(value);
  };

  return (
    <Container>
      <CertificationSelect>
        <Space>
          <Label>{t("통제영역")}</Label>
          {category.length > 0 && (
            <Select
              defaultValue=""
              style={{ width: 200 }}
              onChange={handleChange}
            >
              <Option value="">{t("전체")}</Option>
              {category.map(item => (
                <Option value={item.id} key={item.id}>
                  {item.category_name}
                </Option>
              ))}
            </Select>
          )}
        </Space>
      </CertificationSelect>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
      >
        <Space>
          <Label>{t("증적명")}</Label>
          <Form.Item name="keyword" initialValue="">
            <Input />
          </Form.Item>

          {type === 'certAdmin' && (
            <>
              <Label>{t("증적파일내용")}</Label>
              <Form.Item name="content" initialValue="">
                <Input />
              </Form.Item>
            </>
          )}

          <Form.Item>
            <Button type="primary" htmlType="submit">
            {t("조회")}
            </Button>
          </Form.Item>

          <Form.Item>
            <Button onClick={resetHandler}>{t("초기화")}</Button>
          </Form.Item>
        </Space>
      </Form>
    </Container>
  );
};

export default DesktopCertificationSearch;
