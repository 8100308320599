import { Button, Form, Input, Popconfirm, Space } from 'antd';
import React, { useMemo } from 'react';
import { IProjectDatas } from './type';
import api from '@/api/api';
import moment from 'moment';
import Modal from '@/components/modal';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopAdminProjectTableModalProps {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  selectData: IProjectDatas;
  setProjectList: React.Dispatch<React.SetStateAction<IProjectDatas[]>>;
}

const setProject = async datas => {
  const result = await api.setProject(datas);

  return result.data;
};

const deleteProject = async project_id => {
  const result = await api.deleteProject(project_id);

  return result.data;
};

const DesktopAdminProjectTableModal: React.FunctionComponent<
  IDesktopAdminProjectTableModalProps
> = ({ isModalVisible, setIsModalVisible, selectData, setProjectList }) => {
  const { t } = useTranslation();
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const fields = useMemo(() => {
    const fieldDatas = [];

    if (selectData !== null) {
      for (const [key, value] of Object.entries(selectData)) {
        // console.log(`${key} : ${value}`);
        fieldDatas.push({
          name: [key],
          value:
            key === 'service_end' || key === 'service_start'
              ? moment(value)
              : value,
        });
      }
    }

    return fieldDatas;
  }, [selectData]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = value => {
    const projectData = {
      ...value,
      // service_start: value['service_start'].format('YYYY-MM-DD'),
      // service_end: value['service_end'].format('YYYY-MM-DD')
    };

    setProject(projectData).then(() => {
      setIsModalVisible(false);
    });
  };

  const deleteAccount = () => {
    if (
      window.confirm(
        t('프로젝트를 삭제할 경우 데이터를 다시 복구 할 수 없습니다. \n프로젝트를 삭제 하시겠습니까?')
      )
    ) {
      deleteProject(selectData.id).then(data => {
        setProjectList(prev => prev.filter(project => project.id !== data.id));
        setIsModalVisible(false);
      });
    }
  };

  // const dateFormat = 'YYYY/MM/DD';
  return (
    <Modal
      title={t("프로젝트관리")}
      open={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        fields={fields}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item name="id" hidden={true}>
          <Input />
        </Form.Item>
        <Form.Item
          label={t("프로젝트명")}
          name="project_name"
          rules={[{ required: true, message: t('프로젝트명을 입력해주세요.') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("라이센스키")}
          name="license_key"
          rules={[{ required: true, message: t('라이센스키를 입력해주세요.') }]}
        >
          <Input disabled={selectData?.id !== null} />
        </Form.Item>
        {/* <Form.Item label='서비스 시작' name='service_start'>
            <DatePicker format={dateFormat} disabled />
          </Form.Item>
          <Form.Item label='서비스 종료' name='service_end'>
            <DatePicker format={dateFormat} disabled />
          </Form.Item> */}
        {/* <Form.Item
            label='서비스 유형'
            name='service_type'
            rules={[{ required: true, message: '서비스 유형을 입력해 주세요' }]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label='서비스 등급'
            name='service_level'
            rules={[{ required: true, message: '서비스 레벨을 입력해 주세요' }]}
          >
            <Select defaultValue='1' style={{ width: 120 }} disabled>
              <Option value='1'>1등급</Option>
              <Option value='2'>2등급</Option>
              <Option value='3'>3등급</Option>
              <Option value='4'>4등급</Option>
            </Select>
          </Form.Item> */}

        <div style={{ textAlign: 'right', paddingTop: '30px' }}>
          <Space>
            {selectData?.id !== null && (
              <Popconfirm
                title={t("삭제 하시겠습니까?")}
                onConfirm={deleteAccount}
                // onCancel={cancel}
                okText={t("삭제")}
                cancelText={t("취소")}
              >
                <Button danger>{t("프로젝트삭제")}</Button>
              </Popconfirm>
            )}
            <Button type="primary" htmlType="submit">
              {t("저장")}
            </Button>
          </Space>
        </div>
      </Form>
    </Modal>
  );
};

export default DesktopAdminProjectTableModal;
