import api from '@/api/api';
import { ProjectContext } from '@/common/project-provider';
import { IAdminMenuPermissionProps } from '@/common/type';
import Loading from '@/components/loading';
// import { IPaginationProps } from '@/panel/Assets/Evaluation/type';
import { Button, Empty, Tabs } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import DesktopDangersTechEvaluationContentHeader from './desktop-dangers-techEvaluation-content-header';
import DesktopDangersTechEvaluationContentTable from './desktop-dangers-techEvaluation-content-table';
import { ITechEvaluationDataSource, ITechPageParamsProps } from './type';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopDangersTechEvaluationGroupProps {
  pageInfo: IAdminMenuPermissionProps;
}

const Container = styled.div`
  .ant-form-item {
    margin: 0;
  }
`;
const EmptyContainer = styled.div`
  padding: 150px 0;
`;

const getAssetGroups = async ({ projectId }) => {
  const result = await api.getAssetGroups({ project_id: projectId });

  return result.data;
};

const getRiskTechResult = async props => {
  const result = await api.getRiskTechResult({ ...props });

  return result.data;
};

const DesktopDangersTechEvaluationGroup: React.FunctionComponent<
  IDesktopDangersTechEvaluationGroupProps
> = ({ pageInfo }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { projectId } = useContext(ProjectContext);
  const [groupItems, setGroupItems] = useState([]);
  const [dataSource, setDataSource] = useState<ITechEvaluationDataSource>(null);
  const [groupId, setGroupId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 5,
  });
  const [searchParam, setSearchParam] = useState<ITechPageParamsProps>({
    col: '',
    keyword: '',
    asset_group: '',
    check_code: '',
    check_desc: '',
    risk_value: '',
  });

  const onChangeHandler = id => {
    setGroupId(id);
  };

  useEffect(() => {
    getAssetGroups({ projectId }).then(datas => {
      setIsLoading(false);
      setGroupItems(
        datas.map(group => ({
          label: group.group_name,
          key: `${group.id}`,
          children: (
            <>
              <DesktopDangersTechEvaluationContentHeader
                dataSource={dataSource}
                groupId={groupId === null ? group.id : groupId}
                groupName={group.group_name}
                pagination={pagination}
                setPagination={setPagination}
                setDataSource={setDataSource}
                pageInfo={pageInfo}
                riskTechAPI={getRiskTechResult}
                searchParam={searchParam}
                setSearchParam={setSearchParam}
              />
              <DesktopDangersTechEvaluationContentTable
                dataSource={dataSource}
                setDataSource={setDataSource}
                groupId={groupId === null ? group.id : groupId}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                pagination={pagination}
                setPagination={setPagination}
                riskTechAPI={getRiskTechResult}
                searchParam={searchParam}
              />
            </>
          ),
        }))
      );
    });
  }, [
    dataSource,
    groupId,
    isLoading,
    pageInfo,
    pagination,
    projectId,
    searchParam,
  ]);

  if (isLoading) return <Loading />;

  return (
    <Container>
      {groupItems.length > 0 ? (
        <Tabs onChange={onChangeHandler} type="card" items={groupItems} />
      ) : (
        <EmptyContainer>
          <Empty description={t("자산그룹을 추가 해주세요.")}>
            <Button onClick={() => navigate('/Admin/AssetsGroup')}>
              {t('자산그룹관리 바로가기')}
            </Button>
          </Empty>
        </EmptyContainer>
      )}
    </Container>
  );
};

export default DesktopDangersTechEvaluationGroup;
