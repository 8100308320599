interface IDefaultProps {
  company_id: number;
  table_size: ITableSizeProps;
}

type ITableSizeProps = 'small' | 'middle' | 'large'

const defaultInfo: IDefaultProps = {
  company_id: 1,
  table_size: 'small'
};

export default defaultInfo;
