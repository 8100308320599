import {
  Button,
  DatePicker,
  Form,
  Input,
  Popconfirm,
  Select,
  Space,
  message,
} from 'antd';
import { ICompanyList, ILicenseProps } from './type';
import React, { useMemo } from 'react';
import api from '@/api/api';
import moment from 'moment';
import Modal from '@/components/modal';

const { Option } = Select;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopAdminProjectTableModalProps {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  selectData: ILicenseProps;
  companyList: ICompanyList[];
}

// const setProject = async datas => {
//   const result = await api.setProject(datas);

//   return result.data;
// };

// const deleteProject = async project_id => {
//   const result = await api.deleteProject(project_id);

//   return result.data;
// };

const setAdminLicense = async datas => {
  const result = await api.setAdminLicense(datas);

  return result.data;
};

const deleteAdminLicense = async ({ id }) => {
  const result = await api.deleteAdminLicense(id);

  return result.data;
};

const DesktopAdminProjectTableModal: React.FunctionComponent<
  IDesktopAdminProjectTableModalProps
> = ({ isModalVisible, setIsModalVisible, selectData, companyList }) => {
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const fields = useMemo(() => {
    const fieldDatas = [];

    if (selectData !== null) {
      for (const [key, value] of Object.entries(selectData)) {
        // console.log(`${key} : ${value}`);
        fieldDatas.push({
          name: [key],
          value:
            key === 'service_end' || key === 'service_start'
              ? moment(value)
              : value,
        });
      }
    }

    return fieldDatas;
  }, [selectData]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = value => {
    const projectData = {
      ...value,
      service_start: value['service_start'].format('YYYY-MM-DD'),
      service_end: value['service_end'].format('YYYY-MM-DD'),
    };
    // console.log(projectData);

    // setProject(projectData).then(() => {
    //   setIsModalVisible(false);
    // });

    setAdminLicense({ datas: projectData }).then(() => {
      handleCancel();
    });
  };

  const confirm = e => {
    deleteAdminLicense({ id: selectData.id }).then(() => {
      console.log(e);
      message.success('Click on Yes');
      setIsModalVisible(false);
    });
  };

  const cancel = e => {
    console.log(e);
    message.error('Click on No');
  };

  const dateFormat = 'YYYY/MM/DD';

  return (
    <Modal
      title="프로젝트 관리"
      open={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        fields={fields}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item name="id" hidden={true}>
          <Input />
        </Form.Item>
        <Form.Item
          label="프로젝트명"
          name="project_name"
          rules={[{ required: true, message: '프로젝트명을 입력해 주세요' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="라이센스키" name="license_key">
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="서비스 시작"
          name="service_start"
          rules={[{ required: true, message: '서비스 시작일을 선택해 주세요' }]}
        >
          <DatePicker format={dateFormat} />
        </Form.Item>
        <Form.Item
          label="서비스 종료"
          name="service_end"
          rules={[{ required: true, message: '서비스 종료일을 선택해 주세요' }]}
        >
          <DatePicker format={dateFormat} />
        </Form.Item>
        {/* <Form.Item
            label='서비스 유형'
            name='service_type'
            rules={[{ required: true, message: '서비스 유형을 입력해 주세요' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='서비스 등급'
            name='service_level'
            rules={[{ required: true, message: '서비스 레벨을 입력해 주세요' }]}
          >
            <Select defaultValue='1' style={{ width: 120 }}>
              <Option value='1'>1등급</Option>
              <Option value='2'>2등급</Option>
              <Option value='3'>3등급</Option>
              <Option value='4'>4등급</Option>
            </Select>
          </Form.Item> */}
        <Form.Item
          label="회사 리스트"
          name="company_id"
          rules={[{ required: true, message: '서비스 레벨을 입력해 주세요' }]}
        >
          <Select defaultValue="1" style={{ width: 120 }}>
            {companyList.map(company => {
              return (
                <Option value={company.id} key={company.id}>
                  {company.company_name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <div style={{ textAlign: 'right', paddingTop: '30px' }}>
          <Space>
            <Popconfirm
              title="프로젝트를 삭제 하시겠습니까?"
              onConfirm={confirm}
              onCancel={cancel}
              okText="예"
              cancelText="아니요"
            >
              <Button danger>삭제</Button>
            </Popconfirm>

            <Button type="primary" htmlType="submit">
              저장
            </Button>
          </Space>
        </div>
      </Form>
    </Modal>
  );
};

export default DesktopAdminProjectTableModal;
