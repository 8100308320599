import React, { useContext } from 'react';
import styled from 'styled-components';
import { Column } from '@ant-design/plots';
import { IChartDataProps } from './type';
import { ThemeContext } from '@/common/theme-provider';
interface IDesktopDangersRiskChartProps {
  data: IChartDataProps[];
}

const Container = styled.div`
  border: 1px solid ${props => props.theme.layout.searchBorderColor};
  box-shadow: 2px 0 8px 0 rgb(29 35 41 / 5%);
  padding: 30px;
  border-radius: 10px;
`;

const DesktopDangersRiskChart: React.FunctionComponent<
  IDesktopDangersRiskChartProps
> = ({ data }) => {
  const { isDarkMode } = useContext(ThemeContext);

  const config: any = {
    data,
    isGroup: true,
    xField: 'risk',
    yField: 'value', 
    seriesField: 'name',
    dodgePadding: 2,
    intervalPadding: 20,
    theme: isDarkMode ? 'dark' : 'default',
    label: {
      position: 'middle',
      layout: [
        {
          type: 'interval-adjust-position',
        },
        {
          type: 'interval-hide-overlap',
        },
        {
          type: 'adjust-color',
        },
      ],
    },
  };

  return (
    <Container>
      <Column {...config} />
    </Container>
  );
};

export default DesktopDangersRiskChart;
