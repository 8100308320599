import { Button } from 'antd';
import { IAdminMenuPermissionProps } from '@/common/type';
import { PlusOutlined } from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface IDesktopCertificationRecordHeaderProps {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setIsNewModal?: React.Dispatch<React.SetStateAction<boolean>>;
  type: string;
  pageInfo: IAdminMenuPermissionProps;
}

const NewRecordContainer = styled.div`
  float: right;
  text-align: right;
  position: sticky;
  right: 0;
  top: 70px;
  z-index: 6;
`;

const DesktopCertificationRecordHeader: React.FunctionComponent<
  IDesktopCertificationRecordHeaderProps
> = ({ setIsModalVisible, setIsNewModal, type, pageInfo }) => {
  const { t } = useTranslation();
  return (
    <>
      {type === 'record' && pageInfo.perm_write && (
        <NewRecordContainer>
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              setIsModalVisible(true);
              setIsNewModal(true);
            }}
          >
            {t("증적목록")}{t("추가")}
          </Button>
        </NewRecordContainer>
      )}
    </>
  );
};

export default DesktopCertificationRecordHeader;
