import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

interface ICustomLink {
  url?: string;
  name?: string;
}

const CustomLink: React.FunctionComponent<ICustomLink> = ({ ...props }) => {
  const { url, name } = props;
  const navigate = useNavigate();
  const location = useLocation();

  return location.pathname !== url ? (
    <Link to={url}>{name}</Link>
  ) : (
    <Link
      to={''}
      onClick={() => {
        navigate(0);
      }}
    >
      {name}
    </Link>
  );
};

export default CustomLink;
