import { Button, Descriptions, Spin, Table } from "antd";
import React, { useCallback, useEffect, useState } from "react";

import { IAdminMenuPermissionProps } from "@/common/type";
import { ISystemInfo } from "./type";
import { LoadingOutlined } from "@ant-design/icons";
import api from "@/api/api";
import styled from "styled-components";
import defaultInfo from "@/common/site-info";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopSystemSetupUpdateProps {
  systemInfo: ISystemInfo;
  setSystemInfo: React.Dispatch<React.SetStateAction<ISystemInfo>>;
  pageInfo: IAdminMenuPermissionProps;
}

interface IDataSource {
  key: number;
  target: string;
  now_version: string;
  last_version: string;
  update: {
    target: string;
    isUpdate: boolean;
  };
}

interface IColumns {
  title: string;
  dataIndex: string;
  key: string;
  align?: "left" | "center" | "right";
  width?: string;
  render?: any;
}

const LoadingContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 10;
`;

const StyledSpin = styled(Spin)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const getAdminUpdate = async ({ update }) => {
  const result = await api.getAdminUpdate({ update });

  return result.data;
};

const DesktopSystemSetupUpdate: React.FunctionComponent<
  IDesktopSystemSetupUpdateProps
> = ({ systemInfo, setSystemInfo, pageInfo }) => {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState<IDataSource[]>([]);
  const [isUpdate, setIsUpdate] = useState(false);

  const updateHandler = useCallback(
    (target) => {
      setIsUpdate(true);
      getAdminUpdate({ update: target }).then((data) => {
        setSystemInfo(data);
        setIsUpdate(false);
      });
    },
    [setSystemInfo]
  );

  const columns: IColumns[] = [
    {
      title: t("업데이트 대상"),
      dataIndex: "target",
      align: "center",
      key: "target",
    },
    {
      title: t("현재버전"),
      dataIndex: "now_version",
      align: "center",
      key: "now_version",
    },
    {
      title: t("최신버전"),
      dataIndex: "last_version",
      align: "center",
      key: "last_version",
    },
  ];

  useEffect(() => {
    if (systemInfo !== null) {
      setDataSource([
        {
          key: 0,
          target: "UI",
          now_version: systemInfo.ui_version,
          last_version: systemInfo.ui_last_version,
          update: {
            target: "ui",
            isUpdate: systemInfo.ui_version !== systemInfo.ui_last_version,
          },
        },
        {
          key: 1,
          target: "API",
          now_version: systemInfo.api_version,
          last_version: systemInfo.api_last_version,
          update: {
            target: "api",
            isUpdate: systemInfo.api_version !== systemInfo.api_last_version,
          },
        },
        {
          key: 2,
          target: "DB",
          now_version: systemInfo.db_version,
          last_version: systemInfo.db_last_version,
          update: {
            target: "db",
            isUpdate: systemInfo.db_version !== systemInfo.db_last_version,
          },
        },
      ]);
    }
  }, [systemInfo]);

  const columnsButtons: IColumns[] = [
    {
      title: t("업데이트"),
      dataIndex: "update",
      align: "center",
      width: "20%",
      key: "update",
      // eslint-disable-next-line react/display-name
      render: (value) => {
        return (
          <Button
            type="primary"
            disabled={!value.isUpdate}
            onClick={() => updateHandler(value.target)}
          >
            {t("업데이트")}
          </Button>
        );
      },
    },
  ];

  return (
    <>
      {isUpdate && (
        <LoadingContainer>
          <StyledSpin
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          />
        </LoadingContainer>
      )}
      <Descriptions bordered>
        <Descriptions.Item label={t("업데이트")} span={3}>
          <Table
            dataSource={dataSource}
            columns={
              pageInfo.perm_write
                ? [...columns, ...columnsButtons]
                : [...columns]
            }
            size={defaultInfo.table_size}
            pagination={false}
          />
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default DesktopSystemSetupUpdate;
