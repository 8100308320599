export interface IAppContextData {
  config: IConfig;
  children?: React.ReactNode;
}

export interface IConfig {
  isMobile: boolean;
  isBrowser: boolean;
}

export interface ISignUpValue {
  username: string;
  password: string;
  fullname: string;
  email: string;
  userhp: number;
  compony_id: string;
  dept_cd: string;
  rank_cd: string;
}

export enum ViewTypeEnum {
  audit = 1,
  standard = 2,
  admin = 3,
}

export interface ICodeData {
  item_cd: string;
  item_name: string;
  item_sort: number;
  item_attr: string | null;
  item_desc: string | null;
  company_id: number;
}

export interface IIndexProps {
  readonly id?: string;
}

export interface IAdminMenuProps {
  children: IAdminMenuProps[];
  id: number;
  key: string;
  name: string | JSX.Element;
  name_id: string;
  perm_view: boolean;
  perm_write: boolean;
  url: string;
}

export interface IAdminMenuPermissionProps {
  perm_view: boolean;
  perm_write: boolean;
}

export interface IContentDataProps {
  pageable: {
    sort: {
      sorted: boolean;
      unsorted: boolean;
      empty: boolean;
    };
    offset: number;
    page_number: number;
    page_size: number;
    unpaged: boolean;
    paged: boolean;
  };
  last: boolean;
  total_element: number;
  total_pages: number;
  size: number;
  number: number;
  sort: {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  };
  first: boolean;
  number_of_elements: number;
  empty: boolean;
}
