import { Button, Select, Space, Table } from 'antd';
import { EditableCell, EditableRow } from './assets-editable-table';
import { IAnalysisDataProps, IColumnProps } from './type';
import React, { useCallback, useContext, useMemo, useState, useRef } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { IAdminMenuPermissionProps } from '@/common/type';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import { openNotification } from '@/common/util';
import styled from 'styled-components';
import defaultInfo from '@/common/site-info';
import Modal from '@/components/modal';
import { Excel } from "antd-table-saveas-excel";
import { useTranslation } from 'react-i18next';


const { Option } = Select;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopDangersAnalysisHeaderProps {
  analysisData: IAnalysisDataProps[];
  setAnalysisData: React.Dispatch<React.SetStateAction<IAnalysisDataProps[]>>;
  isEdit: boolean;
  pageInfo: IAdminMenuPermissionProps;
}

const Container = styled.div`
  position: sticky;
  right: 0;
  top: 70px;
  z-index: 6;
  margin-bottom: 20px;
  display: inline-block;
  float: right;
`;

const DownloadButton = styled(Button)`
  a {
    display: inline-block;
    padding-left: 10px;
  }
`;

const setRiskThreat = async ({ projectId, datas }) => {
  const result = await api.setRiskThreat({ project_id: projectId, datas });

  return result.data;
};

const DesktopDangersAnalysisHeader: React.FunctionComponent<
  IDesktopDangersAnalysisHeaderProps
> = ({ analysisData, setAnalysisData, isEdit, pageInfo }) => {
  const { t } = useTranslation();
  const { projectId } = useContext(ProjectContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [addData, setAddData] = useState<IAnalysisDataProps[]>([
    {
      key: analysisData.length,
      threat_code: ``,
      cate1: ``,
      cate2: ``,
      threat_desc: ``,
      secret_yn: false,
      integ_yn: false,
      avail_yn: false,
      grade: 1,
    },
  ]);

  const columns: IColumnProps[] = [
    {
      title: t('위협코드'),
      dataIndex: 'threat_code',
      key: 'threat_code',
      align: 'center',
      width: '10%',
      label: t('위협코드'),
      editable: true,
    },
    {
      title: t('대분류'),
      dataIndex: 'cate1',
      key: 'cate1',
      align: 'center',
      label: t('대분류'),
      editable: true,
    },
    {
      title: t('중분류'),
      dataIndex: 'cate2',
      key: 'cate2',
      align: 'center',
      label: t('중분류'),
      editable: true,
    },
    {
      title: t('위협내용'),
      dataIndex: 'threat_desc',
      key: 'threat_desc',
      align: 'center',
      label: t('위협내용'),
      editable: true,
    },
    {
      title: t('기밀성'),
      dataIndex: 'secret_yn',
      key: 'secret_yn',
      align: 'center',
      width: '7%',
      label: t('기밀성'),
      // eslint-disable-next-line react/display-name
      render: (value, record) => {
        return (
          <Select
            defaultValue={value ? '○' : '✕'}
            onChange={val => {
              handleChange(record, { secret_yn: val === '○' });
            }}
          >
            <Option value="○">○</Option>
            <Option value="✕">✕</Option>
          </Select>
        );
      },
      editable: false,
    },
    {
      title: t('무결성'),
      dataIndex: 'integ_yn',
      key: 'integ_yn',
      align: 'center',
      width: '7%',
      label: t('무결성'),
      // eslint-disable-next-line react/display-name
      render: (value, record) => {
        return (
          <Select
            defaultValue={value ? '○' : '✕'}
            onChange={val => {
              handleChange(record, { integ_yn: val === '○' });
            }}
          >
            <Option value="○">○</Option>
            <Option value="✕">✕</Option>
          </Select>
        );
      },
      editable: false,
    },
    {
      title: t('가용성'),
      dataIndex: 'avail_yn',
      key: 'avail_yn',
      align: 'center',
      width: '7%',
      label: t('가용성'),
      // eslint-disable-next-line react/display-name
      render: (value, record) => {
        return (
          <Select
            defaultValue={value ? '○' : '✕'}
            onChange={val => {
              handleChange(record, { avail_yn: val === '○' });
            }}
          >
            <Option value="○">○</Option>
            <Option value="✕">✕</Option>
          </Select>
        );
      },
      editable: false,
    },
    {
      title: t('위협등급'),
      dataIndex: 'grade',
      key: 'grade',
      align: 'center',
      width: '8%',
      label: t('위협등급'),
      // eslint-disable-next-line react/display-name
      render: (value, record) => {
        return (
          <Select
            defaultValue={value}
            onChange={val => {
              handleChange(record, { grade: Number(val) });
            }}
          >
            <Option value="1">1</Option>
            <Option value="2">2</Option>
            <Option value="3">3</Option>
            <Option value="4">4</Option>
            <Option value="5">5</Option>
          </Select>
        );
      },
      editable: false,
    },
  ];

  const handleChange = useCallback((record, obj) => {
    // console.log(value, record);
    setAddData(prev =>
      prev.map(item =>
        item.id === record.id ? { ...item, ...obj } : { ...item }
      )
    );
  }, []);

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
      t,
    },
  };

  const inColumns = useMemo<IColumnProps[]>(() => {
    return columns.map(col => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave,
        }),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, t ]);

  const handleSave = useCallback(
    row => {
      const newData = [...addData];
      const index = newData.findIndex(item => row.key === item.key);
      const item = newData[index];
      newData.splice(index, 1, { ...item, ...row });

      setAddData(newData);
    },
    [addData]
  );

  const tableRef = useRef(null);

  const excelColumns = [
    {
      title: t('위협코드'),
      dataIndex: 'threat_code',
      key: 'threat_code',
      label: t('위협코드'),
    },
    {
      title: t('대분류'),
      dataIndex: 'cate1',
      key: 'cate1',
      label: t('대분류'),
    },
    {
      title: t('중분류'),
      dataIndex: 'cate2',
      key: 'cate2',
      label: t('중분류'),
    },
    {
      title: t('위협내용'),
      dataIndex: 'threat_desc',
      key: 'threat_desc',
      label: t('위협내용'),
    },
    {
      title: t('기밀성'),
      dataIndex: 'secret_yn',
      key: 'secret_yn',
      label: t('기밀성'),
    },
    {
      title: t('무결성'),
      dataIndex: 'integ_yn',
      key: 'integ_yn',
      label: t('무결성'),
    },
    {
      title: t('가용성'),
      dataIndex: 'avail_yn',
      key: 'avail_yn',
      label: t('가용성'),
    },
    {
      title: t('위협등급'),
      dataIndex: 'grade',
      key: 'grade',
      label: t('위협등급'),
    },
  ];

  const excelData = useMemo(() => {
    return analysisData.map(data => ({
      ...data,
      avail_yn: data.avail_yn ? '○' : '×',
      integ_yn: data.integ_yn ? '○' : '×',
      secret_yn: data.secret_yn ? '○' : '×',
    }));
  }, [analysisData]);

  const handleExcelDownload = () => {
    const excel = new Excel();
        excel
          .addSheet("Sheet1")
          .addColumns(excelColumns)
          .addDataSource(excelData, {
            str2Percent: true
          })
          .saveAs(`risk_analysis_result.xlsx`);
      };


  const saveHandler = useCallback(() => {
    setIsSaveLoading(true);
    setRiskThreat({ projectId, datas: analysisData }).then(() => {
      const notiText = {
        description: t("완료"),
      };

      openNotification(notiText);
      setIsSaveLoading(false);
    });
  }, [analysisData, projectId, t]);

  const handleOk = () => {
    setRiskThreat({ projectId, datas: [...analysisData, ...addData] }).then(
      datas => {
        const notiText = {
          description: t("완료"),
        };

        openNotification(notiText);
        setAnalysisData(datas.map((data, index) => ({ ...data, key: index })));
        setIsModalVisible(false);
      }
    );
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    pageInfo.perm_write && (
      <Container>
        <Space size="small">
          <Button
            type="primary"
            onClick={saveHandler}
            disabled={!isEdit}
            loading={isSaveLoading}
          >
            {t("위험분석 저장")}
          </Button>
          <Button onClick={() => setIsModalVisible(true)}>{t("추가")}</Button>
          <DownloadButton
            icon={<DownloadOutlined />}
            onClick={handleExcelDownload}>
              {t("결과 다운로드")}
          </DownloadButton>
        </Space>
        <Modal
          title={t("위험분석 추가")}
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1200}
        >
          <Table
            ref={tableRef}
            dataSource={addData}
            columns={inColumns}
            loading={false}
            pagination={false}
            size={defaultInfo.table_size}
            components={components}
            rowClassName={() => 'editable-row'}
          />
        </Modal>
      </Container>
    )
  );
};

export default DesktopDangersAnalysisHeader;
