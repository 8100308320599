import { Popconfirm, Select } from 'antd';
import { IAnalysisDataProps, IColumnProps, IDeleteColumn } from './type';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import DesktopDangersAnalysisHeader from './desktop-dangers-analysis-header';
import DesktopDangersAnalysisTable from './desktop-dangers-analysis-table';
import { IAdminMenuPermissionProps } from '@/common/type';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import { openNotification } from '@/common/util';
import styled from 'styled-components';
import DeleteButton from "@/components/deletebutton";
import { useTranslation } from 'react-i18next';

const { Option } = Select;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopDangersAnalysisProps {
  pageInfo: IAdminMenuPermissionProps;
}

const Text = styled.div`
  text-align: left;
`;

const getRiskThreat = async ({ projectId }) => {
  const result = await api.getRiskThreat({ project_id: projectId });

  return result.data;
};
const removeRiskThreat = async ({ projectId, id }) => {
  const result = await api.removeRiskThreat({ project_id: projectId, id });

  return result.data;
};

const DesktopDangersAnalysis: React.FunctionComponent<
  IDesktopDangersAnalysisProps
> = ({ pageInfo }) => {
  const { t } = useTranslation();
  const { projectId } = useContext(ProjectContext);
  const [isLoading, setIsLoading] = useState(true);
  const [analysisData, setAnalysisData] = useState<IAnalysisDataProps[]>([]);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    getRiskThreat({ projectId }).then(datas => {
      setIsLoading(false);
      setAnalysisData(datas.map((data, index) => ({ ...data, key: index })));
    });
  }, [projectId, t]);

  const handleChange = useCallback((record, obj) => {
    // console.log(value, record);
    setAnalysisData(prev =>
      prev.map(item =>
        item.id === record.id ? { ...item, ...obj } : { ...item }
      )
    );
    setIsEdit(true);
  }, []);

  const handleDelete = useCallback(
    id => {
      removeRiskThreat({ projectId, id }).then(() => {
        setAnalysisData(prev => {
          return prev.filter(item => item.id !== id);
        });
        const notiText = {
          description: t("완료"),
        };

        openNotification(notiText);
      });
    },
    [projectId, t]
  );

  const deleteColumn = useMemo<IDeleteColumn>(() => {
    return {
      del_yn: false,
      editable: false,
      field_name: t('삭제'),
      field_option: null,
      id: null,
      key: 'operation',
      object_name: 'operation',
      title: '',
      dataIndex: 'operation',
      width: '60px',
      align: 'center',
      fixed: 'right',
      // eslint-disable-next-line react/display-name
      render: (_, record) => (
        <Popconfirm
          title={`${record.threat_code} ${t("삭제 하시겠습니까?")}`}
          onConfirm={() => handleDelete(record.id)}
        >
          <DeleteButton>{t("삭제")}</DeleteButton>
        </Popconfirm>
      ),
    };
  }, [handleDelete, t]);

  const columns: IColumnProps[] = [
    {
      title: t('위협코드'),
      dataIndex: 'threat_code',
      key: 'threat_code',
      align: 'center',
      width: '10%',
      label: t('위협코드'),
      editable: true,
    },
    {
      title: t('대분류'),
      dataIndex: 'cate1',
      key: 'cate1',
      align: 'center',
      label: t('대분류'),
      editable: true,
    },
    {
      title: t('중분류'),
      dataIndex: 'cate2',
      key: 'cate2',
      align: 'center',
      label: t('중분류'),
      editable: true,
      render: value => {
        return <Text>{value}</Text>;
      },
    },
    {
      title: t('위협내용'),
      dataIndex: 'threat_desc',
      key: 'threat_desc',
      align: 'center',
      label: t('위협내용'),
      editable: true,
      render: value => {
        return <Text>{value}</Text>;
      },
    },
    {
      title: t('기밀성'),
      dataIndex: 'secret_yn',
      key: 'secret_yn',
      align: 'center',
      width: '7%',
      label: t('기밀성'),
      // eslint-disable-next-line react/display-name
      render: (value, record) => {
        return pageInfo.perm_write ? (
          <Select
            defaultValue={value ? '○' : '✕'}
            onChange={val => {
              handleChange(record, { secret_yn: val === '○' });
            }}
          >
            <Option value="○">○</Option>
            <Option value="✕">✕</Option>
          </Select>
        ) : value ? (
          '○'
        ) : (
          '✕'
        );
      },
      editable: false,
    },
    {
      title: t('무결성'),
      dataIndex: 'integ_yn',
      key: 'integ_yn',
      align: 'center',
      width: '7%',
      label: t('무결성'),
      // eslint-disable-next-line react/display-name
      render: (value, record) => {
        return pageInfo.perm_write ? (
          <Select
            defaultValue={value ? '○' : '✕'}
            onChange={val => {
              handleChange(record, { integ_yn: val === '○' });
            }}
          >
            <Option value="○">○</Option>
            <Option value="✕">✕</Option>
          </Select>
        ) : value ? (
          '○'
        ) : (
          '✕'
        );
      },
      editable: false,
    },
    {
      title: t('가용성'),
      dataIndex: 'avail_yn',
      key: 'avail_yn',
      align: 'center',
      width: '7%',
      label: t('가용성'),
      // eslint-disable-next-line react/display-name
      render: (value, record) => {
        return pageInfo.perm_write ? (
          <Select
            defaultValue={value ? '○' : '✕'}
            onChange={val => {
              handleChange(record, { avail_yn: val === '○' });
            }}
          >
            <Option value="○">○</Option>
            <Option value="✕">✕</Option>
          </Select>
        ) : value ? (
          '○'
        ) : (
          '✕'
        );
      },
      editable: false,
    },
    {
      title: t('위협등급'),
      dataIndex: 'grade',
      key: 'grade',
      align: 'center',
      width: '7%',
      label: t('위협등급'),
      // eslint-disable-next-line react/display-name
      render: (value, record) => {
        return pageInfo.perm_write ? (
          <Select
            defaultValue={value}
            onChange={val => {
              handleChange(record, { grade: Number(val) });
            }}
          >
            <Option value="1">1</Option>
            <Option value="2">2</Option>
            <Option value="3">3</Option>
            <Option value="4">4</Option>
            <Option value="5">5</Option>
          </Select>
        ) : (
          value
        );
      },
      editable: false,
    },
  ];

  return (
    <>
      <DesktopDangersAnalysisHeader
        analysisData={analysisData}
        setAnalysisData={setAnalysisData}
        isEdit={isEdit}
        pageInfo={pageInfo}
      />
      <DesktopDangersAnalysisTable
        isLoading={isLoading}
        analysisData={analysisData}
        setAnalysisData={setAnalysisData}
        columns={
          pageInfo.perm_write ? [...columns, deleteColumn] : [...columns]
        }
        setIsEdit={setIsEdit}
      />
    </>
  );
};

export default DesktopDangersAnalysis;
