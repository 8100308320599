import React, { useCallback, useContext, useEffect, useState } from 'react';

import DesktopAssetEvaluationGroup from '@/panel/Assets/Evaluation/desktop-assets-evaluation-group';
import { IAdminMenuPermissionProps } from '@/common/type';
import { IAssetEvaluationDataProps, IPaginationProps } from './type';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import styled from 'styled-components';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopAssetEvaluationProps {
    pageInfo: IAdminMenuPermissionProps;
}

const Container = styled.div`
    .ant-form-item {
        margin: 0;
    }
`;

const getAssetGroups = async ({ projectId }) => {
    const result = await api.getAssetGroups({ project_id: projectId });

    return result.data;
};

const getAssetEvalPage = async ({
    project_id,
    group_id,
    keyword,
    date,
    size,
    page,
}) => {
    const result = await api.getAssetEvalPage({
        project_id,
        group_id,
        keyword,
        date,
        size,
        page,
    });

    return result.data;
};

const DesktopAssetEvaluation: React.FunctionComponent<
    IDesktopAssetEvaluationProps
> = ({ pageInfo }) => {
    const { projectId } = useContext(ProjectContext);
    const [groups, setGroups] = useState([]);
    const [assetList, setAssetList] = useState<IAssetEvaluationDataProps[]>([]);
    const [selectGroupId, setSelectGroupId] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [pagination, setPagination] = useState<IPaginationProps>({
        current: 1,
        pageSize: 20,
        total: 5,
    });

    useEffect(() => {
        getAssetGroups({ projectId }).then(data => {
            const groupsData = data.map((item, index) => {
                return { ...item, key: `${index}` };
            });
            setIsLoading(false);
            setSelectGroupId(groupsData.length > 0 ? groupsData[0].id : 0);
            setGroups(groupsData);
        });
    }, [projectId]);

    const fetch = useCallback(
        (page = pagination) => {
            setAssetList([]); // 페이지 이동시 값 리셋
            setTableLoading(true);
            getAssetEvalPage({
                project_id: projectId,
                group_id: selectGroupId,
                keyword: '',
                date: '',
                size: page.pagination.pageSize || 100,
                page: page.pagination.current || 1,
            }).then(datas => {
                const { content, total_elements } = datas;

                setAssetList(
                    content.map((data, index) => ({ ...data, key: index }))
                );
                setTableLoading(false);
                setPagination(prev => {
                    return {
                        ...prev,
                        pageSize: page.pagination.pageSize,
                        current: page.pagination.current,
                        total: total_elements,
                    };
                });
                window.scrollTo(0, 0);
            });
        },
        [pagination, projectId, selectGroupId]
    );

    useEffect(() => {
        if (selectGroupId !== null) {
            fetch({ pagination });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId, selectGroupId, isModalVisible]);

    return (
        <Container>
            <DesktopAssetEvaluationGroup
                groups={groups}
                getCertTechAPI={getAssetEvalPage}
                selectGroupId={selectGroupId}
                setSelectGroupId={setSelectGroupId}
                assetList={assetList}
                setAssetList={setAssetList}
                isLoading={isLoading}
                tableLoading={tableLoading}
                setTableLoading={setTableLoading}
                pageInfo={pageInfo}
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                pagination={pagination}
                setPagination={setPagination}
                fetch={fetch}
            />
        </Container>
    );
};

export default DesktopAssetEvaluation;
