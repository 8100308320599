import api from '@/api/api';
import { ProjectContext } from '@/common/project-provider';
import { openNotification } from '@/common/util';
import { Button, Space, Tree } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { IMenuProps } from './type';
import Modal from '@/components/modal';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopAdminAccountTableMenuModalProps {
    isAccountMenuModalVisible: boolean;
    setIsAccountMenuModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    userId: string;
}

type ITargetProps = 'perm_view' | 'perm_write';
type ITypeProps = 'checked' | 'clear';

const TitleTree = styled(Tree)`
    padding-top: 80px;
`;
const SearchTree = styled(Tree)`
    .ant-tree-indent {
        display: none;
    }
    .ant-tree-treenode {
        height: 28px;
    }
    .ant-tree-node-selected {
        background-color: #fff !important;
    }
    .ant-tree-switcher {
        display: none;
    }
`;
const EditTree = styled(SearchTree)``;
const Title = styled.div`
    width: 170px;
    display: inline-block;
`;
const ToggleArea = styled.div`
    margin-bottom: 5px;
    display: inline-block;
    button {
        margin-bottom: 5px;
    }
`;
const SearchTreeContainer = styled.div`
    display: inline-block;
    width: 153px;
    padding: 20px;
    box-sizing: border-box;
    border: 1px solid ${props => props.theme.layout.searchBorderColor};
    border-radius: 10px;
    box-shadow: 2px 0 8px 0 rgb(29 35 41 / 5%);
`;

const getAdminUserMenu = async ({ projectId, id }) => {
    const result = await api.getAdminUserMenu({ project_id: projectId, id });

    return result.data;
};

const setAdminUserMenu = async ({ projectId, id, datas }) => {
    const result = await api.setAdminUserMenu({
        project_id: projectId,
        id,
        datas,
    });

    return result.data;
};

let view_arr = [];
let write_arr = [];
let all_arr = [];

const DesktopAdminAccountTableMenuModal: React.FunctionComponent<
    IDesktopAdminAccountTableMenuModalProps
> = ({ isAccountMenuModalVisible, setIsAccountMenuModalVisible, userId }) => {
    const { t } = useTranslation();
    const { projectId } = useContext(ProjectContext);

    const [viewTreeData, setViewTreeData] = useState<IMenuProps[]>([]);
    const [dataSource, setDataSource] = useState<IMenuProps[]>([]);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [searchCheckedKeys, setSearchCheckedKeys] = useState([]);
    const [editCheckedKeys, setEditCheckedKeys] = useState([]);
    const [autoExpandParent] = useState(true);

    useEffect(() => {
        if (userId) {
            getAdminUserMenu({ projectId, id: userId }).then(datas => {
                setViewTreeData(setDataMenuList(datas)); // 메뉴 세팅

                setExpandedKeys(datas.map(item => item.key)); // 기본 확장 (메뉴, view, write)
                setDefaultCheckedKeys(datas, 'perm_view', view_arr); // 선택된 메뉴 배열 생성
                setDefaultCheckedKeys(datas, 'perm_write', write_arr); // 선택된 메뉴 배열 생성
                setSearchCheckedKeys(view_arr);
                setEditCheckedKeys(write_arr);

                setDataSource(datas); // 보낼 데이터 세팅
                console.log(datas);
            });
        }

        return () => {
            view_arr = [];
            write_arr = [];
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId, userId, t]);

    const setDataMenuList = useCallback(datas => {
        return datas.map(item => {
            if (
                typeof item.children !== 'undefined' &&
                item.children.length > 0
            ) {
                return {
                    ...item,
                    title: item.name,
                    children: setDataMenuList(item.children),
                };
            }

            return {
                ...item,
                title: item.name,
            };
        });
    }, []);

    const setDefaultCheckedKeys = useCallback((datas, target, arr) => {
        datas.forEach((item: IMenuProps) => {
            if (
                typeof item.children !== 'undefined' &&
                item.children.length > 0
            ) {
                return setDefaultCheckedKeys(item.children, target, arr);
            }
            if (item[target]) {
                arr.push(item.key);
            }
        });

        return arr;
    }, []);

    const completeData = useCallback((data, arr, target) => {
        return data.map(item => {
            if (
                typeof item.children !== 'undefined' &&
                item.children.length > 0
            ) {
                return {
                    ...item,
                    [target]: arr.includes(item.key),
                    children: completeData(item.children, arr, target),
                };
            }

            return { ...item, [target]: arr.includes(item.key) };
        });
    }, []);

    const onSearchCheck = useCallback(
        checkedKeysValue => {
            setDataSource(
                completeData(dataSource, checkedKeysValue, 'perm_view')
            );
            setSearchCheckedKeys(checkedKeysValue);
        },
        [completeData, dataSource]
    );
    const onEditCheck = useCallback(
        checkedKeysValue => {
            setDataSource(
                completeData(dataSource, checkedKeysValue, 'perm_write')
            );
            setEditCheckedKeys(checkedKeysValue);
        },
        [completeData, dataSource]
    );

    const handleOk = useCallback(() => {
        console.log(dataSource);

        setAdminUserMenu({ projectId, id: userId, datas: dataSource }).then(
            () => {
                const notiText = {
                    description: t("완료"),
                };

                openNotification(notiText);
                setIsAccountMenuModalVisible(false);
                sessionStorage.removeItem('history');
                window.location.reload();
            }
        );
    }, [dataSource, projectId, setIsAccountMenuModalVisible, userId, t]);

    const handleCancel = () => {
        setIsAccountMenuModalVisible(false);
        view_arr = [];
        write_arr = [];
    };

    const setAllCheckedKeys = useCallback((datas, target) => {
        datas.forEach((item: IMenuProps) => {
            if (
                typeof item.children !== 'undefined' &&
                item.children.length > 0
            ) {
                return setAllCheckedKeys(item.children, target);
            }
            all_arr.push(item.key);
        });

        return all_arr;
    }, []);

    const allCheckedHandler = useCallback(
        (target: ITargetProps, type: ITypeProps = 'checked') => {
            const targetKey =
                type === 'checked' ? setAllCheckedKeys(dataSource, target) : [];

            setDataSource(completeData(dataSource, targetKey, target));
            if (target === 'perm_view') {
                setSearchCheckedKeys(targetKey);
            } else {
                setEditCheckedKeys(targetKey);
            }
        },
        [completeData, dataSource, setAllCheckedKeys]
    );

    const allClearHandler = useCallback(
        target => {
            allCheckedHandler(target, 'clear');
        },
        [allCheckedHandler]
    );

    return (
        <Modal
            title={t("메뉴 접근권한관리")}
            open={isAccountMenuModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={600}
        >
            <Space>
                <TitleTree
                    blockNode
                    expandedKeys={expandedKeys}
                    autoExpandParent={autoExpandParent}
                    treeData={viewTreeData}
                    titleRender={nodeData => {
                        return <Title>{nodeData.title}</Title>;
                    }}
                />
                <SearchTreeContainer>
                    <ToggleArea>
                        <Button onClick={() => allCheckedHandler('perm_view')}>
                            {t("전체선택")}
                        </Button>
                        <Button onClick={() => allClearHandler('perm_view')}>
                            {t("전체해제")}
                        </Button>
                    </ToggleArea>
                    <SearchTree
                        checkable
                        expandedKeys={expandedKeys}
                        autoExpandParent={autoExpandParent}
                        onCheck={onSearchCheck}
                        checkedKeys={searchCheckedKeys}
                        treeData={viewTreeData}
                        titleRender={item => {
                            return (
                                <label title={item.title} key={item.key}>
                                    {t("조회")}
                                </label>
                            );
                        }}
                    />
                </SearchTreeContainer>

                <SearchTreeContainer>
                    <ToggleArea>
                        <Button onClick={() => allCheckedHandler('perm_write')}>
                            {t("전체선택")}
                        </Button>
                        <Button onClick={() => allClearHandler('perm_write')}>
                            {t("전체해제")}
                        </Button>
                    </ToggleArea>
                    <EditTree
                        checkable
                        expandedKeys={expandedKeys}
                        autoExpandParent={autoExpandParent}
                        onCheck={onEditCheck}
                        checkedKeys={editCheckedKeys}
                        treeData={viewTreeData}
                        titleRender={item => {
                            return (
                                <label title={item.title} key={item.key}>
                                    {t("수정")}
                                </label>
                            );
                        }}
                    />
                </SearchTreeContainer>
            </Space>
        </Modal>
    );
};

export default DesktopAdminAccountTableMenuModal;
