import {
  Button,
  ConfigProvider,
  Descriptions,
  Form,
  Input,
  Radio,
  Typography,
} from "antd";
import { FieldData, ISystemInfo } from "./type";
import React, { useCallback, useContext } from "react";
import { darkTheme, lightTheme } from "@/styles/antd-theme";

import { ExclamationCircleOutlined } from "@ant-design/icons";
import FileDragUpload from "@/common/components/dragUpload/FileDragUpload";
import { ThemeContext } from "@/common/theme-provider";
import api from "@/api/api";
import { openNotification } from "@/common/util";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopSystemSetupDefaultProps {
  systemInfo: ISystemInfo;
  setSystemInfo: React.Dispatch<React.SetStateAction<ISystemInfo>>;
  fields: FieldData[];
}

const StyledText = styled(Text)`
  color: ${(props) => props.theme.font.color};
  padding-bottom: 30px;
  display: block;
`;

const setAdminConfig = async ({ data }) => {
  const result = await api.setAdminConfig({ data });

  return result.data;
};

const DesktopSystemSetupDefault: React.FunctionComponent<
  IDesktopSystemSetupDefaultProps
> = ({ systemInfo, setSystemInfo, fields }) => {
  const { t } = useTranslation();
  const { isDarkMode, setIsDarkMode } = useContext(ThemeContext);

  const themeOnChangeHandler = useCallback(
    (e) => {
      const isLightTheme = e.target.value === "L";

      setIsDarkMode(!isLightTheme);
      sessionStorage.setItem("theme", !isLightTheme ? "dark" : "light");

      ConfigProvider.config(isLightTheme ? lightTheme : darkTheme);
    },
    [setIsDarkMode]
  );
  const onFinish = useCallback(
    ({ home_title, home_footer }) => {
      const info = { ...systemInfo, home_title, home_footer };
      console.log(info);

      if (systemInfo !== null) {
        setAdminConfig({ data: { ...info } }).then(() => {
          const notiText = {
            description: t("완료"),
          };

          openNotification(notiText);
        });
      }
    },
    [systemInfo, t]
  );

  return (
    <Descriptions bordered>
      {/* <Descriptions.Item label='언어설정' span={2}>
    <Radio.Group
      defaultValue={systemInfo.language}
      buttonStyle='solid'
      onChange={event => {
        setSystemInfo(prev => ({
          ...prev,
          language: event.target.value
        }));
      }}
    >
      <Radio.Button value='KO'>한국어</Radio.Button>
      <Radio.Button value='EN'>영어</Radio.Button>
    </Radio.Group>
  </Descriptions.Item> */}
      <Descriptions.Item label={t("테마모드")} span={2}>
        <Radio.Group
          defaultValue={systemInfo.mode}
          buttonStyle="solid"
          value={isDarkMode ? "D" : "L"}
          onChange={(event) => {
            themeOnChangeHandler(event);
            setSystemInfo((prev) => ({
              ...prev,
              mode: event.target.value,
            }));
          }}
        >
          <Radio.Button value="L">Light</Radio.Button>
          <Radio.Button value="D">Dark</Radio.Button>
        </Radio.Group>
      </Descriptions.Item>

      <Descriptions.Item label={t("매뉴얼")}>
        <Button
          onClick={() => {
            window.open(
              "https://update.sphereshield.cloud/vsc/sphereshield_manual.pdf"
            );
          }}
          type="default"
        >
          {t("매뉴얼 다운로드")}
        </Button>
      </Descriptions.Item>

      <Descriptions.Item label={t("2단계 인증사용")} span={4}>
        <Radio.Group
          defaultValue={systemInfo.auth2step_yn}
          buttonStyle="solid"
          onChange={(event) => {
            setSystemInfo((prev) => ({
              ...prev,
              auth2step_yn: event.target.value,
            }));
          }}
        >
          <Radio.Button value={true}>{t("사용")}</Radio.Button>
          <Radio.Button value={false}>{t("미사용")}</Radio.Button>
        </Radio.Group>
        <Text style={{ paddingLeft: 10 }}>
          * {t("Google Authenticator(TOTP: 시간기반 일회성 비밀번호) 인증을 사용합니다.")}
        </Text>
      </Descriptions.Item>

      <Descriptions.Item label={t("저장용량")} span={4}>
        <Text>
          {systemInfo.str_use_size} / {systemInfo.str_total_size} ( {t("사용")} /
          {t("총용량")} )
        </Text>
      </Descriptions.Item>
      <Descriptions.Item label={t("시스템 정보")} span={4}>
        <StyledText>
          <ExclamationCircleOutlined />
          {` `} {t("시스템 정보 수정 후 다시 로그인 하시면 적용 됩니다.")}
        </StyledText>
        <Form
          fields={fields}
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          //   onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label={t("시스템 이름")}
            name="home_title"
            rules={[
              {
                required: true,
                message: t("시스템 이름을 입력해주세요."),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Copyright"
            name="home_footer"
            rules={[
              {
                required: true,
                message: t("Copyright 입력해주세요."),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label={t("메인 로고")} name="home_logo">
            <FileDragUpload
              systemInfo={systemInfo}
              setSystemInfo={setSystemInfo}
              target="home_logo"
            />
          </Form.Item>
          <Form.Item label={t("로그인 로고")} name="login_logo">
            <FileDragUpload
              systemInfo={systemInfo}
              setSystemInfo={setSystemInfo}
              target="login_logo"
            />
          </Form.Item>
          <Form.Item label="Favicon" name="home_favicon">
            <FileDragUpload
              systemInfo={systemInfo}
              setSystemInfo={setSystemInfo}
              target="home_favicon"
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
            <Button type="primary" htmlType="submit">
              {t("저장")}
            </Button>
          </Form.Item>
        </Form>
      </Descriptions.Item>
    </Descriptions>
  );
};

export default DesktopSystemSetupDefault;
