// eslint-disable-next-line import/no-anonymous-default-export
export default {
    textsample: "テキストサンプル",
    accountPage: "個人情報",
    logout: "ログアウト",
    confirm: "確認",
    cancle: "キャンセル",
    logoutMsg: "ログアウトしますか？",
    // メニュー
    menu0: "ダッシュボード",
    menu1: "組織管理",
    menu1_1: "組織管理",
    menu1_2: "構成員管理",
    menu1_3: "組織図",
    menu2: "資産管理",
    menu2_1: "資産管理",
    menu2_2: "資産評価",
    menu3: "証跡管理",
    menu3_1: "証跡リスト",
    menu3_2: "証跡管理",
    menu3_3: "運営明細書",
    menu4: "政策管理",
    menu4_1: "情報保護政策",
    menu5: "診断管理",
    menu5_1: "診断現況",
    menu5_2: "診断管理",
    menu5_3: "技術的診断",
    menu6: "リスク管理",
    menu6_1: "リスク分析",
    menu6_2: "リスク評価",
    menu6_3: "リスク管理水準",
    menu6_4: "情報保護計画",
    menu7: "管理者",
    menu7_1: "資産グループ管理",
    menu7_2: "アカウント管理",
    menu7_3: "プロジェクト管理",
    menu7_4: "ログ管理",
    menu7_5: "権限管理",
    menu7_6: "統制項目管理",
    menu7_7: "システム設定",
    menu7_8: "コード管理",
};
