import { Button, Table } from "antd";
import { IColumnsProps, IDataSourceProps } from "./type";
import React, { useCallback, useEffect, useState } from "react";

import { DownloadOutlined } from "@ant-design/icons";
import { IAdminMenuPermissionProps } from "@/common/type";
import { IGetAdminLog } from "@/api/type";
import api from "@/api/api";
import { formatDate } from "@/common/util";
import styled from "styled-components";
import defaultInfo from "@/common/site-info";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopAdminLogTableProps {
  params: IGetAdminLog;
  pagination: {
    current: number;
    pageSize: number;
  };
  setPagination: React.Dispatch<
    React.SetStateAction<{
      current: number;
      pageSize: number;
    }>
  >;
  pageInfo: IAdminMenuPermissionProps;
}

const Container = styled.div`
  padding-top: 20px;
`;
const ButtonArea = styled.div`
  padding-bottom: 20px;
  text-align: right;
`;
const Text = styled.div`
  text-align: left;
`;

const getAdminLog = async (params) => {
  const result = await api.getAdminLog({
    ...params,
  });

  return result.data;
};

const getAdminLogExcelDownload = async (params) => {
  const result = await api.getAdminLogExcelDownload({
    ...params,
  });

  return result;
};

const DesktopAdminLogTable: React.FunctionComponent<
  IDesktopAdminLogTableProps
> = ({ params, pagination, setPagination, pageInfo }) => {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState<IDataSourceProps[]>([]);
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(
    (page) => {
      setLoading(true);
      getAdminLog({
        ...params,
        size: page.pagination.pageSize || 10,
        page: page.pagination.current || 1,
      }).then((datas) => {
        const { content, total_elements } = datas;

        setLoading(false);
        setPagination((prev) => {
          return {
            ...prev,
            pageSize: page.pagination.pageSize,
            current: page.pagination.current,
            total: total_elements,
          };
        });
        setDataSource(
          content.map((item, index) => ({
            ...item,
            key: index,
            no:
              index +
              1 +
              page.pagination.pageSize * page.pagination.current -
              page.pagination.pageSize,
          }))
        );
      });
    },
    [params, setPagination]
  );

  useEffect(() => {
    fetch({ pagination });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const handleTableChange = (page) => {
    fetch({ pagination: page });
  };

  const excelDownloadHandler = useCallback(() => {
    getAdminLogExcelDownload({ ...params }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `log_table.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
  }, [params]);

  const columns: IColumnsProps[] = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      align: "center",
      width: "70px",
    },
    {
      title: "ID",
      dataIndex: "username",
      key: "username",
      align: "center",
      width: "150px",
    },
    {
      title: "IP",
      dataIndex: "ip",
      key: "ip",
      align: "center",
      width: "200px",
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      align: "center",
      width: "200px",
    },
    {
      title: t("메뉴"),
      dataIndex: "summary",
      key: "summary",
      align: "center",
      width: "130px",
    },
    {
      title: "Method",
      dataIndex: "method",
      key: "method",
      align: "center",
      width: "70px",
    },
    {
      title: "Param",
      dataIndex: "param",
      key: "param",
      align: "center",
      width: "auto",
      // eslint-disable-next-line react/display-name
      render: (param) => {
        return <Text>{param}</Text>;
      },
    },
    //   {
    //     title: 'Body',
    //     dataIndex: 'data',
    //     key: 'data',
    //     align: 'center',
    //     width: 'auto'
    //   },
    {
      title: t("상태"),
      dataIndex: "status",
      key: "status",
      align: "center",
      width: "70px",
    },
    {
      title: t("처리일시"),
      dataIndex: "created_date",
      key: "created_date",
      align: "center",
      width: "200px",
      // eslint-disable-next-line react/display-name
      render: (time) => {
        return <>{formatDate(time)}</>;
      },
    },
  ];

  return (
    <Container>
      {pageInfo.perm_write && (
        <ButtonArea>
          <Button icon={<DownloadOutlined />} onClick={excelDownloadHandler}>
            {t("엑셀 다운로드")}
          </Button>
        </ButtonArea>
      )}
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        size={defaultInfo.table_size}
      />
    </Container>
  );
};

export default DesktopAdminLogTable;
