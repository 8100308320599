import { Button, Empty, Space, Tabs, Typography } from "antd";
import { ExclamationCircleOutlined, MenuOutlined } from "@ant-design/icons";
import { IFieldProps, IGroupProps } from "./type";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import DesktopAdminAssetsGroupContentCategoryCell from "./desktop-admin-assets-group-content-category-cell";
import DesktopAdminAssetsGroupContentCategoryEexcel from "./desktop-admin-assets-group-content-category-excel";
import DraggableTabs from "./drag-n-drop";
import { IAdminMenuPermissionProps } from "@/common/type";
import { ProjectContext } from "@/common/project-provider";
import api from "@/api/api";
import { openNotification } from "@/common/util";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

interface IDesktopAdminAssetsGroupContentCategory {
  id: number;
  pageInfo: IAdminMenuPermissionProps;
}

interface IContainerProps {
  isWrite: boolean;
}

const Container = styled.div<IContainerProps>`
  ${(props) =>
    !props.isWrite &&
    `
    .anticon-menu{
      padding-left: 10px;
      svg {
        display: none;
      }
    }
  `}
  .ant-tabs-content {
    position: sticky;
    left: 0;
    top: 140px;
  }
  .ant-tabs {
    .ant-tabs-nav {
      margin: 0;
      .ant-tabs-nav-wrap {
        .ant-tabs-tab {
          padding: 10px 20px;
          position: relative;
          text-align: left;
          transition: background 0.3s ease;
          margin: 0;
          &:before {
            position: absolute;
            top: 50%;
            right: 0;
            width: 1px;
            height: 1.6em;
            background-color: #0000000f;
            transform: translateY(-50%);
            transition: background-color 0.3s;
            content: "";
          }
          .ant-tabs-tab-btn {
            font-weight: 500;
          }
        }
      }
    }
  }
  .ant-upload-list {
    display: none;
  }
`;

const ContainerEmpty = styled.div`
  padding: 130px 0 100px;
`;

const ButtonArea = styled.div`
  position: sticky;
  left: 0;
  top: 70px;
  z-index: 10; ;
`;

const StyledText = styled(Text)`
  color: ${(props) => props.theme.font.color};
  em {
    font-weight: 700;
  }
`;

const getAssetGroupFields = async ({ projectId, id }) => {
  const result = await api.getAssetGroupFields({ project_id: projectId, id });

  return result.data;
};

const setAssetGroupFields = async ({ projectId, datas }) => {
  const result = await api.setAssetGroupFields({
    project_id: projectId,
    datas,
  });

  return result.data;
};

const DesktopAdminAssetsGroupContentCategory: React.FunctionComponent<
  IDesktopAdminAssetsGroupContentCategory
> = ({ id, pageInfo }) => {
  const { t } = useTranslation();
  const { projectId } = useContext(ProjectContext);
  const [fieldsData, setFieldsData] = useState<IGroupProps>();
  const [isNewDataSave, setIsNewDataSave] = useState(false);
  const [keyData, setKeyData] = useState([]);
  const [activeKey, setActiveKey] = useState("");
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);

  const dataSample = useMemo<IFieldProps>(
    () => ({
      id: "new_data",
      group_id: id,
      key: "",
      field_name: "",
      field_sort: fieldsData?.fields.length + 1,
      field_type: null,
      field_option: null,
      del_yn: false,
    }),
    [fieldsData?.fields.length, id]
  );

  useEffect(() => {
    getAssetGroupFields({ projectId, id }).then((data) => {
      setActiveKey(
        data.fields.length > 0 ? data.fields[0].id.toString() : "new_data"
      );
      setFieldsData(data);
    });
  }, [id, projectId, isUploadModalVisible]);

  useEffect(() => {
    if (keyData.length > 0) {
      const sortData = [];
      keyData.forEach((id, index) => {
        fieldsData?.fields.forEach((field) => {
          if (field.id === Number(id)) {
            sortData.push({ ...field, field_sort: index + 1 });
          }
        });
      });

      setAssetGroupFields({
        projectId,
        datas: { ...fieldsData, fields: sortData },
      }).then((data) => {
        const notiText = {
          description: t("완료"),
        };
        setIsNewDataSave(false);
        openNotification(notiText);
        setFieldsData(data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyData, projectId]);

  const onChange = (activeKey) => {
    setActiveKey(activeKey);
    setIsNewDataSave(activeKey === "new_data");
  };

  const addField = useCallback(() => {
    if (!isNewDataSave) {
      setIsNewDataSave(true);
      setFieldsData((prev) => {
        return { ...prev, fields: [...prev.fields, dataSample] };
      });
      window.scrollTo(0, document.body.scrollHeight);
      setActiveKey("new_data");
    }
  }, [dataSample, isNewDataSave]);

  const items = useMemo(() => {
    return fieldsData?.fields.map((field: IFieldProps, index: number) => ({
      label: (
        <Button type="text" icon={<MenuOutlined style={{ color: "#aaa" }} />}>
          {field.field_name}
        </Button>
      ),
      key: `${field.id}`,
      children: (
        <DesktopAdminAssetsGroupContentCategoryCell
          fieldsData={fieldsData}
          selectIndex={index}
          setFieldsData={setFieldsData}
          setIsNewDataSave={setIsNewDataSave}
          isNewDataSave={isNewDataSave}
          setActiveKey={setActiveKey}
          pageInfo={pageInfo}
        />
      ),
    }));
  }, [fieldsData, isNewDataSave, pageInfo]);

  return (
    <Container isWrite={pageInfo.perm_write}>
      {pageInfo.perm_write && (
        <>
          <StyledText>
            <ExclamationCircleOutlined />
            <Text> {t("셀을 드래그하면 위치를 변경할 수 있습니다.")}</Text>
            <br />
            <div>
              <ExclamationCircleOutlined /> {t("자산코드 필드의 키값은 'asset_code'으로 설정하셔야 코드값을 인식합니다.")}
              <br />
              <ExclamationCircleOutlined /> {t("자산명 필드의 키값은 'asset_name'으로 설정하셔야 자산명 데이터가 연동 됩니다.")}
              <br />
              <ExclamationCircleOutlined /> {t("그룹 필드의 키값은 'group'으로 설정하시면 자산 진단 시 데이터가 연동 됩니다.")}
            </div>
          </StyledText>
          <ButtonArea style={{ marginBottom: 10, textAlign: "right" }}>
            <Space size="small">
              <Button onClick={addField}>{t("필드추가")}</Button>
              {typeof fieldsData !== "undefined" && (
                <DesktopAdminAssetsGroupContentCategoryEexcel
                  isUploadModalVisible={isUploadModalVisible}
                  setIsUploadModalVisible={setIsUploadModalVisible}
                  id={fieldsData.id}
                />
              )}
            </Space>
          </ButtonArea>
        </>
      )}

      {fieldsData?.fields.length !== 0 ? (
        <>
          {pageInfo.perm_write ? (
            <DraggableTabs
              setKeyData={setKeyData}
              activeKey={activeKey}
              onChange={onChange}
              items={items}
            />
          ) : (
            <Tabs tabPosition="left" items={items} />
          )}
        </>
      ) : (
        <ContainerEmpty>
          <Empty description={t("자산관리 테이블 필드를 구성해주세요.")} />
        </ContainerEmpty>
      )}
    </Container>
  );
};

export default DesktopAdminAssetsGroupContentCategory;
