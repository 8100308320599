import React, { useCallback, useEffect, useState } from 'react';

import Floating from '@/components/floating';
import Footer from '@/components/footer';
import Header from '@/components/header';
import { Layout } from 'antd';
import Sider from '@/components/sider';

import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import HistoryTab from '@/components/historyTab';
import { IHistoryDataProps } from '@/panel/panels';

const { Content } = Layout;

interface IDesktopLayoutProps {
  children?: React.ReactNode;
  historyTab?: any;
  currentPageItem?: IHistoryDataProps[];
  setHistoryTab?: any;
}

interface IStyledLayoutProps {
  'data-is-folding': boolean;
}

interface IStyledContentProps {
  'data-is-ismain': boolean;
}

const StyledContent = styled(Content)<IStyledContentProps>`
  padding: ${props => (props['data-is-ismain'] ? '24px' : '24px')};
  min-height: calc(100vh - 173px);
  background-color: ${props => props.theme.layout.contentBg};
`;

const StyledLayout = styled(Layout)<IStyledLayoutProps>`
  width: 100% !important;
  padding-left: ${props => (props['data-is-folding'] ? '80px' : '200px')};
  padding-top: 48px;
  transition: 0.3s ease;
  background: transparent;

  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
     Introduced in IE 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
  -ms-user-select: none;
  user-select: none;
`;

const DesktopLayout: React.FunctionComponent<IDesktopLayoutProps> = ({
  children,
  historyTab,
  currentPageItem,
  setHistoryTab,
}) => {
  const [collapsed, setCollapsed] = useState<boolean | null>(null);
  const { pathname } = useLocation();

  const HeaderToggle = useCallback(() => {
    setCollapsed(!collapsed);
    sessionStorage.setItem('menuFolding', `${!collapsed}`);
  }, [collapsed]);

  useEffect(() => {
    if (collapsed === null) {
      setCollapsed(sessionStorage.getItem('menuFolding') === 'true');
    }
  }, [collapsed]);

  const isMain = pathname === '/';

  return (
    collapsed !== null && (
      <>
        <Sider collapsed={collapsed} HeaderToggle={HeaderToggle} />
        <StyledLayout data-is-folding={collapsed}>
          <Header isMain={isMain} />
          {!isMain && (
            <HistoryTab
              pathName={pathname}
              historyTab={historyTab}
              currentPageItem={currentPageItem}
              setHistoryTab={setHistoryTab}
            />
          )}
          {isMain && (
            <StyledContent data-is-ismain={isMain}>{children}</StyledContent>
          )}
          <Footer />
        </StyledLayout>
        <Floating />
      </>
    )
  );
};

export default DesktopLayout;
