import React from 'react';
import { Button, ButtonProps } from 'antd';
import {  EditOutlined } from '@ant-design/icons';





const CustomButton: React.FunctionComponent<ButtonProps> = ({ ...props }) => {
    return <Button {...props} icon={<EditOutlined/>} type='default' size='middle'/>;
};



export default CustomButton