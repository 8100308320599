import React, { useContext, useEffect, useState } from 'react';
import { Select, Space } from 'antd';

import { ICategoryProps } from '../type';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const CertificationSelect = styled.div`
  position: relative;
  z-index: 2;
  display: inline-block;
`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopCertificationSpecHeaderProps {
  setCategoryId: React.Dispatch<React.SetStateAction<string>>;
}

const getCertificationCategory = async ({ projectId }) => {
  const result = await api.getCertificationCategory({ project_id: projectId });

  return result.data;
};

const DesktopCertificationSpecHeader: React.FunctionComponent<
  IDesktopCertificationSpecHeaderProps
> = ({ setCategoryId }) => {
  const { t } = useTranslation();
  const { projectId } = useContext(ProjectContext);
  const [category, setCategory] = useState<ICategoryProps[]>([]);

  function handleChange(value) {
    setCategoryId(value);
  }

  useEffect(() => {
    getCertificationCategory({ projectId }).then(data => {
      setCategory(data);
    });
  }, [projectId]);

  return (
    <>
      <CertificationSelect>
        <Space>
          <span>{t("통제영역")}</span>
          {category.length > 0 && (
            <Select
              defaultValue=""
              style={{ width: 200 }}
              onChange={handleChange}
            >
              <Option value="">{t("전체")}</Option>
              {category.map(item => (
                <Option value={item.id} key={item.id}>
                  {item.category_name}
                </Option>
              ))}
            </Select>
          )}
        </Space>
      </CertificationSelect>
    </>
  );
};

export default DesktopCertificationSpecHeader;
