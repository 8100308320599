import React, { useState } from 'react';

import { Button } from 'antd';
import DesktopOrgMemberManagerList from './desktop-org-members-manager-list';
import { IAdminMenuPermissionProps } from '@/common/type';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

interface IDesktopOrgMembersManager {
  orgId: number | null;
  projectId: number;
  pageInfo: IAdminMenuPermissionProps;
}

const ListArea = styled.div`
  padding: 20px;
  border-radius: 10px;
`;

const ListAddMemberArea = styled.div`
  text-align: right;
  padding-bottom: 20px;
`;

const DesktopOrgMembersManager: React.FunctionComponent<
  IDesktopOrgMembersManager
> = ({ orgId, projectId, pageInfo }) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const [isNewMember, setIsNewMember] = useState(false);

  return (
    <>
      <ListArea>
        {pageInfo.perm_write && (
          <ListAddMemberArea>
            <Button
              type="primary"
              size="middle"
              onClick={() => {
                setIsNewMember(true);
                setVisible(true);
              }}
            >
              {t("구성원")} {t("추가")}
            </Button>
          </ListAddMemberArea>
        )}
        <DesktopOrgMemberManagerList
          visible={visible}
          setVisible={setVisible}
          isNewMember={isNewMember}
          setIsNewMember={setIsNewMember}
          orgId={orgId}
          projectId={projectId}
          pageInfo={pageInfo}
        />
      </ListArea>
    </>
  );
};

export default DesktopOrgMembersManager;
