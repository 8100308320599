import { IMenuFoldingProps } from './type';
import { Layout } from 'antd';
import MenuFolding from './menu-folding';
import MenuList from './menu/menu-list';
import React from 'react';
import styled from 'styled-components';
import { defaultColor } from '@/styles/color';

const { Sider } = Layout;

const StyledSider = styled(Sider)`
    position: fixed !important;
    left: 0;
    z-index: 9;
    box-sizing: border-box;
    padding-top: 48px;
    height: 100vh;
    background: ${defaultColor.white};
    box-shadow: 2px 0 8px 0 rgb(29 35 41 / 5%);
    overflow-y: auto;
    overflow-x: hidden;
    background-color: ${props => props.theme.layout.sideBg};

    &::-webkit-scrollbar {
        width: 2px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #9f9f9f;
        background-clip: padding-box;
    }
    &::-webkit-scrollbar-track {
        background-color: #eeeeee;
    }

    .ant-layout-sider-trigger {
        background: transparent;
        margin-bottom: 20px;
        > span {
            &:after {
                content: '';
                width: 50px;
                height: 50px;
                background: ${defaultColor.gray};
                position: absolute;
                left: 50%;
                top: 0;
                border-radius: 50px;
                transform: translateX(-51%);
            }
            svg {
                position: relative;
                z-index: 1;
                fill: ${defaultColor.lightGray};
            }
        }
    }
`;

const DesktopSider: React.FunctionComponent<IMenuFoldingProps> = ({
    collapsed,
    HeaderToggle,
}) => {
    return (
        <StyledSider trigger={null} collapsible collapsed={collapsed}>
            <MenuList collapsed={collapsed} />
            <MenuFolding collapsed={collapsed} HeaderToggle={HeaderToggle} />
        </StyledSider>
    );
};

export default DesktopSider;
