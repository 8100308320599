import { Table, Tag } from 'antd';
import { IOrgMemberData, IcolumnsProps } from '../type';

import { IAdminMenuPermissionProps } from '@/common/type';
import React from 'react';
import { profile } from '@/panel/Org/Member/desktop-org-members-search-table';
import styled from 'styled-components';
import defaultInfo from '@/common/site-info';
import CustomButton from "@/components/button";
import { useTranslation } from 'react-i18next';

interface IDesktopOrgmMembersManagerListTableProps {
    memberList: IOrgMemberData[];
    selectIndex: number;
    setIsNewMember: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectIndex: React.Dispatch<React.SetStateAction<number>>;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    pageInfo: IAdminMenuPermissionProps;
}

const Text = styled.div``;
const EmptyText = styled.div`
    padding: 20px;
    color: #000;
    color: ${props => props.theme.font.color};
`;
const TagContainer = styled.div`
    display: inline-block;
    padding: 2px 0;
`;

const DesktopOrgmMembersManagerListTable: React.FunctionComponent<
    IDesktopOrgmMembersManagerListTableProps
> = ({ memberList, setIsNewMember, setSelectIndex, setVisible, pageInfo }) => {
    const { t } = useTranslation();

    const columns: IcolumnsProps[] | any = [
        {
            title: t('프로필 사진'),
            dataIndex: 'member_pic',
            sorter: false,
            render: profile,
            width: '8%',
            align: 'center',
        },
        {
            title: t('이름'),
            dataIndex: 'member_name',
            sorter: false,
            width: '10%',
            align: 'center',
            // eslint-disable-next-line react/display-name
            render: text => <Text>{text}</Text>,
        },
        {
            title: t('직급'),
            dataIndex: 'member_pos',
            width: '8%',
            align: 'center',
            // eslint-disable-next-line react/display-name
            render: text => <Text>{text}</Text>,
        },
        {
            title: t('업무'),
            dataIndex: 'member_roll',
            width: '10%',
            align: 'center',
            // eslint-disable-next-line react/display-name
            render: text => <Text>{text}</Text>,
        },
        {
            title: t('소속'),
            dataIndex: 'role',
            width: '10%',
            align: 'center',
            // eslint-disable-next-line react/display-name
            render: orgList => {
                return orgList.map(orgItem => (
                    <TagContainer key={orgItem.id}>
                        <Tag>{orgItem.organ_name}</Tag>
                    </TagContainer>
                ));
            },
        },
    ];

    const editColumns = [
        {
            title: t('수정'),
            dataIndex: 'operation',
            width: '5%',
            align: 'center',
            // eslint-disable-next-line react/display-name
            render: (_: any, record: IOrgMemberData, index: number) => (
                <CustomButton
                    disabled={false}
                    onClick={() => {
                        console.log(record);
                        setIsNewMember(false);
                        setSelectIndex(index);
                        setVisible(true);
                        // setSelectId(record.id);
                    }}
                >
                    {t('수정')}
                </CustomButton>
            ),
        },
    ];

    return (
        <Table
            columns={
                pageInfo.perm_write ? [...columns, ...editColumns] : columns
            }
            rowKey={record => `${record.id}_${record.organ_id}`}
            dataSource={memberList}
            pagination={false}
            //   loading={loading}
            size={defaultInfo.table_size}
            locale={{
                emptyText: <EmptyText>{t("생성된 데이터가 없습니다.")}</EmptyText>,
            }}
        />
    );
};

export default DesktopOrgmMembersManagerListTable;
