import { ConfigProvider, Switch } from 'antd';
import React, { useContext, useEffect } from 'react';
import { darkTheme, lightTheme } from '@/styles/antd-theme';

import { ThemeContext } from '@/common/theme-provider';
import styled from 'styled-components';
import { defaultColor } from '@/styles/color';

const ThemeContainer = styled.div``;
const StyledSwitch = styled(Switch)`
  background: ${defaultColor.gray};
`;

const Theme: React.FunctionComponent = () => {
  const { isDarkMode, setIsDarkMode } = useContext(ThemeContext);

  const themeOnChangeHandler = (e: boolean) => {
    setIsDarkMode(!e);
    sessionStorage.setItem('theme', !e ? 'dark' : 'light');

    ConfigProvider.config(e ? lightTheme : darkTheme);
  };

  useEffect(() => {
    themeOnChangeHandler(
      sessionStorage.getItem('theme') === null ||
        sessionStorage.getItem('theme') === 'light'
    );
  });

  return (
    <ThemeContainer>
      <StyledSwitch
        checkedChildren="☀️"
        unCheckedChildren="🌙"
        checked={
          sessionStorage.getItem('theme') === null ||
          sessionStorage.getItem('theme') === 'light'
        }
        defaultChecked={isDarkMode}
        onChange={themeOnChangeHandler}
      />
    </ThemeContainer>
  );
};

export default Theme;
