import { IAppContextData, IIndexProps } from '@/common/type';
import React from 'react';

import { AppConsumer } from '@/common/app-provider';
import MobileLayout from '@/layout/mobile-layout';

import Panels from '@/panel/panels';

const Index: React.FunctionComponent<IIndexProps> = () => {
  return (
    <AppConsumer>
      {({ config }: IAppContextData) => {
        if (config.isMobile) {
          return <MobileLayout title="자산관리">mobile</MobileLayout>;
        }
        return <Panels />;
      }}
    </AppConsumer>
  );
};

export default Index;
