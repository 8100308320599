import { Button, Space, Table } from 'antd';
import { IColumnsProps, IDataSourceProps } from './type';
import React, { useCallback, useState } from 'react';

import DesktopAdminCategoryTableAddModal from './desktop-admin-category-table-add-modal';
import DesktopAdminCategoryTableSubModal from './desktop-admin-category-table-sub-modal';
import { IAdminMenuPermissionProps } from '@/common/type';
import { PlusOutlined, FormOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import defaultInfo from '@/common/site-info';
import CustomButton from "@/components/button";
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopAdminCategoryTableProps {
  categoryData: IDataSourceProps[];
  setCategoryData: React.Dispatch<React.SetStateAction<IDataSourceProps[]>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  pageInfo: IAdminMenuPermissionProps;
}

const Container = styled.div``;
const ButtonArea = styled.div`
  padding-bottom: 20px;
  text-align: right;
  float: right;
  text-align: right;
  position: sticky;
  right: 0;
  top: 70px;
  z-index: 6;
`;
const Text = styled.p`
  text-align: left;
`;

const DesktopAdminCategoryTable: React.FunctionComponent<
  IDesktopAdminCategoryTableProps
> = ({ categoryData, setCategoryData, isLoading, pageInfo }) => {
  const { t } = useTranslation();
  const [isSubModalVisible, setIsSubModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [editData, setEditData] = useState(null);

  const editHandler = useCallback(record => {
    setIsAddModalVisible(true);
    setEditData([record]);
  }, []);

  const columns: IColumnsProps[] = [
    {
      title: 'No',
      dataIndex: 'category_num',
      key: 'category_num',
      width: '100px',
      align: 'center',
    },
    {
      title: t('분야'),
      dataIndex: 'category_div',
      key: 'category_div',
      width: '200px',
      align: 'center',
    },
    {
      title: t('항목'),
      dataIndex: 'category_name',
      key: 'category_name',
      width: '200px',
      align: 'center',
    },
    {
      title: t('상세내용'),
      dataIndex: 'category_desc',
      key: 'category_desc',
      width: '400px',
      align: 'center',
      render(text) {
        return <Text>{text}</Text>;
      },
    },
  ];

  const columnsButtons: IColumnsProps[] = [
    {
      title: t('주요확인사항'),
      dataIndex: 'category_confirm',
      key: 'category_confirm',
      width: '150px',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: (_, record) => {
        return (
          <Button
            size='middle'
            onClick={() => {
              setEditData([record]);
              setIsSubModalVisible(true);
            }}
            icon={<FormOutlined />}
          >
            {t("관리")}
          </Button>
        );
      },
    },
    {
      title: t('수정'),
      dataIndex: 'edit',
      key: 'edit',
      width: '80px',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: (_, record) => {
        return (
          <Space size="small">
            <CustomButton
              onClick={() => {
                editHandler(record);
              }}
            >
              {t("수정")}
            </CustomButton>
          </Space>
        );
      },
    },
  ];

  const addCategoryHandler = useCallback(() => {
    setEditData(null);
    setIsAddModalVisible(true);
  }, []);

  return (
    <Container>
      {pageInfo.perm_write && (
        <ButtonArea>
          <Space>
            <Button
              icon={<PlusOutlined />}
              onClick={() => {
                addCategoryHandler();
              }}
            >
              {t("통제항목추가")}
            </Button>
          </Space>
        </ButtonArea>
      )}
      <Table
        dataSource={categoryData}
        columns={
          pageInfo.perm_write ? [...columns, ...columnsButtons] : [...columns]
        }
        size={defaultInfo.table_size}
        pagination={false}
        loading={isLoading}
      />
      <DesktopAdminCategoryTableSubModal
        setIsModalVisible={setIsSubModalVisible}
        isModalVisible={isSubModalVisible}
        editData={editData}
      />
      <DesktopAdminCategoryTableAddModal
        setIsModalVisible={setIsAddModalVisible}
        isModalVisible={isAddModalVisible}
        lastNo={categoryData[categoryData.length - 1]?.category_num}
        editData={editData}
        setCategoryData={setCategoryData}
      />
    </Container>
  );
};

export default DesktopAdminCategoryTable;
