import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/scrollbar';

import {
  AutoComplete,
  Button,
  Card,
  Empty,
  Input,
  Popconfirm,
  Space,
  Table,
} from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import SwiperCore, { FreeMode, Scrollbar } from 'swiper';

import DesktopCertificationAdminModal from '@/panel/Certification/CertAdmin/desktop-certification-admin-modal';
import { IAdminMenuPermissionProps } from '@/common/type';
import { IPolicyGroupProps } from '../type';
import Loading from '@/components/loading';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import { arrayMoveImmutable } from 'array-move';
import { openNotification } from '@/common/util';
import styled from 'styled-components';
import DesktopPolicyProtectionCardSortModal from './desktop-policy-protection-card-sort-modal';
import { IAutocompleteOnSelectHandlerProps } from '@/panel/Dangers/Protection/type';
import { useTranslation } from 'react-i18next';

SwiperCore.use([FreeMode, Scrollbar]);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopPolicyProtectionCardProps {
  pageInfo: IAdminMenuPermissionProps;
}

const Container = styled.div``;
const CardContainer = styled.div`
  vertical-align: top;
  width: 33.3333%;
  /* min-height: 400px; */
  box-sizing: border-box;
  padding: 10px;
  position: relative;
  margin-bottom: 20px;
`;
const StyledCard = styled(Card)`
  border: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 10px;
  box-shadow: 2px 0 8px 0 rgb(29 35 41 / 5%);
  border: 1px solid ${props => props.theme.layout.searchBorderColor};
  .ant-card-head {
    margin-bottom: 0;
  }
  .ant-card-body {
    padding: 0;
  }
`;
const StyledTable = styled(Table)`
  .ant-table-small .ant-table-thead > tr > th {
    background: transparent;
    font-size: 12px;
  }
  .ant-table-small .ant-table-tbody > tr > td {
    font-size: 12px;
  }
`;
const ButtonContainer = styled.div`
  text-align: right;
  padding-right: 15px;
  position: sticky;
  left: 0;
  top: 70px;
  text-align: right;
  z-index: 5;
  padding-bottom: 10px;
`;
const GroupContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;
const PolicyTitle = styled.p`
  text-align: left;
  margin: 0;
  button {
    width: 100%;
    text-align: left;
  }
  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: keep-all;
    display: block;
  }
`;
const AddContainer = styled.div`
  text-align: left;
  padding: 20px;
  margin-top: -1px;
`;
const Title = styled.h2`
  display: inline-block;
  font-size: 16px;
`;
const Item = styled.div``;

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
));

const SortableItem = SortableElement(props => (
  <tr {...props} style={{ zIndex: '10000' }} />
));
const SortableContainers = SortableContainer(props => <tbody {...props} />);

const getPolicyGroup = async ({ projectId }) => {
  const result = await api.getPolicyGroup({
    project_id: projectId,
  });

  return result.data;
};

const setPolicyGroup = async ({ projectId, data }) => {
  const result = await api.setPolicyGroup({
    project_id: projectId,
    data,
  });

  return result.data;
};

const deletePolicyGroup = async ({ projectId, id }) => {
  const result = await api.deletePolicyGroup({
    project_id: projectId,
    id,
  });

  return result.data;
};

const getCertificationSearch = async ({ projectId, categoryId, params }) => {
  const result = await api.getCertificationSearch({
    project_id: projectId,
    id: categoryId,
    params: {
      keyword: params.keyword,
    },
  });

  return result.data;
};

const DesktopPolicyProtectionCard: React.FunctionComponent<
  IDesktopPolicyProtectionCardProps
> = ({ pageInfo }) => {
  const { t } = useTranslation();
  const { projectId } = useContext(ProjectContext);
  const [group, setGroup] = useState<IPolicyGroupProps[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectDocId, setSelectDocId] = useState(0);
  const [selectId, setSelectId] = useState(0);
  const [recordTitle, setRecordTitle] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isSoltModalVisible, setIsSoltModalVisible] = useState(false);

  const columns: any = [
    {
      title: '',
      dataIndex: 'sort',
      className: 'drag-visible',
      align: 'center',
      width: '50px',
      // eslint-disable-next-line react/display-name
      render: () => <DragHandle />,
    },
    {
      title: t('정책명'),
      dataIndex: 'name',
      align: 'center',
      className: 'drag-visible',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record) => {
        return (
          <PolicyTitle>
            <Button
              type="text"
              size="small"
              onClick={() => {
                setSelectDocId(record.doc_id);
                setSelectId(record.id);
                setRecordTitle(record.name);
                setIsModalVisible(true);
              }}
              title={text}
            >
              {text}
            </Button>
          </PolicyTitle>
        );
      },
    },
  ];

  const getGroupData = () => {
    getPolicyGroup({ projectId }).then(groupData => {
      setGroup(
        groupData.map(item => ({
          ...item,
          isEdit: false,
          isLoading: false,
          searchText: '',
        }))
      );
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!isSoltModalVisible) {
      getGroupData();
    }
    // eslint-disable-next-line
  }, [projectId, isSoltModalVisible]);

  const setLoad = useCallback((index, flag) => {
    setGroup(prev => {
      return prev.map((item, idx) => {
        if (idx === index) {
          return { ...item, isLoading: flag };
        }
        return item;
      });
    });
  }, []);

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }, index) => {
      if (oldIndex !== newIndex) {
        setLoad(index, true);

        const newData = arrayMoveImmutable(
          [].concat(group[index].policy),
          oldIndex,
          newIndex
        )
          .filter(el => !!el)
          .map((item, idx) => ({ ...item, doc_sort: idx + 1 }));

        setGroup(prev =>
          prev.map((group, idx) =>
            index === idx ? { ...group, policy: newData } : group
          )
        );

        setPolicyGroup({
          projectId,
          data: [{ ...group[index], policy: newData }],
        }).then(() => {
          setLoad(index, false);
        });
      }
    },
    [group, projectId, setLoad]
  );

  const DraggableContainer = (props, index) => (
    <SortableContainers
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={props => {
        onSortEnd(props, index);
      }}
      {...props}
    />
  );

  const DraggableBodyRow = useCallback(
    ({ className, style, ...restProps }, cardIndex) => {
      // function findIndex base on Table rowKey props and should always be a right array index
      const index = group[cardIndex].policy.findIndex(
        x => x.id === restProps['data-row-key']
      );
      return <SortableItem index={index} {...restProps} />;
    },
    [group]
  );

  const addGroup = useCallback(() => {
    setGroup(prev => {
      return [
        ...prev,
        {
          id: null,
          group_name: '',
          group_sort: group.length + 1,
          isEdit: true,
          isLoading: false,
          searchText: '',
          policy: [],
        },
      ];
    });

    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 300);
  }, [group]);

  const editTitleToggle = useCallback(index => {
    setGroup(prev => {
      return prev.map((item, idx) =>
        index === idx ? { ...item, isEdit: !item.isEdit } : item
      );
    });
  }, []);

  const editTitleHandle = useCallback(
    index => {
      setPolicyGroup({ projectId, data: [group[index]] }).then(data => {
        const notiText = {
          description: t("완료"),
        };

        openNotification(notiText);
        setGroup(data);
      });
    },
    [group, projectId, t]
  );

  const editTitleOnChangHandle = useCallback((event, index) => {
    const group_name = event.target.value;

    setGroup(prev => {
      return prev.map((item, idx) =>
        idx === index
          ? {
              ...item,
              group_name,
            }
          : item
      );
    });
  }, []);

  const groupRemoveHandle = useCallback(
    id => {
      deletePolicyGroup({ projectId, id }).then(data => {
        const notiText = {
          description: t("완료"),
        };

        openNotification(notiText);
        setGroup(data);
      });
    },
    [projectId, t]
  );

  // autocomplete

  const [options, setOptions] = useState<{ value: string; key: number }[]>([]);
  const onSearch = (searchText: string) => {
    getCertificationSearch({
      projectId,
      categoryId: '',
      params: { keyword: searchText },
    }).then(data => {
      // console.log(data);

      const optionList = data.map((item, index) => ({
        value: item.doc_name,
        key: index,
        id: item.id,
      }));

      setOptions(!searchText ? [] : optionList);
    });
  };
  const onSelect = (data: string, group_index: number, doc_id) => {
    setLoad(group_index, true);
    setPolicyGroup({
      projectId,
      data: [
        {
          ...group[group_index],
          policy: [
            ...group[group_index].policy,
            {
              doc_id,
              doc_name: data,
              doc_sort: group[group_index].policy.length + 1,
              id: null,
            },
          ],
        },
      ],
    }).then(data => {
      const notiText = {
        description: t("완료"),
      };

      openNotification(notiText);
      setLoad(group_index, false);
      setGroup(data);
    });
  };

  if (isLoading) return <Loading />;

  return (
    <Container>
      <>
        {pageInfo.perm_write && (
          <ButtonContainer>
            <Space>
              <Button onClick={() => setIsSoltModalVisible(true)}>
                {t("정책 그룹 순서 변경")}
              </Button>
              <Button onClick={addGroup}>{t("정책 그룹 추가")}</Button>
            </Space>
          </ButtonContainer>
        )}
        {group.length > 0 ? (
          <GroupContainer>
            {group.map((item, index) => {
              const policyList = item.policy.map(policyItem => {
                return {
                  key: policyItem.id,
                  name: policyItem.doc_name,
                  index: policyItem.id,
                  id: policyItem.id,
                  doc_id: policyItem.doc_id,
                };
              });

              return (
                <CardContainer key={item.id}>
                  <StyledCard
                    title={
                      <Item key={item.id}>
                        {item.isEdit ? (
                          <Input
                            placeholder={t("정책명")}
                            style={{ width: '50%' }}
                            defaultValue={item.group_name}
                            onPressEnter={() => {
                              editTitleHandle(index);
                              editTitleToggle(index);
                            }}
                            onChange={event => {
                              editTitleOnChangHandle(event, index);
                            }}
                          />
                        ) : (
                          <Title>{item.group_name}</Title>
                        )}{' '}
                        {pageInfo.perm_write && (
                          <>
                            <Button
                              type="text"
                              icon={
                                item.isEdit ? (
                                  <CheckOutlined />
                                ) : (
                                  <EditOutlined />
                                )
                              }
                              onClick={() => {
                                if (item.isEdit) {
                                  editTitleHandle(index);
                                } else {
                                  editTitleToggle(index);
                                }
                              }}
                            />
                            {item.isEdit && (
                              <Button
                                type="text"
                                icon={<CloseOutlined />}
                                onClick={() => {
                                  editTitleToggle(index);
                                  getGroupData();
                                }}
                              />
                            )}
                          </>
                        )}
                      </Item>
                    }
                    style={{ width: '100%' }}
                    size="small"
                    extra={
                      !item.isEdit && pageInfo.perm_write ? (
                        <Popconfirm
                          title={t("삭제 하시겠습니까?")}
                          onConfirm={() => {
                            // onConfirm(record);
                            groupRemoveHandle(item.id);
                          }}
                          // onCancel={cancel}
                          okText={t("삭제")}
                          cancelText={t("취소")}
                        >
                          <Button
                            size="small"
                            type="text"
                            icon={<CloseOutlined />}
                          ></Button>
                        </Popconfirm>
                      ) : (
                        <></>
                      )
                    }
                  >
                    <StyledTable
                      size="small"
                      pagination={false}
                      loading={item.isLoading}
                      dataSource={policyList}
                      columns={columns}
                      rowKey="index"
                      components={
                        pageInfo.perm_write && {
                          body: {
                            wrapper: props => {
                              return DraggableContainer(props, index);
                            },
                            row: props => {
                              return DraggableBodyRow(props, index);
                            },
                          },
                        }
                      }
                    />
                    {pageInfo.perm_write && (
                      <AddContainer>
                        <AutoComplete
                          options={options}
                          style={{ width: '100%' }}
                          onSelect={(
                            text: string,
                            record: IAutocompleteOnSelectHandlerProps
                          ) => {
                            onSelect(text, index, record.id);
                          }}
                          onSearch={value => {
                            onSearch(value);
                          }}
                          onChange={text => {
                            setGroup(prev => {
                              return prev.map((item, idx) => {
                                if (index === idx) {
                                  return { ...item, searchText: text };
                                }
                                return item;
                              });
                            });
                          }}
                          onBlur={() => {
                            setOptions([]);
                            setGroup(prev => {
                              return prev.map((item, idx) => {
                                if (index === idx) {
                                  return { ...item, searchText: '' };
                                }
                                return item;
                              });
                            });
                          }}
                          value={item.searchText}
                        >
                          <Input.Search size="middle" placeholder={t("정책 추가")} />
                        </AutoComplete>
                      </AddContainer>
                    )}
                  </StyledCard>
                </CardContainer>
              );
            })}
          </GroupContainer>
        ) : (
          <div style={{ padding: '100px', textAlign: 'center' }}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        )}

        <DesktopCertificationAdminModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          selectDocId={selectDocId}
          selectId={selectId}
          recordTitle={recordTitle}
          isDeletBtn={true}
          setRecordDatas={setGroup}
          pageInfo={pageInfo}
        />
        <DesktopPolicyProtectionCardSortModal
          isSoltModalVisible={isSoltModalVisible}
          setIsSoltModalVisible={setIsSoltModalVisible}
          group={group}
        />
      </>
    </Container>
  );
};

export default DesktopPolicyProtectionCard;
