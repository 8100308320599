import { Button, Form, Input, Select, Space } from 'antd';

import { IOrgGroupData } from '../type';
import React from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

const { Option } = Select;

interface IDesktopOrgMembersSearchForm {
  setSearchData: React.Dispatch<
    React.SetStateAction<{ member_name: string; organ_id: number | null }>
  >;
  organList: IOrgGroupData[];
}

interface IFinishProps {
  member_name: string;
  organ_id: number;
}

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  text-align: left;
  padding: 20px;
  border: 1px solid ${props => props.theme.layout.searchBorderColor};
  border-radius: 10px;
  .ant-form-item {
    margin: 0;
  }
`;
const Label = styled.label`
  font-size: 15px;
  margin-left: 15px;
`;

const DesktopOrgMembersSearchForm: React.FunctionComponent<
  IDesktopOrgMembersSearchForm
> = ({ setSearchData, organList }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const onFinish = ({ member_name, organ_id }: IFinishProps) => {
    setSearchData({
      member_name: typeof member_name === 'undefined' ? '' : member_name,
      organ_id: typeof organ_id === 'undefined' ? 0 : organ_id,
    });
  };

  return (
    <Container>
      <Form
        form={form}
        name="advanced_search"
        className="ant-advanced-search-form"
        onFinish={onFinish}
      >
        <Space>
          <Label>{t("이름")}</Label>
          <Form.Item name="member_name" style={{ width: 200 }}>
            <Input placeholder={t("이름")} />
          </Form.Item>
          {organList.length > 0 && (
            <>
              <Label>{t("조직명")}</Label>
              <Form.Item
                name="organ_id"
                style={{ width: 220 }}
                initialValue={0}
              >
                <Select>
                  <Option key="0" value={0}>
                    {t("전체")}
                  </Option>
                  {organList.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.organ_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}
          <Button type="primary" htmlType="submit">
          {t("조회")}
          </Button>
        </Space>
      </Form>
    </Container>
  );
};

export default DesktopOrgMembersSearchForm;
