import {
  Button,
  Space,
  Spin,
  Tooltip,
  message,
  UploadProps,
} from 'antd';
import { DownloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import React, { useCallback, useContext, useState } from 'react';
import { IAssetEvaluationDataProps } from './type';
import { ProjectContext } from '@/common/project-provider';
import { UploadOutlined } from '@ant-design/icons';
import api from '@/api/api';
import styled from 'styled-components';
import Dragger from 'antd/lib/upload/Dragger';
import Modal from '@/components/modal';
import { useTranslation } from 'react-i18next';

interface IDesktopAssetsManagementGroupContentExcelProps {
  id: number;
  setAssetList: React.Dispatch<
    React.SetStateAction<IAssetEvaluationDataProps[]>
  >;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledUploadArea = styled.div`
  padding-bottom: 20px;
  .ant-upload-list {
    display: none;
  }
  .ant-upload-text {
    color: ${props => props.theme.font.color}!important;
  }
`;

const CloseBtnArea = styled.div`
  text-align: right;
`;

const Title = styled.h4`
  font-size: 18px;
  margin: 0 0 10px 0;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${props => props.theme.layout.searchBorderColor};
  margin: 30px 0;
`;

const getAssetsEvalSampleDownload = async ({ projectId, id }) => {
  const result = await api.getAssetsEvalSampleDownload({
    project_id: projectId,
    id,
  });

  return result;
};

const setAssetsEvalExcelDownload = async ({ projectId, id, formData }) => {
  const result = await api.setAssetsEvalExcelDownload({
    project_id: projectId,
    id,
    formData,
  });

  return result.data;
};

const DesktopAssetsManagementGroupContentExcel: React.FunctionComponent<
  IDesktopAssetsManagementGroupContentExcelProps
> = ({ id, isModalVisible, setIsModalVisible }) => {
  const { t } = useTranslation();
  
  const { projectId } = useContext(ProjectContext);

  const [loading, setLoading] = useState(false);

  const sampleDownload = () => {
    getAssetsEvalSampleDownload({ projectId, id: id }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `asset_eval_template.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
  };

  const handleChange = useCallback(
    info => {
      if (info.file.status === 'uploading') {
        return;
      }
      const formData = new FormData();

      formData.append('file', info.file.originFileObj);
      setLoading(true);
      setAssetsEvalExcelDownload({
        projectId,
        id,
        formData,
      })
        .then(() => {
          setLoading(false);
          setIsModalVisible(false);
        })
        .catch(error => {
          console.log(error);
          message.error(t('파일 업로드에 오류가 있습니다.'));
          setLoading(false);
          setIsModalVisible(false);
        });
    },
    [id, projectId, setIsModalVisible, t]
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const props: UploadProps = {
    name: 'file',
    multiple: true,
    onChange(info) {
      // const { status } = info.file;
      handleChange(info);
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  return (
    <Space size="small">
      <Tooltip title={<span>{t("엑셀을 이용하여 데이터 일괄 추가")}</span>}>
        <Button onClick={showModal}>{t("데이터 일괄 등록")}</Button>
      </Tooltip>

      <Modal
        title={t("데이터 일괄 등록")}
        open={isModalVisible}
        maskClosable={false}
        keyboard={false}
        footer={false}
        onCancel={handleCancel}
      >
        <Spin spinning={loading} delay={500}>
          <Title>
            <ExclamationCircleOutlined /> {t("엑셀 업로드 양식 다운로드")}
          </Title>
          <Button onClick={sampleDownload}>
            <DownloadOutlined /> {t("업로드 양식 다운로드")}
          </Button>
          <Line />
          <Title>
            <ExclamationCircleOutlined />
            {` `} {t("자산평가 일괄등록")}
          </Title>
          <StyledUploadArea>
            {/* <Button icon={<UploadOutlined />}>엑셀 업로드</Button> */}
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <UploadOutlined />
              </p>
              <p className="ant-upload-text">
              {t("클릭 또는 마우스로 파일을 끌어오세요.")}
              </p>
            </Dragger>
          </StyledUploadArea>
          <CloseBtnArea>
            <Button onClick={handleCancel}>{t("닫기")}</Button>
          </CloseBtnArea>
        </Spin>
      </Modal>
    </Space>
  );
};

export default DesktopAssetsManagementGroupContentExcel;
