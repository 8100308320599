import { AutoComplete, Button, Input, Popconfirm, message } from 'antd';
import {
    CloseOutlined,
    EditOutlined,
    PlusSquareOutlined,
    ArrowLeftOutlined,
} from '@ant-design/icons';
import React, { useCallback, useContext, useState } from 'react';

import { IAdminMenuPermissionProps } from '@/common/type';
import { ICertificationTableData } from '../type';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import { replaceNl2Br } from '@/common/util';
import styled from 'styled-components';
import { ICertificationList } from '../CertAdmin/type';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopCertificationSpecTableEvidenceProps {
    datas: ICertificationTableData;
    setEditCertData: React.Dispatch<
        React.SetStateAction<ICertificationTableData[]>
    >;
    setSelectItem: React.Dispatch<React.SetStateAction<ICertificationList>>;
    dataSync: (data) => void;
    pageInfo: IAdminMenuPermissionProps;
    type: 'doc' | 'sets';
    showDrawer: () => void;
}

interface ITextProps {
    align?: string;
    isWrite: boolean;
}

interface IAutoCompleteContainerProps {
    type: 'doc' | 'sets';
}


const Container = styled.div`
    text-align: left;
`;
const AutoCompleteContainer = styled.div<IAutoCompleteContainerProps>`
    position: relative;
    ${props => {
        return props.type === 'doc'
            ? `
    padding: 0 10px 10px 10px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px;
    `
            : `
    padding: 0 10px 0 10px;
    `;
    }}
    border-color: ${props => props.theme.layout.searchBorderColor};
`;

const StyledAutoComplete = styled(AutoComplete)`
    margin-bottom: 5px;
    .ant-select-selection-search {
        padding-right: 30px;
    }
`;
const SubDocContainer = styled.div`
    padding-bottom: 5px;
`;
const StyledTextArea = styled(TextArea)``;
const Text = styled.p<ITextProps>`
    ${props =>
        props.align === 'center' ? `text-align: center;` : `text-align: left;`}
    padding-top: 10px;
    padding-left: ${props => (props.isWrite ? '0px' : '15px')};
    line-height: 1.2;
`;
interface IWordBoxProps {
    isWrite: boolean;
}
const WordBox = styled.div<IWordBoxProps>`
    min-height: 33px;
    margin-bottom: 5px;
    padding: ${props => (props.isWrite ? '5px 75px 4px 11px' : '5px')};
    position: relative;
    transition: 0.5s ease;
    border: 1px solid ${props => props.theme.layout.searchBorderColor};
    border-radius: 3px;
    cursor: pointer;
    &:hover {
        border-color: #cccccc !important;
        box-shadow: none;
    }
`;
const StyledEditButton = styled(Button)`
    position: absolute;
    right: 32px;
    top: 0;
    font-size: 12px;
`;
const StyledDeleteButton = styled(Button)`
    position: absolute;
    right: 2px;
    top: 0;
    font-size: 12px;
`;


const StyledCloseButton = styled(StyledDeleteButton)``;


const TitleText = styled.p`
    display: inline-block;
`;
const InputContainer = styled.div`
    position: relative;
`;

const getCertificationDocList = async ({ projectId, keyword }) => {
    const result = await api.getCertificationDocList({
        project_id: projectId,
        keyword,
    });

    return result.data;
};

const getCertStateDoc = async ({ project_id, doc_id }) => {
    const result = await api.getCertStateDoc({
        project_id,
        doc_id,
    });

    return result.data;
};

const DesktopCertificationSpecTableEvidence: React.FunctionComponent<
    IDesktopCertificationSpecTableEvidenceProps
> = ({
    datas,
    setEditCertData,
    dataSync,
    pageInfo,
    type,
    showDrawer,
    setSelectItem,
}) => {
    const { t } = useTranslation();
    const { projectId } = useContext(ProjectContext);
    const [evidence, setEvidence] = useState(
        datas[type].map(item => ({ ...item, isEdit: false }))
    );

    const [options, setOptions] = useState<{ value: string; key: number }[]>(
        []
    );

    const onSearch = useCallback(
        (searchText: string) => {
            getCertificationDocList({ projectId, keyword: searchText }).then(
                data => {
                    const optionList = data.map(item => ({
                        value: item.doc_name,
                        key: item.id,
                        id: item.id,
                    }));
                    setOptions(!searchText ? [] : optionList);
                }
            );
        },
        [projectId]
    );

    const onBlur = useCallback(() => {
        const paramsDatas = { ...datas, [type]: evidence };
        console.log(paramsDatas);

        setEditCertData(prev => [
            ...prev.filter(
                item => item.category_id !== paramsDatas.category_id
            ),
            paramsDatas,
        ]);
        dataSync([paramsDatas]);
    }, [dataSync, datas, evidence, setEditCertData, type]);

    const onSelect = useCallback(
        (selectText: string, selectOption, selectIndex) => {
            setEvidence(prev => {
                return prev.map((item, index) => {
                    if (index === selectIndex) {
                        return {
                            ...item,
                            doc_name: selectText,
                            doc_id: selectOption.id,
                            isEdit: false,
                        };
                    }
                    return item;
                });
            });
        },
        []
    );

    const onChange = useCallback((event, selectIndex) => {
        setEvidence(prev => {
            return prev.map((item, index) =>
                index === selectIndex
                    ? { ...item, doc_desc: event.target.value }
                    : item
            );
        });
    }, []);

    const addDocsHandler = useCallback(() => {
        setOptions([]);
        setEvidence(prev => [
            ...prev,
            {
                id: null,
                doc_name: '',
                doc_desc: '',
                doc_id: null,
                isEdit: true,
            },
        ]);
    }, []);

    const editDocsHandler = useCallback(
        (selectText: string, id, selectIndex) => {
            setEvidence(prev => {
                return prev.map((item, index) => {
                    if (index === selectIndex) {
                        return {
                            ...item,
                            doc_name: selectText,
                            doc_id: id,
                            isEdit: true,
                        };
                    }
                    return item;
                });
            });
        },
        []
    );

    const closeHandler = useCallback(
        data => {
            if (data.doc_name !== '') {
                setEvidence(prev => {
                    return prev.map(item => {
                        if (item.doc_id === data.doc_id) {
                            return { ...item, isEdit: false };
                        }

                        return item;
                    });
                });
            } else {
                setEvidence(prev => prev.slice(0, prev.length - 1));
                //
            }
        },
        []
    );

    const removeDocsHandler = useCallback(
        selectIndex => {
            const docsFilter = evidence.filter(
                (_, index) => index !== selectIndex
            );
            const paramsDatas = { ...datas, [type]: docsFilter };

            setOptions([]);
            setEvidence(docsFilter);
            dataSync([paramsDatas]);
            setEditCertData(prev => [
                ...prev.filter(
                    item => item.category_id !== paramsDatas.category_id
                ),
                paramsDatas,
            ]);
        },
        [dataSync, datas, evidence, setEditCertData, type]
    );

    return (
        <Container>
            {evidence.length > 0 &&
                evidence.map((data, index) => (
                    <AutoCompleteContainer
                        key={data.id}
                        title={data.doc_name}
                        type={type}
                    >
                        <InputContainer
                            style={{ display: data.isEdit ? 'block' : 'none' }}
                        >
                            <StyledAutoComplete
                                options={options}
                                style={{
                                    width: '100%',
                                    fontSize: '14px',
                                }}
                                onBlur={onBlur}
                                onSearch={onSearch}
                                onSelect={(value, option) => {
                                    onSelect(value, option, index);
                                }}
                                placeholder={t("증적자료조회")}
                                defaultValue={data.doc_name}
                            />

                            <StyledCloseButton
                                type="text"
                                icon={<ArrowLeftOutlined />}
                                onClick={() => closeHandler(data)}
                            />
                        </InputContainer>
                        <WordBox
                            style={{ display: !data.isEdit ? 'block' : 'none' }}
                            type="default"
                            isWrite={pageInfo.perm_write}
                            onClick={() => {
                                getCertStateDoc({
                                    project_id: projectId,
                                    doc_id: data.doc_id,
                                }).then(datas => {
                                    const { params } = datas;
                                    if (params !== '') {
                                        const searchParams =
                                            new URLSearchParams(params);

                                        setSelectItem({
                                            created_dt: '',
                                            file_ext:
                                                searchParams.get('filetype'),
                                            field: '',
                                            filename:
                                                searchParams.get('filename'),
                                            filesize: 0,
                                            fileurl:
                                                searchParams.get('filekey'),
                                            id: 0,
                                            modified_dt: null,
                                            trace_text: null,
                                        });
                                        showDrawer();
                                    } else {
                                        message.info(t('데이터가 없습니다.'));
                                    }
                                });
                            }}
                        >
                            <TitleText
                                dangerouslySetInnerHTML={{
                                    __html: replaceNl2Br(data.doc_name),
                                }}
                            ></TitleText>
                            {pageInfo.perm_write && (
                                <>
                                    <StyledEditButton
                                        type="text"
                                        onClick={e => {
                                            e.stopPropagation();
                                            editDocsHandler(
                                                data.doc_name,
                                                data.doc_id,
                                                index
                                            );
                                        }}
                                        icon={<EditOutlined />}
                                    ></StyledEditButton>
                                    <Popconfirm
                                        title={t("삭제 하시겠습니까?")}
                                        onCancel={e => {
                                            e.stopPropagation();
                                        }}
                                        onConfirm={e => {
                                            e.stopPropagation();
                                            removeDocsHandler(index);
                                        }}
                                        // onCancel={cancel}
                                        okText={t("삭제")}
                                        cancelText={t("취소")}
                                    >
                                        <StyledDeleteButton
                                            type="text"
                                            onClick={e => e.stopPropagation()}
                                            icon={<CloseOutlined />}
                                        ></StyledDeleteButton>
                                    </Popconfirm>
                                </>
                            )}
                        </WordBox>

                        {type === 'doc' && (
                            <SubDocContainer>
                                {pageInfo.perm_write ? (
                                    <StyledTextArea
                                        // size="small"
                                        autoSize={true}
                                        defaultValue={data.doc_desc}
                                        placeholder={t("자료설명")}
                                        onChange={event => {
                                            onChange(event, index);
                                        }}
                                        onBlur={onBlur}
                                    />
                                ) : (
                                    <Text
                                        isWrite={pageInfo.perm_write}
                                        dangerouslySetInnerHTML={{
                                            __html: replaceNl2Br(data.doc_desc),
                                        }}
                                    ></Text>
                                )}
                            </SubDocContainer>
                        )}
                    </AutoCompleteContainer>
                ))}
            {pageInfo.perm_write && (
                <Button
                    size="small"
                    type="text"
                    icon={<PlusSquareOutlined />}
                    onClick={addDocsHandler}
                    style={{ fontSize: '12px', fontWeight: 'bold' }}
                >
                    {t("문서추가")}
                </Button>
            )}
        </Container>
    );
};

export default DesktopCertificationSpecTableEvidence;
