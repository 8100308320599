import { notification } from "antd";
import React from "react";
import { usePrompt } from "./usePrompt";
import Modal from "@/components/modal";
import { useTranslation } from "react-i18next";

type NotificationType = "success" | "info" | "warning" | "error";

interface IOpenNotification {
  message?: string;
  description?: any;
  type?: NotificationType;
}

export const openNotification = ({
  message = "",
  type = "success",
  description,
}: IOpenNotification): void => {
  let messageText = "";

  if (type === "success") messageText = "Message";
  if (type === "info") messageText = "Info";
  if (type === "warning") messageText = "Alert";
  if (type === "error") messageText = "Error";
  notification[type]({
    message: message === "" ? messageText : message,
    description,
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const lineBreak = (text: string | null) => {
  return typeof text !== "undefined" && text !== null
    ? text.split("\n").map((line, index) => (
        <span key={index}>
          {line}
          <br />
        </span>
      ))
    : "";
};

export const formatDate = (date: string): string => {
  const targetDate = new Date(date);
  let month = "" + (targetDate.getMonth() + 1);
  let day = "" + targetDate.getDate();
  const year = "" + targetDate.getFullYear();
  let hours = "" + targetDate.getHours();
  let minute = "" + targetDate.getMinutes();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (hours.length < 2) hours = "0" + hours;
  if (minute.length < 2) minute = "0" + minute;

  const first = [year, month, day].join("-");
  const second = [hours, minute].join(":");

  return first + " " + second;
};

export const formatDt = (date: string): string => {
  const targetDate = new Date(date);
  let month = "" + (targetDate.getMonth() + 1);
  let day = "" + targetDate.getDate();
  const year = "" + targetDate.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  const first = [year, month, day].join("-");

  return first;
};

export const getToday = (): string => {
  let today: string | Date = new Date();
  let dd: string | number = today.getDate();
  let mm: string | number = today.getMonth() + 1;
  const yyyy = today.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  today = mm + "-" + dd + "-" + yyyy;

  return today;
};

export const removeSessionStorage = () => {
  sessionStorage.removeItem("accessToken");
  sessionStorage.removeItem("refreshToken");
  sessionStorage.removeItem("username");
  sessionStorage.removeItem("userInfo");
  sessionStorage.removeItem("history");
};

export const replaceNl2Br = (str: string) => {
  if (!str) {
    return str;
  }
  return str.replace(/\n/g, "<br/>");
};

type NavigationGuardProps = {
  message?: string;
  when: boolean;
};

export const NavigationGuard = ({
  when,
  message = "No data is saved",
}: NavigationGuardProps) => {
  const { t } = useTranslation();
  const { showPrompt, confirmNavigation, cancelNavigation } = usePrompt(when);

  const handleClickNo = () => {
    cancelNavigation();
  };

  const handleClickYes = () => {
    confirmNavigation();
  };

  return (
    <Modal
      title={t("이 페이지에서 나가시겠습니까?")}
      open={showPrompt}
      onOk={handleClickYes}
      onCancel={handleClickNo}
      width={350}
    >
      <p
        style={{ textAlign: "center" }}
        dangerouslySetInnerHTML={{
          __html: replaceNl2Br(message),
        }}
      ></p>
    </Modal>
  );
};

export const graphColor = [
  "#215B77",
  "#476CA0",
  "#647DA0",
  "#8892A0",
  "#69707B",
  "#3E4249",
  "#47A090",
  "#7DA09A",
  "#5C7571",
  "#32403D",
  "#8E64A0",
  "#9884A0",
  "#6A5C6F",
  "#6A7E83",
];
