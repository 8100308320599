import { IEvaluationColumsProps, IEvaluationDataProps } from './type';

import React, { useState, useRef } from 'react';
import { Button, Space, Table } from 'antd';
import defaultInfo from '@/common/site-info';
import { IAdminMenuPermissionProps } from '@/common/type';
import styled from 'styled-components';
import { DownloadOutlined } from '@ant-design/icons';
import { Excel } from "antd-table-saveas-excel";
import Modal from '@/components/modal';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopDangersEvaluationTableProps {
  riskData: IEvaluationDataProps[];
  columns: IEvaluationColumsProps[];
  isLoading: boolean;
  pageInfo: IAdminMenuPermissionProps;
}

const Container = styled.div`
  text-align: right;
  margin-bottom: 20px;
  position: sticky;
  left: 0;
  top: 70px;
  text-align: right;
  z-index: 5;
`;
const DownloadButton = styled(Button)`
  a {
    display: inline-block;
    padding-left: 10px;
  }
`;

const DesktopDangersEvaluationTable: React.FunctionComponent<
  IDesktopDangersEvaluationTableProps
> = ({ columns, riskData, isLoading, pageInfo }) => {

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);


const iframePrivacyPart = () => {
  return {
    __html:
    '<iframe src="/static/document/management.html" width="100%" height="700px"></iframe>,'
  };
};

const tableRef = useRef(null);

const excelColumns = [
  {
    title: t('통제번호'),
    label: t('통제번호'),
    dataIndex: 'category_num',
    key: 'category_num',
  },
  {
    title: t('구분'),
    label: t('구분'),
    dataIndex: 'category_name',
    key: 'category_name',
  },
  {
    title: t('점검항목'),
    label: t('점검항목'),
    dataIndex: 'check_list_desc',
    key: 'check_list_desc',
  },
  {
    title: t('현황'),
    label: t('현황'),
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: t('위협코드'),
    label: t('위협코드'),
    dataIndex: 'threat_code',
    key: 'threat_code',
  },
  {
    title: t('위협내용'),
    label: t('위협내용'),
    dataIndex: 'threat_desc',
    key: 'threat_desc',
  },
  {
    title: t('취약점'),
    label: t('취약점'),
    dataIndex: 'vulner_desc',
    key: 'vulner_desc',
  },
  {
    title: t('개선방향'),
    label: t('개선방향'),
    dataIndex: 'action_desc',
    key: 'action_desc',
  },
  {
    title: t('발생가능성'),
    label: t('발생가능성'),
    dataIndex: 's_value',
    key: 's_value',
  },
  {
    title: t('유출위험도'),
    label: t('유출위험도'),
    dataIndex: 'leak_risk_value',
    key: 'leak_risk_value',
  },
  {
    title: t('위험도'),
    label: t('위험도'),
    dataIndex: 'risk_value',
    key: 'risk_value',
  },
];

const handleExcelDownload = () => {
  const excel = new Excel();
        excel
          .addSheet("Sheet1")
          .addColumns(excelColumns)
          .addDataSource(riskData, {
            str2Percent: true
          })
          .saveAs(`risk_analisys_result.xlsx`);
      };


  return (
    <>
    <Container>
          <Space size="small">
    <Button type="primary" onClick={() => setOpen(true)}>{t("산출기준")}</Button>
      {pageInfo.perm_write && (
            <DownloadButton
              icon={<DownloadOutlined />}
              onClick={handleExcelDownload}>
                {t("결과 다운로드")}
            </DownloadButton>
      )}
       </Space>
        </Container>
      <Modal
        title={t("관리위험평가 산출기준")}
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={1000}
        footer={<Button onClick={() => setOpen(false)}>{t("닫기")}</Button>}
      >
        <div dangerouslySetInnerHTML={iframePrivacyPart()} />
      </Modal>
      <Table
        ref={tableRef} 
        size={defaultInfo.table_size}
        dataSource={riskData}
        columns={columns}
        pagination={false}
        loading={isLoading}
      />
    </>
  );
};

export default DesktopDangersEvaluationTable;
