import { IAppContextData, IIndexProps } from '@/common/type';

import { AppConsumer } from '@/common/app-provider';
import MobileLayout from '@/layout/mobile-layout';
import React from 'react';
import Panels from '@/panel/panels';

const Index: React.FunctionComponent<IIndexProps> = () => {
  return (
    <AppConsumer>
      {({ config }: IAppContextData) => {
        if (config.isMobile) {
          return <MobileLayout title="진단현황">mobile</MobileLayout>;
        }
        return <Panels />;
      }}
    </AppConsumer>
  );
};

export default Index;
