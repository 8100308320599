import {
    IAssetEvaluationColumnsProps,
    IAssetEvaluationDataProps,
    IPaginationProps,
} from './type';
import { Input, Select, Table } from 'antd';
import React, { useCallback, useState } from 'react';

import DesktopAssetEvaluationTableModal from './desktop-assets-evaluation-table-modal';
import { IAdminMenuPermissionProps } from '@/common/type';
import styled from 'styled-components';
import defaultInfo from '@/common/site-info';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const { TextArea } = Input;
const StyledTextArea = styled(TextArea)``;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopAssetEvaluationTableProps {
    assetList: IAssetEvaluationDataProps[];
    selectGroupName: string;
    setAssetList: React.Dispatch<
        React.SetStateAction<IAssetEvaluationDataProps[]>
    >;
    selectGroupId?: number;
    isLoading: boolean;
    setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
    pageInfo: IAdminMenuPermissionProps;
    pagination: IPaginationProps;
    fetch: (page: any) => void;
    setEditAssetList: React.Dispatch<
        React.SetStateAction<IAssetEvaluationDataProps[]>
    >;
}

const Container = styled.div`
    padding: 20px 0 0 0;
`;
const Text = styled.div`
    word-break: break-all;
`;

const DesktopAssetEvaluationTable: React.FunctionComponent<
    IDesktopAssetEvaluationTableProps
> = ({
    assetList,
    selectGroupName,
    setAssetList,
    isLoading,
    setIsEdit,
    pageInfo,
    pagination,
    fetch,
    setEditAssetList,
}) => {
    const { t } = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectAssetData] = useState<IAssetEvaluationDataProps | null>(null);

    const setDatas = useCallback(
        (value, record, targetKey) => {
            const data = { ...record, [targetKey]: value };

            setEditAssetList(prev => [
                ...prev.filter(item => item.id !== data.id),
                data,
            ]);
            //   dataSync([data]);
        },
        [setEditAssetList]
    );

    const scoreSelect = useCallback(
        (score, record, targetKey) =>
            pageInfo.perm_write ? (
                <Select
                    value={score}
                    onChange={value => {
                        const newRecord = {
                            ...record,
                            [targetKey]: Number(value),
                        };

                        setAssetList(prev => {
                            return prev.map(group =>
                                group.id === newRecord.id ? newRecord : group
                            );
                        });
                        setDatas(Number(value), record, targetKey);
                        setIsEdit(true);
                    }}
                    disabled={!pageInfo.perm_write}
                >
                    <Option value="0">0</Option>
                    <Option value="1">1</Option>
                    <Option value="2">2</Option>
                    <Option value="3">3</Option>
                </Select>
            ) : (
                score
            ),
        [pageInfo.perm_write, setAssetList, setDatas, setIsEdit]
    );

    const columns: IAssetEvaluationColumnsProps[] = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            align: 'center',
            width: '70px',
            hidden: true,
            // eslint-disable-next-line react/display-name
            render: (_, record) => {
                return <span>{record.id}</span>;
            },
        },
        {
            title: t('자산코드'),
            dataIndex: 'asset_code',
            key: 'asset_code',
            align: 'center',
            width: '150px',
            render: text => {
                return <Text>{text}</Text>;
            },
        },
        {
            title: t('자산명'),
            dataIndex: 'asset_name',
            key: 'asset_name',
            align: 'center',
            width: '250px',
            render: text => {
                return <Text>{text}</Text>;
            },
        },
        {
            title: t('기밀성'),
            dataIndex: 'confident_score',
            key: 'confident_score',
            align: 'center',
            width: '40px',
            render: (score, record) => {
                return scoreSelect(score, record, 'confident_score');
            },
        },
        {
            title: t('무결성'),
            dataIndex: 'integrity_score',
            key: 'integrity_score',
            align: 'center',
            width: '40px',
            render: (score, record) => {
                return scoreSelect(score, record, 'integrity_score');
            },
        },
        {
            title: t('가용성'),
            dataIndex: 'avail_score',
            key: 'avail_score',
            align: 'center',
            width: '40px',
            render: (score, record) => {
                return scoreSelect(score, record, 'avail_score');
            },
        },
        {
            title: t('합계'),
            dataIndex: 'sum_score',
            key: 'sum_score',
            align: 'center',
            width: '50px',
            render: (_, record) => {
                const sum =
                    record.confident_score +
                    record.integrity_score +
                    record.avail_score;

                return <>{sum}</>;
            },
        },
        {
            title: t('중요도 등급'),
            dataIndex: 'weight',
            key: 'weight',
            align: 'center',
            width: '70px',
            // eslint-disable-next-line react/display-name
            render: (rank, record) => {
                let value = '-';

                if (rank === 1) {
                    value = '1' + t('등급');
                } else if (rank === 2) {
                    value = '2' + t('등급');
                } else if (rank === 3) {
                    value = '3' + t('등급');
                } else {
                    value = '-';
                }

                return pageInfo.perm_write ? (
                    <Select
                        value={value}
                        style={{ width: '90px' }}
                        onChange={value => {
                            const newRecord = {
                                ...record,
                                weight: Number(value),
                            };

                            setAssetList(prev => {
                                return prev.map(group =>
                                    group.id === newRecord.id
                                        ? newRecord
                                        : group
                                );
                            });
                            setDatas(Number(value), record, 'weight');
                            setIsEdit(true);
                        }}
                    >
                        <Option value="1">1{t("등급")}</Option>
                        <Option value="2">2{t("등급")}</Option>
                        <Option value="3">3{t("등급")}</Option>
                    </Select>
                ) : (
                    rank
                );
            },
        },
        {
            title: t('비고'),
            dataIndex: 'remark',
            key: 'remark',
            align: 'center',
            width: 200,
            // eslint-disable-next-line react/display-name
            render: (text, record) => {
                return pageInfo.perm_write ? (
                    <StyledTextArea
                        allowClear
                        style={{ height: 40 }}
                        defaultValue={text}
                        onChange={() => {
                            setIsEdit(true);
                        }}
                        onBlur={event => {
                            setDatas(event.target.value, record, 'remark');
                        }}
                    />
                ) : (
                    text
                );
            },
        },
        {
            title: t('평가일'),
            dataIndex: 'eval_date',
            key: 'eval_date',
            align: 'center',
            width: '120px',
        },
    ];

    const handleTableChange = page => {
        fetch({ pagination: page });
    };

    return (
        <Container>
            <Table
                columns={columns.filter(item => !item.hidden)}
                dataSource={assetList.length > 0 ? assetList : []}
                pagination={pagination}
                loading={isLoading}
                onChange={handleTableChange}
                size={defaultInfo.table_size}
            />
            <DesktopAssetEvaluationTableModal
                setIsModalVisible={setIsModalVisible}
                isModalVisible={isModalVisible}
                selectAssetEvalData={selectAssetData}
                selectGroupName={selectGroupName}
                setAssetList={setAssetList}
            />
        </Container>
    );
};

export default DesktopAssetEvaluationTable;
