import { Table } from 'antd';
import React from 'react';
import styled from 'styled-components';
import defaultInfo from '@/common/site-info';
import { ITableDataProps } from './type';
import { useTranslation } from 'react-i18next';

interface IDesktopDangersRiskTableProps {
  dataSource: ITableDataProps[];
}

const Container = styled.div`
  border: 1px solid ${props => props.theme.layout.searchBorderColor};
  box-shadow: 2px 0 8px 0 rgb(29 35 41 / 5%);
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 20px;
  h5 {
    font-size: 18px;
    color: 1px solid ${props => props.theme.font.color};
    padding-bottom: 10px;
  }
`;



const DesktopDangersRiskTable: React.FunctionComponent<
  IDesktopDangersRiskTableProps
> = ({ dataSource }) => {
  
  const { t } = useTranslation();
  for (let x in dataSource) {
    dataSource[x].area = t(dataSource[x].area);
    console.log(x + ": "+ dataSource[x].area);
 }
  const columns: any = [
    {
      title: t('영역'),
      dataIndex: 'area',
      key: 'area',
      align: 'center',
    },
    {
      title: `${t("위험도")}3`,
      dataIndex: 'risk3',
      key: 'risk3',
      align: 'center',
    },
    {
      title: `${t("위험도")}4`,
      dataIndex: 'risk4',
      key: 'risk4',
      align: 'center',
    },
    {
      title: `${t("위험도")}5`,
      dataIndex: 'risk5',
      key: 'risk5',
      align: 'center',
    },
    {
      title: `${t("위험도")}6`,
      dataIndex: 'risk6',
      key: 'risk6',
      align: 'center',
    },
    {
      title: `${t("위험도")}7`,
      dataIndex: 'risk7',
      key: 'risk7',
      align: 'center',
    },
    {
      title: `${t("위험도")}8`,
      dataIndex: 'risk8',
      key: 'risk8',
      align: 'center',
    },
    {
      title: `${t("위험도")}9`,
      dataIndex: 'risk9',
      key: 'risk9',
      align: 'center',
    },
    {
      title: t('총 위험개수'),
      dataIndex: 'totalrisk',
      key: 'totalrisk',
      align: 'center',
    },
  ];
  
  return (
    <Container>
      <h5>{t("영역별 위험개수")}</h5>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        size={defaultInfo.table_size}
      />
    </Container>
  );
};

export default DesktopDangersRiskTable;
