import { Avatar, Dropdown, Menu, Space } from 'antd';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import React, { useCallback, useContext, useState } from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';

import DesktopAdminAccountTableModal from '@/panel/admin/Account/desktop-admin-account-table-project-modal';
import { FormattedMessage } from 'react-intl';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import cookie from 'react-cookies';
import { removeSessionStorage } from '@/common/util';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { defaultColor } from '@/styles/color';
import Modal from '@/components/modal';
import { useTranslation } from 'react-i18next';

type IProfileProps = WrappedComponentProps;

const ProfileName = styled.div`
  color: ${defaultColor.lightGray};
  height: 47px;
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
  font-size: 12px;
  .ant-avatar {
    margin-right: 5px;
  }
`;

const LogoutButton = styled.a`
  text-align: left;
`;

const StyledMenu = styled(Menu)`
  border: 1px solid ${props => props.theme.layout.searchBorderColor};
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  margin-top: 4px;
`;

const AccountButton = styled.a``;

const setLogOut = async () => {
  const result = await api.setLogOut({
    username: cookie.load('username'),
  });

  return result;
};

const Profile: React.FunctionComponent<IProfileProps> = ( ) => {
  const { t } = useTranslation();

  const { userInfo } = useContext(ProjectContext);
  const navigate = useNavigate();
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);
  const [isAccountModalVisible, setIsAccountModalVisible] = useState(false);

  const items = [
    {
      label: (
        <AccountButton onClick={() => setIsAccountModalVisible(true)}>
          <Space size={10}>
            <UserOutlined />
            <FormattedMessage id="accountPage" />
          </Space>
        </AccountButton>
      ),
      key: 'item-1',
    }, // remember to pass the key prop
    {
      label: (
        <LogoutButton onClick={() => setIsLogoutModalVisible(true)}>
          <Space size={10}>
            <LogoutOutlined />
            <FormattedMessage id="logout" />
          </Space>
        </LogoutButton>
      ),
      key: 'item-2',
    }, // which is required
  ];

  const menu = <StyledMenu items={items} />;

  const logOutHandler = useCallback(() => {
    setLogOut().then(() => {
      removeSessionStorage();
      setIsLogoutModalVisible(false);
      navigate('/Login', { replace: true });
    });
  }, [navigate]);

  return (
    <>
      <Dropdown overlay={menu}>
        <ProfileName>
          <Avatar
            icon={<UserOutlined />}
            size="small"
            src={userInfo?.profile?.member_pic || ''}
          />
          <>
            {userInfo?.profile?.username || ''} (
            {userInfo?.profile?.fullname || `{t('미인증')} {t('계정')}`})
          </>
        </ProfileName>
      </Dropdown>
      <Modal
        open={isLogoutModalVisible}
        onOk={logOutHandler}
        onCancel={() => setIsLogoutModalVisible(false)}
        okText={t('확인')}
        cancelText={t('취소')}
        width={300}
        centered
      >
        {t('로그아웃msg')}
      </Modal>

      <DesktopAdminAccountTableModal
        isModalVisible={isAccountModalVisible}
        setIsModalVisible={setIsAccountModalVisible}
        selectData={{
          username: userInfo?.profile?.username,
          fullname: userInfo?.profile?.fullname,
          email: userInfo?.profile?.email,
          dept_cd: userInfo?.profile?.dept_cd,
          userhp: userInfo?.profile?.userhp,
          rank_cd: userInfo?.profile?.rank_cd,
        }}
        organList={userInfo?.codes.dp || []}
        codeList={userInfo?.codes.rk || []}
        isAccountPage={false}
      />
    </>
  );
};

export default injectIntl(Profile);
