import { IAppContextData } from '@/common/type';
import React from 'react';

const initialState: IAppContextData = {
  config: {
    isMobile: false,
    isBrowser: true
  }
};

const Context = React.createContext(initialState);

type IAppProviderProps = IAppContextData;

const AppProvider: React.FunctionComponent<IAppProviderProps> = ({
  children,
  config
}) => {
  return <Context.Provider value={{ config }}>{children}</Context.Provider>;
};

const AppConsumer = Context.Consumer;

export default AppProvider;
export { AppConsumer };
