import { ColumnsType } from 'antd/lib/table/interface';
import DesktopDashboardCard from './components/desktop-dashboard-card';
import { IEvidenceDataType } from './type';
import React from 'react';
import { Table } from 'antd';
import styled from 'styled-components';
import defaultInfo from '@/common/site-info';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopDashboardCertificationProps {
    dataSource: IEvidenceDataType[];
    isLoading: boolean;
}

const Inner = styled.div`
    padding: 30px;
    box-sizing: border-box;
    min-height: 332px;
    overflow: hidden;
    overflow-y: auto;
`;

const DesktopDashboardCertification: React.FunctionComponent<
    IDesktopDashboardCertificationProps
> = ({ dataSource, isLoading }) => {
    const { t } = useTranslation();

    const columns: ColumnsType = [
        {
            title: t('주기'),
            dataIndex: 'date',
            key: 'date',
            align: 'center',
        },
        {
            title: t('대상건수'),
            dataIndex: 'total',
            key: 'total',
            align: 'center',
        },
        {
            title: t('금일'),
            dataIndex: 'today',
            key: 'today',
            align: 'center',
        },
        {
            title: t('누적'),
            dataIndex: 'miss',
            key: 'miss',
            align: 'center',
        },
        {
            title: t('미등록'),
            dataIndex: 'noneReg',
            key: 'noneReg',
            align: 'center',
        },
    ];

    return (
        <DesktopDashboardCard
            cardTitle={t("증적관리")}
            moreLink="/Certification/Record"
            isLoading={isLoading}
        >
            <Inner>
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                    size={defaultInfo.table_size}
                />
            </Inner>
        </DesktopDashboardCard>
    );
};

export default DesktopDashboardCertification;
