import { Modal, ModalProps } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';

const StyledCloseOutlined = styled(CloseOutlined)`
    background-color: ${props => props.theme.layout.modalBg};
    width: 30px;
    height: 30px;
    text-align: center;
    display: block;
    box-sizing: border-box;
    padding-top: 7px;
    opacity: 1;
    border-radius: 5px;
    &:hover {
        background-color: ${props => props.theme.layout.modalHoverBg};
    }
`;

const CustomModal: React.FunctionComponent<ModalProps> = ({ ...props }) => {
    return <Modal {...props} closeIcon={<StyledCloseOutlined />} destroyOnClose={true}/>;
};

export default CustomModal;
