import { Avatar, Button, Space, Table, Tag } from 'antd';
import { IAdminMenuPermissionProps, ICodeData } from '@/common/type';
import { IOrgMemberData, IRoleData, IcolumnsProps } from '../type';
import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import DesktopOrgMemberExcel from '../components/desktop-org-member-excel';
import DesktopOrgMembersManagerModal from '../components/desktop-org-members-manager-modal';
import IMask from 'imask';
import { MaskedInput } from 'antd-mask-input';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import styled from 'styled-components';
import defaultInfo from '@/common/site-info';
import CustomButton from "@/components/button";

import { useTranslation } from 'react-i18next';

interface IDesktopOrgMembersSearchTable {
    searchData: { member_name: string; organ_id: number | null };
    orgArrList: IRoleData[];
    pageInfo: IAdminMenuPermissionProps;
}

const Container = styled.div``;

const PorfileContainer = styled.div`
    overflow: hidden;
`;

const StyledAvatar = styled(Avatar)`
    border-radius: 200px;
    width: 30px;
    height: 30px;
    object-fit: cover;
`;

const AddContainer = styled.div`
    text-align: right;
    padding-bottom: 20px;
    position: sticky;
    left: 0;
    top: 70px;
    text-align: right;
    z-index: 5;
`;

const ContainerOrganName = styled.div`
    padding-bottom: 15px;
`;

const Text = styled.div`
    .ant-input {
        text-align: center;
    }
`;
const TagContainer = styled.div`
    display: inline-block;
    padding: 2px 0;
`;

const StyledMaskedInput = styled(MaskedInput)`
    border-radius: 3px;
    &:focus,
    &:active {
        border: 1px solid #6887ad !important;
        box-shadow: 0 0 0 2px rgb(11 55 83 / 18%);
        border-inline-end-width: 1px;
        outline: 0;
    }
    &:hover {
        border: 1px solid #6887ad !important;
        border-inline-end-width: 1px;
    }
`;

const getRandomuserParams = params => ({
    results: params.pagination.pageSize,
    page: params.pagination.current,
    ...params,
});

const getCodes = async ({ projectId, id }) => {
    const result = await api.getCodes({ project_id: projectId, id });

    return result.data;
};

const getMembers = async props => {
    const result = await api.getMembers({
        ...props,
        project_id: props.projectId,
    });

    return result.data;
};

export const profile = (url, record: IOrgMemberData) => (
    <PorfileContainer>
        {url !== '' && url !== null ? (
            <StyledAvatar
                src={process.env.REACT_APP_URL + url}
                size={40}
                icon={<UserOutlined />}
                alt={record.member_name}
            />
        ) : (
            <StyledAvatar size={40} icon={<UserOutlined />} />
        )}
    </PorfileContainer>
);

const DesktopOrgMembersSearchTable: React.FunctionComponent<
    IDesktopOrgMembersSearchTable
> = ({ searchData, orgArrList, pageInfo }) => {
    const { t } = useTranslation();

    const { projectId } = useContext(ProjectContext);

    const [data, setData] = useState<IOrgMemberData[]>([]);
    const [codeList, setCodeList] = useState<ICodeData[]>([]);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
    const [isNewMember, setIsNewMember] = useState(false);
    const [selectId, setSelectId] = useState(0);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 5,
    });
    const [currentPage, setCurrentPage] = useState(1);

    const defaultData: IOrgMemberData = {
        id: null,
        member_email: '',
        member_hp: '',
        member_name: '',
        member_pic: '',
        member_pos: '',
        member_roll: '',
        project_id: projectId,
        rank_cd: '',
        roll_desc: '',
        organ_id: searchData.organ_id,
        key: null,
        role: [
            {
                id: null,
                organ_name: '',
                roll_desc: '',
                member_id: null,
                organ_id: null,
            },
        ],
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columns: IcolumnsProps[] | any = [
        {
            title: t('프로필 사진'),
            dataIndex: 'member_pic',
            sorter: false,
            render: profile,
            width: '10%',
            align: 'center',
        },
        {
            title: t('이름'),
            dataIndex: 'member_name',
            sorter: false,
            width: '8%',
            align: 'center',
            // eslint-disable-next-line react/display-name
            render: text => <Text>{text}</Text>,
        },
        {
            title: t('이메일'),
            dataIndex: 'member_email',
            width: '15%',
            align: 'center',
            // eslint-disable-next-line react/display-name
            render: text => <Text>{text}</Text>,
        },
        {
            title: t('연락처'),
            dataIndex: 'member_hp',
            width: '15%',
            align: 'center',
            // eslint-disable-next-line react/display-name
            render: text => (
                <Text>
                    <StyledMaskedInput
                        value={text}
                        type="text"
                        bordered={false}
                        readOnly
                        mask={[
                            {
                                mask: '000-0000-0000',
                                blocks: {
                                    RGB: {
                                        mask: IMask.MaskedRange,
                                        from: 0,
                                        to: 9999,
                                    },
                                },
                            },
                        ]}
                    />
                </Text>
            ),
        },
        {
            title: t('직급'),
            dataIndex: 'member_pos',
            width: '8%',
            align: 'center',
            // eslint-disable-next-line react/display-name
            render: text => <Text>{text}</Text>,
        },
        {
            title: t('업무'),
            dataIndex: 'member_roll',
            width: '12%',
            align: 'center',
            // eslint-disable-next-line react/display-name
            render: text => <Text>{text}</Text>,
        },
        {
            title: t('소속'),
            dataIndex: 'organ_names',
            width: '17%',
            align: 'center',
            // eslint-disable-next-line react/display-name
            render: (names: string[]) => (
                <ContainerOrganName>
                    {names.map((name, index) => (
                        <TagContainer key={index}>
                            <Tag>{name}</Tag>
                        </TagContainer>
                    ))}
                </ContainerOrganName>
            ),
        },
    ];

    const editColumns = [
        {
            title: t('수정'),
            dataIndex: 'operation',
            width: '5%',
            align: 'center',
            // eslint-disable-next-line react/display-name
            render: (_: any, record: IOrgMemberData) => (
                <CustomButton
                    disabled={false}
                    onClick={() => {
                        setIsNewMember(false);
                        setSelectId(record.id);
                        setVisible(true);
                    }}
                >
                    {t("수정")}
                </CustomButton>
            ),
        },
    ];

    const apiParams = useCallback(
        ({ pagination: { current, pageSize } }) => {
            const organ_id = searchData.organ_id;
            const member_name = searchData.member_name;

            return {
                organ_id: organ_id === null ? '' : organ_id,
                member_name,
                page: member_name !== '' ? 1 : current,
                size: member_name !== '' ? 10000 : pageSize,
                sort: ['member_name', 'desc'],
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [pagination, searchData]
    );

    const fetch = useCallback(
        pagination => {
            setLoading(true);

            getMembers({
                projectId,
                data: getRandomuserParams(pagination),
                ...apiParams(pagination),
            }).then(({ content, pageable, total_elements }) => {
                setLoading(false);
                setData(
                    content.map(item => {
                        return {
                            ...item,
                            role:
                                item.role.length > 0
                                    ? [...item.role]
                                    : [
                                          {
                                              id: null,
                                              organ_name: '',
                                              roll_desc: '',
                                              member_id: null,
                                              organ_id: null,
                                          },
                                      ],
                        };
                    })
                );
                setPagination({
                    current: pagination.current,
                    pageSize: pageable.page_size,
                    total: total_elements,
                });
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [pagination, searchData, visible, isUploadModalVisible]
    );

    useEffect(() => {
        if (!visible) {
            fetch({ pagination: { ...pagination, current: currentPage } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchData, isUploadModalVisible, currentPage, visible]);

    useEffect(() => {
        getCodes({ projectId, id: 'RK' }).then(data => {
            setCodeList(data);
        });
    }, [projectId]);

    const handleTableChange = page => {
        setCurrentPage(page.current);
    };

    return (
        <Container>
            {pageInfo.perm_write && (
                <AddContainer>
                    <Space>
                        <DesktopOrgMemberExcel
                            isUploadModalVisible={isUploadModalVisible}
                            setIsUploadModalVisible={setIsUploadModalVisible}
                        />
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            size="middle"
                            onClick={() => {
                                setIsNewMember(true);
                                setVisible(true);
                            }}
                        >
                            {t("구성원 추가")}
                        </Button>
                    </Space>
                </AddContainer>
            )}
            <Table
                columns={
                    pageInfo.perm_write ? [...columns, ...editColumns] : columns
                }
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                size={defaultInfo.table_size}
            />
            <DesktopOrgMembersManagerModal
                visible={visible}
                setVisible={setVisible}
                isNewMember={isNewMember}
                codeList={codeList}
                orgId={searchData.organ_id}
                datas={
                    isNewMember
                        ? defaultData
                        : data.filter(item => item.id === selectId)[0] ||
                          defaultData
                }
                pageName="searchTable"
                orgArrList={orgArrList}
                fetch={fetch}
                pagination={pagination}
                currentPage={currentPage}
            />
        </Container>
    );
};

export default DesktopOrgMembersSearchTable;
