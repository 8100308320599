import { Button, Result } from 'antd';

import { Link } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Index: React.FunctionComponent = () => {
  return (
    <Container>
      <Result
        status='404'
        title='404'
        subTitle='Sorry, the page you visited does not exist.'
        extra={
          <Link to='/'>
            <Button type='primary'>Back Home</Button>
          </Link>
        }
      />
    </Container>
  );
};

export default Index;
