import { Button, Popconfirm, Space, Table } from 'antd';
import {
  EditOutlined,
  UploadOutlined,
  ZoomInOutlined,
} from '@ant-design/icons';
import {
  ICertificationTableColumnsProps,
  ICertificationTableRecord,
} from '../type';
import React, { useCallback, useContext } from 'react';
import { formatDate, lineBreak, openNotification } from '@/common/util';

import { IAdminMenuPermissionProps } from '@/common/type';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import styled from 'styled-components';
import defaultInfo from '@/common/site-info';
import DeleteButton from "@/components/deletebutton";
import CustomButton from "@/components/button";
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopCertificationTableProps {
  setSelectId: React.Dispatch<React.SetStateAction<number>>;
  recordDatas: ICertificationTableRecord[];
  setRecordDatas: React.Dispatch<
    React.SetStateAction<ICertificationTableRecord[]>
  >;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setIsNewModal?: React.Dispatch<React.SetStateAction<boolean>>;
  type: string;
  isOnLoadData: boolean;
  pageInfo: IAdminMenuPermissionProps;
}

interface ITextProps {
  align?: string;
}

const CertificationTable = styled.div`
  clear: both;
  padding-top: 20px;
  p {
    margin: 0;
  }
  .ant-table-cell {
    vertical-align: top;
  }
`;

const Text = styled.p<ITextProps>`
  ${props =>
    props.align === 'center' ? `text-align: center;` : `text-align: left;`}
  padding: 2px 0;
`;
const Date = styled.span``;

const delCertificationTrace = async ({ project_id, record }) => {
  const id = record.id;

  const result = await api.delCertificationTrace({
    project_id,
    id,
  });

  return result.data;
};


const sorter = (
  a: ICertificationTableRecord,
  b: ICertificationTableRecord,
  target: string
): number => {
  if (a[target] > b[target]) return -1;
  else if (a[target] < b[target]) return 1;
  return 0;
};

const DesktopCertificationTable: React.FunctionComponent<
  IDesktopCertificationTableProps
> = ({
  recordDatas,
  setRecordDatas,
  setIsModalVisible,
  setSelectId,
  setIsNewModal,
  type,
  isOnLoadData,
  pageInfo,
}) => {
  const { t } = useTranslation();
  const { projectId } = useContext(ProjectContext);

    
  const setCycle = num => {
    switch (num) {
      case 0:
        return t('상시');
      case 1:
        return '1' + t('개월');
      case 2:
        return '2' + t('개월');
      case 3:
        return '3' + t('개월');
      case 6:
        return '6' + t('개월');
      case 12:
        return '1' + t('년');
    }
  };

  const onConfirm = useCallback(
    record => {
      delCertificationTrace({ project_id: projectId, record }).then(() => {
        const notiText = {
          description: t('완료'),
        };

        openNotification(notiText);
        setIsNewModal(true);
        setRecordDatas(prev => {
          return prev.filter(item => item.id !== record.id);
        });
      });
    },
    [projectId, setIsNewModal, setRecordDatas, t]
  );

  const commonColumns: ICertificationTableColumnsProps[] = [
    {
      title: 'No',
      dataIndex: 'id',
      key: 'id',
      width: 'auto',
      align: 'center',
      render: (_text, _record, index) => {
        return <>{index + 1}</>;
      },
      // eslint-disable-next-line react/display-name
      // render: text => <Text align='center'>{lineBreak(text)}</Text>
    },
    {
      title: t('구분'),
      dataIndex: 'category_name',
      key: 'category_name',
      align: 'center',
      width: 'auto',
      sorter: (a, b) => {
        return sorter(a, b, 'category_name');
      },
      // eslint-disable-next-line react/display-name
      render: text => <Text align="left">{lineBreak(text)}</Text>,
    },
    {
      title: t('증적명'),
      dataIndex: 'doc_name',
      key: 'doc_name',
      align: 'center',
      width: 'auto',
      sorter: (a, b) => {
        return sorter(a, b, 'doc_name');
      },
      // eslint-disable-next-line react/display-name
      render: text => <Text align="left">{lineBreak(text)}</Text>,
    },
    {
      title: t('이행주기'),
      dataIndex: 'cycle',
      key: 'cycle',
      align: 'center',
      width: '100px',
      sorter: (a, b) => {
        return sorter(a, b, 'cycle');
      },
      // eslint-disable-next-line react/display-name
      render: num => <p>{setCycle(num)}</p>,
    },
    {
      title: t('담당자'),
      dataIndex: 'manager',
      key: 'manager',
      align: 'center',
      width: '100px',
      hidden: true,
      sorter: (a, b) => {
        return sorter(a, b, 'manager');
      },
      // eslint-disable-next-line react/display-name
      render: text => <Text align="center">{lineBreak(text)}</Text>,
    },
  ];

  const insertColumns: ICertificationTableColumnsProps[] =
    type === 'record'
      ? [
          {
            title: pageInfo.perm_write ? `${t('수정')} / ${t('삭제')}` : t('상세내용'),
            dataIndex: 'control',
            key: 'control',
            align: 'center',
            width: 'auto',
            // eslint-disable-next-line react/display-name
            render: (_, record) => {
              return (
                <Space size="small">
                  <CustomButton
                    icon={
                      pageInfo.perm_write ? (
                        <EditOutlined />
                      ) : (
                        <ZoomInOutlined />
                      )
                    }
                    onClick={() => {
                      setIsNewModal(false);
                      setSelectId(record.id);
                      setIsModalVisible(true);
                    }}
                  ></CustomButton>
                  {pageInfo.perm_write && (
                    <Popconfirm
                      title={t("삭제 하시겠습니까?")}
                      onConfirm={() => {
                        onConfirm(record);
                      }}
                      // onCancel={cancel}
                      okText={t("삭제")}
                      cancelText={t("취소")}
                    >
                      <DeleteButton
                        style={{ fontSize: '12x' }}
                      ></DeleteButton>
                    </Popconfirm>
                  )}
                </Space>
              );
            },
          },
        ]
      : [
          {
            title: t('최종 업로드'),
            dataIndex: 'last_date',
            key: 'last_date',
            align: 'center',
            width: '170px',
            sorter: (a, b) => {
              return sorter(a, b, 'last_date');
            },
            // eslint-disable-next-line react/display-name
            render: text =>
              text !== null ? <Date>{formatDate(text)}</Date> : <>-</>,
          },
          {
            title: pageInfo.perm_write ? t('증적등록') : t('증적확인'),
            dataIndex: 'data_upload',
            key: 'data_upload',
            align: 'center',
            width: 'auto',
            // eslint-disable-next-line react/display-name
            render: (_, record) => {
              return (
                <Button
                  style={{ fontSize: '12px' }}
                  size="middle"
                  icon={
                    pageInfo.perm_write ? (
                      <UploadOutlined />
                    ) : (
                      <ZoomInOutlined />
                    )
                  }
                  onClick={() => {
                    setSelectId(record.id);
                    setIsModalVisible(true);
                  }}
                >
                  {pageInfo.perm_write ? t('증적등록') : t('증적확인')}
                </Button>
              );
            },
          },
        ];

  const columns: ICertificationTableColumnsProps[] = [
    ...commonColumns.filter(item => !item.hidden),
    ...insertColumns,
  ];

  return (
    <CertificationTable>
      <Table
        dataSource={recordDatas}
        columns={columns}
        size={defaultInfo.table_size}
        pagination={false}
        loading={isOnLoadData}
      />
    </CertificationTable>
  );
};

export default DesktopCertificationTable;
