import { Button, Upload } from 'antd';
import { CloseOutlined, InboxOutlined } from '@ant-design/icons';
import React, { useState } from 'react';

import { ISystemInfo } from '@/panel/admin/System/type';
import api from '@/api/api';
import styled from 'styled-components';

const { Dragger } = Upload;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IFileDragUploadProps {
    systemInfo: ISystemInfo;
    setSystemInfo: React.Dispatch<React.SetStateAction<ISystemInfo>>;
    target: string;
}

const Container = styled.div`
    .ant-upload-list {
        display: none;
    }
`;
const StyledDragger = styled(Dragger)`
    padding: 10px;
    font-size: 12px !important;
    &.ant-upload.ant-upload-drag p.ant-upload-text {
        font-size: 13px;
    }
    &.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
        margin-bottom: 10px;
    }
`;
const PreViewImageContainer = styled.div`
    width: 264px;
    height: 124px;
    display: table;
    table-layout: fixed;
    padding: 10px;
    box-sizing: border-box;
    position: relative;
    background-color: ${props => props.theme.layout.logoBg};
    border-radius: 2px;
    text-align: center;
    overflow: hidden;
    &:hover {
        .ant-btn.ant-btn-text {
            opacity: 1;
        }
    }
`;
const Item = styled.div`
    display: table-cell;
    vertical-align: middle;
    width: 100%;
`;
const StyledButton = styled(Button)`
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0;

    &.ant-btn.ant-btn-text {
        color: #8c8c8c;
    }
`;

const setProfileImageUpload = async file => {
    const result = await api.setProfileImageUpload(file);

    return result.data;
};

const FileDragUpload: React.FunctionComponent<IFileDragUploadProps> = ({
    systemInfo,
    setSystemInfo,
    target,
}) => {
    const [previewImage, setPreviewImage] = useState(
        `${process.env.REACT_APP_URL}/api/images/${systemInfo[target]}`
    );

    const props = {
        name: 'file',
        multiple: false,
        onChange(info) {
            if (info.file.status === 'uploading') {
                // setLoading(true);
                return;
            }
            const formData = new FormData();

            formData.append('file', info.file.originFileObj);
            setProfileImageUpload(formData).then(data => {
                setPreviewImage(
                    process.env.REACT_APP_URL + data.file_download_uri
                );
                setSystemInfo(prev => {
                    return { ...prev, [target]: data.file_name };
                });
            });
        },

        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    return (
        <Container>
            {systemInfo[target] === '' || systemInfo[target] === null ? (
                <StyledDragger {...props}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                        영역을 클릭하거나 <br />
                        영역으로 파일을 끌어다 놓으십시오.
                    </p>
                </StyledDragger>
            ) : (
                <PreViewImageContainer>
                    <Item>
                        <img
                            alt=""
                            style={{ height: '20px', objectFit: 'contain' }}
                            src={previewImage}
                        />
                    </Item>
                    <StyledButton
                        type="text"
                        icon={<CloseOutlined />}
                        onClick={() => {
                            setPreviewImage('');
                            setSystemInfo(prev => {
                                return { ...prev, [target]: null };
                            });
                        }}
                    />
                </PreViewImageContainer>
            )}
        </Container>
    );
};

export default FileDragUpload;
