import { BackTop } from 'antd';
import React from 'react';
import { VerticalAlignTopOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { defaultColor } from '@/styles/color';

const Container = styled.div``;

const TopButtonContainer = styled(BackTop)`
  bottom: 65px;
  right: 30px;
`;

const TopButton = styled.button`
  background: ${defaultColor.gray};
  width: 50px;
  height: 50px;
  border-radius: 50px;
  color: ${defaultColor.white}!important;
  font-size: 20px;
  cursor: pointer;
`;

const Floting: React.FunctionComponent = () => {
  return (
    <Container>
      <TopButtonContainer>
        <TopButton>
          <VerticalAlignTopOutlined />
        </TopButton>
      </TopButtonContainer>
    </Container>
  );
};

export default Floting;
