import DesktopBreadcrumb from './desktop-breadcrumb';
import { IBreadCrumbProps } from './type';
import React from 'react';

const Breadcrumb: React.FunctionComponent<IBreadCrumbProps> = ({
  breadCrumb
}) => {
  return (
    <div>
      <DesktopBreadcrumb breadCrumb={breadCrumb} />
    </div>
  );
};

export default Breadcrumb;
