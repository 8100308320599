import React, { useCallback, useContext, useEffect, useState } from 'react';

import DesktopDiagnosisManagementHeader from '@/panel/Diagnosis/Management/desktop-diagnosis-management-header';
import DesktopDiagnosisManagementTable from './desktop-diagnosis-management-table';
import { IAdminMenuPermissionProps } from '@/common/type';
import { IDiagnosisManagementDataProps } from './type';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import { openNotification } from '@/common/util';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopDiagnosisManagementProps {
  pageInfo: IAdminMenuPermissionProps;
}

const Container = styled.div``;

const getCertScore = async ({ projectId, categoryId, diaDateValue }) => {
  const result = await api.getCertScore({
    project_id: projectId,
    category_id: categoryId,
    date: diaDateValue,
  });

  return result.data;
};

const setCertScore = async ({ projectId, datas }) => {
  const result = await api.setCertScore({
    project_id: projectId,
    datas,
  });

  return result.data;
};

const setCertScoreUpdate = async ({ projectId, date, datas }) => {
  const result = await api.setCertScoreUpdate({
    project_id: projectId,
    datas,
    date,
  });

  return result.data;
};

const setCertScoreProc = async ({ projectId, datas }) => {
  const result = await api.setCertScoreProc({
    project_id: projectId,
    datas,
  });

  return result.data;
};

const getCertScoreDates = async ({ projectId }) => {
  const result = await api.getCertScoreDates({ project_id: projectId });

  return result.data;
};

const DesktopDiagnosisManagement: React.FunctionComponent<
  IDesktopDiagnosisManagementProps
> = ({ pageInfo }) => {
  const { t } = useTranslation();
  const { projectId } = useContext(ProjectContext);

  const [categoryId, setCategoryId] = useState(null);
  const [diaDateValue, setDiaDateValue] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [dataSource, setDataSource] = useState<IDiagnosisManagementDataProps[]>(
    []
  );
  const [editCertScoreData, setEditCertScoreData] = useState([]);
  const [date, setDate] = useState([]);
  const [isCompleteButton, setIsCompleteButton] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);

  const [loading, setLoading] = useState([false]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dataSync = useCallback(data => {
    setDataSource(prev =>
      prev.map(item => {
        if (item.check_id === data[0].check_id) {
          return data[0];
        }
        return item;
      })
    );
  }, []);

  const getData = useCallback(() => {
    setIsLoading(true);
    setDataSource([]);
    getCertScore({ projectId, categoryId, diaDateValue }).then(datas => {
      setDataSource(
        datas.map((data: IDiagnosisManagementDataProps, index: number) => ({
          ...data,
          key: index,
        }))
      );
      setIsLoading(false);
      setIsCompleteButton(diaDateValue === '');
    });
  }, [categoryId, diaDateValue, projectId]);

  const setDataAPI = useCallback(
    callback => {
      if (isEditMode) {
        setCertScoreUpdate({
          projectId,
          date: diaDateValue,
          datas: editCertScoreData,
        }).then(() => {
          const notiText = {
            description: t("완료"),
          };

          callback();
          openNotification(notiText);
          setLoading([false]);
          setIsCompleteButton(true);
          setIsEditMode(false);
        });
      } else {
        setCertScore({ projectId, datas: editCertScoreData }).then(() => {
          const notiText = {
            description: t("완료"),
          };

          callback();
          openNotification(notiText);
          setLoading([false]);
        });
      }
    },
    [diaDateValue, editCertScoreData, isEditMode, projectId, t]
  );

  const setCompleteAPI = useCallback(() => {
    setCertScoreProc({ projectId, datas: dataSource }).then(() => {
      const notiText = {
        description: t("완료"),
      };

      openNotification(notiText);
      setLoading([false]);
      getData();
      getCertScoreDates({ projectId }).then(data => {
        setDate(data);
      });
      // window.location.reload();
    });
  }, [dataSource, getData, projectId, t]);

  return (
    <Container>
      <DesktopDiagnosisManagementHeader
        setCategoryId={setCategoryId}
        setDiaDateValue={setDiaDateValue}
        getData={getData}
        getCertScoreDates={getCertScoreDates}
        setDate={setDate}
        date={date}
      />
      <DesktopDiagnosisManagementTable
        isLoading={isLoading}
        dataSource={dataSource}
        setDataAPI={setDataAPI}
        setCompleteAPI={setCompleteAPI}
        loading={loading}
        setLoading={setLoading}
        setEditCertScoreData={setEditCertScoreData}
        dataSync={dataSync}
        isCompleteButton={isCompleteButton}
        getData={getData}
        diaDateValue={diaDateValue}
        categoryId={categoryId}
        pageInfo={pageInfo}
        setIsCompleteButton={setIsCompleteButton}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
      />
    </Container>
  );
};

export default DesktopDiagnosisManagement;
