import {
  AutoComplete,
  Button,
  Form,
  Input,
  List,
  Radio,
  Space,
  Tag,
} from 'antd';
import {
  ICertificationTableRecord,
  ICertificationTableRecordCategory,
} from '../type';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { IAdminMenuPermissionProps } from '@/common/type';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import { openNotification } from '@/common/util';
import styled from 'styled-components';
import Modal from '@/components/modal';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopCertificationHeaderAddProps {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  targetData?: ICertificationTableRecord;
  isNewModal: boolean;
  pageInfo: IAdminMenuPermissionProps;
}

const SubmitButton = styled.div`
  text-align: center;
  border-top: 1px solid ${props => props.theme.layout.searchBorderColor};
  padding-top: 20px;
`;
const StyledButton = styled(Button)`
  position: absolute;
  right: 5px;
  top: 7px;
  font-size: 10px;
  color: #ccc;
`;
const ManagerList = styled.div``;
const StyledTag = styled(Tag)`
  padding: 5px 8px;
  .anticon-close {
    margin-left: 8px;
  }
`;

const setCertificationTrace = async ({ project_id, datas }) => {
  const result = await api.setCertificationTrace({
    project_id,
    datas,
  });

  return result.data;
};

const getCertificationCategorySearch = async ({ project_id, keyword }) => {
  const result = await api.getCertificationCategorySearch({
    project_id,
    keyword,
  });

  return result.data;
};

const getMemberSearch = async ({ project_id, member_name }) => {
  const result = await api.getMemberSearch({
    project_id,
    member_name,
  });

  return result.data;
};

const DesktopCertificationHeaderAdd: React.FunctionComponent<
  IDesktopCertificationHeaderAddProps
> = ({
  isModalVisible,
  setIsModalVisible,
  targetData,
  isNewModal,
  pageInfo,
}) => {
  const { t } = useTranslation();
  const { projectId } = useContext(ProjectContext);

  const [recordData, setRecordData] = useState(targetData);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryText, setCategoryText] = useState('');
  const [categoryOptions, setCategoryOptions] = useState<{ value: string }[]>(
    []
  );
  const [memberText, setMemberText] = useState('');
  const [membersOptions, setMembersOptions] = useState<{ value: string }[]>([]);
  const [isTagVisible, setIsTagVisible] = useState(true);

  useEffect(() => {
    setRecordData(targetData);
  }, [targetData]);

  const recordFields = useMemo(() => {
    return typeof targetData !== 'undefined'
      ? Object.keys(targetData).map(item => {
          return {
            name: [item],
            value: targetData[item],
          };
        })
      : [{ name: '', value: '' }];
  }, [targetData]);

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsTagVisible(true);
  };

  const onFinish = useCallback(
    (values: any) => {
      const newData: ICertificationTableRecordCategory = {
        ...values,
        category: [...recordData.category],
        manager_id: recordData.manager_id,
        manager: recordData.manager,
        key: recordData.id,
      };

      setIsLoading(true);
      setCertificationTrace({ project_id: projectId, datas: newData }).then(
        () => {
          const notiText = {
            description: t("완료"),
          };

          openNotification(notiText);

          setIsLoading(false);
          setIsModalVisible(false);
          if (isNewModal) {
            window.scrollTo(0, document.body.scrollHeight);
          }
        }
      );
    },
    [
      isNewModal,
      projectId,
      recordData.category,
      recordData.id,
      recordData.manager,
      recordData.manager_id,
      setIsModalVisible,
      t,
    ]
  );

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const onCategoryNameSearch = (searchText: string) => {
    getCertificationCategorySearch({
      project_id: projectId,
      keyword: searchText,
    }).then(datas => {
      const optionList = datas.map((item, index) => ({
        value: item.category_num + ' - ' + item.category_name,
        key: index,
        id: item.id,
        category_desc: item.category_desc,
        category_name: item.category_name,
        parent_id: item.parent_id,
        category_num: item.category_num,
      }));
      setCategoryOptions(!searchText ? [] : optionList);
    });
  };
  const onMemberNameSearch = (searchText: string) => {
    getMemberSearch({
      project_id: projectId,
      member_name: searchText,
    }).then(datas => {
      const optionList = datas.map((item, index) => ({
        value: item.member_name,
        key: index,
        id: item.id,
      }));

      setMembersOptions(!searchText ? [] : optionList);
    });
  };
  const onCategoryNameSelect = (_, record) => {
    const selectCategory = {
      id: record.id,
      category_desc: record.category_desc,
      category_name: record.category_name,
      category_num: record.category_num,
      parent_id: record.parent_id,
    };

    setRecordData(prev => {
      return {
        ...prev,
        category: [...prev.category, selectCategory],
      };
    });
    setCategoryText('');
  };
  const onMemberNameSelect = useCallback(
    (value, record) => {
      setRecordData(prev => {
        return {
          ...prev,
          manager_id: record.id,
          manager: value,
        };
      });
      // setMemberText('');
      setIsTagVisible(true);
    },
    [setRecordData]
  );

  const removeCategortItem = item => {
    const data = recordData.category.filter(
      category => category.id !== item.id
    );

    setRecordData(prev => {
      return {
        ...prev,
        category: data,
      };
    });
    setCategoryOptions([]);
  };

  const memberTagClose = () => {
    setRecordData(prev => {
      return {
        ...prev,
        manager: '',
        manager_id: null,
      };
    });
    setIsTagVisible(false);
  };

  return typeof recordData !== 'undefined' ? (
    <Modal
      title={t("증적목록")}
      open={isModalVisible}
      footer={null}
      onCancel={handleCancel}
    >
      <Form
        name="basic"
        initialValues={{ remember: true }}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        fields={recordFields}
      >
        <Form.Item name="manager_id" hidden={true}>
          <Input />
        </Form.Item>
        <Form.Item name="category" hidden={true}>
          <Input />
        </Form.Item>
        <Form.Item name="id" hidden={true}>
          <Input />
        </Form.Item>
        <Form.Item
          label={t("증적명")}
          name="doc_name"
          rules={[{ required: true, message: t('증적명을 입력해주세요.') }]}
        >
          <Input
            placeholder={t("증적명")}
            readOnly={!pageInfo.perm_write}
            bordered={pageInfo.perm_write}
          />
        </Form.Item>
        <Form.Item
          label={t("설명")}
          name="doc_desc"
          rules={[{ required: false, message: t('설명을 입력해주세요.') }]}
        >
          <TextArea
            rows={2}
            placeholder={t("설명")}
            readOnly={!pageInfo.perm_write}
            bordered={pageInfo.perm_write}
          />
        </Form.Item>
        <Form.Item
          label={t("통제항목 지정")}
          // name='category'
          rules={[{ required: true, message: t('통제항목을 선택해주세요.') }]}
        >
          {pageInfo.perm_write && (
            <AutoComplete
              options={categoryOptions}
              onSelect={onCategoryNameSelect}
              onSearch={onCategoryNameSearch}
              onChange={value => {
                setCategoryText(value);
              }}
              onBlur={() => {
                setCategoryText('');
              }}
              style={{ zIndex: 1 }}
              value={categoryText}
            >
              <Input.Search placeholder={t("통제항목 지정")} />
            </AutoComplete>
          )}
          <List
            header={null}
            footer={null}
            bordered
            size="small"
            style={{ marginTop: '-2px', width: 'calc(100% - 1px)' }}
            dataSource={recordData?.category}
            renderItem={item => (
              <List.Item style={{ position: 'relative' }}>
                {item.category_num} - {item.category_name}
                {pageInfo.perm_write && (
                  <StyledButton
                    type="text"
                    size="small"
                    icon={<CloseOutlined />}
                    onClick={() => {
                      removeCategortItem(item);
                    }}
                  ></StyledButton>
                )}
              </List.Item>
            )}
          />
        </Form.Item>
        <Form.Item
          label={t("이행주기")}
          name="cycle"
          rules={[{ required: true, message: t('이행주기를 선택해주세요.') }]}
        >
          <Radio.Group value={0}>
            <Radio value={0}>{t("상시")}</Radio>
            <Radio value={1}>1{t("개월")}</Radio>
            <Radio value={2}>2{t("개월")}</Radio>
            <Radio value={3}>3{t("개월")}</Radio>
            <Radio value={6}>6{t("개월")}</Radio>
            <Radio value={12}>1{t("년")}</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={t("증적 관리 담당자")}
          name="manager"
          rules={[
            {
              required: false,
              message: t('담당자를 선택해주세요.'),
            },
          ]}
        >
          <Space>
            {recordData.manager_id === null ? (
              <AutoComplete
                options={membersOptions}
                style={{ width: 200 }}
                onSearch={onMemberNameSearch}
                onSelect={onMemberNameSelect}
                onBlur={() => {
                  // setMemberText('');
                }}
                onChange={value => {
                  setMemberText(value);
                }}
                value={memberText}
              >
                <Input.Search placeholder={t("증적 관리 담당자")} />
              </AutoComplete>
            ) : (
              <ManagerList>
                {recordData.manager_id !== null && (
                  <StyledTag
                    closable={pageInfo.perm_write}
                    visible={isTagVisible}
                    onClose={() => {
                      memberTagClose();
                    }}
                  >
                    {recordData.manager}
                  </StyledTag>
                )}
              </ManagerList>
            )}
          </Space>
        </Form.Item>

        <Form.Item key="submit" style={{ margin: 0 }}>
          {pageInfo.perm_write ? (
            <SubmitButton>
              <Space>
                <Button key="back" onClick={handleCancel}>
                {t("취소")}
                </Button>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  {t("저장")}
                </Button>
              </Space>
            </SubmitButton>
          ) : (
            <SubmitButton>
              <Button type="primary" key="back" onClick={handleCancel}>
              {t("확인")}
              </Button>
            </SubmitButton>
          )}
        </Form.Item>
      </Form>
    </Modal>
  ) : (
    <></>
  );
};

export default DesktopCertificationHeaderAdd;
