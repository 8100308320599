import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

import { IMenuFoldingProps } from '../type';
import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';

interface IMenuFoldingBtnProps {}

const MenuFoldingBtn = styled(Button)<IMenuFoldingBtnProps>`
    width: 48px;
    height: 48px;
    box-sizing: border-box;
    position: fixed;
    padding: 0;
    left: 15px;
    /* top: 0; */
    font-size: 20px;
    bottom: 0;
    > span {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
`;

const StyledMenuFoldOutlined = styled(MenuFoldOutlined)`
    color: ${props => props.theme.font.color};
`;
const StyledMenuUnfoldOutlined = styled(MenuUnfoldOutlined)`
    color: ${props => props.theme.font.color};
`;

const MenuFolding: React.FunctionComponent<IMenuFoldingProps> = ({
    collapsed,
    HeaderToggle,
}) => {
    return (
        <MenuFoldingBtn type="button">
            {React.createElement(
                collapsed ? StyledMenuFoldOutlined : StyledMenuUnfoldOutlined,
                {
                    className: 'trigger',
                    onClick: HeaderToggle,
                }
            )}
        </MenuFoldingBtn>
    );
};

export default MenuFolding;
