import { Button, Form, Input, Space, message } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { IVersion } from './type';
import api from '@/api/api';
import { openNotification } from '@/common/util';
import Modal from '@/components/modal';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopVersionModalProps {
  dataSource: IVersion[];
  setIsVersionModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isVersionModalVisible: boolean;
}

const setAdminVersion = async ({ datas }) => {
  const result = await api.setAdminVersion({ datas });

  return result.data;
};

const DesktopVersionModal: React.FunctionComponent<
  IDesktopVersionModalProps
> = ({ dataSource = [], setIsVersionModalVisible, isVersionModalVisible }) => {
  const fields = useMemo(
    () =>
      dataSource.length > 0
        ? [
            {
              name: ['ui_version'],
              value: dataSource[0].ui_version,
            },
            {
              name: ['db_version'],
              value: dataSource[0].db_version,
            },
            {
              name: ['api_version'],
              value: dataSource[0].api_version,
            },
          ]
        : [],
    [dataSource]
  );

  const isEdit = useCallback(
    values => {
      const lastData = dataSource[0];

      if (
        lastData.ui_version === values.ui_version &&
        lastData.api_version === values.api_version &&
        lastData.db_version === values.db_version
      ) {
        return false;
      }

      return true;
    },
    [dataSource]
  );

  const onFinish = useCallback(
    (values: any) => {
      if (isEdit(values)) {
        setAdminVersion({
          datas: { ...dataSource[0], ...values },
        }).then(() => {
          setIsVersionModalVisible(false);
          const notiText = {
            description: `새로운 버전이 저장 완료`,
          };

          openNotification(notiText);
        });
      } else {
        message.info('버전이 변경되지 않았습니다.');
      }
    },
    [dataSource, isEdit, setIsVersionModalVisible]
  );

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Modal
        title="버전추가"
        open={isVersionModalVisible}
        footer={null}
        onCancel={() => setIsVersionModalVisible(false)}
      >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          fields={fields}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="UI Version"
            name="ui_version"
            rules={[{ required: true, message: '버전을 입력해주세요' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="API Version"
            name="api_version"
            rules={[{ required: true, message: '버전을 입력해주세요' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="DB Version"
            name="db_version"
            rules={[{ required: true, message: '버전을 입력해주세요' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Space size="small">
              <Button type="primary" htmlType="submit">
                저장
              </Button>
              <Button
                type="default"
                onClick={() => setIsVersionModalVisible(false)}
              >
                취소
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default DesktopVersionModal;
