import { Button, Form, Input, Select, Space } from 'antd';
import React, { useContext, useMemo } from 'react';

import { IFieldProps } from '@/panel/admin/AssetsGroup/type';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import styled from 'styled-components';
import { IPaginationProps } from '../Evaluation/type';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

interface IDesktopAssetsManagementGroupContentSearchProps {
  id: number;
  columns: IFieldProps[];
  setDataSource: React.Dispatch<React.SetStateAction<IFieldProps[]>>;
  pagination: IPaginationProps;
  setPagination: React.Dispatch<React.SetStateAction<IPaginationProps>>;
}

const Container = styled.div`
  text-align: left;
  padding: 24px 20px;
  margin-bottom: 20px;
  border: 1px solid ${props => props.theme.layout.searchBorderColor};
  border-radius: 10px;
  .ant-form-item {
    margin: 0;
  }
`;
const StyledSelect = styled(Select)`
  text-align: left;
`;
const SelectContainer = styled.div`
  min-width: 150px;
  height: 30px;
  box-sizing: border-box;
`;

const getAssetsPage = async ({ projectId, id, params, size, page }) => {
  const result = await api.getAssetsPage({
    project_id: projectId,
    id,
    size,
    params,
    page,
  });

  return result.data;
};

const DesktopAssetsManagementGroupContentSearch: React.FunctionComponent<
  IDesktopAssetsManagementGroupContentSearchProps
> = ({ columns, id, setDataSource, pagination, setPagination }) => {
  const { t } = useTranslation();
  
  const { projectId } = useContext(ProjectContext);

  const onFinish = (values: any) => {
    getAssetsPage({
      projectId,
      id,
      params: values,
      size: pagination.pageSize || 100,
      page: pagination.current || 1,
    }).then(data => {
      const { content, total_elements } = data;

      setDataSource(content.map(item => ({ ...item, key: item.id })));
      setPagination(prev => {
        return {
          ...prev,
          pageSize: pagination.pageSize,
          current: pagination.current,
          total: total_elements,
        };
      });
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const filterColumnsData = useMemo(() => {
    return columns.filter(column => column.id !== null);
  }, [columns]);

  const [form] = Form.useForm();

  const resetHandler = () => {
    form.resetFields();
  };

  return (
    <Container>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
      >
        <Space>
          <SelectContainer>
            {filterColumnsData.length > 0 && (
              <Form.Item name="col" initialValue={filterColumnsData[0].id}>
                <StyledSelect style={{ width: 150 }}>
                  {filterColumnsData.map(column => (
                    <Option value={column.id} key={column.object_name}>
                      {column.field_name}
                    </Option>
                  ))}
                </StyledSelect>
              </Form.Item>
            )}
          </SelectContainer>
          <Form.Item name="keyword" initialValue="" style={{ width: '130px' }}>
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t("조회")}
            </Button>
          </Form.Item>

          <Form.Item>
            <Button onClick={resetHandler}>{t("초기화")}</Button>
          </Form.Item>
        </Space>
      </Form>
    </Container>
  );
};

export default DesktopAssetsManagementGroupContentSearch;
