import { Button, Form, Input, Space } from 'antd';
import React, { useCallback, useState } from 'react';
import DesktopDiagnosisTechnicalProgramModal from './desktop-diagnosis-technical-program-modal';
import { IAdminMenuPermissionProps } from '@/common/type';
import styled from 'styled-components';
import { IPaginationProps } from '@/panel/Assets/Evaluation/type';
import { IContentProps } from './type';
import { useTranslation } from 'react-i18next';

interface IDesktopDiagnosisTechnicalProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pageInfo: IAdminMenuPermissionProps;
  fetch: (
    pagination: IPaginationProps,
    keyword: string,
    cb: () => void
  ) => void;
  pagination: IPaginationProps;
  setIsTableLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchKeyword: React.Dispatch<React.SetStateAction<string>>;
  setSelectsRecord: React.Dispatch<React.SetStateAction<IContentProps[]>>;
  setSelectedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>;
}

const Container = styled.div`
  position: relative;
  text-align: left;
  padding: 20px;
  margin-top: 20px;
  border: 1px solid ${props => props.theme.layout.searchBorderColor};
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 20px;
`;
const CertificationSearch = styled.div``;
const ButtonArea = styled.div``;
const ProgramBtn = styled.div`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
`;

const DesktopDiagnosisTechnical: React.FunctionComponent<
  IDesktopDiagnosisTechnicalProps
> = ({
  pageInfo,
  fetch,
  pagination,
  setIsTableLoading,
  setSearchKeyword,
  setSelectsRecord,
  setSelectedRowKeys,
}) => {
  const { t } = useTranslation();
  const [isProgramModalVisible, setIsProgramModalVisible] = useState(false);

  const onFinish = useCallback(
    result => {
      const keyword =
        typeof result.keyword !== 'undefined' ? result.keyword : '';
      setIsTableLoading(true);
      fetch({ ...pagination, current: 1 }, keyword, () => {
        window.scrollTo(0, 0);
        setSearchKeyword(keyword);
        setSelectsRecord([]);
        setSelectedRowKeys([]);
      });
    },
    [
      fetch,
      pagination,
      setIsTableLoading,
      setSearchKeyword,
      setSelectedRowKeys,
      setSelectsRecord,
    ]
  );

  return (
    <Container>
      <CertificationSearch>
        <Form name="basic" onFinish={onFinish} autoComplete="off">
          <Space>
            <Form.Item name="keyword">
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t("조회")}
              </Button>
            </Form.Item>
          </Space>
        </Form>
      </CertificationSearch>
      {pageInfo.perm_write && (
        <ProgramBtn>
          <ButtonArea>
            <Button onClick={() => setIsProgramModalVisible(true)}>
            {t("진단 프로그램")}
            </Button>
          </ButtonArea>
          <DesktopDiagnosisTechnicalProgramModal
            setIsProgramModalVisible={setIsProgramModalVisible}
            isProgramModalVisible={isProgramModalVisible}
          />
        </ProgramBtn>
      )}
    </Container>
  );
};

export default DesktopDiagnosisTechnical;
