import React, { useContext, useEffect, useMemo, useState } from 'react';

import DesktopCertificationAdminModal from './desktop-certification-admin-modal';
import DesktopCertificationRecordHeader from '../components/desktop-certification-header';
import DesktopCertificationRecordTable from '../components/desktop-certification-table';
import DesktopCertificationSearch from '../components/desktop-certification-search';
import { IAdminMenuPermissionProps } from '@/common/type';
import { ICertificationTableRecord } from '../type';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import styled from 'styled-components';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopCertificationAdminProps {
  pageInfo: IAdminMenuPermissionProps;
}

const RecordContainer = styled.div`
  box-sizing: border-box;
  border-radius: 10px;
`;

const getCertificationTrace = async ({ project_id, category_id }) => {
  const result = await api.getCertificationTrace({
    project_id,
    category_id,
  });

  return result.data;
};

const DesktopCertificationAdmin: React.FunctionComponent<
  IDesktopCertificationAdminProps
> = ({ pageInfo }) => {
  const { projectId } = useContext(ProjectContext);

  const [categoryId, SetCategoryId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectId, setSelectId] = useState(0);
  const [recordDatas, setRecordDatas] = useState<ICertificationTableRecord[]>(
    []
  );
  const [isOnLoadData, setIsOnLoadData] = useState(true);

  const recordTitle = useMemo(() => {
    return recordDatas.filter(item => item.id === selectId)[0]?.doc_name;
  }, [recordDatas, selectId]);

  useEffect(() => {
    getCertificationTrace({
      project_id: projectId,
      category_id: categoryId,
    }).then(datas => {
      setRecordDatas(datas.map(data => ({ ...data, key: data.id })));
      setIsOnLoadData(false);
    });
  }, [categoryId, projectId]);

  return (
    <>
      <DesktopCertificationSearch
        categoryId={categoryId}
        setRecordDatas={setRecordDatas}
        setCategoryId={SetCategoryId}
        type="certAdmin"
      />
      <RecordContainer>
        <DesktopCertificationRecordHeader
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          type="certAdmin"
          pageInfo={pageInfo}
        />
        <DesktopCertificationRecordTable
          setSelectId={setSelectId}
          recordDatas={recordDatas}
          setRecordDatas={setRecordDatas}
          setIsModalVisible={setIsModalVisible}
          type="certAdmin"
          isOnLoadData={isOnLoadData}
          pageInfo={pageInfo}
        />
      </RecordContainer>
      <DesktopCertificationAdminModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        selectDocId={selectId}
        setRecordDatas={setRecordDatas}
        recordTitle={recordTitle}
        pageInfo={pageInfo}
      />
    </>
  );
};

export default DesktopCertificationAdmin;
