import { IEvaluationColumsProps, IEvaluationDataProps } from './type';

import React from 'react';
import { Table } from 'antd';
import styled from 'styled-components';
import defaultInfo from '@/common/site-info';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopDangersProtectionTableProps {
  riskData: IEvaluationDataProps[];
  columns: IEvaluationColumsProps[];
  isLoading: boolean;
}

const Container = styled.div`
  clear: both;
  .ant-table-cell {
    vertical-align: top;
  }
`;

const DesktopDangersProtectionTable: React.FunctionComponent<
  IDesktopDangersProtectionTableProps
> = ({ columns, riskData, isLoading }) => {
  return (
    <Container>
      <Table
        size={defaultInfo.table_size}
        dataSource={riskData}
        columns={columns}
        pagination={false}
        loading={isLoading}
        scroll={{ x: '2300px' }}
      />
    </Container>
  );
};

export default DesktopDangersProtectionTable;
