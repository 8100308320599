import { Button, Empty, Tabs } from 'antd';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import DesktopAssetsManagementGroupContent from './desktop-assets-management-group-content';
import { IAdminMenuPermissionProps } from '@/common/type';
import { IPaneProps } from '@/panel/admin/AssetsGroup/type';
import { Link } from 'react-router-dom';
import Loading from '@/components/loading';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface IDesktopAssetsManagementGroupProps {
    pageInfo: IAdminMenuPermissionProps;
}

/*
const Title = styled.h5`
    font-size: 18px;
    color: ${props => props.theme.font.color};
    padding-bottom: 20px;
`;
*/
const ContainerEmpty = styled.div`
    padding: 100px 0;
    text-align: center;
`;

const getAssetGroups = async ({ projectId }) => {
    const result = await api.getAssetGroups({ project_id: projectId });

    return result.data;
};

const DesktopAssetsManagementGroup: React.FunctionComponent<
    IDesktopAssetsManagementGroupProps
> = ({ pageInfo }) => {
    const { t } = useTranslation();
    const { projectId } = useContext(ProjectContext);

    const [isLoading, setIsLoading] = useState(true);
    const [panes, setPanes] = useState<IPaneProps[]>([]);
    const [selectTabId, setSelectTabId] = useState(1);
    const [activeKey, setActiveKey] = useState(
        panes.length > 0 ? panes[0].key : '0'
    );
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 100,
        total: 5,
    });

    const getId = targetKey =>
        panes.filter(pane => pane.key === targetKey)[0].id;

    useEffect(() => {
        getAssetGroups({ projectId }).then(data => {
            const groupData = data.map((item, index) => {
                return {
                    ...item,
                    content: DesktopAssetsManagementGroupContent,
                    key: `${index}`,
                };
            });

            setSelectTabId(data[0]?.id);
            setPanes(groupData);
            setIsLoading(false);
        });
    }, [projectId]);

    const onChange = activeKey => {
        setActiveKey(activeKey);
        setPagination({ current: 1, pageSize: 100, total: 5 });
    };

    const tabClickHandler = key => {
        const id = getId(key);

        setSelectTabId(id);
    };

    const items = useMemo(() => {
        return panes.map(pane => ({
            label: pane.group_name,
            key: pane.key,
            children: (
                <>
                    {selectTabId === pane.id && (
                        <pane.content
                            pane={pane}
                            pageInfo={pageInfo}
                            pagination={pagination}
                            setPagination={setPagination}
                        />
                    )}
                </>
            ),
        }));
    }, [pageInfo, pagination, panes, selectTabId]);

    if (isLoading) return <Loading />;

    return panes.length > 0 ? (
        <div>
           
            <Tabs
                hideAdd
                onChange={onChange}
                activeKey={activeKey}
                type="card"
                onTabClick={tabClickHandler}
                items={items}
            />
        </div>
    ) : (
        <ContainerEmpty>
            <Empty description={t("신규 자산그룹을 추가하세요")}>
                <Link to="/Admin/AssetsGroup">
                    <Button>{t("자산그룹관리 바로가기")}</Button>
                </Link>
            </Empty>
        </ContainerEmpty>
    );
};

export default DesktopAssetsManagementGroup;
