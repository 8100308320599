import { Button, Select, Space } from 'antd';
import React, { useContext, useEffect, useState } from 'react';

import { IDiaDateProps } from './type';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopDiagnosisManagementProps {
  setCategoryId: React.Dispatch<React.SetStateAction<number | null>>;
  setDiaDateValue: React.Dispatch<React.SetStateAction<string>>;
  getCertScoreDates: any;
  getData: any;
  setDate: React.Dispatch<React.SetStateAction<IDiaDateProps[]>>;
  date: IDiaDateProps[];
}

const Container = styled.div`
  text-align: left;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid ${props => props.theme.layout.searchBorderColor};
  box-sizing: border-box;
  border-radius: 10px;
`;

const StyledSelect = styled(Select)`
  text-align: left;
`;
const Label = styled.label`
  font-size: 15px;
  margin-left: 15px;
`;

const getCertificationCategory = async ({ projectId }) => {
  const result = await api.getCertificationCategory({ project_id: projectId });

  return result.data;
};

const DesktopDiagnosisManagement: React.FunctionComponent<
  IDesktopDiagnosisManagementProps
> = ({
  setCategoryId,
  setDiaDateValue,
  getData,
  getCertScoreDates,
  setDate,
  date,
}) => {
  const { t } = useTranslation();
  const { projectId } = useContext(ProjectContext);
  const [category, setCategory] = useState([]);

  useEffect(() => {
    Promise.all([
      getCertificationCategory({ projectId }),
      getCertScoreDates({ projectId }),
    ]).then(totalData => {
      setCategory(totalData[0]);
      setDate(totalData[1]);
    });
  }, [getCertScoreDates, projectId, setDate]);

  const categoryHandleChange = value => {
    setCategoryId(value);
  };
  const dateHandleChange = value => {
    setDiaDateValue(value);
  };

  return (
    <Container>
      <Space>
        <Label>{t("통제영역")}</Label>
        <StyledSelect
          defaultValue=""
          style={{ width: 200 }}
          onChange={categoryHandleChange}
        >
          <Option value="">{t("전체")}</Option>
          {category.map(item => (
            <Option value={item.id} key={item.id}>
              {item.category_name}
            </Option>
          ))}
        </StyledSelect>
        {` `}
        <Label>{t("진단기간")}</Label>
        <StyledSelect
          defaultValue=""
          style={{ width: 130 }}
          onChange={dateHandleChange}
        >
          {date.map(item => (
            <Option value={item.value} key={item.value}>
              {t(item.text)}
            </Option>
          ))}
        </StyledSelect>
        <Button type="primary" onClick={getData}>
        {t("조회")}
        </Button>
      </Space>
    </Container>
  );
};

export default DesktopDiagnosisManagement;
