import { Button, Spin, Tooltip, UploadProps } from 'antd';
import { DownloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
// import { IFieldProps, IGroupProps } from '@/panel/admin/AssetsGroup/type';
import React, { useCallback, useContext, useState } from 'react';
import { ProjectContext } from '@/common/project-provider';
import { UploadOutlined } from '@ant-design/icons';
import api from '@/api/api';
import styled from 'styled-components';
import Dragger from 'antd/lib/upload/Dragger';
import Modal from '@/components/modal';
import { useTranslation } from 'react-i18next';

interface IDesktopAdminAssetsGroupContentCategoryEexcelProps {
  isUploadModalVisible: boolean;
  setIsUploadModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  id: number | null;
}

const StyledUploadArea = styled.div`
  padding-bottom: 20px;
  .ant-upload-list {
    display: none;
  }
`;

const CloseBtnArea = styled.div`
  text-align: right;
`;

const Title = styled.h4`
  font-size: 18px;
  margin: 0 0 10px 0;
`;
const Caution = styled.p`
  color: #983333;
  margin-top: -10px;
  margin-bottom: 15px;
`;
const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #dedede;
  margin: 30px 0;
`;

const setAssetGroupFromSampleDownload = async ({ projectId, id }) => {
  const result = await api.setAssetGroupFromSampleDownload({
    project_id: projectId,
    id,
  });

  return result;
};

const setAssetGroupCellExcelUpload = async ({ projectId, id, formData }) => {
  const result = await api.setAssetGroupCellExcelUpload({
    project_id: projectId,
    id,
    formData,
  });

  return result;
};

const DesktopAdminAssetsGroupContentCategoryEexcel: React.FunctionComponent<
  IDesktopAdminAssetsGroupContentCategoryEexcelProps
> = ({ isUploadModalVisible, setIsUploadModalVisible, id }) => {
  const { t } = useTranslation();
  const { projectId } = useContext(ProjectContext);

  const [loading, setLoading] = useState(false);

  const sampleDownload = () => {
    setAssetGroupFromSampleDownload({ projectId, id }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `asset_group_template.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
  };

  const handleChange = useCallback(
    info => {
      if (info.file.status === 'uploading') {
        return;
      }
      setLoading(true);
      const formData = new FormData();

      formData.append('file', info.file.originFileObj);

      setAssetGroupCellExcelUpload({
        projectId,
        id,
        formData,
      }).then(() => {
        setLoading(false);
        setIsUploadModalVisible(false);
        // message.info('셀별로 key 값을 설정해 주세요	');
        // setFieldsData(data);
      });
    },
    [id, projectId, setIsUploadModalVisible]
  );

  const handleCancel = () => {
    setIsUploadModalVisible(false);
  };

  const props: UploadProps = {
    name: 'file',
    multiple: true,
    onChange(info) {
      // const { status } = info.file;
      handleChange(info);
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  return (
    <>
      <Tooltip title={<span>{t("엑셀을 이용하여 데이터 일괄 추가")}</span>}>
        <Button onClick={() => setIsUploadModalVisible(true)}>
          {t("자산필드 일괄등록")}
        </Button>
      </Tooltip>

      <Modal
        title={t("자산필드 일괄등록")}
        open={isUploadModalVisible}
        maskClosable={false}
        keyboard={false}
        footer={false}
        onCancel={handleCancel}
      >
        <Spin spinning={loading} delay={500}>
          <Title>
            <ExclamationCircleOutlined /> {t("엑셀 업로드 양식 다운로드")}
          </Title>
          <Button onClick={sampleDownload}>
            <DownloadOutlined /> {t("업로드 양식 다운로드")}
          </Button>
          <Line />
          <Title>
            <ExclamationCircleOutlined /> {t("기존 데이터 하단에 일괄 추가")}
          </Title>
          <Caution>({t("데이터 양에 따라 많은 시간이 소요될 수 있습니다.")})</Caution>
          <StyledUploadArea>
            {/* <Button icon={<UploadOutlined />}>엑셀 업로드</Button> */}
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <UploadOutlined />
              </p>
              <p className="ant-upload-text">
                {t("클릭 또는 마우스로 파일을 끌어오세요.")}
              </p>
            </Dragger>
          </StyledUploadArea>
          <CloseBtnArea>
            <Button onClick={handleCancel}>{t("닫기")}</Button>
          </CloseBtnArea>
        </Spin>
      </Modal>
    </>
  );
};

export default DesktopAdminAssetsGroupContentCategoryEexcel;
