import { Button, Empty, Input, Space, Tree } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import DesktopAdminCodeInput from './components/desktop-admin-code-input';
import DesktopAdminCodeTitle from './components/desktop-admin-code-title';
import { DownOutlined } from '@ant-design/icons';
import { IAdminMenuPermissionProps } from '@/common/type';
import { ICodeData } from './type';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import { openNotification } from '@/common/util';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface IDesktopAdminCodeList {
  datas: ICodeData[];
  cd: string;
  pageInfo: IAdminMenuPermissionProps;
}

const Container = styled.div``;

const StyledTree = styled(Tree)`
  background: transparent;
  .ant-tree-title > div {
    padding: 3px 3px 3px 0;
  }
  .ant-tree-draggable-icon {
    line-height: 29px;
  }
  .ant-tree-switcher {
    background: transparent;
    line-height: 29px;
  }
  .ant-tree-node-content-wrapper:hover {
    .dark & {
      background-color: #434343;
    }
  }
  .ant-tree-node-content-wrapper.ant-tree-node-selected {
    .dark & {
      background-color: #434343;
    }
  }
  .ant-tree-switcher {
    .dark & {
      background: transparent;
    }
  }
`;
const EmptyContainer = styled.div`
  padding: 130px 0;
`;

// 초기데이터
let item: ICodeData = {
  id: '',
  item_cd: '',
  key: '',
  item_name: '',
  item_level: '',
  item_sort: '',
  parent_id: '',
  children: [],
};

const setAdminCode = async ({ projectId, adminCodeData, cd }) => {
  const result = await api.setAdminCode({
    projectId,
    data: adminCodeData,
    cd: cd,
  });

  return result.data;
};

const deleteAdminCode = async ({ projectId, id }) => {
  const result = await api.deleteAdminCode({
    projectId,
    id,
  });

  return result.data;
};

const DesktopAdminCodeList: React.FunctionComponent<IDesktopAdminCodeList> = ({
  datas,
  cd,
  pageInfo,
}) => {
  const { t } = useTranslation();
  const [adminCodeData, setAdminCodeData] = useState<ICodeData[]>([]);
  const [isAddMode, setIsAddMode] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [useDraggable, setUseDraggable] = useState(true);
  const { projectId } = useContext(ProjectContext);

  let compareKey = '';

  useEffect(() => {
    setAdminCodeData([...datas]);
    // for (let x in adminCodeData) {
    //   adminCodeData[x].item_name = adminCodeData[x].item_name;
    // }
    // ,adminCodeData
  }, [datas]);

  // 드래그 로직
  const onDrop = info => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    const data = [...adminCodeData];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        item.children = item.children || [];
        item.children.unshift(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 && 
      info.node.props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        item.children = item.children || [];
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar;
      let i;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }

    setAdminCodeData(data);
  };

  // 입력 취소
  const removeNodeHandler = () => {
    // targetItem.children.slice(-1);
    const data: ICodeData[] = updateTreeData({
      list: adminCodeData,
      compareKey: '',
      newData: [],
    });

    setIsAddMode(false);
    setIsEditMode(false);
    setAdminCodeData(data);
  };

  // 추가
  const insertNodeHandler = (targetItem, flag) => {
    if (targetItem.item_name === '') {
      alert(t('코드를 입력해주세요.'));
      return;
    }

    const insertData: ICodeData[] = updateTreeData({
      list: adminCodeData,
      compareKey,
      newData: { ...targetItem, id: '', children: [] },
    });

    const editData: ICodeData[] = editTreeData({
      list: adminCodeData,
      compareKey,
      newData: { ...targetItem, children: [...targetItem.children] },
    });

    const datas = flag ? editData : insertData;

    setAdminCode({ projectId, adminCodeData: datas, cd }).then(data => {
      const notiText = {
        description: t("완료"),
      };

      openNotification(notiText);
      setIsAddMode(false);
      setIsEditMode(false);
      setAdminCodeData(data);
    });
  };

  // 입력폼 추가
  const addInputFormHandler = (targetItem: ICodeData) => {
    compareKey = targetItem.key;

    const data: ICodeData[] = updateTreeData({
      list: adminCodeData,
      compareKey,
      newData: {
        key: createNodeKey(targetItem),
        id: '',
        item_name: DesktopAdminCodeInput({
          targetItem,
          isEditMode: false,
          item,
          createNodeKey,
          insertNodeHandler,
          removeNodeHandler,
        }),
        parent_id: targetItem.item_cd,
        item_level: '',
        item_desc: '',
        item_sort: '',
        children: [],
      },
    });

    setIsAddMode(true);
    setAdminCodeData(data);
  };

  // 삭제
  const removeNodeItemHandler = (targetItem: ICodeData) => {
    if (window.confirm(`${targetItem.item_name} ${t("삭제 하시겠습니까?")}`)) {
      const list = deleteAdminCode({ id: targetItem.id, projectId: projectId });

      list.then(() => {
        const notiText = {
          description: t("완료"),
        };

        openNotification(notiText);
        setAdminCodeData(
          removeTreeData({ list: adminCodeData, compareKey: targetItem.key })
        );
      });
    }
  };

  // 수정
  const editNodeItemHandler = (targetItem: ICodeData) => {
    compareKey = targetItem.key;

    console.log(compareKey);
    
    const data: ICodeData[] = editTreeData({
      list: adminCodeData,
      compareKey,
      newData: {
        item_name: DesktopAdminCodeInput({
          targetItem,
          isEditMode: true,
          item,
          createNodeKey,
          insertNodeHandler,
          removeNodeHandler,
        }),
      },
    });

    setIsEditMode(true);
    setAdminCodeData(data);
  };

  // 키 생성
  const createNodeKey = (targetItem: ICodeData) => {
    if (targetItem.children.length === 0) {
      return `${targetItem.key}-0`;
    }

    const lastSliceKey = targetItem.children[
      targetItem.children.length - 1
    ].key.slice(0, -1);

    return `${lastSliceKey}${targetItem.children.length}`;
  };

  // 추가 로직
  const updateTreeData = ({ list, compareKey, newData }) => {
    return list.map(node => {
      if (node.key === compareKey) {
        return {
          ...node,
          children: [...node.children, newData],
        };
      }

      if (node.children) {
        return {
          ...node,
          children: updateTreeData({
            list: node.children,
            compareKey,
            newData,
          }),
        };
      }

      return node;
    });
  };

  // 삭제 로직
  const removeTreeData = ({ list, compareKey }) => {
    return list
      .filter(node => node.key !== compareKey)
      .map(node => {
        if (node.children) {
          return {
            ...node,
            children: removeTreeData({ list: node.children, compareKey }),
          };
        }

        return node;
      });
  };

  // 수정 로직
  const editTreeData = ({ list, compareKey, newData }) => {
    return list.map(node => {
      if (node.key === compareKey) {
        return {
          ...node,
          ...newData,
        };
      }

      if (node.children) {
        return {
          ...node,
          children: editTreeData({ list: node.children, compareKey, newData }),
        };
      }

      return node;
    });
  };

  // 부서 + 사원 데이터로 변경
  const dataReFactoring = useCallback(
    (data: ICodeData[]) => {
      return data.map(item => {
        const title = (
          <DesktopAdminCodeTitle
            item={item}
            isAddMode={isAddMode}
            isEditMode={isEditMode}
            addInputFormHandler={addInputFormHandler}
            removeNodeItemHandler={removeNodeItemHandler}
            editNodeItemHandler={editNodeItemHandler}
            pageInfo={pageInfo}
          />
        );

        if (item.children) {
          return {
            ...item,
            title,
            children: dataReFactoring(item.children),
          };
        }

        return item;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [adminCodeData]
  );

  const onSelectHandler = (_, event) => {
    const {
      node: { id },
    } = event;

    console.log(id);
  };

  const onDragEnd = () => {
    const list = setAdminCode({ projectId, adminCodeData, cd });

    list.then(data => {
      setAdminCodeData(data);
    });
  };

  // 데이터 변화시 초기화
  useEffect(() => {
    item = {
      id: '',
      item_cd: '',
      key: '',
      item_name: '',
      item_level: '',
      item_sort: '',
      parent_id: '',
      children: [],
    };
    setUseDraggable(!isAddMode && !isEditMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminCodeData]);

  const [createGroupName, setCreateGroupName] = useState('');

  const createGroupNameOnChange = event => {
    const { value } = event.target;

    setCreateGroupName(value);
  };

  const createGroupNameOnClick = useCallback(() => {
    const datas = [
      {
        id: '',
        key: '0-0',
        item_cd: '',
        item_level: 0,
        item_name: createGroupName,
        item_sort: 0,
        children: [],
      },
    ];

    setAdminCode({ projectId, adminCodeData: datas, cd: cd }).then(data => {
      setAdminCodeData(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createGroupName]);

  return adminCodeData.length > 0 ? (
    <StyledTree
      treeData={dataReFactoring(adminCodeData)}
      showLine
      switcherIcon={<DownOutlined />}
      defaultExpandAll={true}
      draggable={useDraggable}
      onDragEnd={onDragEnd}
      onDrop={onDrop}
      blockNode
      autoExpandParent={true}
      onSelect={(_, e) => onSelectHandler(_, e)}
    />
  ) : (
    <Container>
      <EmptyContainer>
        <Empty description="생성된 코드가 없습니다.">
          <Space style={{ marginTop: '20px' }}>
            <Input
              placeholder={t("코드명")}
              value={createGroupName}
              onChange={createGroupNameOnChange}
            />
            <Button type="primary" onClick={createGroupNameOnClick}>
              {t("추가")}
            </Button>
          </Space>
        </Empty>
      </EmptyContainer>
    </Container>
  );
};

export default DesktopAdminCodeList;
