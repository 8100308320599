import { Button, Form, Input, Space } from 'antd';

import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopAdminCategoryHeaderProps {}

const Container = styled.div`
  text-align: left;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid ${props => props.theme.layout.searchBorderColor};
  box-sizing: border-box;
  border-radius: 10px;
  .ant-form-item {
    margin: 0;
  }
  .ant-picker {
    background: #f0f2f5;
    border: 1px solid transparent;
  }
`;
const Label = styled.label`
  font-size: 15px;
  margin-left: 15px;
`;

const DesktopAdminCategoryHeader: React.FunctionComponent<
  IDesktopAdminCategoryHeaderProps
> = () => {
  const { t } = useTranslation();
  const onFinish = e => {
    console.log(e);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const categorySearchHandler = value => {
    console.log(value);
  };

  return (
    <Container>
      <Form
        name="basic"
        initialValues={{ remember: false }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Space>
          <Label>{t("항목명")}</Label>
          <Form.Item name="category_name">
            <Input onClick={categorySearchHandler} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t("조회")}
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </Container>
  );
};

export default DesktopAdminCategoryHeader;
