import { Button, Layout, Popconfirm, Table } from 'antd';
import { IColumns, ICompanyList, ILicenseProps, IVersion } from './type';
import React, { useEffect, useState } from 'react';

import DesktopLicenseModal from './desktop-license-modal';
import DesktopVersionModal from './desktop-version-modal';
import Floating from '@/components/floating';
import Footer from '@/components/footer';
import Header from '@/components/header';
import api from '@/api/api';
import moment from 'moment';
import styled from 'styled-components';
import defaultInfo from '@/common/site-info';
import { defaultColor } from '@/styles/color';

const { Content } = Layout;

interface IStyledContentProps {
  'data-is-ismain': boolean;
}

const StyledContent = styled(Content)<IStyledContentProps>`
  padding: ${props => (props['data-is-ismain'] ? '24px' : '24px')};
  /* min-height: calc(100vh - 173px); */
  background: ${defaultColor.gray};
`;

const StyledLayout = styled(Layout)`
  width: 100% !important;
  padding-top: 48px;
  transition: 0.3s ease;
  background: transparent;
`;

const Title = styled.h1`
  font-size: 25px;
  padding-bottom: 10px;
`;

const TableContainer = styled.div`
  padding: 20px;
  background: #ffffff;
  margin-bottom: 50px;
  border: 1px solid #373737;
`;

const NoSerial = styled.p`
  color: red;
  padding: 0;
  margin: 0;
`;

const Serial = styled.p`
  padding: 0;
  margin: 0;
`;
const AddArea = styled.div`
  text-align: right;
  padding-bottom: 20px;
`;

const getAdminLicense = async ({ project_name, license_key }) => {
  const result = await api.getAdminLicense({ project_name, license_key });

  return result.data;
};

const getCompanyList = async () => {
  const result = await api.getCompanyList();

  return result.data;
};

const getAdminVersion = async () => {
  const result = await api.getAdminVersion();

  return result.data;
};

const deleteAdminVersion = async id => {
  const result = await api.deleteAdminVersion(id);

  return result.data;
};

const Index: React.FunctionComponent = () => {
  const [dataSource, setDataSource] = useState<ILicenseProps[]>([]);
  const [dataSource2, setDataSource2] = useState<IVersion[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectData, setSelectData] = useState<ILicenseProps | null>(null);
  const [companyList, setCompanyList] = useState<ICompanyList[]>([]);
  const [isVersionModalVisible, setIsVersionModalVisible] = useState(false);

  useEffect(() => {
    getAdminLicense({ project_name: '', license_key: '' }).then(datas => {
      setDataSource(datas.map(data => ({ ...data, key: data.id })));
    });
  }, [isModalVisible]);

  useEffect(() => {
    getCompanyList().then(datas => {
      setCompanyList(datas.map(data => ({ ...data, key: data.id })));
    });
  }, []);

  useEffect(() => {
    getAdminVersionList();
  }, [isVersionModalVisible]);

  const getAdminVersionList = () => {
    getAdminVersion().then(datas => {
      setDataSource2(datas.map(data => ({ ...data, key: data.id })));
    });
  };

  const removeHandler = id => {
    deleteAdminVersion(id).then(() => {
      getAdminVersionList();
    });
  };

  const columns: IColumns[] = [
    {
      title: 'No',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    },
    {
      title: '프로젝트명',
      dataIndex: 'project_name',
      key: 'project_name',
      align: 'center',
    },
    {
      title: '라이센스 키',
      dataIndex: 'license_key',
      key: 'license_key',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: value => (
        <>
          {value === '' ? (
            <NoSerial>미생성</NoSerial>
          ) : (
            <Serial>{value}</Serial>
          )}
        </>
      ),
    },
    {
      title: '서비스 시작일',
      dataIndex: 'service_start',
      key: 'service_start',
      align: 'center',
    },
    {
      title: '서비스 종료일',
      dataIndex: 'service_end',
      key: 'service_end',
      align: 'center',
    },
    {
      title: '회사명',
      dataIndex: 'company_name',
      key: 'company_name',
      align: 'center',
    },
    {
      title: '수정',
      dataIndex: 'create_key',
      key: 'create_key',
      width: '150px',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: (_, record) => (
        <>
          <Button
            type="primary"
            onClick={() => {
              setSelectData(record);
              setIsModalVisible(true);
            }}
          >
            수정
          </Button>
        </>
      ),
    },
  ];

  const columns2: any = [
    {
      title: 'No',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    },
    {
      title: 'UI',
      dataIndex: 'ui_version',
      key: 'ui_version',
      align: 'center',
    },
    {
      title: 'API',
      dataIndex: 'api_version',
      key: 'api_version',
      align: 'center',
    },
    {
      title: 'DB',
      dataIndex: 'db_version',
      key: 'db_version',
      align: 'center',
    },
    {
      title: '업데이트 날짜',
      dataIndex: 'created_date',
      key: 'created_date',
      width: '300px',
      align: 'center',
      render: value => {
        return moment(value).format('llll');
      },
    },
    {
      title: '삭제',
      dataIndex: 'create_key',
      key: 'create_key',
      width: '150px',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: (_, record) => (
        <Popconfirm
          title="버전을 삭제 하시겠습니까?"
          onConfirm={() => removeHandler(record.id)}
          // onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <Button type="primary">삭제</Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <StyledLayout data-is-folding={false}>
        <Header isMain={false} isManager={true} />

        <StyledContent data-is-ismain={false}>
          <Title>라이센스 관리자</Title>
          <TableContainer>
            <AddArea>
              <Button
                onClick={() => {
                  setSelectData({
                    id: null,
                    project_name: '',
                    license_key: '',
                    service_type: '',
                    service_level: '',
                    service_start: moment().format('YYYY-MM-DD'),
                    service_end: moment().add(1, 'days').format('YYYY-MM-DD'),
                    company_id: 0,
                  });
                  setIsModalVisible(true);
                }}
              >
                프로젝트 추가
              </Button>
            </AddArea>
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={false}
              size={defaultInfo.table_size}
            />
            <DesktopLicenseModal
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              selectData={selectData}
              companyList={companyList}
            />
          </TableContainer>
          <Title>버전 관리자</Title>
          <TableContainer>
            <AddArea>
              <Button
                onClick={() => {
                  setIsVersionModalVisible(true);
                }}
              >
                버전 추가
              </Button>
            </AddArea>
            <Table
              dataSource={dataSource2}
              columns={columns2}
              pagination={false}
              size={defaultInfo.table_size}
            />
            <DesktopVersionModal
              dataSource={dataSource2}
              isVersionModalVisible={isVersionModalVisible}
              setIsVersionModalVisible={setIsVersionModalVisible}
            />
          </TableContainer>
        </StyledContent>
        <Footer />
      </StyledLayout>
      <Floating />
    </>
  );
};

export default Index;
