import { Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { arrayMoveImmutable } from 'array-move';
import React, { useContext, useEffect, useState } from 'react';
import type { SortableContainerProps, SortEnd } from 'react-sortable-hoc';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { IPolicyGroupProps } from '../type';
import styled from 'styled-components';
import api from '@/api/api';
import { ProjectContext } from '@/common/project-provider';
import { openNotification } from '@/common/util';
import defaultInfo from '@/common/site-info';
import Modal from '@/components/modal';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopPolicyProtectionCardSortModalprops {
  isSoltModalVisible: boolean;
  setIsSoltModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  group: IPolicyGroupProps[];
}

interface DataType {
  key?: string;
  group_name: string;
  id: number;
}

const Text = styled.div`
  text-align: left;
  padding-left: 20px;
`;

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
));

const columns: ColumnsType<DataType> = [
  {
    title: '',
    dataIndex: 'sort',
    width: 30,
    className: 'drag-visible',
    render: () => <DragHandle />,
  },
  {
    title: t('정책명'),
    dataIndex: 'group_name',
    className: 'drag-visible',
    align: 'center',
    render: text => <Text>{text}</Text>,
  },
];

const SortableItem = SortableElement(
  (props: React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />
);
const SortableBody = SortableContainer(
  (props: React.HTMLAttributes<HTMLTableSectionElement>) => <tbody {...props} />
);

const setPolicyGroupSort = async ({ projectId, data }) => {
  const result = await api.setPolicyGroupSort({
    project_id: projectId,
    data,
  });

  return result.data;
};

const DesktopPolicyProtectionCardSortModal: React.FunctionComponent<
  IDesktopPolicyProtectionCardSortModalprops
> = ({ isSoltModalVisible, setIsSoltModalVisible, group }) => {
  const { t } = useTranslation();
  const { projectId } = useContext(ProjectContext);

  const [dataSource, setDataSource] = useState(
    group.map((item, index) => ({ ...item, index }))
  );

  useEffect(() => {
    setDataSource(group.map((item, index) => ({ ...item, index })));
  }, [group]);

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(dataSource.slice(), oldIndex, newIndex)
        .filter((el: IPolicyGroupProps) => !!el)
        .map((item, index) => ({ ...item, group_sort: index + 1 }));
      console.log('Sorted items: ', newData);

      setPolicyGroupSort({ projectId, data: newData }).then(() => {
        const notiText = {
          description: t("완료"),
        };

        openNotification(notiText);
      });

      setDataSource(newData);
    }
  };

  const DraggableContainer = (props: SortableContainerProps) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow: React.FC<any> = ({
    className,
    style,
    ...restProps
  }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(
      x => x.index === restProps['data-row-key']
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const handleOk = () => {
    setIsSoltModalVisible(false);
  };

  const handleCancel = () => {
    setIsSoltModalVisible(false);
  };

  return (
    <Modal
      title={t("정책 그룹 순서 변경")}
      open={isSoltModalVisible}
      footer={<Button onClick={handleOk}>{t("닫기")}</Button>}
      onCancel={handleCancel}
    >
      <Table
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        rowKey="index"
        size={defaultInfo.table_size}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
    </Modal>
  );
};

export default DesktopPolicyProtectionCardSortModal;
