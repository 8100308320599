import { Col, Row } from 'antd';
import {
  IAssetsDiagnosisData,
  IAssetsEvaluationData,
  IAssetsType,
  IDiagnosticDataType,
  IEvidenceDataType,
} from './type';
import React, { useContext, useEffect, useState } from 'react';

import DesktopDashboardAssets from './desktop-dashboard-assets';
import DesktopDashboardAssetsDiagnosis from './desktop-dashboard-assets-diagnosis';
import DesktopDashboardAssetsEvaluation from './desktop-dashboard-assets-evaluation';
import DesktopDashboardDiagnosticCare from './desktop-dashboard-diagnostic-care';
import DesktopDashboardEvidence from './desktop-dashboard-evidence';
import DesktopDashboardVulnerability from './desktop-dashboard-vulnerability';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import styled from 'styled-components';

const Container = styled.div`
  margin: 0 -10px;
`;

const StyledCol = styled(Col)`
  padding: 0 10px 20px 10px;
`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopDashboardProps {}

const getDashboardDiagnostic = async ({ projectId }) => {
  const result = await api.getDashboardDiagnostic({ project_id: projectId });

  return result.data;
};

const getDashboarDevidence = async ({ projectId }) => {
  const result = await api.getDashboarDevidence({ project_id: projectId });

  return result.data;
};

const getDashboarAsset = async ({ projectId }) => {
  const result = await api.getDashboarAsset({ project_id: projectId });

  return result.data;
};

const getDashboarAssetDiagnosis = async ({ projectId }) => {
  const result = await api.getDashboarAssetDiagnosis({ project_id: projectId });

  return result.data;
};

const getDashboarAssetEvaluation = async ({ projectId }) => {
  const result = await api.getDashboarAssetEvaluation({
    project_id: projectId,
  });

  return result.data;
};

const DesktopDashboard: React.FunctionComponent<
  IDesktopDashboardProps
> = () => {
  const { projectId } = useContext(ProjectContext);
  const [assetsDataSource, setassetsDataSource] = useState<IAssetsType[]>([
    {
      id: 0,
      type: '',
      value: 0,
      rate: 0,
    },
  ]);
  const [diagnosticData, setDiagnosticData] = useState<IDiagnosticDataType[]>(
    []
  );
  const [evidenceData, setEvidenceData] = useState<IEvidenceDataType[]>([]);
  const [assetsDiagnosisData, setAssetsDiagnosisData] = useState<
    IAssetsDiagnosisData[]
  >([]);
  const [assetsEvaluationData, setAssetsEvaluationData] = useState<
    IAssetsEvaluationData[]
  >([]);
  const [achievement, setAchievement] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    Promise.all([
      getDashboardDiagnostic({ projectId }),
      getDashboarDevidence({ projectId }),
      getDashboarAsset({ projectId }),
      getDashboarAssetDiagnosis({ projectId }),
      getDashboarAssetEvaluation({ projectId }),
    ]).then(totalDatas => {
      // 진단관리
      setDiagnosticData(
        totalDatas[0].map(item => {
          return {
            ...item,
            ranges: [item.ranges],
            measures: [item.measures],
            target: item.target,
          };
        })
      );
      let nums = 0;

      totalDatas[0].forEach(item => {
        nums = nums + item.measures;
      });

      setAchievement((nums / (totalDatas[0].length * 100)) * 100);

      // 증적관리
      setEvidenceData(totalDatas[1]);
      //자산현황
      setassetsDataSource(totalDatas[2]);
      // 자산진단
      setAssetsDiagnosisData(totalDatas[3]);
      // 자산평가
      setAssetsEvaluationData(
        totalDatas[4].map(item => {
          return {
            type: item.type,
            value: item.value,
          };
        })
      );
      setIsLoading(false);
    });
  }, [projectId]);

  return (
    <Container>
      <Row>
        <StyledCol span={12}>
          <DesktopDashboardDiagnosticCare
            dataSource={diagnosticData}
            achievement={achievement}
            isLoading={isLoading}
          />
        </StyledCol>
        <StyledCol span={12}>
          <DesktopDashboardEvidence
            dataSource={evidenceData}
            isLoading={isLoading}
          />
        </StyledCol>
      </Row>

      <Row>
        <StyledCol span={8}>
          <DesktopDashboardAssets
            assetsData={assetsDataSource}
            isLoading={isLoading}
          />
        </StyledCol>
        <StyledCol span={8}>
          <DesktopDashboardAssetsDiagnosis
            assetsData={assetsDiagnosisData}
            isLoading={isLoading}
          />
        </StyledCol>
        <StyledCol span={8}>
          <DesktopDashboardAssetsEvaluation
            dataSource={assetsEvaluationData}
            isLoading={isLoading}
          />
        </StyledCol>
      </Row>
      <Row>
        <StyledCol span={24}>
          <DesktopDashboardVulnerability
            assetsData={assetsDataSource}
            isLoading={isLoading}
          />
        </StyledCol>
      </Row>
    </Container>
  );
};

export default DesktopDashboard;
