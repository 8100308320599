import React, { useContext, useEffect, useState } from 'react';

import Loading from '@/components/loading';
import { ProjectContext } from '@/common/project-provider';
import { Select } from 'antd';
import api from '@/api/api';
import styled from 'styled-components';
import { useAuth } from '@/common/auth-provider';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;

const Container = styled.div`
  width: 190px;
`;
const StyledSelect = styled(Select)`
  width: 100%;
`;

const getProjectList = async () => {
  const result = await api.getProjectList();

  return result.data;
};

const getLicenseCheck = async ({ project_id }) => {
  const result = await api.getLicenseCheck({ project_id });

  return result.data;
};

const Index: React.FunctionComponent = () => {
  const { projectId, setProjectId, setUserInfo } = useContext(ProjectContext);
  const [projectList, setProjectList] = useState([]);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleChange = value => {
    setProjectId(value);
    navigate('/');
    sessionStorage.removeItem('history');
  };

  useEffect(() => {
    getProjectList().then(ListData => {
      setProjectList(ListData);
    });
    getLicenseCheck({
      project_id: projectId,
    }).then(data => {
      login(data.user_level);
      setUserInfo(prev => {
        return {
          ...prev,
          userLevel: data.user_level,
        };
      });
    });
  }, [login, projectId, setUserInfo]);

  return (
    <Container>
      {projectList.length > 0 ? (
        <StyledSelect
          defaultValue={projectId === 0 ? projectList[0].id : projectId}
          onChange={handleChange}
          size="middle"
        >
          {projectList.map(item => (
            <Option key={item.id} value={item.id}>
              {item.project_name}
            </Option>
          ))}
        </StyledSelect>
      ) : (
        <Loading />
      )}
    </Container>
  );
};

export default Index;
