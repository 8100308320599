import { defaultColor } from './color';

interface ITheme {
    font: {
        color: string;
    };
    layout: {
        bodyBg: string;
        sideBg: string;
        contentBg: string;
        pageHeaderBg: string;
        pageHeaderBorderColor: string;
        tabBg: string;
        tabBorderColor: string;
        tabActiveBg: string;
        innerContentBg: string;
        innerContentBorderColor: string;
        searchBorderColor: string;
        dashboardBorderColor: string;
        dashboardBg: string;
        chartBg: string;
        logoBg: string;
        modalBg: string;
        modalHoverBg: string;
    };
}

export const light: ITheme = {
    font: {
        color: '#292a2d',
    },
    layout: {
        bodyBg: '#f0f2f5',
        sideBg: '#ffffff',
        contentBg: defaultColor.lightGray,
        pageHeaderBg: '#ffffff',
        pageHeaderBorderColor: '#E0E0E0',
        tabBg: '#dddddd',
        tabActiveBg: '#ffffff',
        tabBorderColor: '#cccccc',
        innerContentBg: '#ffffff',
        innerContentBorderColor: '#E0E0E0',
        searchBorderColor: '#E0E0E0',
        dashboardBorderColor: '#E0E0E0',
        dashboardBg: '#ffffff',
        chartBg: '#ffffff',
        logoBg: '#E0E0E0',
        modalBg: '#ffffff',
        modalHoverBg: '#ededed'
    },
};

export const dark: ITheme = {
    font: {
        color: '#CCCCCC',
    },
    layout: {
        bodyBg: '#1F1F20',
        sideBg: '#141414',
        contentBg: defaultColor.veryDarkGray,
        pageHeaderBg: '#141414',
        pageHeaderBorderColor: '#2B2B2B',
        tabBg: '#292929',
        tabActiveBg: '#141414',
        tabBorderColor: '#424242',
        innerContentBg: '#141414',
        innerContentBorderColor: '#2B2B2B',
        searchBorderColor: '#2B2B2B',
        dashboardBorderColor: '#424242',
        dashboardBg: '#141414',
        chartBg: '#292929',
        logoBg: '#424242',
        modalBg: '#1F1F20',
        modalHoverBg: '#424242',
    },
};
