import React, { useContext } from 'react';

import { Bullet } from '@ant-design/plots';
import DesktopDashboardCard from './components/desktop-dashboard-card';
import { Empty } from 'antd';
import { IDiagnosticDataType } from './type';
import styled from 'styled-components';
import { ThemeContext } from '@/common/theme-provider';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopDashboardDiagnosticCareProps {
  achievement: number;
  dataSource: IDiagnosticDataType[];
  isLoading: boolean;
}
interface IInnerProps {
  isData: boolean;
}
const Text = styled.span``;
const Inner = styled.div<IInnerProps>`
  height: 285px;
  padding: 30px 30px 20px 30px;
  box-sizing: border-box;
  /* height: ${props => (props.isData ? 214 : 262)}px; */
  overflow: hidden;
`;

const DesktopDashboardDiagnosticCare: React.FunctionComponent<
  IDesktopDashboardDiagnosticCareProps
> = ({ dataSource, achievement, isLoading }) => {
  const { t } = useTranslation();

  const { isDarkMode } = useContext(ThemeContext);

  for (let x in dataSource) {
    dataSource[x].title = t(dataSource[x].title);
  }
  
  const config: any = {
    height: 194,
    data: dataSource,
    measureField: 'measures',
    rangeField: 'ranges',
    targetField: 'target',
    xField: 'title',
    theme: isDarkMode ? 'dark' : 'default',
    color: {
      range: '#f0efff',
      measure: '#476CA0',
      target: '#BD0F0F',
    },
    xAxis: { line: null },
    yAxis: {
      tickMethod: function tickMethod(_ref) {
        const max = _ref.max;
        const interval = Math.ceil(max / 5);
        return [0, interval, interval * 2, interval * 3, interval * 4, max];
      },
    },
    legend: {
      custom: true,
      position: 'bottom',
      items: [
        {
          value: t('달성율'),
          name: t('달성율'),
          marker: {
            symbol: 'square',
            style: {
              fill: '#476CA0',
              r: 5,
            },
          },
        },
        {
          value: t('목표'),
          name: t('목표'),
          marker: {
            symbol: 'line',
            style: {
              stroke: '#BD0F0F',
              r: 5,
            },
          },
        },
      ],
    },
  };

  return (
    <DesktopDashboardCard
      cardTitle={t("진단관리")}
      moreLink="/Diagnosis/Status"
      actions={
        dataSource.length > 0
          ? [<Text key="average">{t("평균")} {t("달성율")}: {achievement.toFixed(0)}%</Text>]
          : []
      }
      isLoading={isLoading}
    >
      <Inner isData={dataSource.length > 0}>
        {dataSource.length > 0 ? <Bullet {...config} /> : <Empty />}
      </Inner>
    </DesktopDashboardCard>
  );
};

export default DesktopDashboardDiagnosticCare;
