import { Table } from 'antd';
import React, { useCallback, useContext, useEffect } from 'react';

import styled from 'styled-components';
import {
  IPaginationProps,
  ITechEvaluationDataSource,
  ITechPageParamsProps,
} from './type';
import Loading from '@/components/loading';
import { ProjectContext } from '@/common/project-provider';
import defaultInfo from '@/common/site-info';
import { useTranslation } from 'react-i18next';

interface IDesktopDangersTechEvaluationContentTableProps {
  dataSource: ITechEvaluationDataSource | null;
  setDataSource: React.Dispatch<
    React.SetStateAction<ITechEvaluationDataSource | null>
  >;
  groupId: number;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  pagination: IPaginationProps;
  setPagination: React.Dispatch<React.SetStateAction<IPaginationProps>>;
  riskTechAPI: any;
  searchParam: ITechPageParamsProps;
}

const Container = styled.div``;

const Text = styled.div`
  text-align: left;
`;

const DesktopDangersTechEvaluationContentTable: React.FunctionComponent<
  IDesktopDangersTechEvaluationContentTableProps
> = ({
  dataSource,
  setDataSource,
  groupId,
  isLoading,
  setIsLoading,
  pagination,
  setPagination,
  riskTechAPI,
  searchParam,
}) => {
  const { t } = useTranslation();
  const { projectId } = useContext(ProjectContext);

  const columns: any = [
    {
      title: 'No',
      label: 'No',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: '6%',
      render: (_, __, index) => {
        const currentPage = (pagination.current - 1) * pagination.pageSize;

        return <>{currentPage + index + 1}</>;
      },
    },
    {
      title: t('자산코드'),
      label: t('자산코드'),
      dataIndex: 'asset_code',
      key: 'asset_code',
      align: 'center',
      width: '10%',
    },
    {
      title: t('자산명'),
      label: t('자산명'),
      dataIndex: 'asset_name',
      key: 'asset_name',
      align: 'center',
      width: '10%',
    },
    {
      title: t('진단영역'),
      label: t('진단영역'),
      dataIndex: 'cate_name',
      key: 'cate_name',
      align: 'center',
      width: '10%',
    },
    {
      title: t('항목코드'),
      label: t('항목코드'),
      dataIndex: 'check_code',
      key: 'check_code',
      align: 'center',
      width: '6%',
    },
    {
      title: t('세부항목'),
      label: t('세부항목'),
      dataIndex: 'check_desc',
      key: 'check_desc',
      align: 'center',
      width: '15%',
    },
    {
      title: t('영향(위험)'),
      label: t('영향(위험)'),
      dataIndex: 'check_risk',
      key: 'check_risk',
      align: 'center',
      width: 'auto',
      render: text => <Text>{text}</Text>,
    },
    {
      title: t('자산가치'),
      label: t('자산가치'),
      dataIndex: 'asset_weight',
      key: 'asset_weight',
      align: 'center',
      width: '6%',
    },
    {
      title: t('위험값'),
      label: t('위험값'),
      dataIndex: 'risk_value',
      key: 'risk_value',
      align: 'center',
      width: '6%',
    },
  ];

  const fetch = useCallback(
    (page = pagination) => {
      riskTechAPI({
        project_id: projectId,
        group_id: groupId,
        ...searchParam,
        size: page.pagination.pageSize || 100,
        page: page.pagination.current || 1,
      }).then(datas => {
        setIsLoading(false);
        setDataSource({
          ...datas,
          content: [
            ...datas.content.map((item, index) => ({ ...item, key: index })),
          ],
        });
        setPagination(prev => {
          return {
            ...prev,
            pageSize: page.pagination.pageSize,
            current: page.pagination.current,
            total: datas.total_elements,
          };
        });
        window.scrollTo(0, 0);
      });
    },
    [
      searchParam,
      groupId,
      pagination,
      projectId,
      riskTechAPI,
      setDataSource,
      setIsLoading,
      setPagination,
    ]
  );

  useEffect(() => {
    if (typeof groupId !== 'undefined') {
      fetch({ pagination });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId, projectId]);

  const handleTableChange = page => {
    fetch({ pagination: page });
  };

  return (
    <Container>
      {dataSource !== null ? (
        <Table
          dataSource={dataSource.content}
          columns={columns}
          loading={isLoading}
          pagination={pagination}
          onChange={handleTableChange}
          size={defaultInfo.table_size}
        />
      ) : (
        <Loading />
      )}
    </Container>
  );
};

export default DesktopDangersTechEvaluationContentTable;
