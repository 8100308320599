import {
    Button,
    Empty,
    message,
    Popconfirm,
    Space,
    Tabs,
    Tooltip,
    Typography,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import React, { useCallback, useContext, useMemo, useState } from 'react';

import DesktopDiagnosisTechnicalTable from './desktop-diagnosis-technical-table';
import DesktopDiagnosisTechnicalTableModal from './desktop-diagnosis-technical-table-modal';
import DesktopDiagnosisTechnicalUploadModal from './desktop-diagnosis-technical-upload-modal';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { IAdminMenuPermissionProps } from '@/common/type';
import { IContentProps, IDiagnosisTechnicalDataProps } from './type';
import { IGroupProps } from '@/panel/admin/AssetsGroup/type';
import Loading from '@/components/loading';
import styled from 'styled-components';
import { UploadOutlined } from '@ant-design/icons';
import useScroll from '@/common/customHooks/useScroll';
// import api from '@/api/api';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import { IPaginationProps } from '@/panel/Assets/Evaluation/type';
import DesktopDiagnosisTechnicalHeader from '@/panel/Diagnosis/Technical/desktop-diagnosis-technical-header';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopDiagnosisTechnicalGroupProps {
    groups: IGroupProps[];
    selectGroupId: number;
    setSelectGroupId: React.Dispatch<React.SetStateAction<number>>;
    setSelectGroupName: React.Dispatch<React.SetStateAction<string>>;
    assetList: IDiagnosisTechnicalDataProps;
    setAssetList: React.Dispatch<
        React.SetStateAction<IDiagnosisTechnicalDataProps>
    >;
    isLoading: boolean;
    selectGroupName: string;
    pageInfo: IAdminMenuPermissionProps;
    isTableLoading: boolean;
    setIsTableLoading: React.Dispatch<React.SetStateAction<boolean>>;
    isModalVisible: boolean;
    setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    isUploadModalVisible: boolean;
    setIsUploadModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    pagination: IPaginationProps;
    setPagination: React.Dispatch<React.SetStateAction<IPaginationProps>>;
    fetch: (
        pagination: IPaginationProps,
        keyword: string,
        cb: () => void
    ) => void;
}

const Container = styled.div``;
const EmptyContainer = styled.div`
    padding: 150px 0;
`;
const ButtonArea = styled.div`
    text-align: left;

    /* transition: all 0.2s ease; */
    padding: 15px;
    &.on {
        position: sticky;
        left: 0;
        top: 140px;
        background: ${props => props.theme.layout.pageHeaderBg};
        border: 1px solid ${props => props.theme.layout.searchBorderColor};
        border-radius: 10px;
        z-index: 10;
        -webkit-box-shadow: 2px 0 8px 0 rgb(29 35 41 / 5%);
        box-shadow: 2px 0 8px 0 rgb(29 35 41 / 5%);
    }
`;
const SelectInfo = styled.p`
    padding-bottom: 10px;
`;

const setTechManualProc = async ({
    projectId,
    groupName,
    os,
    assetId,
    datas,
    selectGroupId,
}) => {
    const result = await api.setTechManualProc({
        project_id: projectId,
        group_name: groupName,
        os,
        asset_id: assetId,
        datas,
        group_id: selectGroupId,
    });

    return result.data;
};

const setTechManualCloseProc = async ({
    projectId,
    groupName,
    os,
    assetId,
    datas,
    selectGroupId,
}) => {
    const result = await api.setTechManualCloseProc({
        project_id: projectId,
        group_name: groupName,
        os,
        asset_id: assetId,
        datas,
        group_id: selectGroupId,
    });

    return result.data;
};

const setTechDelete = async ({
    projectId,
    groupName,
    os,
    assetId,
    datas,
    selectGroupId,
}) => {
    const result = await api.setTechDelete({
        project_id: projectId,
        group_name: groupName,
        os,
        asset_id: assetId,
        datas,
        group_id: selectGroupId,
    });

    return result.data;
};

const DesktopDiagnosisTechnicalGroup: React.FunctionComponent<
    IDesktopDiagnosisTechnicalGroupProps
> = ({
    groups,
    pageInfo,
    assetList,
    isLoading,
    selectGroupId,
    isTableLoading,
    isModalVisible,
    selectGroupName,
    isUploadModalVisible,
    setAssetList,
    setSelectGroupId,
    setIsModalVisible,
    setIsTableLoading,
    setSelectGroupName,
    setIsUploadModalVisible,
    fetch,
    pagination,
    setPagination,
}) => {
    const { t } = useTranslation();
    const { projectId } = useContext(ProjectContext);

    const [selectAssetData, setSelectAssetData] = useState<IContentProps>();
    const [selectRecord, setSelectRecord] = useState<IContentProps>();
    const [selectsRecord, setSelectsRecord] = useState<IContentProps[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const navigate = useNavigate();
    function callback(id) {
        setPagination(prev => ({ ...prev, current: 1 }));
        setIsTableLoading(true);
        setSelectGroupId(id);
        setSelectGroupName(
            groups.filter(group => group.id === Number(id))[0].group_name
        );
    }
    const [isBundle, setIsBundle] = useState(false);

    const { scrollY } = useScroll();

    const isEffect = useMemo(() => {
        return scrollY > 240;
    }, [scrollY]);

    const completeConfirm = useCallback(() => {
        if (selectsRecord.length > 0) {
            const { os } = selectsRecord[0];

            const selectsId = selectsRecord.map(item => item.id).sort();

            setIsTableLoading(true);

            setTechManualProc({
                projectId,
                groupName: selectGroupName,
                os,
                assetId: selectsId,
                datas: selectsRecord[0],
                selectGroupId: Number(selectGroupId),
            }).then(() => {
                fetch(pagination, searchKeyword, () => {
                    setSelectedRowKeys([]);
                    setSelectsRecord([]);
                    message.success(t('진단이 완료 되었습니다.'));
                });
                // getCertTechList(setIsTableLoading(false));
            });
        }
    }, [
        fetch,
        pagination,
        projectId,
        searchKeyword,
        selectGroupId,
        selectGroupName,
        selectsRecord,
        setIsTableLoading,
        t,
    ]);

    const deleteConfirm = useCallback(() => {
        if (selectsRecord.length > 0) {
            const { os } = selectsRecord[0];

            const selectsId = selectsRecord.map(item => item.id).sort();

            setIsTableLoading(true);

            setTechDelete({
                projectId,
                groupName: selectGroupName,
                os,
                assetId: selectsId,
                datas: { ...selectsRecord[0], del_yn: true },
                selectGroupId: Number(selectGroupId),
            }).then(() => {
                fetch(pagination, searchKeyword, () => {
                    setSelectedRowKeys([]);
                    setSelectsRecord([]);
                    message.success(t('선택한 진단이 삭제 되었습니다.'));
                });
            });
        }
    }, [
        fetch,
        pagination,
        projectId,
        searchKeyword,
        selectGroupId,
        selectGroupName,
        selectsRecord,
        setIsTableLoading,
        t,
    ]);

    const onecMoreConfirm = useCallback(() => {
        if (selectsRecord.length > 0) {
            const { os } = selectsRecord[0];

            const selectsId = selectsRecord.map(item => item.id).sort();

            setIsTableLoading(true);

            setTechManualCloseProc({
                projectId,
                groupName: selectGroupName,
                os,
                assetId: selectsId,
                datas: selectsRecord[0],
                selectGroupId: Number(selectGroupId),
            }).then(() => {
                fetch(pagination, searchKeyword, () => {
                    setSelectsRecord([]);
                    setSelectedRowKeys([]);
                    message.success(t('진단이 초기화 되었습니다.'));
                });
                // getCertTechList(setIsTableLoading(false));
            });
        }
    }, [
        fetch,
        pagination,
        projectId,
        searchKeyword,
        selectGroupId,
        selectGroupName,
        selectsRecord,
        setIsTableLoading,
        t,
    ]);

    const cancel = () => {};

    const items = useMemo(() => {
        return groups.map(group => ({
            label: group.group_name,
            key: `${group.id}`,
            children: (
                <>
                    {pageInfo.perm_write && (
                        <>
                            <Text>
                                <ExclamationCircleOutlined /> {t("이행완료 된 자산에 대해서 완료처리 후 다시 진단이 가능합니다.")}
                            </Text>
                        </>
                    )}
                    <DesktopDiagnosisTechnicalHeader
                        fetch={fetch}
                        pageInfo={pageInfo}
                        pagination={pagination}
                        setIsTableLoading={setIsTableLoading}
                        setSearchKeyword={setSearchKeyword}
                        setSelectsRecord={setSelectsRecord}
                        setSelectedRowKeys={setSelectedRowKeys}
                    />
                    {pageInfo.perm_write && (
                        <>
                            <ButtonArea className={isEffect ? 'on' : ''}>
                                <SelectInfo>
                                {t("선택항목")} : <em>{selectsRecord.length}</em>
                                </SelectInfo>
                                <Space>
                                    <Button
                                        onClick={() => {
                                            setIsBundle(true);
                                            setIsUploadModalVisible(true);
                                        }}
                                        icon={<UploadOutlined />}
                                        disabled={selectsRecord.length === 0}
                                    >
                                        {t("선택 결과 업로드")}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setIsBundle(true);
                                            setIsModalVisible(true);
                                        }}
                                        disabled={selectsRecord.length === 0}
                                    >
                                        {t("선택항목")}{t("수동진단")}
                                    </Button>
                                    <Tooltip
                                        placement="bottom"
                                        title={t("진단을 완료하면 수정이 불가합니다.")}
                                    >
                                        <Popconfirm
                                            title={t("진단을 완료하시겠습니까?")}
                                            onConfirm={completeConfirm}
                                            onCancel={cancel}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button
                                                type="primary"
                                                disabled={
                                                    selectsRecord.length === 0
                                                }
                                            >
                                                {t("진단완료")}
                                            </Button>
                                        </Popconfirm>
                                    </Tooltip>

                                    <Tooltip
                                        placement="bottom"
                                        title={t("진단을 삭제하면 복구가 불가합니다.")}
                                    >
                                        <Popconfirm
                                            title={t("진단을 삭제하시겠습니까?")}
                                            onConfirm={deleteConfirm}
                                            onCancel={cancel}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button
                                                type="primary"
                                                disabled={
                                                    selectsRecord.length === 0
                                                }
                                            >
                                                {t("진단삭제")}
                                            </Button>
                                        </Popconfirm>
                                    </Tooltip>
                                    <Tooltip
                                        placement="bottom"
                                        title={t("완료된 진단(진단완료)을 다시 진단 합니다.")}
                                    >
                                        <Popconfirm
                                            title={t("완료된 진단을 다시 진단하시겠습니까?")}
                                            onConfirm={onecMoreConfirm}
                                            onCancel={cancel}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button
                                                type="primary"
                                                disabled={
                                                    selectsRecord.length === 0
                                                }
                                            >
                                                {t("다시진단")}
                                            </Button>
                                        </Popconfirm>
                                    </Tooltip>
                                </Space>
                            </ButtonArea>
                        </>
                    )}
                    <DesktopDiagnosisTechnicalTable
                        assetList={assetList}
                        selectGroupName={selectGroupName}
                        selectGroupId={group.id}
                        setAssetList={setAssetList}
                        pageInfo={pageInfo}
                        isTableLoading={isTableLoading}
                        isModalVisible={isModalVisible}
                        setIsModalVisible={setIsModalVisible}
                        setSelectRecord={setSelectRecord}
                        setSelectAssetData={setSelectAssetData}
                        selectRecord={selectRecord}
                        setIsUploadModalVisible={setIsUploadModalVisible}
                        setSelectsRecord={setSelectsRecord}
                        setIsBundle={setIsBundle}
                        selectedRowKeys={selectedRowKeys}
                        setSelectedRowKeys={setSelectedRowKeys}
                        pagination={pagination}
                        fetch={fetch}
                        setIsTableLoading={setIsTableLoading}
                        searchKeyword={searchKeyword}
                    />
                </>
            ),
        }));
    }, [
        assetList,
        completeConfirm,
        deleteConfirm,
        fetch,
        groups,
        isEffect,
        isModalVisible,
        isTableLoading,
        onecMoreConfirm,
        pageInfo,
        pagination,
        searchKeyword,
        selectGroupName,
        selectRecord,
        selectedRowKeys,
        selectsRecord.length,
        setAssetList,
        setIsModalVisible,
        setIsTableLoading,
        setIsUploadModalVisible,
        t,
    ]);

    // useEffect(() => {
    // if (!isUploadModalVisible) {
    //     setIsTableLoading(true);
    //     fetch(pagination, searchKeyword, () => {
    //         setIsTableLoading(false);
    //     });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isUploadModalVisible]);

    if (isLoading) return <Loading />;

    return (
        <Container>
            {groups.length > 0 ? (
                <Tabs onChange={callback} type="card" items={items} />
            ) : (
                <EmptyContainer>
                    <Empty description="자산데이터를 추가 해주세요">
                        <Button onClick={() => navigate('/Admin/AssetsGroup')}>
                            자산그룹관리 바로가기
                        </Button>
                    </Empty>
                </EmptyContainer>
            )}
            <DesktopDiagnosisTechnicalTableModal
                setIsModalVisible={setIsModalVisible}
                isModalVisible={isModalVisible}
                selectAssetData={selectAssetData}
                selectGroupName={selectGroupName}
                setAssetList={setAssetList}
                selectGroupId={selectGroupId}
                selectsRecord={selectsRecord}
                isBundle={isBundle}
                pagination={pagination}
                fetch={fetch}
                searchKeyword={searchKeyword}
                setIsTableLoading={setIsTableLoading}
            />
            <DesktopDiagnosisTechnicalUploadModal
                isUploadModalVisible={isUploadModalVisible}
                setIsUploadModalVisible={setIsUploadModalVisible}
                selectGroupId={selectGroupId}
                selectGroupName={selectGroupName}
                selectRecord={selectRecord}
                selectsRecord={selectsRecord}
                isBundle={isBundle}
                fetch={fetch}
                pagination={pagination}
                searchKeyword={searchKeyword}
                setIsTableLoading={setIsTableLoading}
            />
        </Container>
    );
};

export default DesktopDiagnosisTechnicalGroup;
