import React, { useCallback, useContext, useEffect, useState } from 'react';

import DesktopDiagnosisTechnicalGroup from '@/panel/Diagnosis/Technical/desktop-diagnosis-technical-group';

import { IAdminMenuPermissionProps } from '@/common/type';
import { IDiagnosisTechnicalDataProps } from './type';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import styled from 'styled-components';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopDiagnosisTechnicalProps {
    pageInfo: IAdminMenuPermissionProps;
}

const Container = styled.div`
    box-sizing: border-box;
    margin-bottom: 20px;
    border-radius: 10px;
    .ant-form-item {
        margin: 0;
    }
`;

const getAssetGroups = async ({ projectId }) => {
    const result = await api.getAssetGroups({ project_id: projectId });

    return result.data;
};

const getCertTechPage = async props => {
    const result = await api.getCertTechPage({
        ...props,
    });

    return result.data;
};

const DesktopDiagnosisTechnical: React.FunctionComponent<
    IDesktopDiagnosisTechnicalProps
> = ({ pageInfo }) => {
    const { projectId } = useContext(ProjectContext);
    const [groups, setGroups] = useState([]);
    const [assetList, setAssetList] = useState<IDiagnosisTechnicalDataProps>();
    const [selectGroupId, setSelectGroupId] = useState(null);
    const [selectGroupName, setSelectGroupName] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isTableLoading, setIsTableLoading] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
        total: 5,
    });

    useEffect(() => {
        getAssetGroups({ projectId }).then(data => {
            const groupsData = data.map((item, index) => {
                return { ...item, key: `${index}` };
            });
            setSelectGroupId(groupsData.length > 0 ? groupsData[0].id : 0);
            setSelectGroupName(
                groupsData.length > 0 ? groupsData[0].group_name : ''
            );
            setGroups(groupsData);
            setIsLoading(false);
        });
    }, [projectId]);

    useEffect(() => {
        if (typeof selectGroupId !== 'undefined' && selectGroupId !== null) {
            fetch(pagination);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId, selectGroupId]);

    const fetch = useCallback(
        (page = pagination, keyword = '', cb = () => {}) => {
            getCertTechPage({
                project_id: projectId,
                group_id: selectGroupId,
                keyword,
                size: page.pageSize || 100,
                page: page.current || 1,
            }).then(datas => {
                setIsTableLoading(false);
                setAssetList({
                    ...datas,
                    content: [
                        ...datas.content.map((data, index) => ({
                            ...data,
                            key: index,
                        })),
                    ],
                });
                setPagination(prev => {
                    return {
                        ...prev,
                        pageSize: page.pageSize,
                        current: page.current,
                        total: datas.total_elements,
                    };
                });
                cb();
            });
        },
        [pagination, projectId, selectGroupId]
    );

    return (
        <>
            <Container>
                <DesktopDiagnosisTechnicalGroup
                    groups={groups}
                    fetch={fetch}
                    selectGroupId={selectGroupId}
                    setSelectGroupId={setSelectGroupId}
                    setSelectGroupName={setSelectGroupName}
                    assetList={assetList}
                    setAssetList={setAssetList}
                    isLoading={isLoading}
                    selectGroupName={selectGroupName}
                    pageInfo={pageInfo}
                    isTableLoading={isTableLoading}
                    setIsTableLoading={setIsTableLoading}
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    isUploadModalVisible={isUploadModalVisible}
                    setIsUploadModalVisible={setIsUploadModalVisible}
                    pagination={pagination}
                    setPagination={setPagination}
                />
            </Container>
        </>
    );
};

export default DesktopDiagnosisTechnical;
