import { Button, Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { IAnalysisDataProps, IColumnProps } from '../Analysis/type';
import styled from 'styled-components';
import api from '@/api/api';
import { ProjectContext } from '@/common/project-provider';
import defaultInfo from '@/common/site-info';
import { IRkskAnalysisDataProps } from './type';
import Modal from '@/components/modal';
import { useTranslation } from 'react-i18next';

interface IDesktopDangersRiskAnalysisTableModalProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dataSync: (
    text: string,
    record: IRkskAnalysisDataProps,
    name: string
  ) => void;
  selectData: IRkskAnalysisDataProps;
  setDataSource: React.Dispatch<React.SetStateAction<IRkskAnalysisDataProps[]>>;
}

const Text = styled.div`
  text-align: left;
`;

const getRiskThreat = async ({ projectId }) => {
  const result = await api.getRiskThreat({ project_id: projectId });

  return result.data;
};

const DesktopDangersRiskAnalysisTableModal: React.FunctionComponent<
  IDesktopDangersRiskAnalysisTableModalProps
> = ({ isModalOpen, setIsModalOpen, dataSync, selectData, setDataSource }) => {
  const { t } = useTranslation();
  const { projectId } = useContext(ProjectContext);
  const [analysisData, setAnalysisData] = useState<IAnalysisDataProps[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getRiskThreat({ projectId }).then(datas => {
      setIsLoading(false);
      setAnalysisData(datas.map((data, index) => ({ ...data, key: index })));
    });
  }, [projectId]);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const riskSelect = record => {
    dataSync(record, selectData, 'threat');
    setDataSource(prev => {
      return prev.map(item => {
        if (item.key === selectData.key) {
          return {
            ...item,
            threat: { ...record },
            threat_desc: record.threat_desc,
          };
        }
        return { ...item };
      });
    });
    handleCancel();
  };

  const columns: IColumnProps[] = [
    {
      title: t('위협코드'),
      dataIndex: 'threat_code',
      key: 'threat_code',
      align: 'center',
      width: '15%',
      label: t('위협코드'),
    },
    {
      title: t('대분류'),
      dataIndex: 'cate1',
      key: 'cate1',
      align: 'center',
      label: t('대분류'),
    },
    {
      title: t('중분류'),
      dataIndex: 'cate2',
      key: 'cate2',
      align: 'center',
      label: t('중분류'),
      render: value => {
        return <Text>{value}</Text>;
      },
    },
    {
      title: t('위협내용'),
      dataIndex: 'threat_desc',
      key: 'threat_desc',
      align: 'center',
      label: t('위협내용'),
      render: value => {
        return <Text>{value}</Text>;
      },
    },
    {
      title: t('선택'),
      dataIndex: '',
      key: 'selectbtn',
      align: 'center',
      label: '',
      render: (_, record) => {
        return <Button onClick={() => riskSelect(record)}>{t("선택")}</Button>;
      },
    },
  ];

  return (
    <Modal
      title={t("위협코드 리스트")}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
      width={1000}
    >
      <Table
        dataSource={analysisData}
        columns={columns}
        loading={isLoading}
        size={defaultInfo.table_size}
      />
    </Modal>
  );
};

export default DesktopDangersRiskAnalysisTableModal;
