import DesktopPolicyProtectionCard from './components/desktop-policy-protection-card';
import { IAdminMenuPermissionProps } from '@/common/type';
import React from 'react';
import styled from 'styled-components';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopPolicyProtectionProps {
  pageInfo: IAdminMenuPermissionProps;
}

const Container = styled.div``;

const DesktopPolicyProtection: React.FunctionComponent<
  IDesktopPolicyProtectionProps
> = ({ pageInfo }) => {
  return (
    <Container>
      <DesktopPolicyProtectionCard pageInfo={pageInfo} />
    </Container>
  );
};

export default DesktopPolicyProtection;
