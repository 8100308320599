import { IConfig } from '@/common/type';
import { IHeaderProps } from './type';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import Locale from '@/common/components/locale';
import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import { defaultColor } from '@/styles/color';

const { Title } = Typography;

interface IMobileHeaderProps extends IHeaderProps {
  config: IConfig;
}

const { Header } = Layout;

const StyledHeader = styled(Header)`
  height: 48px;
  background: transparent;
  position: sticky;
  left: 0;
  top: 0;
  background: ${defaultColor.lightGray};
`;

const LocaleContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 48px;
  height: 48px;
`;

const LogoContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  display: flex;
  height: 48px;
`;

const LogoImage = styled.div`
  position: relative;
  img {
    height: 20px;
    object-fit: contain;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const StyledTitle = styled(Title)`
  text-align: center;
  padding-top: 11px;
`;

const MobileHeader: React.FunctionComponent<IMobileHeaderProps> = ({
  config,
  isMain,
  title,
}) => {
  return (
    <StyledHeader>
      {isMain ? (
        <LogoContainer>
          <Link to="/Main">
            <LogoImage>
              <img src="/static/images/bi_logo.png" alt="" />
            </LogoImage>
          </Link>
        </LogoContainer>
      ) : (
        <StyledTitle level={4}>{title}</StyledTitle>
      )}

      {/* <MenuFolding collapsed={collapsed} HeaderToggle={HeaderToggle} /> */}
      <LocaleContainer>
        <Locale config={config} />
      </LocaleContainer>
    </StyledHeader>
  );
};

export default MobileHeader;
