import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { IAdminMenuPermissionProps } from '@/common/type';

import api from '@/api/api';
import { ProjectContext } from '@/common/project-provider';

import DesktopDangersRiskChart from './desktop-dangers-risk-chart';
import DesktopDangersRiskTable from './desktop-dangers-risk-table';
import { IChartDataProps } from './type';
import DesktopDangersRiskLevel from './desktop-dangers-risk-level';
import { useTranslation } from 'react-i18next';

interface IDesktopDangersRistProps {
  pageInfo: IAdminMenuPermissionProps;
}

const Container = styled.div`
  position: relative;
  padding-left: 280px;
  min-height: 500px;
`;

const getRiskChartdata = async ({ projectId }) => {
  const result = await api.getRiskChartdata({ project_id: projectId });
  
  return result.data;
};

const DesktopDangersRist: React.FunctionComponent<IDesktopDangersRistProps> = ({
  pageInfo,
}) => {
  const { t } = useTranslation();
  const { projectId } = useContext(ProjectContext);
  const [dataSource, setDataSource] = useState([]);
  const [data, setData] = useState<IChartDataProps[]>([]);

  
  const asyncFetch = data => {
    setData([
      {
        name: t(data[0].area),
        risk: `${t("위험도")}3`,
        value: data[0].risk3,
      },
      {
        name: t(data[0].area),
        risk: `${t("위험도")}4`,
        value: data[0].risk4,
      },
      {
        name: t(data[0].area),
        risk: `${t("위험도")}5`,
        value: data[0].risk5,
      },
      {
        name: t(data[0].area),
        risk: `${t("위험도")}6`,
        value: data[0].risk6,
      },
      {
        name: t(data[0].area),
        risk: `${t("위험도")}7`,
        value: data[0].risk7,
      },
      {
        name: t(data[0].area),
        risk: `${t("위험도")}8`,
        value: data[0].risk8,
      },
      {
        name: t(data[0].area),
        risk: `${t("위험도")}9`,
        value: data[0].risk9,
      },

      {
        name: t(data[1].area),
        risk: `${t("위험도")}3`,
        value: data[1].risk3,
      },
      {
        name: t(data[1].area),
        risk: `${t("위험도")}4`,
        value: data[1].risk4,
      },
      {
        name: t(data[1].area),
        risk: `${t("위험도")}5`,
        value: data[1].risk5,
      },
      {
        name: t(data[1].area),
        risk: `${t("위험도")}6`,
        value: data[1].risk6,
      },
      {
        name: t(data[1].area),
        risk: `${t("위험도")}7`,
        value: data[1].risk7,
      },
      {
        name: t(data[1].area),
        risk: `${t("위험도")}8`,
        value: data[1].risk8,
      },
      {
        name: t(data[1].area),
        risk: `${t("위험도")}9`,
        value: data[1].risk9,
      },
    ]);
  };
  
  useEffect(() => {
    getRiskChartdata({ projectId }).then(data => {
      setDataSource(
        data.map(item => {
          return {
            ...item,
            key: item.id,
          };
        })
      );
      asyncFetch(data);
    });
    // eslint-disable-next-line
  }, [projectId]);


  return (
    <Container>
      <DesktopDangersRiskLevel pageInfo={pageInfo} />
      <DesktopDangersRiskTable dataSource={dataSource} />
      <DesktopDangersRiskChart data={data} />
    </Container>
  );
};

export default DesktopDangersRist;
