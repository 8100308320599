import React, { useContext, useState } from 'react';

import DesktopAdminLogHeader from './desktop-admin-log-header';
import DesktopAdminLogTable from './desktop-admin-log-table';
import { IAdminMenuPermissionProps } from '@/common/type';
import { IGetAdminLog } from '@/api/type';
import { ProjectContext } from '@/common/project-provider';
import moment from 'moment';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopAdminLogProps {
  pageInfo: IAdminMenuPermissionProps;
}

const DesktopAdminLog: React.FunctionComponent<IDesktopAdminLogProps> = ({
  pageInfo,
}) => {
  const { projectId } = useContext(ProjectContext);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 5,
  });
  const [params, setParams] = useState<IGetAdminLog>({
    project_id: projectId,
    username: '',
    ip: '',
    url: '',
    from: moment().subtract(1, 'months').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
    summary: '',
  });
  return (
    <>
      <DesktopAdminLogHeader setParams={setParams} />
      <DesktopAdminLogTable
        params={params}
        pagination={pagination}
        setPagination={setPagination}
        pageInfo={pageInfo}
      />
    </>
  );
};

export default DesktopAdminLog;
