import { IAppContextData, IIndexProps } from '@/common/type';
import React, { useContext } from 'react';

import { AppConsumer } from '@/common/app-provider';
import MobileLayout from '@/layout/mobile-layout';
import { ProjectContext } from '@/common/project-provider';
import Panels from '@/panel/panels';

const Index: React.FunctionComponent<IIndexProps> = ({ id }) => {
  const { getPageInfo } = useContext(ProjectContext);
  const pageInfo = getPageInfo(id);

  return (
    <AppConsumer>
      {({ config }: IAppContextData) => {
        if (config.isMobile) {
          return <MobileLayout title="자산평가">mobile</MobileLayout>;
        }
        return <Panels pageInfo={pageInfo} />;
      }}
    </AppConsumer>
  );
};

export default Index;
