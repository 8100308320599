import {
    Button,
    Form,
    Input,
    Popconfirm,
    Space,
    Table,
    Tooltip,
    Typography,
} from 'antd';
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import DesktopAssetsManagementGroupContentExcel from './desktop-assets-management-group-content-excel';
import DesktopAssetsManagementGroupContentSearch from './desktop-assets-management-group-content-search';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { IAdminMenuPermissionProps } from '@/common/type';
import { IGroupProps } from '@/panel/admin/AssetsGroup/type';
import { Link } from 'react-router-dom';
import Loading from '@/components/loading';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import { openNotification } from '@/common/util';
import styled from 'styled-components';
import { IPaginationProps } from '../Evaluation/type';
import defaultInfo from '@/common/site-info';
import CustomButton from "@/components/button";
import DeleteButton from "@/components/deletebutton";
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

interface IDesktopAssetsManagementGroupContent {
    pane: IGroupProps;
    pageInfo: IAdminMenuPermissionProps;
    pagination: IPaginationProps;
    setPagination: React.Dispatch<React.SetStateAction<IPaginationProps>>;
}
interface IAddTableAreaProps {
    isAddMode: boolean;
}

interface ITableContainerProps {
    isLoading: boolean;
}

const Container = styled.div``;

const AddButtonArea = styled.div`
    text-align: right;
    z-index: 5;
    margin-top: -33px;
`;

const RemoveButtonArea = styled.div`
    position: relative;
    display: inline-block;
`;

const SelectButton = styled.div``;

const StyledText = styled(Text)`
    color: ${props => props.theme.font.color};
`;
const TableContainer = styled.div<ITableContainerProps>`
    visibility: ${props => (props.isLoading ? 'hidden' : 'none')};
    margin-bottom: 30px;
`;
const AddTableArea = styled.div<IAddTableAreaProps>`
    transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
    transition-duration: 0.3s;
    transform: translateZ(0);
    transform: translateY(${props => (props.isAddMode ? '0' : '-100px')});
    opacity: ${props => (props.isAddMode ? '1' : '0')};
    overflow: hidden;
    height: ${props => (props.isAddMode ? '112px' : '0')};
    box-shadow: 2px 0 8px 0 rgb(29 35 41 / 15%);
`;
const TableArea = styled.div<IAddTableAreaProps>`
    opacity: ${props => (props.isAddMode ? '0.2' : '1')};
    pointer-events: ${props => (props.isAddMode ? 'none' : 'auto')};
    transform: translateZ(0);
    .ant-table-cell {
        min-width: 130px;
        &.ant-table-selection-column {
            min-width: 50px;
        }
    }
`;
const SearchArea = styled(TableArea)``;

const getAssetGroupFields = async ({ projectId, id }) => {
    const result = await api.getAssetGroupFields({ project_id: projectId, id });

    return result.data;
};

// const getAssetField = async ({ projectId, id }) => {
//   const result = await api.getAssetField({ project_id: projectId, id });

//   return result.data;
// };

const setAssetField = async ({ projectId, id, datas, size, page }) => {
    const result = await api.setAssetField({
        project_id: projectId,
        id,
        datas,
        size,
        page,
    });

    return result.data;
};

const delAssetField = async ({ projectId, id, datas, size, page }) => {
    const result = await api.delAssetField({
        project_id: projectId,
        id,
        datas,
        size,
        page,
    });

    return result.data;
};

const getAssetsPage = async ({ projectId, id, size, page }) => {
    const result = await api.getAssetsPage({
        project_id: projectId,
        id,
        size,
        page,
    });

    return result.data;
};

let count = 2;

const EditableCell = ({
    editing,
    dataIndex,
    editable,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item name={dataIndex}>
                    <Input />
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const DesktopAssetsManagementGroupContent: React.FunctionComponent<
    IDesktopAssetsManagementGroupContent
> = ({ pane, pageInfo, pagination, setPagination }) => {
    const { t } = useTranslation();
    
    const { projectId } = useContext(ProjectContext);

    const assetTableForm = Form.useForm();
    const assetAddTableForm = Form.useForm();

    const [dataSource, setDataSource] = useState([]);
    const [columns, setColumns] = useState([]);
    // const [scrollWidth, setScrollWidth] = useState(0);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [removeLoading, setRemoveLoading] = useState(false);
    const [editingKey, setEditingKey] = useState('');
    const [isAddMode, setIsAddMode] = useState(false);

    const isEditing = useCallback(
        record => record.key === editingKey,
        [editingKey]
    );

    const handleAdd = useCallback(() => {
        setIsAddMode(!isAddMode);
    }, [isAddMode]);

    const handleDelete = useCallback(
        key => {
            const removeRow = dataSource.filter(item => item.key === key);
            delAssetField({
                projectId,
                id: pane.id,
                datas: removeRow,
                size: pagination.pageSize,
                page: pagination.current,
            }).then(datas => {
                handleTableChange({
                    pageSize: pagination.pageSize,
                    current:
                        pagination.current > datas.total_pages
                            ? datas.total_pages
                            : pagination.current,
                    total: datas.total_elements,
                });

                const notiText = {
                    description: t("완료"),
                };

                openNotification(notiText);
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dataSource, pagination, pane.id, projectId, t]
    );

    const inColumns = useMemo(() => {
        return columns.map(col => {
            if (!col.editable) {
                return col;
            }

            return {
                ...col,
                onCell: record => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    // handleSave: handleAddArr,
                    editing: isEditing(record),
                }),
            };
        });
    }, [columns, isEditing]);

    const inAddColumns = useMemo(() => {
        return columns.map(col => {
            if (!col.editable) {
                return col;
            }
            const { defaultSortOrder, sorter, ...otherColumns } = col;

            return {
                ...otherColumns,
                onCell: record => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    // handleSave: handleAddArr,
                    editing: true,
                }),
            };
        });
    }, [columns]);

    const getAssetInfo = params => {
        return Promise.all([
            getAssetGroupFields({ ...params }),
            // getAssetField({ ...params }),
        ]);
    };

    const edit = useCallback(
        record => {
            assetTableForm[0].setFieldsValue({
                ...record,
            });
            setEditingKey(record.key);
        },
        [assetTableForm]
    );

    const cancel = () => {
        setEditingKey('');
    };

    const save = useCallback(
        async key => {
            const row = await assetTableForm[0].validateFields();

            try {
                const newData = [...dataSource];
                const index = newData.findIndex(item => key === item.key);
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });

                setAssetField({
                    projectId,
                    id: pane.id,
                    datas: [{ ...item, ...row }],
                    size: pagination.pageSize,
                    page: pagination.current,
                }).then(datas => {
                    const setDatas = datas.content.map(item => ({
                        ...item,
                        key: item.id,
                    }));

                    setDataSource(setDatas);
                    const notiText = {
                        description: t("완료"),
                    };

                    openNotification(notiText);

                    if (index > -1) {
                        // setEditDatas(prev => [...prev, { ...item, ...row }]);
                        setDataSource(newData);
                        setEditingKey('');
                    } else {
                        newData.push(row);
                        setDataSource(newData);
                        setEditingKey('');
                    }
                    // setSaveArr([]);
                });
            } catch (errInfo) {
                console.log('Validate Failed:', errInfo);
            }
        },
        [assetTableForm, dataSource, pagination, pane.id, projectId, t]
    );

    const addSave = useCallback(async () => {
        try {
            const row = await assetAddTableForm[0].validateFields();

            try {
                const newData = {
                    key: count,
                    asset_code: ``,
                    asset_name: ``,
                    audit_yn: true,
                    del_yn: false,
                    use_yn: true,
                    id: null,
                };

                setAssetField({
                    projectId,
                    id: pane.id,
                    datas: [{ ...newData, ...row }],
                    size: pagination.pageSize,
                    page: pagination.current,
                }).then(datas => {
                    const notiText = {
                        description: t("완료"),
                    };

                    openNotification(notiText);
                    assetAddTableForm[0].resetFields();
                    setIsAddMode(false);

                    handleTableChange({
                        pageSize: pagination.pageSize,
                        current: pagination.current,
                        total: datas.total_elements,
                    });
                });
            } catch (errInfo) {
                console.log('Validate Failed:', errInfo);
            }
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetAddTableForm, pagination, pane.id, projectId, t]);

    useEffect(() => {
        getAssetInfo({ projectId, id: pane.id }).then(data => {
            const [columns] = data;
            const fields = [...columns.fields];

            // setScrollWidth(fields.length * 200);

            const newColumns = fields.map(field => {
                return {
                    ...field,
                    title: field.field_name,
                    dataIndex: field.object_name,
                    editable: pageInfo.perm_write,
                    align: 'center',
                    width: 'auto',
                    defaultSortOrder: ['descend', 'ascend'],

                    sorter: (a, b) => {
                        if (a[field.object_name] > b[field.object_name])
                            return -1;
                        else if (a[field.object_name] < b[field.object_name])
                            return 1;
                        else return 0;
                    },
                };
            });

            // setDataSource(data[1].map(item => ({ ...item, key: item.id })));

            // setColumns([...newColumns, pageInfo.perm_write ? deleteColumn : []]);
            setColumns([...newColumns]);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pane, projectId, isModalVisible, handleDelete, editingKey]);

    useEffect(() => {}, [pane.id, projectId, isModalVisible]);

    const deleteColumn = useMemo(() => {
        return {
            del_yn: false,
            editable: false,
            field_name: t('삭제'),
            field_option: null,
            id: null,
            key: 'operation',
            object_name: 'operation',
            title: '',
            dataIndex: 'operation',
            width: '120px',
            align: 'center',
            fixed: 'right',
            // eslint-disable-next-line react/display-name
            render: (_, record) => {
                const editable = isEditing(record);

                return editable ? (
                    <Space size="small">
                        <Typography.Link onClick={() => save(record.key)}>
                            <Button type="text" size="small">
                                {t("저장")}
                            </Button>
                        </Typography.Link>
                        <Popconfirm
                            title={t("취소 하시겠습니까?")}
                            onConfirm={cancel}
                        >
                            <Button type="text" size="small">
                            {t("취소")}
                            </Button>
                        </Popconfirm>
                    </Space>
                ) : (
                    <Space size="small">
                        <Typography.Link
                            disabled={editingKey !== ''}
                            onClick={() => edit(record)}
                        >
                            <CustomButton
                                disabled={editingKey !== ''}
                            >
                            </CustomButton>
                        </Typography.Link>
                        <Popconfirm
                            title={t("삭제 하시겠습니까?")}
                            disabled={editingKey !== ''}
                            onConfirm={() => handleDelete(record.key)}
                        >
                            <DeleteButton
                                disabled={editingKey !== ''}
                            >
                            </DeleteButton>
                        </Popconfirm>
                    </Space>
                );
            },
        };
    }, [edit, editingKey, handleDelete, isEditing, save, t]);

    const addTableDeleteColumn = useMemo(() => {
        return {
            del_yn: false,
            editable: false,
            field_name: t('삭제'),
            field_option: null,
            id: null,
            key: 'operation',
            object_name: 'operation',
            title: '',
            dataIndex: 'operation',
            width: '120px',
            align: 'center',
            fixed: 'right',
            // eslint-disable-next-line react/display-name
            render: () => {
                return (
                    <Space size="small">
                        <Typography.Link onClick={() => addSave()}>
                            <Button type="primary">{t("저장")}</Button>
                        </Typography.Link>
                    </Space>
                );
            },
        };
    }, [addSave, t]);

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelectedRowKeys(selectedRowKeys);
        },
    };

    const handleSelectDelete = useCallback(() => {
        setRemoveLoading(true);
        const result = [...dataSource].filter(data =>
            selectedRowKeys.includes(data.key)
        );
        setAssetField({
            projectId,
            id: pane.id,
            datas: result.map(item => ({ ...item, del_yn: true })),
            size: pagination.pageSize,
            page: pagination.current,
        }).then(datas => {
            setSelectedRowKeys([]);
            handleTableChange({
                pageSize: pagination.pageSize,
                current:
                    pagination.current > datas.total_pages
                        ? datas.total_pages
                        : pagination.current,
                total: datas.total_elements,
            });
            setRemoveLoading(false);

            const notiText = {
                message: t("완료"),
            };

            openNotification(notiText);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSource, pane.id, projectId, selectedRowKeys, pagination, t]);

    const fetch = useCallback(
        page => {
            setRemoveLoading(true);
            getAssetsPage({
                projectId,
                id: pane.id,
                size: page.pagination.pageSize || 100,
                page: page.pagination.current || 1,
            }).then(data => {
                const { content, total_elements } = data;

                const newDataSource = content.map(data => ({
                    ...data,
                    key: data.id,
                }));
                setDataSource([...newDataSource]);

                setPagination(prev => {
                    return {
                        ...prev,
                        pageSize: page.pagination.pageSize,
                        current: page.pagination.current,
                        total: total_elements,
                    };
                });

                setIsLoading(false);
                setRemoveLoading(false);
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [pane.id, projectId]
    );

    const handleTableChange = page => {
        fetch({ pagination: page });
    };

    useEffect(() => {
        fetch({ pagination });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container>
            {pageInfo.perm_write && (
                <>
                    <StyledText>
                        <ExclamationCircleOutlined />
                        {` `} {t("자산 그룹 편집은 관리자")}&gt;{' '}
                        <Link to="/Admin/AssetsGroup">{t("자산그룹관리")}</Link>{t("에서 설정할 수 있습니다.")}
                    </StyledText>
                    <br />
                    <StyledText>
                        <ExclamationCircleOutlined />
                        {` `} {t("엑셀 파일을 이용하여 데이터 업로드가 가능합니다.")}
                    </StyledText>
                    <br />
                    <br />
                </>
            )}

            <SearchArea isAddMode={isAddMode}>
                <DesktopAssetsManagementGroupContentSearch
                    columns={columns}
                    id={pane.id}
                    setDataSource={setDataSource}
                    pagination={pagination}
                    setPagination={setPagination}
                />
            </SearchArea>
            {pageInfo.perm_write && (
                <>
                    <RemoveButtonArea>
                        <SelectButton>
                            <Popconfirm
                                title={t("선택한 행을 삭제 하시겠습니까?")}
                                onConfirm={() => handleSelectDelete()}
                            >
                                <Button
                                    type="default"
                                    disabled={
                                        selectedRowKeys.length === 0 ||
                                        isAddMode
                                    }
                                >
                                    {t("선택삭제")}
                                </Button>
                            </Popconfirm>
                        </SelectButton>
                    </RemoveButtonArea>
                    <AddButtonArea>
                        <Space
                            size="small"
                            style={{
                                marginBottom: 16,
                                marginLeft: 10,
                            }}
                        >
                            <Tooltip title={t("마지막 페이지에 추가 됩니다.")}>
                                <Button
                                    type="default"
                                    onClick={handleAdd}
                                    disabled={columns.length === 0}
                                >
                                    {isAddMode ? t('닫기') : t('자산추가')}
                                </Button>
                            </Tooltip>
                        </Space>

                        <DesktopAssetsManagementGroupContentExcel
                            pane={pane}
                            setAssetField={setAssetField}
                            isModalVisible={isModalVisible}
                            setIsModalVisible={setIsModalVisible}
                            isAddMode={isAddMode}
                            dataSource={dataSource}
                        />
                    </AddButtonArea>
                </>
            )}

            <AddTableArea isAddMode={isAddMode}>
                <Form form={assetAddTableForm[0]} component={false}>
                    {isLoading && <Loading />}
                    <TableContainer isLoading={isLoading}>
                        <Table
                            components={{
                                body: {
                                    cell: EditableCell,
                                },
                            }}
                            pagination={false}
                            bordered
                            dataSource={[
                                {
                                    key: count,
                                    asset_code: ``,
                                    asset_name: ``,
                                    audit_yn: true,
                                    del_yn: false,
                                    use_yn: true,
                                },
                            ]}
                            columns={[...inAddColumns, addTableDeleteColumn]}
                            rowClassName="editable-row"
                            loading={isLoading || removeLoading}
                            scroll={{ x: 'max-content', y: 500 }}
                            onChange={handleTableChange}
                        />
                    </TableContainer>
                </Form>
            </AddTableArea>

            <TableArea isAddMode={isAddMode}>
                <Form form={assetTableForm[0]} component={false}>
                    {isLoading && <Loading />}
                    <TableContainer
                        isLoading={isLoading}
                        className="assetManagement"
                    >
                        <Table
                            components={{
                                body: {
                                    cell: EditableCell,
                                },
                            }}
                            pagination={pagination}
                            bordered
                            dataSource={dataSource}
                            columns={
                                pageInfo.perm_write
                                    ? [...inColumns, deleteColumn]
                                    : [...inColumns]
                            }
                            rowClassName="editable-row"
                            rowSelection={{
                                type: 'checkbox',
                                ...rowSelection,
                            }}
                            loading={isLoading || removeLoading}
                            scroll={{ x: 'max-content', y: 500 }}
                            onChange={handleTableChange}
                            size={defaultInfo.table_size}
                        />
                    </TableContainer>
                </Form>
            </TableArea>
        </Container>
    );
};

export default DesktopAssetsManagementGroupContent;
