import { Button, Form, Popconfirm, Select, Space, Table } from 'antd';
import { IAccountColumnsProps, IAdminRoleProps } from './type';
import React, { useContext, useEffect, useState } from 'react';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import { openNotification } from '@/common/util';
import styled from 'styled-components';
import defaultInfo from '@/common/site-info';
import Modal from '@/components/modal';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopAdminRoleTableModalProps {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  selectUsername: string;
}

const Header = styled.div`
  text-align: right;
  padding-bottom: 20px;
`;
const Container = styled.div`
  position: relative;
`;
const AddContainer = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 1);
  box-sizing: border-box;
  padding: 30px 0;
  text-align: center;
`;
const Inner = styled.div``;

const getAdminRole = async ({ projectId, userID }) => {
  const result = await api.getAdminRole({ project_id: projectId, userID });

  return result.data;
};

const setAdminRole = async ({ projectId, userID, datas }) => {
  const result = await api.setAdminRole({
    project_id: projectId,
    userID,
    datas,
  });

  return result.data;
};

const deleteAdminRole = async ({ projectId, userID, roleID }) => {
  const result = await api.deleteAdminRole({
    project_id: projectId,
    userID,
    roleID,
  });

  return result.data;
};

const getProjectList = async () => {
  const result = await api.getProjectList();

  return result.data;
};

const DesktopAdminRoleTableModal: React.FunctionComponent<
  IDesktopAdminRoleTableModalProps
> = ({ isModalVisible, setIsModalVisible, selectUsername }) => {
  const { t } = useTranslation();
  const { projectId } = useContext(ProjectContext);

  const [projectList, setProjectList] = useState([]);
  const [userProjectList, setUserProjectList] = useState<IAdminRoleProps[]>([]);
  const [isAddMode, setIsAddMode] = useState(false);

  useEffect(() => {
    if (selectUsername !== null) {
      getAdminRole({ projectId, userID: selectUsername }).then(datas => {
        setUserProjectList(
          datas.map(data => ({
            id: data.id,
            project: data.project.project_name,
            user_role: data.user_role,
            key: data.id,
          }))
        );
      });
      getProjectList().then(datas => {
        setProjectList(datas);
      });
    }
  }, [projectId, selectUsername]);

  const handleOk = () => {
    setIsModalVisible(false);
    setIsAddMode(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsAddMode(false);
  };

  function cancel(e) {
    console.log(e);
  }
  const deleteProject = record => {
    console.log(record);

    deleteAdminRole({
      projectId,
      userID: selectUsername,
      roleID: record.id,
    }).then(datas => {
      const notiText = {
        description: t("완료"),
      };

      openNotification(notiText);

      setUserProjectList(
        datas.map(data => ({
          id: data.id,
          project: data.project.project_name,
          user_role: data.user_role,
          key: data.id,
        }))
      );
    });
  };

  const onFinish = (values: any) => {
    console.log('Success:', values);
    setAdminRole({ projectId, userID: selectUsername, datas: values }).then(
      datas => {
        setIsAddMode(false);

        setUserProjectList(
          datas.map(data => ({
            id: data.id,
            project: data.project.project_name,
            user_role: data.user_role,
            key: data.id,
          }))
        );
      }
    );
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const columns: IAccountColumnsProps[] = [
    {
      title: t('프로젝트'),
      dataIndex: 'project',
      align: 'center',
      key: 'project',
    },
    {
      title: t('사용자 유형'),
      dataIndex: 'user_role',
      align: 'center',
      width: '140px',
      key: 'user_role',
      // eslint-disable-next-line react/display-name
      render: value => {
        if (value === 1) return <>{t("심사원")}</>;
        if (value === 2) return <>{t("사용자")}</>;
        if (value === 3) return <>{t("관리자")}</>;
        return <></>;
      },
    },
    {
      title: t('삭제'),
      dataIndex: 'role_delete',
      key: 'role_delete',
      align: 'center',
      width: '100px',
      // eslint-disable-next-line react/display-name
      render: (_, record) => {
        return (
          <Popconfirm
            title={t("삭제 하시겠습니까?")}
            onConfirm={() => {
              deleteProject(record);
            }}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button>{t("삭제")}</Button>
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <Modal
      title={t("프로젝트 및 사용자 유형 관리")}
      open={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      <Container>
        {isAddMode ? (
          <AddContainer>
            <Inner>
              <Form
                name="basic"
                labelCol={{ span: 9 }}
                wrapperCol={{ span: 10 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label={t("프로젝트")}
                  name="project_id"
                  rules={[
                    { required: true, message: t('프로젝트를 선택해주세요.') },
                  ]}
                >
                  <Select defaultValue="">
                    {projectList.map(list => (
                      <Option key={list.id} value={list.id}>
                        {list.project_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={t("사용자 유형")}
                  name="user_role"
                  rules={[{ required: true, message: t('유형을 선택해주세요.') }]}
                >
                  <Select>
                    <Option value={1}>{t("심사원")}</Option>
                    <Option value={2}>{t("사용자")}</Option>
                    <Option value={3}>{t("관리자")}</Option>
                  </Select>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                  <Space>
                    <Button type="primary" htmlType="submit">
                      {t("추가")}
                    </Button>
                    <Button onClick={() => setIsAddMode(false)}>{t("취소")}</Button>
                  </Space>
                </Form.Item>
              </Form>
            </Inner>
          </AddContainer>
        ) : (
          <>
            <Header>
              <Button type="primary" onClick={() => setIsAddMode(true)}>
                {t("프로젝트 사용자 추가")}
              </Button>
            </Header>
            <Table
              columns={columns}
              dataSource={userProjectList}
              pagination={false}
              size={defaultInfo.table_size}
            />
          </>
        )}
      </Container>
    </Modal>
  );
};

export default DesktopAdminRoleTableModal;
