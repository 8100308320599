import { EditOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';

import { IAdminMenuPermissionProps } from '@/common/type';
import { IOrgGroupData } from '../type';
import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';

const TitleContainer = styled.div`
  /* padding: 0 0 3px 0; */
  font-size: 14px;
  margin: 0;
  &:hover button {
    opacity: 1;
  }
`;

const StyledButton = styled(Button)`
  transition: 0.5s ease;
  opacity: 0;
`;

const ButtonContainer = styled.div`
  position: relative;
  z-index: 10;
  display: inline-block;
  margin-left: 10px;
  button {
    width: 24px;
    height: 24px;
    font-size: 11px;
    margin: 0;
  }
`;

interface IDesktopManagerTitleProps {
  item: IOrgGroupData;
  isAddMode: boolean;
  isEditMode: boolean;
  addInputFormHandler: (item: IOrgGroupData) => void;
  removeNodeItemHandler: (item: IOrgGroupData) => void;
  editNodeItemHandler: (item: IOrgGroupData) => void;
  pageInfo: IAdminMenuPermissionProps;
}

const DesktopManagerTitle: React.FunctionComponent<
  IDesktopManagerTitleProps
> = ({
  item,
  isAddMode,
  isEditMode,
  addInputFormHandler,
  removeNodeItemHandler,
  editNodeItemHandler,
  pageInfo,
}) => {
  return (
    <TitleContainer>
      {item.organ_name}
      {!isAddMode && !isEditMode && pageInfo.perm_write && (
        <ButtonContainer>
          <StyledButton
            type="button"
            onClick={() => {
              editNodeItemHandler(item);
            }}
          >
            <EditOutlined />
          </StyledButton>
          <StyledButton
            type="button"
            onClick={() => {
              addInputFormHandler(item);
            }}
          >
            <PlusOutlined />
          </StyledButton>
          {item.key !== '0-0' && (
            <StyledButton
              type="button"
              onClick={() => {
                removeNodeItemHandler(item);
              }}
            >
              <MinusOutlined />
            </StyledButton>
          )}
        </ButtonContainer>
      )}
    </TitleContainer>
  );
};

export default DesktopManagerTitle;
