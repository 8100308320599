import { IConfig, ViewTypeEnum } from '@/common/type';
import { Layout, Space } from 'antd';
import React, { useContext, useState } from 'react';

import { Link } from 'react-router-dom';
// import Locale from '@/common/components/locale';
import Notification from '@/common/components/notification';
import Profile from '@/common/components/profile';
import Project from '@/common/components/project';
import { ProjectContext } from '@/common/project-provider';
import ThemeSwitch from '@/common/components/theme';
import styled from 'styled-components';

const { Header } = Layout;

interface IDesktopHeaderProps {
  config: IConfig;
  isManager?: boolean;
}

const StyledHeader = styled(Header)`
  width: 100%;
  height: 48px !important;
  position: fixed;
  left: 0;
  top: 0;
  text-align: right;
  display: flex;
  flex-direction: row-reverse;
  background: transparent;
  transition: all 0.2s;
  padding-right: 25px !important;
  z-index: 10;
  box-sizing: border-box;
  background: #232323 !important;
  border-bottom: 1px solid #000000;
`;

const ProjectContainer = styled.div`
  /* min-width: 120px; */
  text-align: left;
`;

const LogoContainer = styled.div`
  width: auto;
  position: absolute;
  left: 10px;
  top: 0px;
  display: flex;
  padding-left: 20px;
  height: 48px;
  overflow: hidden;
  a {
    display: block;
    box-sizing: border-box;
    padding-top: 18px;
    img {
      vertical-align: top;
    }
  }
`;

const LogoImage = styled.div`
  display: inline-block;
  overflow: hidden;
  margin-top: -3px;
  img {
    height: 20px;
    object-fit: contain;
  }
`;

const DesktopHeader: React.FunctionComponent<IDesktopHeaderProps> = ({
  isManager,
}) => {
  const { userInfo } = useContext(ProjectContext);
  const [homeLogo] = useState(
    sessionStorage.getItem('systemInfo') !== null
      ? JSON.parse(sessionStorage.getItem('systemInfo')).home_logo
      : ''
  );

  return (
    <StyledHeader>
      <LogoContainer>
        <Link to="/">
          <>
            <LogoImage>
              <img src={process.env.REACT_APP_URL + homeLogo} alt="" />
            </LogoImage>
            {/* {!collapsed && <CompanyName>NOONE Company</CompanyName>} */}
          </>
        </Link>
      </LogoContainer>
      {!isManager && (
        <Space size={15}>
          <Profile />
          <ThemeSwitch />
          {/* <Locale /> */}
          {userInfo.userLevel !== ViewTypeEnum.audit && <Notification />}
          <ProjectContainer>
            <Project />
          </ProjectContainer>
        </Space>
      )}
    </StyledHeader>
  );
};

export default DesktopHeader;
