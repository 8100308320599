import { Empty, Tabs } from 'antd';

import DesktopDashboardCard from './components/desktop-dashboard-card';
import DesktopDashboardVulnerabilityTabItem from './components/desktop-dashboard-vulnerability-tabItem';
import { IAssetsType } from './type';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopDashboardVulnerability {
  assetsData: IAssetsType[];
  isLoading: boolean;
}

const Inner = styled.div`
  padding: 20px;
  height: 406px;
`;

const StyledEmpty = styled(Empty)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const DesktopDashboardVulnerability: React.FunctionComponent<
  IDesktopDashboardVulnerability
> = ({ assetsData, isLoading }) => {
  const { t } = useTranslation();

  const items = useMemo(() => {
    return assetsData.map(tab => ({
      label: tab.type,
      key: `${tab.type}_${tab.id}`,
      children: <DesktopDashboardVulnerabilityTabItem id={tab.id} />,
    }));
  }, [assetsData]);

  return (
    <DesktopDashboardCard
      cardTitle={t("취약점점검")}
      moreLink="/Diagnosis/Technical"
      isLoading={isLoading}
    >
      <Inner>
        {assetsData.length > 0 ? (
          <Tabs
            defaultActiveKey={`${assetsData[0].type}_${assetsData[0].id}`}
            type="card"
            items={items}
          />
        ) : (
          <StyledEmpty />
        )}
      </Inner>
    </DesktopDashboardCard>
  );
};

export default DesktopDashboardVulnerability;
