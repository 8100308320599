import { Button, Drawer, Space, Table } from 'antd';
import {
    ICertificationTableColumnsProps,
    ICertificationTableData,
} from '../type';
import { NavigationGuard, lineBreak, openNotification } from '@/common/util';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import DesktopCertificationSpecTableDesc from './desktop-certification-spec-table-desc';
import DesktopCertificationSpecTableDoc from './desktop-certification-spec-table-evidence';
import DesktopCertificationSpecTableOperation from './desktop-certification-spec-table-operation';
import { DownloadOutlined } from '@ant-design/icons';
import { IAdminMenuPermissionProps } from '@/common/type';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import styled from 'styled-components';
import defaultInfo from '@/common/site-info';
import Viewer from '@/pages/Viewer';
import { ICertificationList } from '../CertAdmin/type';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopCertificationSpecTableProps {
    categoryId: string;
    pageInfo: IAdminMenuPermissionProps;
}

interface ITextProps {
    align?: string;
}

const CertificationTable = styled.div`
    padding-top: 20px;
    clear: both;
    p {
        margin: 0;
    }
    .ant-table-cell {
        vertical-align: top;
    }
`;

const Text = styled.p<ITextProps>`
    ${props =>
        props.align === 'center' ? `text-align: center;` : `text-align: left;`}
    padding: 2px 0;
`;

const DownloadContainer = styled.div`
    text-align: right;
    position: sticky;
    right: 0;
    top: 70px;
    z-index: 6;
    margin-top: -52px;
    padding-bottom: 20px;
    display: inline-block;
    float: right;
`;

const getCertificationState = async ({ projectId, categoryId }) => {
    const result = await api.getCertificationState({
        project_id: projectId,
        category_id: categoryId,
    });

    return result.data;
};

const setCertificationState = async ({ project_id, category_id, datas }) => {
    const result = await api.setCertificationState({
        project_id,
        category_id,
        datas,
    });

    return result.data;
};

const getCertStateExcelDownload = async ({ projectId }) => {
    const result = await api.getCertStateExcelDownload({
        project_id: projectId,
    });

    return result;
};

const DesktopCertificationSpecTable: React.FunctionComponent<
    IDesktopCertificationSpecTableProps
> = ({ categoryId, pageInfo }) => {
    const { t } = useTranslation();
    const { projectId } = useContext(ProjectContext);
    const [certData, setCertData] = useState<ICertificationTableData[]>([]);
    const [isOnLoadData, setIsOnLoadData] = useState(true);
    const [editCertData, setEditCertData] = useState<ICertificationTableData[]>(
        []
    );
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [activeGuard, setActiveGuard] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectItem, setSelectItem] = useState<ICertificationList | null>(
        null
    );

    useEffect(() => {
        setIsOnLoadData(true);
        setCertData([]);
        getCertificationState({ projectId, categoryId }).then(data => {
            setCertData(
                data.map(item => ({
                    ...item,
                    key: item.category_id,
                }))
            );
            setIsOnLoadData(false);
        });
    }, [categoryId, projectId, t]);

    const dataSync = useCallback(data => {
        setCertData(prev =>
            prev.map(item => {
                if (item.category_id === data[0].category_id) {
                    return data[0];
                }
                return item;
            })
        );
        setActiveGuard(true);
    }, []);

    const excelDownloadHandler = useCallback(() => {
        getCertStateExcelDownload({ projectId }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `statement_operation_list.xlsx`);
            document.body.appendChild(link);
            link.click();
        });
    }, [projectId]);

    const saveHandler = useCallback(() => {
        setIsSaveLoading(true);
        setCertificationState({
            project_id: projectId,
            category_id: categoryId,
            datas: [...editCertData],
        }).then(() => {
            const notiText = {
                description: t("완료"),
            };

            openNotification(notiText);
            setIsSaveLoading(false);
            setEditCertData([]);
            setActiveGuard(false);
        });
    }, [categoryId, editCertData, projectId, t]);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const columns: ICertificationTableColumnsProps[] = [
        {
            title: 'No',
            dataIndex: 'category_num',
            key: 'category_num',
            width: '5%',
            align: 'center',
            // eslint-disable-next-line react/display-name
            render: text => <Text align="center">{lineBreak(text)}</Text>,
        },
        {
            title: t('통제항목'),
            dataIndex: 'category_name',
            key: 'category_name',
            width: '10%',
            align: 'center',
            // eslint-disable-next-line react/display-name
            render: text => <Text>{lineBreak(text)}</Text>,
        },
        {
            title: t('상세내용'),
            dataIndex: 'category_desc',
            key: 'category_desc',
            width: '17%',
            align: 'center',
            // eslint-disable-next-line react/display-name
            render: text => <Text>{lineBreak(text)}</Text>,
        },
        {
            title: t('운영여부'),
            dataIndex: 'state',
            key: 'state',
            width: '6%',
            align: 'center',
            // eslint-disable-next-line react/display-name
            render: (value, datas) =>
                pageInfo.perm_write ? (
                    <DesktopCertificationSpecTableOperation
                        datas={datas}
                        setEditCertData={setEditCertData}
                        dataSync={dataSync}
                    />
                ) : (
                    <Text align="center">{value}</Text>
                ),
        },
        {
            title: t('운영현황'),
            dataIndex: 'state_desc',
            key: 'state_desc',
            width: 'auto',
            align: 'center',
            // eslint-disable-next-line react/display-name
            render: (value, datas) => {
                return pageInfo.perm_write ? (
                    <DesktopCertificationSpecTableDesc
                        datas={datas}
                        setEditCertData={setEditCertData}
                        dataSync={dataSync}
                    />
                ) : (
                    <Text align="left">{value}</Text>
                );
            },
        },
        {
            title: t('관련문서'),
            dataIndex: 'doc',
            key: 'doc',
            width: '20%',
            align: 'center',
            render: (_, datas) => {
                return (
                    <DesktopCertificationSpecTableDoc
                        datas={datas}
                        setEditCertData={setEditCertData}
                        dataSync={dataSync}
                        pageInfo={pageInfo}
                        type="doc"
                        setSelectItem={setSelectItem}
                        showDrawer={showDrawer}                        
                    />
                );
            },
        },
        {
            title: t('기록(증적자료)'),
            dataIndex: 'sets',
            key: 'sets',
            width: '20%',
            align: 'center',
            render: (_, datas) => {
                return (
                    <DesktopCertificationSpecTableDoc
                        datas={datas}
                        setEditCertData={setEditCertData}
                        dataSync={dataSync}
                        pageInfo={pageInfo}
                        type="sets"
                        setSelectItem={setSelectItem}
                        showDrawer={showDrawer}    
                    />
                );
            },
        },
    ];

    return (
        <CertificationTable>
            <NavigationGuard when={activeGuard} />
            {pageInfo.perm_write && (
                <DownloadContainer>
                    <Space>
                        <Button
                            type="primary"
                            onClick={saveHandler}
                            loading={isSaveLoading}
                        >
                            {t("저장")}
                        </Button>
                        <Button
                            icon={<DownloadOutlined />}
                            onClick={excelDownloadHandler}
                            disabled={certData.length === 0}
                        >
                            {t("다운로드")}
                        </Button>
                    </Space>
                </DownloadContainer>
            )}
            <Table
                dataSource={certData}
                columns={columns}
                size={defaultInfo.table_size}
                pagination={false}
                loading={isOnLoadData}
            />
            <Drawer
                title={selectItem?.filename || 'FILE PREVIEW'}
                placement="right"
                onClose={onClose}
                open={open}
                width="80%"
            >
                <Viewer selectItem={selectItem} />
            </Drawer>
        </CertificationTable>
    );
};

export default DesktopCertificationSpecTable;
