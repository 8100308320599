import React, { useCallback, useState } from 'react';

import { ICertificationTableData } from '../type';
import { Input } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

interface IDesktopCertificationSpecTableDescProps {
  datas: ICertificationTableData;
  setEditCertData: React.Dispatch<
    React.SetStateAction<ICertificationTableData[]>
  >;
  dataSync: (data) => void;
}

const StyledTextArea = styled(TextArea)`
  box-sizing: border-box;
`;

const DesktopCertificationSpecTableDesc: React.FunctionComponent<
  IDesktopCertificationSpecTableDescProps
> = ({ datas, setEditCertData, dataSync }) => {
  const { t } = useTranslation();
  const [desc, setDesc] = useState(datas);
  // console.log(desc);

  const onBlur = useCallback(() => {
    const paramsDatas = { ...datas, state_desc: desc.state_desc };

    setEditCertData(prev => [
      ...prev.filter(item => item.category_id !== paramsDatas.category_id),
      paramsDatas,
    ]);
    dataSync([paramsDatas]);
  }, [dataSync, datas, desc.state_desc, setEditCertData]);

  const onChange = useCallback(
    event => {
      setDesc(prev => ({ ...prev, state_desc: event.target.value }));
    },
    [setDesc]
  );

  return (
    <StyledTextArea
      rows={4}
      autoSize={true}
      placeholder={t("운영명세서")}
      defaultValue={desc.state_desc}
      // size="small"
      onBlur={onBlur}
      onChange={onChange}
    />
  );
};

export default DesktopCertificationSpecTableDesc;
