import { Button, Form, Input, Select, Space } from 'antd';
import React, { useMemo } from 'react';
import { ICodeData } from '../Code/type';
import styled from 'styled-components';
import Modal from '@/components/modal';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopAdminAssetsGroupAddModalProps {
  isCreateGroupModalVisible: boolean;
  setIsCreateGroupModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  assetsTypeList: ICodeData[];
  onFinish: ({ assetstype, newGroupName }: any) => void;
  datas?: any;
  isEdit: boolean;
}

const StyledInput = styled(Input)`
  width: 150px;
`;
const ButtonContainer = styled.div`
  text-align: right;
`;

const DesktopAdminAssetsGroupAddModal: React.FunctionComponent<
  IDesktopAdminAssetsGroupAddModalProps
> = ({
  isCreateGroupModalVisible,
  setIsCreateGroupModalVisible,
  assetsTypeList,
  onFinish,
  datas = {},
  isEdit,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const groupData = useMemo(() => {
    return Object.keys(datas).map(item => ({
      name: [item],
      value: datas[item],
    }));
  }, [datas]);

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  return (
    <Modal
      title={isEdit ? t('자산그룹수정') : t('자산그룹생성')}
      open={isCreateGroupModalVisible}
      footer={null}
      //   onOk={handleOk}
      onCancel={() => setIsCreateGroupModalVisible(false)}
    >
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        fields={groupData}
      >
        <Space size="small">
          <Form.Item label={t("자산유형")} name="assetstype">
            <Select
              placeholder={t("자산유형")}
              style={{ width: 120 }}
              onChange={handleChange}
            >
              {assetsTypeList.map(item => (
                <Option value={item.item_cd} key={item.item_cd}>
                  {t(item.item_name)}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={t("그룹명")} name="newGroupName">
            <StyledInput placeholder={t("그룹명")} />
          </Form.Item>
        </Space>
        <ButtonContainer>
          <Button type="primary" htmlType="submit">
            {isEdit ? t('수정') : t('추가')}
          </Button>
        </ButtonContainer>
      </Form>
    </Modal>
  );
};

export default DesktopAdminAssetsGroupAddModal;
