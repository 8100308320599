import api from '@/api/api';
import { ProjectContext } from '@/common/project-provider';
import { Button, List } from 'antd';
import React, { useContext } from 'react';
import styled from 'styled-components';
import Modal from '@/components/modal';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopDiagnosisTechnicalProgramModalProps {
  isProgramModalVisible: boolean;
  setIsProgramModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IDataProps {
  type: '서버' | 'DBMS' | 'PC';
  name: string;
  param: string;
}

const StyledList = styled(List)`
  .ant-list-item {
    position: relative;
    .ant-list-item-action {
      position: absolute;
      right: 0px;
      top: 7px;
    }
  }
`;

const DownloadLink = styled(Button)`
  position: absolute;
  right: 10px;
  top: 0;
`;

const getCertTechDownload = async ({ projectId, type }) => {
  const result = await api.getCertTechDownload({
    project_id: projectId,
    type,
  });

  return result;
};

const DesktopDiagnosisTechnicalProgramModal: React.FunctionComponent<
  IDesktopDiagnosisTechnicalProgramModalProps
> = ({ isProgramModalVisible, setIsProgramModalVisible }) => {
  const { t } = useTranslation();
  const { projectId } = useContext(ProjectContext);
  // const data = ['Windows 서버', 'Linux 서버', 'Solaris 서버', 'Windows PC'];
  const data: IDataProps[] = [
    {
      type: t('서버'),
      name: 'Linux',
      param: 's1',
    },
    {
      type: t('서버'),
      name: 'Windows',
      param: 's2',
    },
    {
      type: t('서버'),
      name: 'Unix',
      param: 's3',
    },
    {
      type: 'DBMS',
      name: 'MYSQL',
      param: 'd1',
    },
    {
      type: 'DBMS',
      name: 'MSSQL',
      param: 'd2',
    },
    {
      type: 'DBMS',
      name: 'Oracle(linux)',
      param: 'd3',
    },
    {
      type: 'DBMS',
      name: 'Oracle(windows)',
      param: 'd4',
    },
    {
      type: 'PC',
      name: 'Windows',
      param: 'p1',
    },
    {
      type: 'PC',
      name: 'MacOS',
      param: 'p2',
    },
  ];

  const download = (item: IDataProps) => {
    getCertTechDownload({ projectId, type: item.param }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      const contentDisposition = response.headers['content-disposition'];

      let fileName = '';

      if (contentDisposition) {
        const [fileNameMatch] = contentDisposition
          .split(';')
          .filter(str => str.includes('filename'));
        if (fileNameMatch) [, fileName] = fileNameMatch.split('=');
      }

      link.href = url;
      link.setAttribute('download', `${fileName}`);
      link.style.cssText = 'display:none';
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  };

  return (
    <Modal
      width="400px"
      title={t("진단 프로그램")}
      open={isProgramModalVisible}
      onCancel={() => setIsProgramModalVisible(false)}
      maskClosable={false}
      footer={false}
    >
      <StyledList
        bordered
        dataSource={data}
        renderItem={item => (
          <List.Item
            actions={[
              <DownloadLink key={item.name} onClick={() => download(item)}>
                {t("다운로드")}
              </DownloadLink>,
            ]}
          >
            {item.type} - {item.name}
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default DesktopDiagnosisTechnicalProgramModal;
