import { AuthProvider } from './common/auth-provider';
import LocaleProvider from '@/common/locale-provider';
import ProjectProvider from '@/common/project-provider';
import Router from './Router';
import ThemeProvider from '@/common/theme-provider';
import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import "@/locale/i18n";

const GlobalStyle = createGlobalStyle`
  ${reset}
  /* other styles */
  * {
    box-sizing: border-box;
  }
  
  body{
    font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
    background: ${props => props.theme.layout.bodyBg};
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  input, button {
    background-color: transparent;
    border: none;
    outline: none;
  }
  h1, h2, h3, h4, h5, h6{
    font-family:'Maven Pro', sans-serif;
  }
 

  @media only screen and (max-width: 768px) {
    body {
      font-size: 12px;
    }
  }

  @media only screen and (max-width: 576px) {
    body {
      font-size: 10px;
    }
  }
`;

function App() {
  return (
    <>
      <ProjectProvider>
        <AuthProvider>
          <LocaleProvider>
            <ThemeProvider>
              <Router />
              <GlobalStyle />
            </ThemeProvider>
          </LocaleProvider>
        </AuthProvider>
      </ProjectProvider>
    </>
  );
}

export default App;
