import React, { useContext, useEffect, useState } from 'react';

import DesktopAdminCodeList from './desktop-admin-code-list';
import { IAdminMenuPermissionProps } from '@/common/type';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface IDesktopAdminCode {
    pageInfo: IAdminMenuPermissionProps;
}

const Container = styled.div`
    min-width: 400px;
    display: inline-block;
    padding-bottom: 30px;
    &.dpWrap {
        margin-right: 10px;
    }
    &.rkWrap {
        margin-left: 10px;
    }
    h5 {
        font-size: 18px;
        padding-bottom: 0px;
    }
`;

const ContainerManager = styled.div`
    padding: 20px;
    height: 100%;
    box-sizing: border-box;
    border-radius: 10px;
    border-radius: 10px;
    box-shadow: 2px 0 8px 0 rgb(29 35 41 / 5%);
    border: 1px solid ${props => props.theme.layout.searchBorderColor};
`;

const getAdminCodeList = async ({ projectId, cd }) => {
    const result = await api.getAdminCode({ projectId: projectId, id: cd });

    return result.data;
};

const DesktopAdminCode: React.FunctionComponent<IDesktopAdminCode> = ({
    pageInfo,
}) => {
    const { t } = useTranslation();
    const { projectId } = useContext(ProjectContext);
    const [adminDPCode, setAdminDPCode] = useState([]);
    const [adminRKCode, setAdminRKCode] = useState([]);

    useEffect(() => {
        getAdminCodeList({ projectId, cd: 'DP' }).then(data => {
            setAdminDPCode(data);
        });
        getAdminCodeList({ projectId, cd: 'RK' }).then(data => {
            setAdminRKCode(data);
        });
    }, [projectId]);

    return (
        <>
            <Container className="dpWrap">
                <h5>{t("부서코드")}</h5>
                <ContainerManager>
                    <DesktopAdminCodeList
                        datas={adminDPCode}
                        cd="DP"
                        pageInfo={pageInfo}
                    />
                </ContainerManager>
            </Container>
            <Container className="rkWrap">
                <h5>{t("직급코드")}</h5>
                <ContainerManager>
                    <DesktopAdminCodeList
                        datas={adminRKCode}
                        cd="RK"
                        pageInfo={pageInfo}
                    />
                </ContainerManager>
            </Container>
        </>
    );
};

export default DesktopAdminCode;
