import DesktopDashboardCard from './components/desktop-dashboard-card';
import { Empty } from 'antd';
import { Gauge } from '@ant-design/plots';
import { IAssetsDiagnosisData } from './type';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopDashboardAssetsProps {
  assetsData: IAssetsDiagnosisData[];
  isLoading: boolean;
}

const Inner = styled.div`
  /* margin: -12px; */
  /* padding: 27px 0 0 0; */
  height: 399px;
`;
const StyledEmpty = styled(Empty)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const List = styled.ul`
  /* display: flex; */
  height: 400px;
  overflow-y: auto;
  padding: 20px 10px 20px 10px;
  margin: 0;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #9f9f9f;
    background-clip: padding-box;
  }
  &::-webkit-scrollbar-track {
    background-color: #eeeeee;
  }
`;
const Item = styled.li`
  width: 50%;
  float: left;
  margin-bottom: 20px;
  /* margin: 10px; */
`;

// const circleColors = [
//   ['#6395F9', '#E8EDF3'],
//   ['#65DAAB', '#E8EDF3'],
//   // ['#657798', '#E8EDF3'],
//   // ['#F6C02D', '#E8EDF3'],
//   // ['#7566FA', '#E8EDF3'],
//   // ['#74CBED', '#E8EDF3'],
// ]

const DesktopDashboardAssets: React.FunctionComponent<
  IDesktopDashboardAssetsProps
> = ({ assetsData, isLoading }) => {
  const { t } = useTranslation();

  const config: any = {
    height: 150,
    width: 150,
    autoFit: true,
    percent: 0.1,
    innerRadius: 0.85,
    radius: 0.88,
    statistic: {
      title: {
        style: {
          fontSize: '14px',
          lineHeight: '14px',
        },
        formatter: function formatter() {
          return 'test';
        },
      },
    },
  };

  return (
    <DesktopDashboardCard
      cardTitle={t("자산진단")}
      moreLink="/Diagnosis/Status"
      isLoading={isLoading}
    >
      <Inner>
        {assetsData.length > 0 ? (
          <List>
            {assetsData.map(item => {
              const data = {
                ...config,
                percent: item.value === 0 ? 0.0001 : item.value / 100,
                range: {
                  color: 'l(0) 0:#B8E1FF 1:#476CA0',
                },
                startAngle: 0.9 * Math.PI,
                endAngle: 2.1 * Math.PI,
                indicator: null,
                statistic: {
                  title: {
                    offsetY: -36,
                    style: {
                      fontSize: '18px',
                      color: '#4B535E',
                    },
                    formatter: () =>
                      item.value === 0 ? '0%' : `${item.value}%`,
                  },
                  content: {
                    style: {
                      fontSize: '16px',
                      lineHeight: '16px',
                      color: '#4B535E',
                    },
                    formatter: () => item.type,
                  },
                },
              };

              return (
                <Item key={item.id}>
                  <Gauge {...data} />
                </Item>
              );
            })}
          </List>
        ) : (
          <StyledEmpty />
        )}
      </Inner>
    </DesktopDashboardCard>
  );
};

export default DesktopDashboardAssets;
