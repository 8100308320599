// eslint-disable-next-line import/no-anonymous-default-export
export default {
  textsample: "텍스트 샘플",
  accountPage: "개인정보",
  logout: "로그아웃",
  confirm: "확인",
  cancle: "취소",
  logoutMsg: "로그아웃 하시겠습니까?",
  // 메뉴
  menu0: "대시보드",
  menu1: "조직 관리",
  menu1_1: "조직 관리",
  menu1_2: "구성원 관리",
  menu1_3: "조직도",
  menu2: "자산관리",
  menu2_1: "자산관리",
  menu2_2: "자산평가",
  menu3: "증적관리",
  menu3_1: "증적목록",
  menu3_2: "증적관리",
  menu3_3: "운영명세서",
  menu4: "정책관리",
  menu4_1: "정보보호정책",
  menu5: "진단관리",
  menu5_1: "진단현황",
  menu5_2: "진단관리",
  menu5_3: "기술적진단",
  menu6: "위험관리",
  menu6_1: "위험분석",
  menu6_2: "위험평가",
  menu6_3: "위험관리수준",
  menu6_4: "정보보호계획",
  menu7: "관리자",
  menu7_1: "자산그룹관리",
  menu7_2: "계정관리",
  menu7_3: "프로젝트관리",
  menu7_4: "로그관리",
  menu7_5: "권한관리",
  menu7_6: "통제항목관리",
  menu7_7: "시스템설정",
  menu7_8: "코드관리",
};
