import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';

const Container = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20%;
  position: relative;
`;

const StyledSpin = styled(Spin)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
`;

const Loading: React.FunctionComponent = () => {
  return (
    <Container>
      <StyledSpin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      />
    </Container>
  );
};

export default Loading;
