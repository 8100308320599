import React, { useContext, useEffect, useState } from 'react';
import DesktopOrgMembersSearchForm from '@/panel/Org/Member/desktop-org-members-search-form';
import DesktopOrgMembersSearchTable from '@/panel/Org/Member/desktop-org-members-search-table';
import styled from 'styled-components';
import api from '@/api/api';
import { ProjectContext } from '@/common/project-provider';
import { IAdminMenuPermissionProps } from '@/common/type';

interface IDesktopOrgMembersProps {
  pageInfo: IAdminMenuPermissionProps;
}

const Container = styled.div`
  box-sizing: border-box;
  margin-bottom: 20px;
  border-radius: 10px;
`;

const getOrgArrList = async ({ project_id }) => {
  const result = await api.getOrgArrList({ project_id });

  return result.data;
};

const DesktopOrgMembers: React.FunctionComponent<IDesktopOrgMembersProps> = ({
  pageInfo,
}) => {
  const { projectId } = useContext(ProjectContext);
  const [organList, setOrganList] = useState([]);
  const [searchData, setSearchData] = useState({
    member_name: '',
    organ_id: null,
  });

  useEffect(() => {
    getOrgArrList({ project_id: projectId }).then(data => {
      setOrganList(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container>
        <DesktopOrgMembersSearchForm
          setSearchData={setSearchData}
          organList={organList}
        />
      </Container>
      <Container>
        <DesktopOrgMembersSearchTable
          searchData={searchData}
          orgArrList={organList}
          pageInfo={pageInfo}
        />
      </Container>
    </>
  );
};

export default DesktopOrgMembers;
