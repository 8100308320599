import { IAdminMenuPermissionProps } from '@/common/type';
import DesktopLayout from '@/layout/desktop-layout';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DesktopAssetEvaluation from './Assets/Evaluation/desktop-assets-evaluation';
import DesktopAssetsManagementGroup from './Assets/Management/desktop-assets-management-group';
import styled from 'styled-components';
import Breadcrumb from '@/components/breadcrumb';
import { Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import DesktopDiagnosisStatus from './Diagnosis/Status/desktop-diagnosis-status';
import DesktopPolicyProtection from './Policy/desktop-policy-protection';
import DesktopDiagnosisManagement from '@/panel/Diagnosis/Management/desktop-diagnosis-management';
import DesktopDiagnosisTechnical from '@/panel/Diagnosis/Technical/desktop-diagnosis-technical';
import DesktopDangersAnalysis from '@/panel/Dangers/Analysis/desktop-dangers-analysis';
import DesktopDangersEvaluation from '@/panel/Dangers/Evaluation/desktop-dangers-evaluation';
import DesktopDangersRisk from '@/panel/Dangers/Risk/desktop-dangers-risk';
import DesktopDangersRiskAnalysis from '@/panel/Dangers/RiskAnalysis/desktop-dangers-risk-analysis';
import DesktopDangersProtection from '@/panel/Dangers/Protection/desktop-dangers-protection';
import DesktopCertificationRecord from '@/panel/Certification/Record/desktop-certification-record';
import DesktopCertificationAdmin from '@/panel/Certification/CertAdmin/desktop-certification-admin';
import DesktopCertificationSpec from '@/panel/Certification/Spec/desktop-certification-spec';
import DesktopAssetsGroup from '@/panel/admin/AssetsGroup/desktop-admin-assets-group';
import DesktopAdminAccount from '@/panel/admin/Account/desktop-admin-account';
import DesktopAdminProject from '@/panel/admin/Project/desktop-admin-project';
import DesktopAdminCode from '@/panel/admin/Code/desktop-admin-code';
import DesktopAdminLog from '@/panel/admin/Log/desktop-admin-log';
import DesktopAdminCategory from '@/panel/admin/Category/desktop-admin-category';
import DesktopSystemSetup from '@/panel/admin/System/desktop-system-setup';
import DesktopOrgManager from '@/panel/Org/Manager/desktop-org-manager';
import DesktopOrgMembers from '@/panel/Org/Member/desktop-org-members';
import DesktopOrgChart from '@/panel/Org/Chart/desktop-org-chart';
import DesktopDangersTechEvaluation from './Dangers/TechEvaluation/desktop-dangers-techEvaluation';
import DesktopDangersTechProtection from './Dangers/TechProtection/desktop-dangers-techProtection';
import { defaultColor } from '@/styles/color';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

interface IPanelsProps {
    pageInfo?: IAdminMenuPermissionProps;
}

interface IStyledContentProps {
    'data-is-ismain': boolean;
}

export interface IHistoryDataProps {
    pageName: string;
    pathName: string;
}

const Container = styled.div`
    padding: 30px;
    box-sizing: border-box;
    min-height: 90vh;
    background: ${props => props.theme.layout.innerContentBg};
    border-radius: 10px;
    border: 1px solid ${props => props.theme.layout.innerContentBorderColor};
`;

const Label = styled.div`
    display: inline-block;
`;

const StyledTitle = styled(Title)`
    padding-top: 20px;
    &.ant-typography {
        color: ${props => props.theme.font.color};
    }
`;

const PageInHeader = styled.div`
    position: sticky;
    top: 8px;
    /* border-bottom: 1px solid ${defaultColor.lightGray}; */
    padding: 20px 0 10px 30px;
    z-index: 5;
    margin-bottom: 20px;
    border-radius: 0 10px 10px 10px;
    transition-timing-function: cubic-bezier(0.42, 0, 1, 1);
    transition-duration: 0.3s;
    margin-top: -1px;
    &.on {
        background: ${props => props.theme.layout.pageHeaderBg};
        border: 1px solid ${props => props.theme.layout.pageHeaderBorderColor};
        -webkit-box-shadow: 2px 0 8px 0 rgb(29 35 41 / 5%);
        box-shadow: 2px 0 8px 0 rgb(29 35 41 / 5%);
    }
`;

const StyledContent = styled(Content)<IStyledContentProps>`
    min-height: calc(100vh - 173px);
    background-color: ${props => props.theme.layout.contentBg};
`;



const getPanels = path => {

    const permission = JSON.parse(
        sessionStorage.getItem('userInfo')
    ).permission;
    const { perm_view, perm_write } = permission.filter(
        item => item.url === path
    )[0];
    const pageInfo = {
        perm_view,
        perm_write,
    };

    if (path === '/Org/Manager') {
        return {
            breadCrumb: ['조직 관리', '조직 관리'],
            content: <DesktopOrgManager pageInfo={pageInfo} />,
        };
    }
    if (path === '/Org/Member') {
        return {
            breadCrumb: ['조직 관리', '구성원 관리'],
            content: <DesktopOrgMembers pageInfo={pageInfo} />,
        };
    }
    if (path === '/Org/Chart') {
        return {
            breadCrumb: ['조직 관리', '조직도'],
            content: <DesktopOrgChart />,
        };
    }
    if (path === '/Assets/Evaluation') {
        return {
            breadCrumb: ['자산관리', '자산평가'],
            content: <DesktopAssetEvaluation pageInfo={pageInfo} />,
        };
    }
    if (path === '/Assets/Management') {
        return {
            breadCrumb: ['자산관리', '자산관리'],
            content: <DesktopAssetsManagementGroup pageInfo={pageInfo} />,
        };
    }
    if (path === '/Policy/Protection') {
        return {
            breadCrumb: ['정책관리', '정보보호정책'],
            content: <DesktopPolicyProtection pageInfo={pageInfo} />,
        };
    }
    if (path === '/Diagnosis/Status') {
        return {
            breadCrumb: ['진단관리', '진단현황'],
            content: <DesktopDiagnosisStatus />,
        };
    }
    if (path === '/Diagnosis/Management') {
        return {
            breadCrumb: ['진단관리', '진단관리'],
            content: <DesktopDiagnosisManagement pageInfo={pageInfo} />,
        };
    }
    if (path === '/Diagnosis/Technical') {
        return {
            breadCrumb: ['진단관리', '기술적진단'],
            content: <DesktopDiagnosisTechnical pageInfo={pageInfo} />,
        };
    }
    if (path === '/Dangers/Analysis') {
        return {
            breadCrumb: ['위험관리', '위협관리'],
            content: <DesktopDangersAnalysis pageInfo={pageInfo} />,
        };
    }
    if (path === '/Dangers/RiskAnalysis') {
        return {
            breadCrumb: ['위험관리', '관리위험분석'],
            content: <DesktopDangersRiskAnalysis pageInfo={pageInfo} />,
        };
    }
    if (path === '/Dangers/Evaluation') {
        return {
            breadCrumb: ['위험관리', '관리위험평가'],
            content: <DesktopDangersEvaluation pageInfo={pageInfo} />,
        };
    }
    if (path === '/Dangers/TechEvaluation') {
        return {
            breadCrumb: ['위험관리', '기술위험평가'],
            content: <DesktopDangersTechEvaluation pageInfo={pageInfo} />,
        };
    }
    if (path === '/Dangers/Risk') {
        return {
            breadCrumb: ['위험관리', '위험관리수준'],
            content: <DesktopDangersRisk pageInfo={pageInfo} />,
        };
    }
    if (path === '/Dangers/Protection') {
        return {
            breadCrumb: ['위험관리', '관리위험 이행 조치계획'],
            content: <DesktopDangersProtection pageInfo={pageInfo} />,
        };
    }
    if (path === '/Dangers/TechProtection') {
        return {
            breadCrumb: ['위험관리', '기술위험 이행 조치 계획'],
            content: <DesktopDangersTechProtection pageInfo={pageInfo} />,
        };
    }
    if (path === '/Certification/Record') {
        return {
            breadCrumb: ['증적관리', '증적관리'],
            content: <DesktopCertificationRecord pageInfo={pageInfo} />,
        };
    }
    if (path === '/Certification/CertAdmin') {
        return {
            breadCrumb: ['증적관리', '증적목록'],
            content: <DesktopCertificationAdmin pageInfo={pageInfo} />,
        };
    }
    if (path === '/Spec') {
        return {
            breadCrumb: ['', '운영명세서'],
            content: <DesktopCertificationSpec pageInfo={pageInfo} />,
        };
    }
    if (path === '/Admin/AssetsGroup') {
        return {
            breadCrumb: ['관리자', '자산그룹관리'],
            content: <DesktopAssetsGroup pageInfo={pageInfo} />,
        };
    }
    if (path === '/Admin/Account') {
        return {
            breadCrumb: ['관리자', '계정관리'],
            content: <DesktopAdminAccount pageInfo={pageInfo} />,
        };
    }
    if (path === '/Admin/Project') {
        return {
            breadCrumb: ['관리자', '프로젝트관리'],
            content: <DesktopAdminProject pageInfo={pageInfo} />,
        };
    }
    if (path === '/Admin/Code') {
        return {
            breadCrumb: ['관리자', '코드 관리'],
            content: <DesktopAdminCode pageInfo={pageInfo} />,
        };
    }
    if (path === '/Admin/Log') {
        return {
            breadCrumb: ['관리자', '로그관리'],
            content: <DesktopAdminLog pageInfo={pageInfo} />,
        };
    }
    if (path === '/Admin/Category') {
        return {
            breadCrumb: ['관리자', '통제항목관리'],
            content: <DesktopAdminCategory pageInfo={pageInfo} />,
        };
    }
    if (path === '/Admin/System') {
        return {
            breadCrumb: ['관리자', '시스템설정'],
            content: <DesktopSystemSetup pageInfo={pageInfo} />,
        };
    }
};

const Panels: React.FunctionComponent<IPanelsProps> = () => {
    const { t } = useTranslation();

    const { pathname } = useLocation();
    const [historyTab, setHistoryTab] = useState([]);

    const isMain = pathname === '/';

    const currentPageItem = useMemo<IHistoryDataProps[]>(() => {
        const panelData = getPanels(pathname);

        return [
            {
                pageName: t(panelData.breadCrumb[1]),
                pathName: pathname,
            },
        ];
    }, [pathname, t]);

    const setsessionStorage = useCallback(
        target => {
            const result = target.filter(
                page => currentPageItem[0].pageName === page.pageName
            );

            if (sessionStorage.getItem('history') !== null) {
                if (result.length === 0) {
                    sessionStorage.setItem(
                        'history',
                        JSON.stringify([...target, ...currentPageItem])
                    );
                }
            } else {
                sessionStorage.setItem(
                    'history',
                    JSON.stringify([...currentPageItem])
                );
            }
        },
        [currentPageItem]
    );

    useEffect(() => {
        setsessionStorage(
            sessionStorage.getItem('history') !== null
                ? [...JSON.parse(sessionStorage.getItem('history'))]
                : currentPageItem
        );

        setHistoryTab(
            [...JSON.parse(sessionStorage.getItem('history'))].map(
                (item, index) => {
                    const panelData = getPanels(item.pathName);

                    return {
                        label: <Label>{item.pageName}</Label>,
                        key: `item-${index + 1}`,
                        closable:
                            [...JSON.parse(sessionStorage.getItem('history'))]
                                .length > 1,
                        pageName: item.pageName,
                        pathName: item.pathName,
                        children: (
                            <StyledContent data-is-ismain={isMain}>
                                <PageInHeader className={true ? 'on' : ''}>
                                    <Breadcrumb
                                        breadCrumb={panelData.breadCrumb}
                                    />
                                    <StyledTitle level={3}>
                                        {
                                            t(panelData.breadCrumb[
                                                panelData.breadCrumb.length - 1
                                            ])
                                        }
                                    </StyledTitle>
                                </PageInHeader>
                                <Container>{panelData.content}</Container>
                            </StyledContent>
                        ),
                    };
                }
            )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPageItem, isMain, setsessionStorage, t]);

    //   historyTab 이 실제 패널 데이터
    return (
        <DesktopLayout
            historyTab={historyTab}
            currentPageItem={currentPageItem}
            setHistoryTab={setHistoryTab}
        />
    );
};

export default Panels;
