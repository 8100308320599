import { Card } from 'antd';
import { Link } from 'react-router-dom';
import Loading from '@/components/loading';
import React from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopDashboardCardProps {
  children?: React.ReactNode;
  cardTitle: string;
  actions?: any;
  moreLink: string;
  isLoading: boolean;
}
const StyledCard = styled(Card)`
  background: transparent;
  &.ant-card-bordered {
    border: none;
  }
  .ant-card-head {
    padding-right: 0;
    border-bottom: none;
    .ant-card-head-wrapper {
      .ant-card-head-title {
        padding: 0;
        h3 {
          font-size: 20px;
          margin: 0;
        }
      }
      .ant-card-extra {
        a > span {
          font-size: 15px;
        }
      }
    }
  }
  .ant-card-body {
    border: 1px solid ${props => props.theme.layout.searchBorderColor};
    background-color: ${props => props.theme.layout.dashboardBg};
    padding: 0;
    border-radius: 10px;
    overflow: hidden;
    ul {
      margin: 0;
    }
  }
  .ant-card-actions {
    border: 1px solid ${props => props.theme.layout.searchBorderColor};
    border-radius: 10px;
    overflow: hidden;
    margin-top: -1px;
  }
`;

const MoreText = styled.span`
  font-size: 12px;
  padding-right: 10px;
  cursor: pointer;
`;
const Title = styled.h3`
  font-size: 16px;
  margin: 0;
`;

const DesktopDashboardCard: React.FunctionComponent<
  IDesktopDashboardCardProps
> = ({ children, cardTitle, actions, moreLink, isLoading }) => {
  const { t } = useTranslation();

  return (
    <StyledCard
      title={<Title>{cardTitle}</Title>}
      size="small"
      extra={
        moreLink !== '' ? (
          <Link to={moreLink}>
            <MoreText>{t("더보기")}</MoreText>
          </Link>
        ) : (
          false
        )
      }
      actions={actions}
    >
      {isLoading ? <Loading /> : children}
    </StyledCard>
  );
};

export default DesktopDashboardCard;
