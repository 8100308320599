import { Form, Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { IOrgMemberData } from '../type';
import ImgCrop from 'antd-img-crop';
import api from '@/api/api';
import styled from 'styled-components';
import { defaultColor } from '@/styles/color';
import Modal from '@/components/modal';

interface IDesktopOrgMembersManagerModalProfile {
  datas: IOrgMemberData;
  setImageURL: React.Dispatch<React.SetStateAction<string>>;
}

const ImageUploadContainer = styled.div`
  overflow: hidden;
  text-align: center;
  .ant-upload {
    overflow: hidden;
  }
  .ant-upload-list-picture-card-container {
    margin: 0 auto;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-info::before {
    left: 0;
  }
  .avatar-uploader > .ant-upload {
    width: 160px !important;
    height: 160px !important;
    border-radius: 500px;
    border: 1px solid ${defaultColor.lightGray};
  }
`;

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

const getBase64Preview = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

const setProfileImageUpload = async file => {
  const result = await api.setProfileImageUpload(file);

  return result.data;
};

const DesktopOrgMembersManagerDrawerProfile: React.FunctionComponent<
  IDesktopOrgMembersManagerModalProfile
> = ({ datas, setImageURL }) => {
  const [loading, setLoading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileInfo, setFileInfo] = useState({
    uid: '-1',
    name: datas.member_name,
    status: 'done',
    url: datas.member_pic,
  });

  useEffect(() => {
    setFileInfo({
      uid: '-1',
      name: datas.member_name,
      status: 'done',
      url: datas.member_pic,
    });
  }, [datas]);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleChange = info => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    const formData = new FormData();

    formData.append('file', info.file.originFileObj);

    // Get this url from response in real world.
    getBase64(info.file.originFileObj, imageUrl => {
      setFileInfo(prev => ({ ...prev, url: imageUrl }));
      setLoading(false);
    });
    setProfileImageUpload(formData).then(data => {
      setImageURL(data.file_download_uri);
    });
  };

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64Preview(file.originFileObj);
    }

    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);

    setFileInfo(prev => ({ ...prev, url: file.url }));
  };

  return (
    <ImageUploadContainer>
      <Form.Item name="member_pic">
        <ImgCrop rotate>
          <Upload
            name="member_pic"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            onPreview={handlePreview}
          >
            {fileInfo.url ? (
              <img src={fileInfo.url} alt="avatar" style={{ width: '100%' }} />
            ) : (
              uploadButton
            )}
          </Upload>
        </ImgCrop>
      </Form.Item>
      <Modal
        open={previewVisible}
        title="이미지 상세"
        footer={null}
        onCancel={() => {
          setPreviewVisible(false);
        }}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </ImageUploadContainer>
  );
};

export default DesktopOrgMembersManagerDrawerProfile;
