import React from 'react';
import { Button, ButtonProps } from 'antd';
import {  DeleteOutlined } from '@ant-design/icons';





const DeleteButton: React.FunctionComponent<ButtonProps> = ({ ...props }) => {
    return <Button {...props} icon={<DeleteOutlined/>} type='default' size='middle'/>;
};



export default DeleteButton