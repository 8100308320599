import { Button, Dropdown, Menu } from 'antd';
import React, { useContext } from 'react';

import { GlobalOutlined } from '@ant-design/icons';
import { IConfig } from '@/common/type';
import { LocaleContext } from '@/common/locale-provider';
import moment from 'moment';
import styled from 'styled-components';
import { defaultColor } from '@/styles/color';

interface ILocaleProps {
  config?: IConfig;
}

const StyledGlobalOutlined = styled(GlobalOutlined)`
  color: ${defaultColor.darkGray};
`;

const StyledDropdown = styled(Dropdown)`
  background: transparent;
  border: none;
  margin-top: 17px;
  box-shadow: none;
`;

const StyledMenu = styled(Menu)``;

const DesktopMenuItemButton = styled(Button)`
  background: transparent !important;
  border: none !important;
  color: ${defaultColor.lightGray} !important;
  &:focus,
  &:hover,
  &:active {
    background: transparent;
  }
`;
const MobileMenuItemButton = styled(Button)`
  width: 48px;
  height: 48px;
  margin: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: transparent !important;
  border: none !important;
  color: ${defaultColor.lightGray} !important;
  &:focus,
  &:hover,
  &:active {
    background: transparent;
  }
`;

const MenuItemButton = styled.button``;

const Locale: React.FunctionComponent<ILocaleProps> = ({ config }) => {
  const { setLocale } = useContext(LocaleContext);

  const changeLocale = (language: string) => {
    const localeValue = language;
    setLocale(localeValue);
    moment.locale(localeValue);

  };

  const menu = (
    <StyledMenu>
      <Menu.Item key="ko">
        <MenuItemButton type="button" onClick={() => changeLocale('ko')}>
          🇰🇷 한국어
        </MenuItemButton>
      </Menu.Item>
      <Menu.Item key="jp">
        <MenuItemButton type="button" onClick={() => changeLocale('jp')}>
          JP 日本語
        </MenuItemButton>
      </Menu.Item>
      <Menu.Item key="en">
        <MenuItemButton type="button" onClick={() => changeLocale('en')}>
          🇺🇸 English
        </MenuItemButton>
      </Menu.Item>
    </StyledMenu>
  );

  const LocaleButton = config?.isMobile
    ? MobileMenuItemButton
    : DesktopMenuItemButton;

  return (
    <StyledDropdown overlay={menu} placement="bottomRight" arrow>
      <LocaleButton icon={<StyledGlobalOutlined />}></LocaleButton>
    </StyledDropdown>
  );
};

export default Locale;
