import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';

import {
    Viewer as PdfViewer,
    SpecialZoomLevel,
    Worker,
} from '@react-pdf-viewer/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { AuthProvider } from '@/common/auth-provider';
import FileViewer from 'react-file-viewer';
import { Viewer } from 'hwp.js';
import api from '@/api/api';
import styled from 'styled-components';
import { zoomPlugin } from '@react-pdf-viewer/zoom';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IIndexProps {
    selectItem: any;
}

const Container = styled.div`
    /* background-color: #e8eaed; */
    .rpv-core__inner-pages {
        overflow: visible !important;
    }
    .rpv-core__minimal-button {
        position: relative;
        z-index: 11;
        position: sticky;
        left: 0;
        top: 0;
    }
    .photo-viewer-container {
        text-align: center;
        width: 100% !important;
        height: 100% !important;
        box-sizing: border-box;
        padding: 30px;
        img {
            width: 100% !important;
            height: 100% !important;
            border: 1px solid #eee;
        }
    }
`;

const ViewerContainer = styled.div`
    position: relative;
    .pg-viewer-wrapper {
        overflow-y: initial;
        .pdf-canvas {
            text-align: center;
            padding-top: 50px;
            canvas {
                -webkit-box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.19);
                box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.19);
                width: 40%;
            }
        }
        .spreadsheet-viewer {
            height: 100vh !important;
        }
    }
`;
const ScreenBlocks = styled.div`
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
`;


const getPreviewDocs = async ({ filekey }) => {
    const result = await api.getPreviewDocs({
        filekey,
    });

    return result.data;
};

const Index: React.FunctionComponent<IIndexProps> = ({ selectItem }) => {

    const [homeFooter] = useState(
        JSON.parse(sessionStorage.getItem('systemInfo')).home_footer
    );

    //const [copywrite] = useState(
    //    JSON.parse(sessionStorage.getItem('systemInfo')).login_logo
    //);
    
    const Inner = styled.div`
        color: rgba(0, 0, 0, 0.04);
        font-size: 4rem;
        font-weight: bold;
        text-transform: uppercase;
        pointer-events: none;
        width: 50%;
        height: 80%;
        overflow: hidden;
        position: fixed;
        left: 35%;
        top: 150px;
        z-index: 10;
        word-break: break-all;
        
    `;

    const zoomPluginInstance = zoomPlugin();
    const { ZoomPopover } = zoomPluginInstance;
    const filekey = selectItem.fileurl;
    const filetype = selectItem.file_ext;

    

    const ref = useRef(null);
    const url = `${process.env.REACT_APP_API_URL}previewDocs/${filekey}`;

    const showViewer = useCallback(file => {
        const reader = new FileReader();
        reader.onloadend = result => {
            let _a;
            const bstr =
                (_a = result.target) === null || _a === void 0
                    ? void 0
                    : _a.result;
            if (bstr) {
                new Viewer(ref.current, bstr);
            }
        };

        reader.readAsBinaryString(file);
    }, []);

    useEffect(() => {
        if (filetype === 'hwp' || filetype === 'hwpx') {
            getPreviewDocs({ filekey }).then(file => {
                showViewer(file);
            });
        }

    }, [filekey, filetype, showViewer]);

    const onError = e => {
        console.log(e, "error in file-viewer");
    };

    return selectItem !== null ? (
        <AuthProvider>
            <Container>
                {(filetype === 'hwp' || filetype === 'hwpx') && (
                    <div ref={ref} />
                )}
                {filetype === 'pdf' && (
                    <ViewerContainer>
                        <Worker workerUrl="/static/pdf.worker.min.js">
                            <ScreenBlocks>
                                <Inner>{homeFooter} {homeFooter} {homeFooter} {homeFooter} {homeFooter} {homeFooter} {homeFooter} {homeFooter} {homeFooter} {homeFooter} {homeFooter} {homeFooter} {homeFooter} {homeFooter} {homeFooter} {homeFooter} {homeFooter} {homeFooter} {homeFooter}</Inner>
                            </ScreenBlocks>

                            <div style={{ height: '95%', textAlign: 'center' }}>
                                <ZoomPopover />
                                <PdfViewer
                                    fileUrl={url}
                                    defaultScale={SpecialZoomLevel.PageWidth}
                                    plugins={[zoomPluginInstance]}
                                />
                            </div>
                        </Worker>
                    </ViewerContainer>
                )}
                {(filetype === 'doc' ||
                    filetype === 'docx' ||
                    filetype === 'xls' ||
                    filetype === 'xlsx' ||
                    filetype === 'ppt' ||
                    filetype === 'pptx' ||
                    filetype === 'jpg' ||
                    filetype === 'jpeg' ||
                    filetype === 'png' ||
                    filetype === 'gif') && (
                    <ViewerContainer>
                        <ScreenBlocks>
                            <Inner>{homeFooter} {homeFooter} {homeFooter} {homeFooter} {homeFooter} {homeFooter} {homeFooter} {homeFooter} {homeFooter} {homeFooter} {homeFooter} {homeFooter} {homeFooter} {homeFooter} {homeFooter}</Inner>
                            
                        </ScreenBlocks>
                        <FileViewer fileType={filetype} filePath={url} key={filekey} onError={onError}/>
                    </ViewerContainer>
                )}
            </Container>
        </AuthProvider>
    ) : (
        <></>
    );
};

export default Index;
