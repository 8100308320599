import { Column } from '@ant-design/plots';
import DesktopDashboardCard from './components/desktop-dashboard-card';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from '@/common/theme-provider';
import { useTranslation } from 'react-i18next';

interface IDesktopDashboardAssetsEvaluationProps {
  dataSource: {
    type: string;
    value: number;
  }[];
  isLoading: boolean;
}

const Inner = styled.div`
  padding: 22px 20px 18px 20px;
`;

const DesktopDashboardAssetsEvaluation: React.FunctionComponent<
  IDesktopDashboardAssetsEvaluationProps
> = ({ dataSource, isLoading }) => {

  const { t } = useTranslation();

  const { isDarkMode } = useContext(ThemeContext);

  const paletteSemanticRed = '#F4664A';
  const brandColor = '#476CA0';
  const config: any = {
    data: dataSource,
    height: 359,
    xField: 'type',
    yField: 'value',
    seriesField: '',
    theme: isDarkMode ? 'dark' : 'default',
    color: function color(_ref) {
      const type = _ref.type;
      if (type === '10-30分' || type === '30+分') {
        return paletteSemanticRed;
      }
      return brandColor;
    },
    label: {
      content: function content(originData) {
        const val = parseFloat(originData.value);
        if (val < 0.05) {
          return (val * 100).toFixed(1) + '%';
        }
      },
      offset: 10,
    },
    legend: false,
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
  };

  return (
    <DesktopDashboardCard
      cardTitle={t("자산평가")}
      moreLink="/Assets/Evaluation"
      isLoading={isLoading}
    >
      <Inner>
        <Column {...config} />
      </Inner>
    </DesktopDashboardCard>
  );
};

export default DesktopDashboardAssetsEvaluation;
