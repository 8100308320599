import React, { useState } from 'react';

import DesktopCertificationSpecHeader from './desktop-certification-spec-header';
import DesktopCertificationSpecTable from './desktop-certification-spec-table';
import { IAdminMenuPermissionProps } from '@/common/type';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopCertificationProps {
  pageInfo: IAdminMenuPermissionProps;
}

const DesktopCertificationSpec: React.FunctionComponent<
  IDesktopCertificationProps
> = ({ pageInfo }) => {
  const [categoryId, SetCategoryId] = useState(null);

  return (
    <>
      <DesktopCertificationSpecHeader setCategoryId={SetCategoryId} />
      <DesktopCertificationSpecTable
        categoryId={categoryId}
        pageInfo={pageInfo}
      />
    </>
  );
};

export default DesktopCertificationSpec;
