import { Button, Empty, Form, Input, Select, Space, Tabs } from "antd";
import {
  IAssetEvaluationDataProps,
  IEvalDateProps,
  IPaginationProps,
} from "./type";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import DesktopAssetEvaluationTable from "./desktop-assets-evaluation-table";
import DesktopDashboardAssetsEvaluationExcel from "@/panel/Assets/Evaluation/desktop-assets-evaluation-excel";
import { DownloadOutlined } from "@ant-design/icons";
import { IAdminMenuPermissionProps } from "@/common/type";
import { IGroupProps } from "@/panel/admin/AssetsGroup/type";
import Loading from "@/components/loading";
import { ProjectContext } from "@/common/project-provider";
import api from "@/api/api";
import { openNotification } from "@/common/util";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const { Option } = Select;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopAssetEvaluationGroupProps {
  groups: IGroupProps[];
  getCertTechAPI: ({ page }: any) => any;
  selectGroupId: number;
  setSelectGroupId: React.Dispatch<React.SetStateAction<number>>;
  assetList: IAssetEvaluationDataProps[];
  setAssetList: React.Dispatch<
    React.SetStateAction<IAssetEvaluationDataProps[]>
  >;
  isLoading: boolean;
  tableLoading: boolean;
  setTableLoading: React.Dispatch<React.SetStateAction<boolean>>;
  pageInfo: IAdminMenuPermissionProps;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isModalVisible: boolean;
  pagination: IPaginationProps;
  setPagination: React.Dispatch<React.SetStateAction<IPaginationProps>>;
  fetch: (page: any) => void;
}

const Container = styled.div`
  position: relative;
`;
const EmptyContainer = styled.div`
  padding: 150px 0;
`;
const ButtonArea = styled.div`
  text-align: right;
  position: sticky;
  top: 70px;
  z-index: 6;
  display: inline-block;
  width: 100%;
`;
const CertificationSearch = styled.div`
  text-align: left;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid ${(props) => props.theme.layout.searchBorderColor};
  margin-top: 20px;
  border-radius: 10px;
`;
const StyledSelect = styled(Select)`
  text-align: left;
`;
const Label = styled.label`
  font-size: 15px;
  margin-left: 15px;
`;

const getAssetExcelDownload = async ({ projectId, id, date }) => {
  const result = await api.getAssetExcelDownload({
    project_id: projectId,
    id,
    date,
  });

  return result;
};

const setAssetEvalution = async ({
  project_id,
  group_id,
  datas,
  size,
  page,
}) => {
  const result = await api.setAssetEvalution({
    project_id,
    group_id,
    datas,
    size,
    page,
  });

  return result.data;
};

const getAssetsEvalDate = async ({ projectId, id }) => {
  const result = await api.getAssetsEvalDate({
    project_id: projectId,
    id,
  });

  return result.data;
};

const DesktopAssetEvaluationGroup: React.FunctionComponent<
  IDesktopAssetEvaluationGroupProps
> = ({
  groups,
  setSelectGroupId,
  assetList,
  setAssetList,
  selectGroupId,
  isLoading,
  tableLoading,
  setTableLoading,
  getCertTechAPI,
  pageInfo,
  isModalVisible,
  setIsModalVisible,
  pagination = { current: 1, pageSize: 20, total: 5 },
  setPagination,
  fetch,
}) => {
  const { t } = useTranslation();
  const { projectId } = useContext(ProjectContext);
  const [isEdit, setIsEdit] = useState(false);
  const [evalDate, setEvalDate] = useState<IEvalDateProps[]>([]);
  const [selectDate, setSelectDate] = useState("");
  const [editAssetList, setEditAssetList] = useState<
    IAssetEvaluationDataProps[]
  >([]);

  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const navigate = useNavigate();

  const [form] = Form.useForm();

  function callback(id) {
    setTableLoading(true);
    setSelectGroupId(id);
    setPagination({ current: 1, pageSize: 20, total: 5 });
    setEditAssetList([]);
  }

  useEffect(() => {
    if (selectGroupId !== null) {
      getAssetsEvalDate({ projectId, id: selectGroupId }).then((data) => {
        setEvalDate(data.map((item, index) => ({ ...item, key: index })));
      });
    }
  }, [projectId, selectGroupId, setEvalDate, t]);

  const assetSaveHandler = useCallback(() => {
    setIsSaveLoading(true);
    setAssetEvalution({
      project_id: projectId,
      group_id: selectGroupId,
      datas: editAssetList,
      size: pagination.pageSize || 20,
      page: pagination.current || 1,
    }).then((datas) => {
      const notiText = {
        description: `완료`,
      };
      openNotification(notiText);
      // fetch({ pagination });
      setAssetList(
        datas.content.map((data, index) => ({ ...data, key: index }))
      );
      setIsSaveLoading(false);
      setEditAssetList([]);
    });
  }, [editAssetList, pagination, projectId, selectGroupId, setAssetList]);

  const excelDownload = useCallback(
    (id, name) => {
      getAssetExcelDownload({ projectId, id, date: selectDate }).then(
        (response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${name}_asset_eval.xlsx`);
          document.body.appendChild(link);
          link.click();
        }
      );
    },
    [projectId, selectDate]
  );

  const onFinish = useCallback(
    (result) => {
      setTableLoading(true);
      getCertTechAPI({
        project_id: projectId,
        group_id: selectGroupId,
        keyword: typeof result.keyword !== "undefined" ? result.keyword : "",
        date: typeof result.date !== "undefined" ? result.date : "",
        size: pagination.pageSize || 10,
        page: pagination.current || 1,
      }).then((datas) => {
        const { content, total_elements } = datas;

        setAssetList(content.map((data, index) => ({ ...data, key: index })));
        setTableLoading(false);
        setPagination((prev) => {
          return {
            ...prev,
            total: total_elements,
          };
        });
      });
    },
    [
      getCertTechAPI,
      pagination,
      projectId,
      selectGroupId,
      setAssetList,
      setPagination,
      setTableLoading,
    ]
  );

  const resetHandler = useCallback(() => {
    form.resetFields();
  }, [form]);

  const handleChange = (value) => {
    setSelectDate(value);
  };

  const items = useMemo(() => {
    return groups.map((group) => ({
      label: group.group_name,
      key: `${group.id}`,
      children: (
        <>
          <CertificationSearch>
            <Form
              name="basic"
              onFinish={onFinish}
              autoComplete="off"
              form={form}
            >
              <Space>
                <Label>{t("평가일")}</Label>
                <Form.Item name="date">
                  {evalDate.length > 0 ? (
                    <StyledSelect
                      placeholder={t("평가일")}
                      // defaultValue={evalDate[0].value}
                      style={{ width: 150 }}
                      onChange={handleChange}
                    >
                      {evalDate.map((item) => (
                        <Option value={item.value} key={item.key}>
                          {item.text}
                        </Option>
                      ))}
                    </StyledSelect>
                  ) : (
                    <Loading />
                  )}
                </Form.Item>
                <Label>{t("키워드")}</Label>
                <Form.Item name="keyword">
                  <Input placeholder={t("키워드")} />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    {t("조회")}
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button onClick={resetHandler}>{t("초기화")}</Button>
                </Form.Item>
              </Space>
            </Form>
          </CertificationSearch>
          {pageInfo.perm_write && (
            <ButtonArea>
              <Space>
                <Button
                  type="primary"
                  onClick={assetSaveHandler}
                  disabled={!isEdit}
                  loading={isSaveLoading}
                >
                  {t("자산평가 저장")}
                </Button>
                <DesktopDashboardAssetsEvaluationExcel
                  id={group.id}
                  setAssetList={setAssetList}
                  isModalVisible={isModalVisible}
                  setIsModalVisible={setIsModalVisible}
                />
                <Button
                  icon={<DownloadOutlined />}
                  onClick={() => excelDownload(group.id, group.group_name)}
                  disabled={assetList.length === 0}
                >
                  {t("엑셀 다운로드")}
                </Button>
              </Space>
            </ButtonArea>
          )}
          <DesktopAssetEvaluationTable
            assetList={assetList}
            selectGroupName={group.group_name}
            setAssetList={setAssetList}
            selectGroupId={selectGroupId}
            isLoading={tableLoading}
            setIsEdit={setIsEdit}
            pageInfo={pageInfo}
            pagination={pagination}
            fetch={fetch}
            setEditAssetList={setEditAssetList}
          />
        </>
      ),
    }));
  }, [
    assetList,
    assetSaveHandler,
    evalDate,
    excelDownload,
    fetch,
    form,
    groups,
    isEdit,
    isModalVisible,
    isSaveLoading,
    onFinish,
    pageInfo,
    pagination,
    resetHandler,
    selectGroupId,
    setAssetList,
    setIsModalVisible,
    tableLoading,
    t,
  ]);

  if (isLoading) return <Loading />;

  return (
    <Container>
      {groups.length > 0 ? (
        <Tabs onChange={callback} type="card" items={items} />
      ) : (
        <EmptyContainer>
          <Empty description={t("생성된 데이터가 없습니다.")}>
            <Button onClick={() => navigate("/Admin/AssetsGroup")}>
              {t("자산그룹관리 바로가기")}
            </Button>
          </Empty>
        </EmptyContainer>
      )}
    </Container>
  );
};

export default DesktopAssetEvaluationGroup;
