// import { Button, DatePicker, Pagination, version } from 'antd';

import { IAppContextData, IIndexProps } from '@/common/type';

import { AppConsumer } from '@/common/app-provider';
import DesktopDashBoard from '@/panel/dashboard/desktop-dashboard';
import DesktopLayout from '@/layout/desktop-layout';
import { FormattedMessage } from 'react-intl';
// import Loading from '@/components/loading';
import MobileLayout from '@/layout/mobile-layout';
import React from 'react';

const Index: React.FunctionComponent<IIndexProps> = () => {
  return (
    <AppConsumer>
      {({ config }: IAppContextData) => {
        if (config.isMobile) {
          return (
            <MobileLayout title="">
              <div style={{ height: '3000px' }}>
                언어변경 샘플 : <FormattedMessage id="textsample" />
              </div>
            </MobileLayout>
          );
        }
        return (
          <DesktopLayout>
            <DesktopDashBoard />
            {/* <div>
              언어변경 샘플 : <FormattedMessage id='textsample' />
              <h1>antd version: {version}</h1>
              <DatePicker />
              <Button type="primary" style={{ marginLeft: 8 }}>
                Primary Button11
              </Button>
              <br />
              <Pagination defaultCurrent={1} total={50} showSizeChanger />
              <ul>
                  {data.movies.map(video =>(
                    <li key={video.id}>
                      <span>name : {video.name}</span> | 
                      <span>score : {video.score}</span>
                    </li>
                  ))}
                </ul>
            </div> */}
          </DesktopLayout>
        );
      }}
    </AppConsumer>
  );
};

export default Index;
