import { Button, Descriptions, Form, Input } from 'antd';
import { FieldData, ISystemInfo } from './type';
import React, { useCallback } from 'react';

import { IAdminMenuPermissionProps } from '@/common/type';
import api from '@/api/api';
import { openNotification } from '@/common/util';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopSystemSetupEmailProps {
  systemInfo: ISystemInfo;
  pageInfo: IAdminMenuPermissionProps;
  fields: FieldData[];
}

const setAdminConfig = async ({ data }) => {
  const result = await api.setAdminConfig({ data });

  return result.data;
};

const DesktopSystemSetupEmail: React.FunctionComponent<
  IDesktopSystemSetupEmailProps
> = ({ systemInfo, pageInfo, fields }) => {
  const { t } = useTranslation();
  const onFinish = useCallback(
    (values: any) => {
      if (systemInfo !== null) {
        setAdminConfig({ data: { ...systemInfo, ...values } }).then(() => {
          const notiText = {
            description: t("완료"),
          };

          openNotification(notiText);
        });
      }
    },
    [systemInfo, t]
  );

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Descriptions bordered>
      <Descriptions.Item label={t("메일서버정보")} span={3}>
        <Form
          fields={fields}
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          initialValues={{ remember: false }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label={t("메일발송서버")}
            name="mail_smtp"
            rules={[
              {
                required: true,
                message: t('메일발송서버를 입력해 주세요.'),
              },
            ]}
          >
            <Input readOnly={!pageInfo.perm_write} />
          </Form.Item>
          <Form.Item
            label={t("메일발송 이메일 주소")}
            name="mail_addr"
            rules={[
              {
                required: true,
                message: t('메일발송 이메일 주소를 입력해 주세요.'),
              },
            ]}
          >
            <Input readOnly={!pageInfo.perm_write} />
          </Form.Item>
          <Form.Item
            label={t("메일발송 계정")}
            name="mail_user"
            rules={[
              {
                required: true,
                message: t('메일발송 계정을 입력해 주세요.'),
              },
            ]}
          >
            <Input readOnly={!pageInfo.perm_write} />
          </Form.Item>
          {pageInfo.perm_write && (
            <Form.Item
              label={t("메일발송 계정 패스워드")}
              name="mail_pw"
              rules={[
                {
                  required: true,
                  message: t('메일발송 계정 패스워드를 입력해 주세요.'),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          )}

          <Form.Item
            label={t("메일발송 Port")}
            name="mail_port"
            rules={[
              {
                required: true,
                message: t('메일발송 Port를 입력해 주세요.'),
              },
            ]}
          >
            <Input readOnly={!pageInfo.perm_write} />
          </Form.Item>
          <Form.Item label={t("메일상단내용")} name="mail_header">
            <Input.TextArea
              style={{ height: 120 }}
              readOnly={!pageInfo.perm_write}
            />
          </Form.Item>
          <Form.Item label={t("메일하단내용")} name="mail_footer">
            <Input.TextArea
              style={{ height: 120 }}
              readOnly={!pageInfo.perm_write}
            />
          </Form.Item>

          {pageInfo.perm_write && (
            <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
              <Button type="primary" htmlType="submit">
                {t("저장")}
              </Button>
            </Form.Item>
          )}
        </Form>
      </Descriptions.Item>
    </Descriptions>
  );
};

export default DesktopSystemSetupEmail;
