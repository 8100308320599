import { FieldData, ISystemInfo } from './type';
import React, { useEffect, useState } from 'react';

import DesktopSystemSetupDefault from './desktop-system-setup-default';
import DesktopSystemSetupEmail from './desktop-system-setup-email';
import DesktopSystemSetupUpdate from './desktop-system-setup-update';
import { IAdminMenuPermissionProps } from '@/common/type';
import { Tabs } from 'antd';
import api from '@/api/api';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopSystemSetupProps {
  pageInfo: IAdminMenuPermissionProps;
}

const Container = styled.div``;

const getAdminConfig = async () => {
  const result = await api.getAdminConfig();

  return result.data;
};

const DesktopSystemSetup: React.FunctionComponent<IDesktopSystemSetupProps> = ({
  pageInfo,
}) => {
  const { t } = useTranslation();
  const [systemInfo, setSystemInfo] = useState<ISystemInfo>(null);
  const [fields, setFields] = useState<FieldData[]>([
    { name: ['username'], value: 'Ant Design' },
  ]);

  useEffect(() => {
    getAdminConfig().then(data => {
      setSystemInfo(data);

      for (const [key, value] of Object.entries(data)) {
        setFields(prev => [...prev, { name: [key], value: value }]);
      }
    });
  }, []);

  useEffect(() => {
    if (systemInfo !== null) {
      // setAdminConfig({ data: systemInfo });
    }
  }, [systemInfo]);

  // const dataSource: IDataSource[] = systemInfo && ;

  const onChange = (key: string) => {
    console.log(key);
  };

  const items = [
    {
      label: t('기본설정'),
      key: '1',
      children: (
        <DesktopSystemSetupDefault
          systemInfo={systemInfo}
          setSystemInfo={setSystemInfo}
          fields={fields}
        />
      ),
    },
    {
      label: t('업데이트'),
      key: '2',
      children: (
        <DesktopSystemSetupUpdate
          pageInfo={pageInfo}
          systemInfo={systemInfo}
          setSystemInfo={setSystemInfo}
        />
      ),
    },
    {
      label: t('메일서버정보'),
      key: '3',
      children: (
        <DesktopSystemSetupEmail
          pageInfo={pageInfo}
          systemInfo={systemInfo}
          fields={fields}
        />
      ),
    },
  ];

  return (
    systemInfo !== null && (
      <Container>
        <Tabs onChange={onChange} type="card" items={items} />
      </Container>
    )
  );
};

export default DesktopSystemSetup;
