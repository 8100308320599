// eslint-disable-next-line import/no-anonymous-default-export
export default {
  textsample: 'textsample',
  accountPage: 'Account',
  logout: 'LOGOUT',
  confirm: 'Confirm',
  cancle: 'Cancle',
  logoutMsg: 'Are you sure you want to log out?',
  // 메뉴
  menu0: 'Dashboard',
  menu1: 'Organization management',
  menu1_1: 'Organization management',
  menu1_2: 'Member management',
  menu1_3: 'Organization chart',
  menu2: 'Asset management',
  menu2_1: 'Asset management',
  menu2_2: 'Asset evaluation',
  menu3: 'Proof management',
  menu3_1: 'A list of evidence list',
  menu3_2: 'Proof management',
  menu3_3: 'Statement of operation',
  menu4: 'Policy management',
  menu4_1: 'Information protection policy',
  menu5: 'Diagnosis management',
  menu5_1: 'Diagnosis status',
  menu5_2: 'Diagnosis management',
  menu5_3: 'Technical diagnosis',
  menu6: 'Risk management',
  menu6_1: 'Risk analysis',
  menu6_2: 'Risk assessment',
  menu6_3: 'Risk management level',
  menu6_4: 'Information protection plan',
  menu7: 'Manager',
  menu7_1: 'Asset group management',
  menu7_2: 'Account management',
  menu7_3: 'Project management',
  menu7_4: 'Log management',
  menu7_5: 'Role management',
  menu7_6: 'Category management',
  menu7_7: 'System Settings',
  menu7_8: 'Code management',
};
