import App from './App';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import * as ReactDOMClient from 'react-dom/client';

const container = document.getElementById('root') as HTMLElement;
const root = ReactDOMClient.createRoot(container);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
