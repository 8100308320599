import { Avatar, Button, Empty, Space, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';

import Loading from '@/components/loading';
import { ProjectContext } from '@/common/project-provider';
import { UserOutlined } from '@ant-design/icons';
import api from '@/api/api';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useTranslation } from 'react-i18next';

type memberType = 'company' | 'admin' | 'team';

interface IOrgGroupChartDatas {
    key: string;
    organ_name: string;
    type: memberType;
    organ_member_list?: IOrganMemberList[];
    children: IOrgGroupChartDatas[];
}

interface IOrganMemberList {
    member_name: string;
    member_pos: string;
    member_pic: string;
}

// const Container = styled.div`
//     overflow-x: auto;
//     overflow-y: hidden;
//     padding-bottom: 50px;
// `;
const Container = styled.div``;
const AdminFrame = styled.div`
    position: relative;
    /* padding: 10px 15px; */
    display: inline-block;
    border-radius: 5px;
    font-size: 12px;
    /* background-image: linear-gradient(0deg, #f1f8f2, #ffffff); */
    color: #3f4351;
    /* box-shadow: 0 1px 1px rgb(0 0 0 / 5%), 0 1px 4px rgb(0 0 0 / 5%),
        0 2px 7px rgb(0 0 0 / 10%); */
`;
const TeamFrame = styled(AdminFrame)`
    min-width: 200px;
    margin-bottom: 10px;
    background-color: ${props => props.theme.layout.chartBg};
    color: ${props => props.theme.font.color};
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%), 0 1px 4px rgb(0 0 0 / 5%),
        0 2px 7px rgb(0 0 0 / 10%);
    padding: 10px;
    box-sizing: border-box;
    &.company {
        margin-top: 20px;
    }
`;

const TeamName = styled.h4`
    min-width: 150px;
    font-size: 14px;
    font-weight: 700;
    margin: 0;
`;
const Name = styled.span`
    font-weight: 600;
`;
const MemberList = styled.div`
    padding: 5px 15px;
    border-bottom: 1px solid ${props => props.theme.layout.dashboardBorderColor};
    text-align: left;
    &:last-child {
        border-bottom: none;
    }
`;
const CompanyFrame = styled.div`
    font-size: 30px;
    font-weight: 800;
    background: -webkit-linear-gradient(45deg, #4f73a5, #848484 80%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;
const EmptyContainer = styled.div`
    padding: 130px 0;
`;
const MemberDetail = styled.div``;
const Email = styled.div``;
const Phone = styled.div``;

const getOrgChart = async ({ projectId }) => {
    const result = await api.getOrgChart({ project_id: projectId });

    return result.data;
};

const DesktopOrgChart: React.FunctionComponent = () => {
    const { t } = useTranslation();
    
    const navigate = useNavigate();
    const MemberFrame = ({ data }) => {
        // if (data.type === 'company') {
        //   return <CompanyFrame />;
        // }
        return (
            typeof data.organ_member_list !== 'undefined' && (
                <TeamFrame className={data.type === 'company' ? 'company' : ''}>
                    {data.type !== 'company' && (
                        <TeamName>{data.organ_name}</TeamName>
                    )}
                    {data.organ_member_list.map((member, index) => {
                        return (
                            <Tooltip
                                placement="right"
                                key={member.id}
                                title={
                                    (member.member_hp !== '' ||
                                        member.member_email !== '') && (
                                        <MemberDetail>
                                            {member.member_email !== '' && (
                                                <Email>
                                                    {t("이메일")} :{' '}
                                                    <a
                                                        href={`mailto:${member.member_email}`}
                                                    >
                                                        {member.member_email}
                                                    </a>
                                                </Email>
                                            )}
                                            {member.member_hp !== '' && (
                                                <Phone>
                                                    {t("연락처")} :{' '}
                                                    {member.member_hp}
                                                </Phone>
                                            )}
                                        </MemberDetail>
                                    )
                                }
                            >
                                <MemberList key={index}>
                                    <Space>
                                        <span>
                                            {member.member_pic !== '' &&
                                            member.member_pic !== null ? (
                                                <Avatar
                                                    icon={<UserOutlined />}
                                                    src={
                                                        process.env
                                                            .REACT_APP_URL +
                                                        member.member_pic
                                                    }
                                                />
                                            ) : (
                                                <Avatar
                                                    icon={<UserOutlined />}
                                                />
                                            )}
                                        </span>
                                        <span>{member.member_pos}</span>
                                        <Name>{member.member_name}</Name>
                                    </Space>
                                </MemberList>
                            </Tooltip>
                        );
                    })}
                </TeamFrame>
            )
        );
    };

    const Chart = (datas: IOrgGroupChartDatas) => {
        return (
            datas.children.length > -1 &&
            datas.children.map(group => {
                return (
                    <React.Fragment key={group.key}>
                        <TreeNode label={<MemberFrame data={group} />}>
                            {Chart(group)}
                        </TreeNode>
                    </React.Fragment>
                );
            })
        );
    };

    const { projectId } = useContext(ProjectContext);
    const [chartData, setChartData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getOrgChart({ projectId }).then(data => {
            setIsLoading(false);
            setChartData(data);
        });
    }, [projectId]);

    if (isLoading) return <Loading />;

    return chartData.length > 0 ? (
        <Container>
            <TransformWrapper
                initialScale={1}
                minScale={0.3}
                centerOnInit={true}
                wheel={{ disabled: true }}
                doubleClick={{ disabled: true }}
            >
                {({ zoomIn, zoomOut, resetTransform }) => (
                    <React.Fragment>
                        <Space>
                            <Button onClick={() => zoomIn()}>{t('확대')}</Button>
                            <Button onClick={() => zoomOut()}>{t('축소')}</Button>
                            <Button onClick={() => resetTransform()}>
                            {t('초기화')}
                            </Button>
                        </Space>
                        <TransformComponent
                            wrapperStyle={{
                                width: '100%',
                                height: '100%',
                                cursor: 'grab',
                            }}
                        >
                            <Tree
                                lineColor={'#4F73A5'}
                                lineWidth={'1px'}
                                lineBorderRadius={'30px'}
                                label={
                                    <>
                                        <CompanyFrame>
                                            {chartData[0].organ_name}
                                        </CompanyFrame>
                                        <MemberFrame data={chartData[0]} />
                                    </>
                                }
                            >
                                {Chart(chartData[0])}
                            </Tree>
                        </TransformComponent>
                    </React.Fragment>
                )}
            </TransformWrapper>
        </Container>
    ) : (
        <EmptyContainer>
            <Empty description={t('생성된 데이터가 없습니다.')}>
                <Button onClick={() => navigate('/Org/Manager')}>
                    조직 관리 바로가기
                </Button>
            </Empty>
        </EmptyContainer>
    );
};

export default DesktopOrgChart;
