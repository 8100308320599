import React, { useState } from 'react';

import { Layout } from 'antd';
import styled from 'styled-components';
import { defaultColor } from '@/styles/color';

const { Footer } = Layout;

const StyledFooter = styled(Footer)`
  width: 100%;
  z-index: 1;
  background: transparent;
  transition: all 0.2s;
  padding: 10px 0;
  color: ${defaultColor.darkGray};
  font-weight: 300;
  font-size: 12px;
  background-color: ${props => props.theme.layout.contentBg}!important;
`;

const DesktopFooter: React.FunctionComponent = () => {
  const [homeFooter] = useState(
    JSON.parse(sessionStorage.getItem('systemInfo')).home_footer
  );
  return (
    <StyledFooter style={{ textAlign: 'center' }}>{homeFooter}</StyledFooter>
  );
};

export default DesktopFooter;
