import { Button, Form, Input, Select, Typography, message } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import IMask from 'imask';
import { MaskedInput } from 'antd-mask-input';
// import { ICodeData } from '@/panel/Org/type';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import defaultInfo from '@/common/site-info';
import { openNotification } from '@/common/util';
import styled from 'styled-components';
import { defaultColor } from '@/styles/color';
import Modal from '@/components/modal';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const { Option } = Select;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopLoginSingupProps {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const SignupArea = styled.div`
  border: 1px solid ${defaultColor.lightGray};
  padding: 15px 25px 0 25px;
  margin-bottom: 10px;
  border-radius: 5px;
  background: ${defaultColor.white};
  .ant-form-item-explain-connected {
    height: auto;
    min-height: auto;
    opacity: 1;
  }
  /* Change the white to any color */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`;

const Title = styled.h3`
  padding-bottom: 10px;
  font-size: 16px;
`;
const SubmitArea = styled.div`
  text-align: right;
  padding-top: 10px;
`;
const IDArea = styled.div`
  padding-right: 85px;
  position: relative;
  button {
    position: absolute;
    right: 0;
    top: 0;
  }
`;
const FormContainer = styled.div`
  position: relative;
`;
const TextGuide = styled.p`
  position: absolute;
  right: 0px;
  top: 34px;
  font-size: 12px;
  color: #949494;
  margin: 0;
  padding: 0;
`;

const StyledMaskedInput = styled(MaskedInput)`
  border-radius: 3px;
  &:focus,
  &:active {
    border: 1px solid #6887ad !important;
    box-shadow: 0 0 0 2px rgb(11 55 83 / 18%);
    border-inline-end-width: 1px;
    outline: 0;
  }
  &:hover {
    border: 1px solid #6887ad !important;
    border-inline-end-width: 1px;
  }
`;

const getCodes = async ({ projectId, id }) => {
  const result = await api.getCodes({ project_id: projectId, id });

  return result.data;
};

const setRegist = async datas => {
  const result = await api.setRegist(datas);

  return result.data;
};

const setIDCheck = async username => {
  const result = await api.setIDCheck({ username });

  return result.data;
};

const DesktopLoginSingup: React.FunctionComponent<IDesktopLoginSingupProps> = ({
  isModalVisible,
  setIsModalVisible,
}) => {
  const { t } = useTranslation();

  const { projectId, setUserInfo } = useContext(ProjectContext);
  const [organList, setOrganList] = useState([]);
  const [codeList, setCodeList] = useState<any>([]);
  // const [setIsDuplId] = useState<boolean | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputRef = React.useRef<any>(null);

  const [form] = Form.useForm();

  const dpCode = () => {
    return getCodes({ projectId, id: 'DP' });
  };

  const rkCode = () => {
    return getCodes({ projectId, id: 'RK' });
  };

  // const asCode = () => {
  //   return getCodes({ projectId, id: 'AS' });
  // };

  useEffect(() => {
    Promise.all([dpCode(), rkCode()]).then(datas => {
      setUserInfo(prev => ({
        ...prev,
        codes: { dp: datas[0], rk: datas[1] },
      }));
      setCodeList(datas[0]);
      setOrganList(datas[1]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = (values: any) => {
    // console.log('Success:', values);

    setRegist({ ...values, company_id: defaultInfo.company_id }).then(() => {
      openNotification({
        message: t('사용자 등록 성공'),
        description: t('관리자 승인 후 로그인 가능합니다.'),
      });
      setIsModalVisible(false);
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const idDuplCheck = () => {
    const username = form.getFieldValue('username');

    if (typeof username !== 'undefined' && username !== '') {
      setIDCheck(username).then(data => {
        message.info(data.msg);

        if (data.result === false) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          inputRef.current!.focus({
            cursor: 'end',
          });
        }
      });
    }
  };

  // const validatePassword = (rule, value, callback) => {
  //   console.log(rule, value, callback);

  //   // if (value && value !== "Secret") {
  //   //   callback("Error!");
  //   // } else {
  //   //   callback();
  //   // }
  // };

  return (
    <Modal
      title={t("사용자등록")}
      open={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        name="basic"
        layout="horizontal"
        labelCol={{ span: 6 }}
        // wrapperCol={{ span: 16 }}
        initialValues={{ remember: false }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
      >
        <SignupArea>
          <Title>{t("로그인 정보")}</Title>
          <Form.Item
            label={t("아이디")}
            name="username"
            rules={[{ required: true, message: t("아이디msg") }]}
          >
            <IDArea>
              <Input ref={inputRef} />
              <Button
                onClick={() => {
                  idDuplCheck();
                }}
              >
                {t("중복확인")}
              </Button>
            </IDArea>
          </Form.Item>

          <Form.Item
            label={t("패스워드")}
            name="password"
            rules={[
              { required: true, message: t('패스워드msg') },
              { min: 8, message: '패스워드는 최소 8자 이상이어야 합니다.' },
              ({ getFieldValue }) => {
                return {
                  validator(_, value) {
                    var num = value.search(/[0-9]/g);
                    var eng = value.search(/[a-z]/gi);
                    var spe = value.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi);

                    if (getFieldValue('username') === value) {
                      return Promise.reject(
                        new Error(t('아이디를 패스워드로 사용하실 수 없습니다.'))
                      );
                    }
                    if (value.search(/\s/) !== -1) {
                      return Promise.reject(
                        new Error(t('패스워드는 공백 없이 입력해주세요.'))
                      );
                    }
                    if (num < 0 || eng < 0 || spe < 0) {
                      return Promise.reject(
                        new Error(
                          t('영문, 숫자, 특수문자를 혼합하여 입력해주세요.')
                        )
                      );
                    }
                    return Promise.resolve();
                  },
                };
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirm"
            label={t('패스워드확인')}
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: t('패스워드를 확인해주세요.'),
              },
              ({ getFieldValue }) => {
                return {
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t('패스워드가 일치하지 않습니다.'))
                    );
                  },
                };
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
        </SignupArea>
        <SignupArea>
          <Title>{t("사용자 정보")}</Title>
          <Form.Item
            label={t("이름")}
            name="fullname"
            rules={[{ required: true, message: t('이름을 입력해주세요.') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("이메일")}
            name="email"
            rules={[
              {
                type: t('email'),
                required: true,
                message: t('이메일을 입력해주세요.'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <FormContainer>
            <Form.Item
              label={t("연락처")}
              name="userhp"
              rules={[{ required: true, message: t('연락처를 입력해주세요.') }]}
            >
              <StyledMaskedInput
                mask={[
                  {
                    mask: '000-0000-0000',
                    blocks: {
                      RGB: {
                        mask: IMask.MaskedRange,
                        from: 0,
                        to: 9999,
                      },
                    },
                  },
                ]}
              />
            </Form.Item>
            <TextGuide>{t("숫자만 입력해주세요.")}</TextGuide>
          </FormContainer>
          <Form.Item
            label={t("부서")}
            name="dept_cd"
            rules={[{ required: false, message: t('부서를 선택해주세요.') }]}
          >
            <Select
              placeholder="부서"
              //   onChange={onGenderChange}
              allowClear
            >
              {codeList.length > 0 &&
                codeList.map(item => (
                  <Option key={item.item_cd} value={item.item_cd}>
                    {item.item_name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={t("직급")}
            name="rank_cd"
            rules={[{ required: false, message: t('직급을 선택해주세요.') }]}
          >
            <Select
              placeholder={t("직급")}
              //   onChange={onGenderChange}
              allowClear
            >
              {organList.length > 0 &&
                organList.map(item => (
                  <Option key={item.item_cd} value={item.item_cd}>
                    {item.item_name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </SignupArea>
        <Text>
          <ExclamationCircleOutlined />
          {` `} {t("사용자 등록 후 담당자에게 승인을 받아야 이용이 가능합니다.")}
        </Text>
        <SubmitArea>
          <Form.Item>
            <Button type="primary" htmlType="submit">
            {t("사용자등록")}
            </Button>
          </Form.Item>
        </SubmitArea>
      </Form>
    </Modal>
  );
};

export default DesktopLoginSingup;
