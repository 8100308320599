import React, { useContext, useState } from 'react';

import DesktopAdminAccountSearch from './desktop-admin-account-search';
import DesktopAdminAccountTable from './desktop-admin-account-table';
import { IAccountDatas } from './type';
import { IAdminMenuPermissionProps } from '@/common/type';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopAdminAccountProps {
  pageInfo: IAdminMenuPermissionProps;
}

const getUsers = async ({ projectId, datas }) => {
  const result = await api.getUsers({ project_id: projectId, datas });

  return result.data;
};

const DesktopAdminAccount: React.FunctionComponent<
  IDesktopAdminAccountProps
> = ({ pageInfo }) => {
  const { userInfo } = useContext(ProjectContext);
  const [userList, setUserList] = useState<IAccountDatas[]>(null);
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      <DesktopAdminAccountSearch
        setUserList={setUserList}
        getUsersAPI={getUsers}
      />
      <DesktopAdminAccountTable
        userList={userList}
        setUserList={setUserList}
        getUsersAPI={getUsers}
        organList={userInfo.codes?.dp || []}
        codeList={userInfo.codes?.rk || []}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        pageInfo={pageInfo}
      />
    </>
  );
};

export default DesktopAdminAccount;
