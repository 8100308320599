import { Button, Table, Tag } from 'antd';
import {
    FormOutlined,
    UploadOutlined,
    SnippetsOutlined,
} from '@ant-design/icons';
import {
    IContentProps,
    IDiagnosisTechnicalColumnsProps,
    IDiagnosisTechnicalDataProps,
} from './type';

import { IAdminMenuPermissionProps } from '@/common/type';
import React from 'react';
import styled from 'styled-components';
import { IPaginationProps } from '@/panel/Assets/Evaluation/type';
import defaultInfo from '@/common/site-info';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopDiagnosisTechnicalTableProps {
    assetList: IDiagnosisTechnicalDataProps;
    setAssetList: React.Dispatch<
        React.SetStateAction<IDiagnosisTechnicalDataProps>
    >;
    selectGroupName: string;
    selectGroupId: number;
    pageInfo: IAdminMenuPermissionProps;
    isTableLoading: boolean;
    isModalVisible: boolean;
    setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectRecord: React.Dispatch<React.SetStateAction<IContentProps>>;
    setSelectAssetData: React.Dispatch<React.SetStateAction<IContentProps>>;
    selectRecord: IContentProps;
    setSelectsRecord: React.Dispatch<React.SetStateAction<IContentProps[]>>;
    setIsUploadModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setIsBundle: React.Dispatch<React.SetStateAction<boolean>>;
    selectedRowKeys: React.Key[];
    setSelectedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>;
    pagination: IPaginationProps;
    fetch: (
        pagination: IPaginationProps,
        keyword: string,
        cb: () => void
    ) => void;
    setIsTableLoading: React.Dispatch<React.SetStateAction<boolean>>;
    searchKeyword: string;
}

const Container = styled.div`
    padding: 0;
`;

const DesktopDiagnosisTechnicalTable: React.FunctionComponent<
    IDesktopDiagnosisTechnicalTableProps
> = ({
    assetList,
    pageInfo,
    isTableLoading,
    setIsModalVisible,
    setSelectRecord,
    setSelectAssetData,
    setSelectsRecord,
    setIsUploadModalVisible,
    setIsBundle,
    selectedRowKeys,
    setSelectedRowKeys,
    pagination,
    fetch,
    setIsTableLoading,
    searchKeyword,
}) => {
    const { t } = useTranslation();
    const rowSelection = {
        onChange: (newSelectedRowKeys: React.Key[], selectedRows: any) => {
            console.log(
                `selectedRowKeys: ${newSelectedRowKeys}`,
                'selectedRows: ',
                selectedRows.sort((a, b) => a.id - b.id)
            );
            setSelectedRowKeys(newSelectedRowKeys);
            setSelectsRecord(selectedRows.sort((a, b) => a.id - b.id));
            setSelectAssetData(selectedRows.length > 0 ? selectedRows[0] : {});
        },
        getCheckboxProps: record => ({
            disabled: !pageInfo.perm_write, // Column configuration not to be checked
            name: record.name,
        }),
        selectedRowKeys,
    };

    const columns: IDiagnosisTechnicalColumnsProps[] = [
        {
            title: t('구분'),
            dataIndex: 'group',
            key: 'group',
            align: 'center',
        },
        {
            title: t('자산코드'),
            dataIndex: 'asset_code',
            key: 'asset_code',
            align: 'center',
        },
        {
            title: t('자산명'),
            dataIndex: 'asset_name',
            key: 'asset_name',
            align: 'center',
        },
        {
            title: 'IP',
            dataIndex: 'ip',
            key: 'ip',
            align: 'center',
        },
        {
            title: 'OS/Version',
            dataIndex: 'os',
            key: 'os',
            align: 'center',
        },
        {
            title: t('자산등급'),
            dataIndex: 'asset_grade',
            key: 'asset_grade',
            align: 'center',
        },
        {
            title: t('보안수준'),
            dataIndex: 'secure_level',
            key: 'secure_level',
            align: 'center',
        },
        {
            title: t('진단일'),
            dataIndex: 'check_date',
            key: 'check_date',
            align: 'center',
        },
        {
            title: t('상태'),
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            fixed: 'right',
            width: '100px',
            render: text => {
                return (
                    <Tag
                        color={
                            text === '진단중'
                                ? 'red'
                                : text === '진단완료'
                                ? 'blue'
                                : 'default'
                        }
                    >
                        {t(text)}
                    </Tag>
                );
            },
        },
    ];

    const columns_btn: IDiagnosisTechnicalColumnsProps[] = [
        {
            title: t('진단결과 업로드'),
            dataIndex: 'upload',
            key: 'upload',
            align: 'center',
            width: '120px',
            fixed: 'right',
            // eslint-disable-next-line react/display-name
            render: (_, record) => {
                const isComplete = record.status === t('진단완료');

                return (
                    <Button
                        icon={<UploadOutlined />}
                        onClick={() => {
                            setIsBundle(false);
                            setSelectRecord(record);
                            setIsUploadModalVisible(true);
                        }}
                        disabled={isComplete}
                    >
                        {t("업로드")}
                    </Button>
                );
            },
        },
        {
            title: t('수동진단'),
            dataIndex: 'manual',
            key: 'manual',
            align: 'center',
            width: '130px',
            fixed: 'right',
            // eslint-disable-next-line react/display-name
            render: (_, record) => {
                return (
                    <Button
                        onClick={() => {
                            setIsBundle(false);
                            setSelectAssetData(record);
                            setIsModalVisible(true);
                        }}
                        icon={
                            record.status !== t('진단완료') ? (
                                <FormOutlined />
                            ) : (
                                <SnippetsOutlined />
                            )
                        }
                    >
                        {record.status !== t('진단완료')
                            ? t('수동진단')
                            : t('진단조회')}
                    </Button>
                );
            },
        },
    ];

    const handleTableChange = page => {
        setIsTableLoading(true);
        window.scrollTo(0, 0);
        fetch(page, searchKeyword, () => {
            setSelectsRecord([]);
            setSelectedRowKeys([]);
        });
    };

    return (
        <Container>
            <Table
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                }}
                columns={
                    pageInfo.perm_write
                        ? [...columns, ...columns_btn]
                        : [...columns]
                }
                dataSource={
                    assetList?.content.length > 0 ? assetList?.content : []
                }
                pagination={pagination}
                size={defaultInfo.table_size}
                loading={isTableLoading}
                onChange={handleTableChange}
                scroll={{ x: '1500px' }}
            />
        </Container>
    );
};

export default DesktopDiagnosisTechnicalTable;
