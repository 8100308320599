import { Button, Col, Input, Row } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import { ICodeData } from '../type';
import React from 'react';
import styled from 'styled-components';

interface IDesktopAdminCodeInput {
  targetItem: ICodeData;
  isEditMode: boolean;
  item: ICodeData;
  createNodeKey: (targetItem: ICodeData) => string;
  insertNodeHandler: (targetItem: ICodeData, flag: boolean) => void;
  removeNodeHandler: () => void;
}

const AddButton = styled.div`
  margin-left: 5px;
  button {
    font-size: 12px;
    display: inline-block;
  }
`;

const CancleButton = styled(AddButton)``;

const DesktopAdminCodeInput: React.FunctionComponent<IDesktopAdminCodeInput> =
  ({
    targetItem,
    isEditMode,
    item,
    createNodeKey,
    insertNodeHandler,
    removeNodeHandler
  }) => {
    if (isEditMode) {
      item.item_name = targetItem.item_name;
    }

    return (
      <Row gutter={8} key={Math.random()}>
        <Col span={3.5}>
          <Input
            size='small'
            placeholder='코드명 입력'
            name='item_name'
            defaultValue={item.item_name}
            // eslint-disable-next-line react/no-string-refs
            onChange={event => {
              const {
                target: { name, value }
              } = event;

              item = {
                ...targetItem,
                [name]: value,
                parent_id: targetItem.item_cd,
                key: createNodeKey(targetItem)
              };
            }}
            onKeyPress={event => {
              if (targetItem.item_name === item.item_name) return;
              if (event.key === 'Enter') {
                insertNodeHandler(item, isEditMode);
              }
            }}
            onKeyUp={event => {
              if (event.keyCode === 27) {
                removeNodeHandler();
              }
            }}
          />
        </Col>
        <AddButton>
          <Button
            type='text'
            icon={<CheckOutlined />}
            size='small'
            onClick={() => {
              if (targetItem.item_name === item.item_name) {
                removeNodeHandler();
                return;
              }
              insertNodeHandler(item, isEditMode);
            }}
          />
        </AddButton>
        <CancleButton>
          <Button
            type='text'
            icon={<CloseOutlined />}
            size='small'
            onClick={removeNodeHandler}
          />
        </CancleButton>
      </Row>
    );
  };

export default DesktopAdminCodeInput;
