import { Button, Select, Space, Table, Input } from 'antd';
import {
  IDiagnosisManagementColumnsProps,
  IDiagnosisManagementDataProps,
} from './type';
import React, { useCallback, useContext, useMemo, useState } from 'react';

import { DownloadOutlined } from '@ant-design/icons';
import { IAdminMenuPermissionProps } from '@/common/type';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import styled from 'styled-components';
import defaultInfo from '@/common/site-info';
import { useTranslation } from 'react-i18next';
import { nl2br } from 'react-js-nl2br';

const { Option } = Select;
const { TextArea } = Input;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopDiagnosisManagementTableProps {
  isLoading: boolean;
  dataSource: IDiagnosisManagementDataProps[];

  setDataAPI: any;
  setCompleteAPI: any;
  loading: boolean[];
  setLoading: React.Dispatch<React.SetStateAction<boolean[]>>;
  setEditCertScoreData: React.Dispatch<
    React.SetStateAction<IDiagnosisManagementDataProps[]>
  >;
  dataSync: (data) => void;
  isCompleteButton: boolean;
  getData: any;
  diaDateValue: string;
  categoryId: number;
  pageInfo: IAdminMenuPermissionProps;
  setIsCompleteButton: React.Dispatch<React.SetStateAction<boolean>>;
  isEditMode: boolean;
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ITextProps {
  align?: string;
}

const Container = styled.div`
  clear: both;
  text-align: right;
  margin-bottom: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  .ant-table-cell {
    vertical-align: top;
  }
`;

const Text = styled.p<ITextProps>`
  ${props =>
    props.align === 'center' ? `text-align: center;` : `text-align: left;`}
  padding: 2px 0;
`;

const StyledTextArea = styled(TextArea)`
  width: 100%;
  vertical-align: top;
  font-size: 12px;
`;

const StyledSelect = styled(Select)`
  vertical-align: top;
  font-size: 12px;
`;

const TableArea = styled.div`
  padding-top: 20px;
`;

const ButtonArea = styled.div`
  position: sticky;
  top: 70px;
  text-align: right;
  z-index: 6;
  display: inline-block;
  width: 100%;
`;

const getCertScoreDownload = async ({
  projectId,
  categoryId,
  diaDateValue,
}) => {
  const result = await api.getCertScoreDownload({
    project_id: projectId,
    category_id: categoryId,
    date: diaDateValue,
  });

  return result;
};

const DesktopDiagnosisManagementTable: React.FunctionComponent<
  IDesktopDiagnosisManagementTableProps
> = ({
  isLoading,
  dataSource,
  setDataAPI,
  setCompleteAPI,
  loading,
  setLoading,
  setEditCertScoreData,
  dataSync,
  isCompleteButton,
  getData,
  diaDateValue,
  categoryId,
  pageInfo,
  setIsCompleteButton,
  isEditMode,
  setIsEditMode,
}) => {
  const { t } = useTranslation();
  const { projectId } = useContext(ProjectContext);
  const [saveLoading, setSaveLoading] = useState(false);

  const handleChange = useCallback(
    (value, record) => {
      const data = { ...record, score: Number(value) };

      setEditCertScoreData(prev => [
        ...prev.filter(item => item.check_id !== data.check_id),
        data,
      ]);
      dataSync([data]);
    },
    [dataSync, setEditCertScoreData]
  );

  const onBlur = useCallback(
    (event, record) => {
      const value = event.target.value;
      const data = { ...record, status: value };

      setEditCertScoreData(prev => [
        ...prev.filter(item => item.check_id !== data.check_id),
        data,
      ]);
      dataSync([data]);
    },
    [dataSync, setEditCertScoreData]
  );

  const isSaveCheck = useMemo(
    () => dataSource.filter(data => data.id !== null).length > 0,
    [dataSource]
  );

  const columns: IDiagnosisManagementColumnsProps[] = [
    {
      title: t('통제번호'),
      dataIndex: 'category_num',
      key: 'category_num',
      align: 'center',
      width: '6%',
    },
    {
      title: t('구분'),
      dataIndex: 'category_name',
      key: 'category_name',
      align: 'center',
      width: '14%',
    },
    {
      title: t('점검항목'),
      dataIndex: 'check_desc',
      key: 'check_desc',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: text => <Text align="left">{nl2br(text)}</Text>,
    },
    {
      title: t('현황'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: '32%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) =>
        pageInfo.perm_write && (isCompleteButton || isEditMode) ? (
          <StyledTextArea
            defaultValue={text}
            rows={3}
            key={index}
            onBlur={event => {
              onBlur(event, record);
            }}
          />
        ) : (
          <Text>{text}</Text>
        ),
    },
    {
      title: t('점수'),
      key: 'score',
      dataIndex: 'score',
      width: 130,
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: (score, record, index) => {
        return pageInfo.perm_write && (isCompleteButton || isEditMode) ? (
          <StyledSelect
            defaultValue={score}
            style={{ width: 80 }}
            key={index}
            onChange={value => {
              handleChange(value, record);
            }}
          >
            <Option value="0">0</Option>
            <Option value="1">1</Option>
            <Option value="2">2</Option>
            <Option value="3">3</Option>
            <Option value="4">4</Option>
            <Option value="5">5</Option>
            <Option value="N/A">N/A</Option>
          </StyledSelect>
        ) : (
          score
        );
      },
    },
  ];

  const download = () => {
    getCertScoreDownload({ projectId, categoryId, diaDateValue }).then(
      response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        let categoryText = '';

        switch (categoryId) {
          case null:
            categoryText = t('전체');
            break;
          case 1:
            categoryText = t('관리체계 수립 및 운영');
            break;
          case 2:
            categoryText = t('보호대책 요구사항');
            break;
          case 3:
            categoryText = t('개인정보 처리단계별 요구 사항');
            break;
          default:
            categoryText = t('전체');
            break;
        }

        link.href = url;
        link.setAttribute(
          'download',
          `check_result_${categoryText}_${
            diaDateValue === '' ? t('진단중') : diaDateValue
          }.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      }
    );
  };

  return (
    <Container>
      {pageInfo.perm_write && (
        <ButtonArea>
          <Space>
            {isCompleteButton ? (
              <>
                <Button
                  type="primary"
                  onClick={() => {
                    setSaveLoading(true);
                    setTimeout(() => {
                      setDataAPI(() => {
                        setSaveLoading(false);
                        setLoading([false]);
                        setEditCertScoreData([]);
                        getData();
                      });
                    }, 1000);
                  }}
                  loading={saveLoading}
                >
                  {t("진단저장")}
                </Button>
                {!isEditMode && (
                  <Button
                    disabled={!isSaveCheck}
                    loading={loading[0]}
                    onClick={() => {
                      if (window.confirm(t('진단 완료 처리 하시겠습니까?'))) {
                        setLoading([true]);
                        setCompleteAPI();
                        setEditCertScoreData([]);
                      }
                    }}
                  >
                    {loading[0] ? t('저장중') : t('진단완료')}
                  </Button>
                )}
              </>
            ) : (
              <Button
                onClick={() => {
                  setIsCompleteButton(true);
                  setIsEditMode(true);
                }}
              >
                {t("수정")}
              </Button>
            )}
            <Button disabled>{t("평가기준")}</Button>
            <Button 
              disabled={dataSource.length===0} 
              icon={<DownloadOutlined />} 
              onClick={download}>
              {t("결과 다운로드")}
            </Button>
          </Space>
        </ButtonArea>
      )}
      <TableArea>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          loading={isLoading}
          size={defaultInfo.table_size}
        />
      </TableArea>
    </Container>
  );
};

export default DesktopDiagnosisManagementTable;
