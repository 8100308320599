import { Input, Select, Table } from 'antd';
import { IAssetEvaluationDataProps } from './type';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import defaultInfo from '@/common/site-info';
import Modal from '@/components/modal';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const { TextArea } = Input;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopAssetEvaluationTableModalProps {
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isModalVisible: boolean;
  selectAssetEvalData: IAssetEvaluationDataProps;
  selectGroupName: string;
  setAssetList: React.Dispatch<
    React.SetStateAction<IAssetEvaluationDataProps[]>
  >;
}

interface IColumnsProps {
  title: string;
  dataIndex: string;
  sorter?: boolean;
  key: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render?: (text: any, record: IAssetEvaluationDataProps) => JSX.Element;
  width: string;
  align: 'left' | 'center' | 'right';
}

const TableContainer = styled.div`
  .ant-table-cell {
    vertical-align: top;
  }
`;
const StyledTextArea = styled(TextArea)``;

const DesktopAssetEvaluationTableModal: React.FunctionComponent<
  IDesktopAssetEvaluationTableModalProps
> = ({ setIsModalVisible, isModalVisible, selectAssetEvalData }) => {
  
  const { t } = useTranslation();
  const [manualData, setManualData] = useState([]);

  useEffect(() => {}, [isModalVisible]);

  useEffect(() => {
    if (selectAssetEvalData !== null) {
    }
  }, [selectAssetEvalData]);

  const formHandleChange = useCallback(
    (value, record, target) => {
      const datas = manualData.map(data => {
        if (data.check_code === record.check_code) {
          return { ...data, [target]: value };
        }
        return data;
      });

      setManualData(datas);
    },
    [manualData]
  );

  const columns: IColumnsProps[] = [
    {
      title: t('자산코드'),
      dataIndex: 'asset_code',
      key: 'asset_code',
      align: 'center',
      width: '100px',
    },
    {
      title: t('상태'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: '100px',
    },
    {
      title: t('자산명'),
      dataIndex: 'asset_name',
      key: 'asset_name',
      align: 'center',
      width: '150px',
    },
    {
      title: t('기밀성'),
      dataIndex: 'confident_score',
      key: 'confident_score',
      align: 'center',
      width: '150px',
      render: (text, record) => {
        return (
          <Select
            defaultValue=""
            value={text}
            style={{ width: 120 }}
            onChange={value => {
              formHandleChange(value, record, 'confident_score');
            }}
          >
            <Option value="1">1</Option>
            <Option value="2">2</Option>
            <Option value="3">3</Option>
          </Select>
        );
      },
    },
    {
      title: t('무결성'),
      dataIndex: 'integrity_core',
      key: 'integrity_core',
      align: 'center',
      width: '150px',
      render: (text, record) => {
        return (
          <Select
            defaultValue=""
            value={text}
            style={{ width: 120 }}
            onChange={value => {
              formHandleChange(value, record, 'integrity_score');
            }}
          >
            <Option value="1">1</Option>
            <Option value="2">2</Option>
            <Option value="3">3</Option>
          </Select>
        );
      },
    },
    {
      title: t('가용성'),
      dataIndex: 'avail_score',
      key: 'avail_score',
      align: 'center',
      width: '150px',
      render: (text, record) => {
        return (
          <Select
            defaultValue=""
            value={text}
            style={{ width: 120 }}
            onChange={value => {
              formHandleChange(value, record, 'avail_score');
            }}
          >
            <Option value="1">1</Option>
            <Option value="2">2</Option>
            <Option value="3">3</Option>
          </Select>
        );
      },
    },
    {
      title: t('합계'),
      dataIndex: 'sum_score',
      key: 'sum_score',
      align: 'center',
      width: '150px',
    },
    {
      title: t('중요도'),
      dataIndex: 'weight',
      key: 'weight',
      align: 'center',
      width: '70px',
      render: (text, record) => {
        return (
          <Select
            defaultValue=""
            value={text}
            style={{ width: 120 }}
            onChange={value => {
              formHandleChange(value, record, 'sum_score');
            }}
          >
            <Option value="1">1{t('등급')}</Option>
            <Option value="2">2{t('등급')}</Option>
            <Option value="3">3{t('등급')}</Option>
          </Select>
        );
      },
    },
    {
      title: t('비고'),
      dataIndex: 'remark',
      key: 'remark',
      align: 'center',
      width: '250px',
      // eslint-disable-next-line react/display-name
      render: (text, record) => {
        return (
          <StyledTextArea
            allowClear
            style={{ height: 80 }}
            defaultValue={text}
            onBlur={event => {
              formHandleChange(event.target.value, record, 'remark');
            }}
          />
        );
      },
    },
  ];

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal
      width="100%"
      title={t("자산평가")}
      open={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <TableContainer>
        <Table
          columns={columns}
          dataSource={manualData}
          pagination={false}
          size={defaultInfo.table_size}
        />
      </TableContainer>
    </Modal>
  );
};

export default DesktopAssetEvaluationTableModal;
