import { Button, DatePicker, Form, InputNumber } from 'antd';
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import api from '@/api/api';
import { ProjectContext } from '@/common/project-provider';
import dayjs from 'dayjs';
import { RangePickerProps } from 'antd/lib/date-picker';
import Modal from '@/components/modal';
import { IDataSourceProps } from './type';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface IDesktopDangersRiskLevelSetupModalProps {
    isSetupModalVisible: boolean;
    setIsSetupModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    dataSource: IDataSourceProps[];
}

const StyledFormItem = styled(Form.Item)`
    text-align: right;
    margin: 0;
`;

const setRiskLevel = async ({ projectId, datas }) => {
    const result = await api.setRiskLevel({ project_id: projectId, datas });

    return result.data;
};

const DesktopDangersRiskLevelSetupModal: React.FunctionComponent<
    IDesktopDangersRiskLevelSetupModalProps
> = ({ isSetupModalVisible, setIsSetupModalVisible, dataSource = [] }) => {
    const { t } = useTranslation();
    const { projectId } = useContext(ProjectContext);

    const [editLevelDatas, setEditLevelDatas] = useState({
        id: null,
        level: dataSource[0]?.level,
        level_ym: dataSource[0]?.level_ym,
    });

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setEditLevelDatas({
            id: null,
            level: dataSource[0]?.level,
            level_ym: dataSource[0]?.level_ym,
        });
    }, [dataSource]);

    const onFinish = useCallback(() => {
        setLoading(true);
        if (editLevelDatas !== null) {
            setRiskLevel({
                projectId,
                datas: {
                    ...dataSource[0],
                    level:
                        typeof editLevelDatas.level !== 'undefined'
                            ? editLevelDatas.level
                            : dataSource[0]?.level,
                    level_ym:
                        typeof editLevelDatas.level_ym !== 'undefined'
                            ? dayjs(editLevelDatas.level_ym).format('YYYY-MM')
                            : dayjs(dataSource[0].level_ym).format('YYYY-MM'),
                },
            }).then(() => {
                setIsSetupModalVisible(false);
                setLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editLevelDatas, projectId, dataSource, setIsSetupModalVisible]);

    const handleCancel = () => {
        setIsSetupModalVisible(false);
    };

    const formDatas = useMemo(() => {
        if (dataSource.length > 0) {
            return [
                { name: ['level'], value: dataSource[0].level || 3 },
                { name: ['level_ym'], value: dayjs(dataSource[0].level_ym) },
                { name: ['id'], value: dataSource[0].id },
                {
                    name: ['modified_date'],
                    value: dayjs(dataSource[0].modified_date),
                },
                {
                    name: ['created_date'],
                    value: dayjs(dataSource[0].created_date),
                },
            ];
        }
    }, [dataSource]);

    const onChange = (value, key) => {
        setEditLevelDatas(prev => {
            return { ...prev, [key]: value };
        });
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const disabledDate: RangePickerProps['disabledDate'] = current => {
        return (
            current &&
            current <
                dayjs(
                    dataSource.length > 0
                        ? dataSource[0].level_ym
                        : dayjs(current).month()
                )
                    .add(1, 'month')
                    .endOf('day')
        );
    };

    return (
        <Modal
            title={t("위험관리수준 설정")}
            open={isSetupModalVisible}
            footer={null}
            width={300}
            onCancel={handleCancel}
        >
            <Form
                name="basic"
                // labelCol={{ span: 8 }}
                // wrapperCol={{ span: 16 }}
                autoComplete="off"
                layout="vertical"
                fields={formDatas}
                onFinish={onFinish}
            >
                <Form.Item
                    label={t("위험관리수준")}
                    name="level"
                    rules={[
                        {
                            required: true,
                            message: t('위험관리수준을 설정해주세요.'),
                        },
                    ]}
                >
                    <InputNumber
                        min={3}
                        max={9}
                        onChange={value => onChange(value, 'level')}
                    />
                </Form.Item>
                <Form.Item
                    label={t("기준년월")}
                    name="level_ym"
                    rules={[
                        { required: true, message: t('기준년월을 선택해주세요.') },
                    ]}
                >
                    <DatePicker
                        onChange={value => onChange(value, 'level_ym')}
                        picker="month"
                        disabledDate={date => {
                            return disabledDate(date);
                        }}
                    />
                </Form.Item>
                <StyledFormItem>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        {t("확인")}
                    </Button>
                </StyledFormItem>
            </Form>
        </Modal>
    );
};

export default DesktopDangersRiskLevelSetupModal;
