import { Button, Input, Popconfirm, Table } from 'antd';
import { IColumnsProps, IDataSourceProps } from './type';
import React, { useCallback, useContext, useState } from 'react';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import { openNotification } from '@/common/util';
import styled from 'styled-components';
import { useEffect } from 'react';
import defaultInfo from '@/common/site-info';
import Modal from '@/components/modal';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopAdminCategoryTableAddModalProps {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  lastNo: string;
  editData: IDataSourceProps[];
  setCategoryData: React.Dispatch<React.SetStateAction<IDataSourceProps[]>>;
}

const ButtonArea = styled.div`
  text-align: right;
  padding-bottom: 10px;
`;

const setAdminCertCategory = async ({ projectId, datas }) => {
  const result = await api.setAdminCertCategory({
    project_id: projectId,
    datas,
  });

  return result.data;
};

const deleteAdminCertCategory = async ({ projectId, id }) => {
  const result = await api.deleteAdminCertCategory({
    project_id: projectId,
    id,
  });

  return result.data;
};


const DesktopAdminCategoryTableAddModal: React.FunctionComponent<
  IDesktopAdminCategoryTableAddModalProps
> = ({
  isModalVisible,
  setIsModalVisible,
  lastNo = '1.1.1',
  editData,
  setCategoryData
}) => {
  
  const { t } = useTranslation();

  const { projectId } = useContext(ProjectContext);

  const [datasource, setDatasource] = useState<IDataSourceProps[]>([
    {
      id: null,
      category_num: '',
      category_name: '',
      category_desc: '',
    },
  ]);

  useEffect(() => {
    setDatasource(
      editData !== null
        ? editData
        : [
            {
              id: null,
              category_num: lastNo ? nextNo(lastNo) : '',
              category_name: '',
              category_desc: '',
            },
          ]
    );
  }, [editData, isModalVisible, lastNo, t]);

  const handleOk = useCallback(() => {
    setAdminCertCategory({
      projectId,
      datas: datasource[0],
    }).then(datas => {
      const notiText = {
        description: t("완료"),
      };

      openNotification(notiText);

      setCategoryData(datas);
      setIsModalVisible(false);
    });
  }, [datasource, projectId, setCategoryData, setIsModalVisible, t]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleChange = useCallback((event, target) => {
    setDatasource(prev =>
      prev.map(data => ({ ...data, [target]: event.target.value }))
    );
  }, []);

  const nextNo = str => {
    let resultNum = '';
    const numSplit = str.split('.');

    numSplit[numSplit.length - 1] = String(
      Number(numSplit[numSplit.length - 1]) + 1
    );

    numSplit.forEach(num => {
      resultNum = `${resultNum + num}.`;
    });

    return resultNum.replace(/.\s*$/, '');
  };

  const columns: IColumnsProps[] = [
    {
      title: 'No',
      dataIndex: 'category_num',
      key: 'category_num',
      width: '100px',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: value => {
        return editData === null ? (
          <Input
            // value={nextNo(lastNo)}
            onChange={event => handleChange(event, 'category_num')}
            placeholder={nextNo(lastNo)}
          />
        ) : (
          value
        );
      },
    },
    {
      title: t('항목'),
      dataIndex: 'category_name',
      key: 'category_name',
      width: '200px',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: value => {
        return (
          <Input
            value={value}
            onChange={event => handleChange(event, 'category_name')}
          />
        );
      },
    },
    {
      title: t('상세내용'),
      dataIndex: 'category_desc',
      key: 'category_desc',
      width: '400px',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: value => {
        return (
          <Input
            value={value}
            onChange={event => handleChange(event, 'category_desc')}
          />
        );
      },
    },
  ];

  function confirm() {
    deleteAdminCertCategory({ projectId, id: datasource[0].id }).then(datas => {
      const notiText = {
        description: t("완료"),
      };

      openNotification(notiText);

      setCategoryData(datas);
      setIsModalVisible(false);
    });
  }

  function cancel(e) {
    console.log(e);
  }

  return (
    <Modal
      title={t("통제항목")}
      open={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      width="1000px"
    >
      {editData === null ? (
        <p>{t("마지막 통제항목 번호")} : {lastNo}</p>
      ) : (
        <ButtonArea>
          <Popconfirm
            title={t("삭제 하시겠습니까?")}
            onConfirm={confirm}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>{t("삭제")}</Button>
          </Popconfirm>
        </ButtonArea>
      )}

      <Table
        dataSource={datasource}
        columns={columns}
        pagination={false}
        size={defaultInfo.table_size}
      />
    </Modal>
  );
};

export default DesktopAdminCategoryTableAddModal;
