import { ProjectContext } from '@/common/project-provider';
import { Input, Button, Form, Space, Select } from 'antd';
import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import { IEvaluationDataProps } from './type';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopDangersEvaluationHeaderProps {
  getRiskResult: any;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setRiskData: React.Dispatch<React.SetStateAction<IEvaluationDataProps[]>>;
}

const Container = styled.div`
  text-align: left;
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
  border: 1px solid ${props => props.theme.layout.searchBorderColor};
  border-radius: 10px;
  overflow-x: auto;
  word-break: keep-all;
  .ant-form-item {
    margin: 0;
  }
`;
const Label = styled.label`
  font-size: 15px;
  margin-left: 15px;
`;
const StyledInput = styled(Input)`
  width: 130px;
`;
const ResetButton = styled(Form.Item)`
  padding-right: 20px;
`;
const StyledSelect = styled(Select)`
  text-align: left;
`;

const DesktopDangersEvaluationHeader: React.FunctionComponent<
  IDesktopDangersEvaluationHeaderProps
> = ({ getRiskResult, setIsLoading, setRiskData }) => {
  const { t } = useTranslation();
  const { projectId } = useContext(ProjectContext);
  const [form] = Form.useForm();

  const onFinish = useCallback(
    datas => {
      getRiskResult({ project_id: projectId, ...datas }).then(datas => {
        setIsLoading(false);
        setRiskData(datas.map((item, index) => ({ ...item, key: index })));
      });
    },
    [getRiskResult, projectId, setIsLoading, setRiskData]
  );

  const resetHandler = useCallback(() => {
    form.resetFields();
  }, [form]);

  return (
    <Container>
      <Form name="basic" onFinish={onFinish} autoComplete="off" form={form}>
        <Space>
          <Label>{t("위협코드")}</Label>
          <Form.Item name="threat_code">
            <StyledInput placeholder={t("위협코드")} />
          </Form.Item>
          <Label>{t("위협내용")}</Label>
          <Form.Item name="threat_desc">
            <StyledInput placeholder={t("위협내용")} />
          </Form.Item>
          <Label>{t("취약점")}</Label>
          <Form.Item name="vulner_desc">
            <StyledInput placeholder={t("취약점")} />
          </Form.Item>
          <Label>{t("위험값")}</Label>
          <Form.Item name="risk_value">
            <StyledSelect
              placeholder={t("위험값")}
              defaultValue=""
              style={{ width: 150 }}
            >
              <Option value="3" key="risk_3">
                3
              </Option>
              <Option value="4" key="risk_4">
                4
              </Option>
              <Option value="5" key="risk_5">
                5
              </Option>
              <Option value="6" key="risk_6">
                6
              </Option>
              <Option value="7" key="risk_7">
                7
              </Option>
              <Option value="8" key="risk_8">
                8
              </Option>
              <Option value="9" key="risk_9">
                9
              </Option>
            </StyledSelect>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
            {t("조회")}
            </Button>
          </Form.Item>
          <ResetButton>
            <Button onClick={resetHandler}>{t("초기화")}</Button>
          </ResetButton>
        </Space>
      </Form>
    </Container>
  );
};

export default DesktopDangersEvaluationHeader;
