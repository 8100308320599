import { ProjectContext } from "@/common/project-provider";
import { IAdminMenuPermissionProps } from "@/common/type";
import Loading from "@/components/loading";
import { AutoComplete, DatePicker, Input, Select, Table } from "antd";
import React, { useCallback, useContext, useState, useMemo } from "react";

import styled from "styled-components";
import {
  IContentProps,
  IPaginationProps,
  ITechProtectionDataSource,
} from "./type";
import TextArea from "antd/lib/input/TextArea";
import api from "@/api/api";
import defaultInfo from "@/common/site-info";
import dayjs from "dayjs";
import { IAutocompleteOnSelectHandlerProps } from "../Protection/type";
import { useTranslation } from "react-i18next";

const { Option } = Select;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopDangersTechProtectionTableProps {
  dataSource: ITechProtectionDataSource | null;
  setDataSource: React.Dispatch<
    React.SetStateAction<ITechProtectionDataSource | null>
  >;
  isLoading: boolean;
  pageInfo: IAdminMenuPermissionProps;
  pagination: IPaginationProps;
  selectDate: string;
  riskTechApi: (page: IPaginationProps, ym: string) => void;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

const Container = styled.div`
  .ant-form-item {
    margin: 0;
  }
`;
const Text = styled.div`
  text-align: left;
`;

const getMemberSearch = async ({ project_id, member_name }) => {
  const result = await api.getMemberSearch({
    project_id,
    member_name,
  });

  return result.data;
};

const DesktopDangersTechProtectionTable: React.FunctionComponent<
  IDesktopDangersTechProtectionTableProps
> = ({
  dataSource,
  setDataSource,
  isLoading,
  pageInfo,
  pagination,
  selectDate,
  riskTechApi,
  setIsEdit,
}) => {
  const { t } = useTranslation();
  const { projectId } = useContext(ProjectContext);

  const [membersOptions, setMembersOptions] = useState<{ value: string }[]>([]);

  const columns: any = useMemo(() => {
    return [
      {
        title: "No",
        label: "No",
        dataIndex: "no",
        key: "no",
        align: "center",
        width: "2%",
        render: (_, __, index) => {
          const currentPage = (pagination.current - 1) * pagination.pageSize;

          return <>{currentPage + index + 1}</>;
        },
      },
      {
        title: t("그룹"),
        label: t("그룹"),
        dataIndex: "asset_group",
        key: "asset_group",
        align: "center",
        width: "5%",
      },
      {
        title: t("자산코드"),
        label: t("자산코드"),
        dataIndex: "asset_code",
        key: "asset_code",
        align: "center",
        width: "6%",
      },
      {
        title: t("자산명"),
        label: t("자산명"),
        dataIndex: "asset_name",
        key: "asset_name",
        align: "center",
        width: "5%",
      },
      {
        title: t("진단영역"),
        label: t("진단영역"),
        dataIndex: "cate_name",
        key: "cate_name",
        align: "center",
        width: "5%",
      },
      {
        title: t("항목코드"),
        label: t("항목코드"),
        dataIndex: "check_code",
        key: "check_code",
        align: "center",
        width: "5%",
      },
      {
        title: t("세부항목"),
        label: t("세부항목"),
        dataIndex: "check_desc",
        key: "check_desc",
        align: "center",
        width: "10%",
      },
      {
        title: t("영향(위험)"),
        label: t("영향(위험)"),
        dataIndex: "check_risk",
        key: "check_risk",
        align: "center",
        width: "auto",
        render: (text) => <Text>{text}</Text>,
      },
      {
        title: t("보호대책권고사항"),
        label: t("보호대책권고사항"),
        dataIndex: "action_desc",
        key: "action_desc",
        align: "center",
        width: "10%",
        render: (text) => <Text>{text}</Text>,
      },
      {
        title: t("자산가치"),
        label: t("자산가치"),
        dataIndex: "asset_weight",
        key: "asset_weight",
        align: "center",
        width: "4%",
      },
      {
        title: t("위험값"),
        label: t("위험값"),
        dataIndex: "risk_value",
        key: "risk_value",
        align: "center",
        width: "4%",
      },
      {
        title: t("우선순위"),
        label: t("우선순위"),
        dataIndex: "priority",
        key: "priority",
        align: "center",
        fixed: "right",
        width: "4%",
        render: (text: string, record: IContentProps) => {
          return pageInfo.perm_write ? (
            <Select
              style={{ width: "100%" }}
              defaultValue={text}
              onChange={(val) => {
                selectHandler(val, record, "priority");
              }}
            >
              <Option value={t("단기")}>{t("단기")}</Option>
              <Option value={t("중기")}>{t("중기")}</Option>
              <Option value={t("장기")}>{t("장기")}</Option>
            </Select>
          ) : (
            text
          );
        },
      },
      {
        title: t("조치현황"),
        label: t("조치현황"),
        dataIndex: "status",
        key: "status",
        align: "center",
        width: "5%",
        fixed: "right",
        render: (text: string, record: IContentProps) => {
          return pageInfo.perm_write ? (
            <Select
              style={{ width: "100%" }}
              defaultValue={text}
              onChange={(val) => {
                selectHandler(val, record, "status");
              }}
            >
              <Option value={t("미조치")}>{t("미조치")}</Option>
              <Option value={t("위험수용")}>{t("위험수용")}</Option>
              <Option value={t("조치예정")}>{t("조치예정")}</Option>
              <Option value={t("조치완료")}>{t("조치완료")}</Option>
            </Select>
          ) : (
            text
          );
        },
      },
      {
        title: t("조치일정"),
        label: t("조치일정"),
        dataIndex: "close_date",
        key: "close_date",
        align: "center",
        width: "6%",
        fixed: "right",
        render: (date: string, record: IContentProps) => {
          return pageInfo.perm_write ? (
            <DatePicker
              allowClear={false}
              onChange={(val) => {
                selectHandler(
                  dayjs(val).format("YYYY-MM-DD"),
                  record,
                  "close_date"
                );
              }}
              value={record.close_date !== null && dayjs(record.close_date)}
            />
          ) : (
            date
          );
        },
      },
      {
        title: t("비고"),
        label: t("비고"),
        dataIndex: "remark",
        key: "remark",
        align: "center",
        width: "8%",
        fixed: "right",
        render: (remark, record) => {
          return pageInfo.perm_write ? (
            <TextArea
              rows={2}
              defaultValue={remark}
              style={{ verticalAlign: "top" }}
              onChange={(event) => {
                selectHandler(event.target.value, record, "remark");
              }}
            />
          ) : (
            <Text>{remark}</Text>
          );
        },
      },
      {
        title: t("담당자"),
        label: t("담당자"),
        dataIndex: "member_name",
        key: "member_name",
        align: "center",
        width: "130px",
        fixed: "right",
        render: (member_name, record) => {
          return pageInfo.perm_write ? (
            <AutoComplete
              options={membersOptions}
              style={{ width: 110 }}
              onSearch={onMemberNameSearch}
              onSelect={(
                val: string,
                obj: IAutocompleteOnSelectHandlerProps
              ) => {
                selectHandler(val, record, "member_name");
                selectHandler(obj.id, record, "member_id");
              }}
              defaultValue={member_name}
            >
              <Input.Search placeholder={t("검색")} />
            </AutoComplete>
          ) : (
            member_name
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membersOptions, pageInfo.perm_write, pagination]);

  const handleTableChange = useCallback(
    (page) => {
      riskTechApi(page, selectDate);
    },
    [riskTechApi, selectDate]
  );

  const selectHandler = useCallback(
    (value, record, targetKey) => {
      setIsEdit(true);
      setDataSource((prev: any) => {
        const result = prev.content.map((item) => {
          if (item.id === record.id) {
            return { ...item, [targetKey]: value };
          }
          return { ...item };
        });

        return { ...dataSource, content: [...result] };
      });
    },
    [dataSource, setDataSource, setIsEdit]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onMemberNameSearch = (searchText: string) => {
    setIsEdit(true);
    getMemberSearch({
      project_id: projectId,
      member_name: searchText,
    }).then((datas) => {
      const optionList = datas.map((item, index) => ({
        value: item.member_name,
        key: index,
        id: item.id,
      }));

      setMembersOptions(optionList);
    });
  };

  return (
    <Container>
      {dataSource !== null ? (
        <Table
          dataSource={dataSource.content}
          columns={columns}
          scroll={{ x: "2500px" }}
          loading={isLoading}
          pagination={pagination}
          onChange={handleTableChange}
          size={defaultInfo.table_size}
        />
      ) : (
        <Loading />
      )}
    </Container>
  );
};

export default DesktopDangersTechProtectionTable;
