import React, { useCallback, useState } from 'react';

import Floating from '@/components/floating';
import Footer from '@/components/footer';
import Header from '@/components/header';
import { Layout } from 'antd';
import Sider from '@/components/sider';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { defaultColor } from '@/styles/color';

const { Content } = Layout;

interface IMobileLayoutProps {
  children: React.ReactNode;
  title: string;
}

const StyledContainer = styled(Layout)`
  min-height: 100vh;
  background: ${defaultColor.lightGray};
`;

const StyledContent = styled(Content)`
  padding: 24px;
  min-height: 280px;
  color: ${defaultColor.darkGray};
`;

const MobileLayout: React.FunctionComponent<IMobileLayoutProps> = ({
  children,
  title,
}) => {
  const { pathname } = useLocation();
  const [collapsed, setCollapsed] = useState(true);
  const isMain = pathname === '/';

  const HeaderToggle = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  return (
    <StyledContainer>
      <Header title={title} isMain={isMain} />
      <Sider collapsed={collapsed} HeaderToggle={HeaderToggle} />
      <StyledContent>{children}</StyledContent>
      <Footer />
      <Floating />
    </StyledContainer>
  );
};

export default MobileLayout;
