import { Button, Form, Input, Space } from 'antd';

import { IProjectDatas } from './type';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopAdminProjectSearchProps {
  setProjectList: React.Dispatch<React.SetStateAction<IProjectDatas[]>>;
  getProjectAPI: (project_name: string) => any;
}

const Container = styled.div`
  text-align: left;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid ${props => props.theme.layout.searchBorderColor};
  box-sizing: border-box;
  border-radius: 10px;
  .ant-form-item {
    margin: 0;
  }
`;
const Label = styled.label`
  font-size: 15px;
  margin-left: 15px;
`;

const DesktopAdminProjectSearch: React.FunctionComponent<
  IDesktopAdminProjectSearchProps
> = ({ setProjectList, getProjectAPI }) => {
  const { t } = useTranslation();
  const onFinish = (values: any) => {
    getProjectAPI(values.project_name).then(data => {
      setProjectList(data);
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const [form] = Form.useForm();

  const resetHandler = () => {
    getProjectAPI('').then(data => {
      setProjectList(data);
      form.resetFields();
    });
  };

  return (
    <Container>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
      >
        <Space>
          <Label>{t("프로젝트명")}</Label>
          <Form.Item name="project_name" initialValue="">
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t("조회")}
            </Button>
          </Form.Item>

          <Form.Item>
            <Button onClick={resetHandler}>{t("초기화")}</Button>
          </Form.Item>
        </Space>
      </Form>
    </Container>
  );
};

export default DesktopAdminProjectSearch;
