import { IAdminMenuPermissionProps, IAdminMenuProps, ICodeData } from './type';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

interface IProjectProviderProps {
  children: React.ReactNode;
}

interface IProjectContext {
  projectId: number;
  setProjectId: React.Dispatch<React.SetStateAction<number>>;
  userInfo: IUserInfo;
  setUserInfo: React.Dispatch<React.SetStateAction<IUserInfo>>;
  getPageInfo: (id: string) => IAdminMenuPermissionProps;
  selectTab: string;
  setSelectTab: React.Dispatch<React.SetStateAction<string>>;
}

interface IUserInfo {
  userLevel?: number;
  codes?: {
    dp?: ICodeData[];
    rk?: ICodeData[];
    as?: ICodeData[];
  };
  profile?: {
    dept_cd: string;
    email: string;
    fullname: string;
    rank_cd: string;
    userhp: string;
    username: string;
    member_pic: string;
  };
  permission?: IAdminMenuProps[];
}

export const ProjectContext = React.createContext<IProjectContext>({
  projectId: 0,
  userInfo: {},
  setProjectId: () => 0,
  setUserInfo: () => ({}),
  getPageInfo: () => ({
    perm_view: false,
    perm_write: false,
  }),
  selectTab: '',
  setSelectTab: () => '',
});

const ProjectProvider: React.FunctionComponent<IProjectProviderProps> = ({
  children,
}) => {
  const [projectId, setProjectId] = useState(() => {
    return (
      (typeof window !== 'undefined' &&
        Number(window.sessionStorage.getItem('project_id'))) ||
      1
    );
  });

  const [userInfo, setUserInfo] = useState(() => {
    return JSON.parse(window.sessionStorage.getItem('userInfo'));
  });

  const [selectTab, setSelectTab] = useState('');

  const getPageInfo = useCallback(
    id => {
      if (userInfo.permission && typeof userInfo.permission !== 'undefined') {
        const { perm_view, perm_write } = userInfo.permission.filter(
          item => item.key === id
        )[0];

        return {
          perm_view,
          perm_write,
        };
      }

      return {
        perm_view: false,
        perm_write: false,
      };
    },
    [userInfo]
  );

  const value = useMemo(
    () => ({
      projectId,
      setProjectId,
      userInfo,
      setUserInfo,
      getPageInfo,
      selectTab,
      setSelectTab,
    }),
    [getPageInfo, projectId, selectTab, userInfo]
  );

  useEffect(() => {
    const favicon = document.getElementById('favicon');

    sessionStorage.setItem('project_id', `${projectId}`);
    sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
    if (sessionStorage.getItem('systemInfo') !== null) {
      document.title = JSON.parse(
        sessionStorage.getItem('systemInfo')
      ).home_title;
      favicon.setAttribute(
        'href',
        process.env.REACT_APP_URL +
          JSON.parse(sessionStorage.getItem('systemInfo')).home_favicon
      );
    }
  }, [projectId, userInfo]);

  return (
    <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>
  );
};

export default ProjectProvider;
