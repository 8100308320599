import React, { useContext, useEffect, useState } from 'react';

import DesktopCertificationRecordHeader from '../components/desktop-certification-header';
import DesktopCertificationRecordModal from './desktop-certification-record-modal';
import DesktopCertificationRecordTable from '../components/desktop-certification-table';
import DesktopCertificationSearch from '../components/desktop-certification-search';
import { IAdminMenuPermissionProps } from '@/common/type';
import { ICertificationTableRecord } from '../type';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import styled from 'styled-components';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopCertificationRecordProps {
  pageInfo: IAdminMenuPermissionProps;
}

const RecordContainer = styled.div`
  box-sizing: border-box;
  border-radius: 10px;
`;

const getCertificationTrace = async ({ project_id, category_id }) => {
  const result = await api.getCertificationTrace({
    project_id,
    category_id,
  });

  return result.data;
};

const DesktopCertificationRecord: React.FunctionComponent<
  IDesktopCertificationRecordProps
> = ({ pageInfo }) => {
  const { projectId } = useContext(ProjectContext);

  const [categoryId, SetCategoryId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectId, setSelectId] = useState(0);
  const [recordDatas, setRecordDatas] = useState<ICertificationTableRecord[]>(
    []
  );
  const [isNewModal, setIsNewModal] = useState(true);
  const [isOnLoadData, setIsOnLoadData] = useState(true);

  useEffect(() => {
    getCertificationTrace({
      project_id: projectId,
      category_id: categoryId,
    }).then(datas => {
      setRecordDatas(datas.map(data => ({ ...data, key: data.id })));
      setIsOnLoadData(false);
    });
  }, [categoryId, projectId, isModalVisible]);

  return (
    <>
      <DesktopCertificationSearch
        categoryId={categoryId}
        setRecordDatas={setRecordDatas}
        setCategoryId={SetCategoryId}
      />
      <RecordContainer>
        <DesktopCertificationRecordHeader
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          setIsNewModal={setIsNewModal}
          type="record"
          pageInfo={pageInfo}
        />
        <DesktopCertificationRecordTable
          setSelectId={setSelectId}
          recordDatas={recordDatas}
          setRecordDatas={setRecordDatas}
          setIsModalVisible={setIsModalVisible}
          setIsNewModal={setIsNewModal}
          type="record"
          isOnLoadData={isOnLoadData}
          pageInfo={pageInfo}
        />
      </RecordContainer>
      <DesktopCertificationRecordModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        isNewModal={isNewModal}
        pageInfo={pageInfo}
        targetData={
          isNewModal
            ? {
                key: '',
                category: [],
                category_name: '',
                cycle: null,
                doc_desc: '',
                doc_id: null,
                doc_name: '',
                id: null,
                inspect_yn: true,
                last_date: null,
                manager: '',
                manager_id: null,
              }
            : recordDatas.filter(item => item.id === selectId)[0]
        }
      />
    </>
  );
};

export default DesktopCertificationRecord;
