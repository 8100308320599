import React, { useState } from 'react';

import DesktopAdminProjectSearch from './desktop-admin-project-search';
import DesktopAdminProjectTable from './desktop-admin-project-table';
import { IAdminMenuPermissionProps } from '@/common/type';
import { IProjectDatas } from './type';
import api from '@/api/api';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopAdminProjectProps {
  pageInfo: IAdminMenuPermissionProps;
}

const getProject = async project_name => {
  const result = await api.getProject(project_name);

  return result.data;
};

const DesktopAdminProject: React.FunctionComponent<
  IDesktopAdminProjectProps
> = ({ pageInfo }) => {
  const [projectList, setProjectList] = useState<IProjectDatas[]>(null);

  return (
    <>
      <DesktopAdminProjectSearch
        setProjectList={setProjectList}
        getProjectAPI={getProject}
      />
      <DesktopAdminProjectTable
        projectList={projectList}
        setProjectList={setProjectList}
        getProjectAPI={getProject}
        pageInfo={pageInfo}
      />
    </>
  );
};

export default DesktopAdminProject;
