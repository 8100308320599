import { IAdminMenuPermissionProps, ICodeData } from '@/common/type';
import { IOrgMemberData, IRoleData } from '@/panel/Org/type';
import React, { useEffect, useState } from 'react';

import DesktopOrgMembersManagerListTable from './desktop-org-members-manager-list-table';
import DesktopOrgMembersManagerModal from '@/panel/Org/components/desktop-org-members-manager-modal';
import Loading from '@/components/loading';
import api from '@/api/api';
import styled from 'styled-components';

// visible={visible}
interface IDesktopOrgMembersManagerListProps {
    visible: boolean;
    isNewMember: boolean;
    orgId: number | null;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setIsNewMember: React.Dispatch<React.SetStateAction<boolean>>;
    projectId: number;
    pageInfo: IAdminMenuPermissionProps;
}

const Container = styled.div`
    position: relative;
`;

const GroupName = styled.h2`
    /* position: absolute;
  left: 0;
  top: -40px; */
    font-size: 20px;
    padding-bottom: 20px;
    font-weight: 700;
`;

const getMemberList = async ({ projectId, id }) => {
    const result = await api.getMemberList({ project_id: projectId, id });

    return result.data;
};

const getCodes = async ({ projectId, id }) => {
    const result = await api.getCodes({ project_id: projectId, id });

    return result.data;
};

const getOrgArrList = async ({ project_id }) => {
    const result = await api.getOrgArrList({ project_id });

    return result.data;
};

const DesktopOrgMembersManagerList: React.FunctionComponent<
    IDesktopOrgMembersManagerListProps
> = ({
    visible,
    isNewMember,
    orgId,
    setVisible,
    setIsNewMember,
    projectId,
    pageInfo,
}) => {
    const [selectIndex, setSelectIndex] = useState(0);
    const [memberList, setMemberList] = useState<IOrgMemberData[]>([]);
    const [codeList, setCodeList] = useState<ICodeData[]>([]);
    const [loading, setLoading] = useState(true);
    const [orgArrList, setOrgArrList] = useState<IRoleData[]>([]);

    useEffect(() => {
        if (!visible && orgId !== null) {
            // visible 변경값 감지하여 리스트 재호출
            const datas = getMemberList({ projectId, id: orgId });

            datas.then(result => {
                setMemberList(result.map(item => ({ ...item, key: item.id })));
                setLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgId, visible]);

    useEffect(() => {
        getCodes({ projectId, id: 'RK' }).then(data => {
            setCodeList(data.map(item => ({ ...item, key: item.item_cd })));
        });
    }, [projectId]);

    useEffect(() => {
        getOrgArrList({ project_id: projectId }).then(data => {
            setOrgArrList(data.map(item => ({ ...item, key: item.id })));
        });
    }, [projectId, orgId, visible]);

    if (loading) return <Loading />;

    const isSelected = orgArrList.filter(item => item.id === orgId).length > 0;

    return (
        <Container>
            {orgArrList.filter(item => item.id === orgId).length > 0 && (
                <GroupName>
                    {orgArrList.filter(item => item.id === orgId)[0].organ_name}
                </GroupName>
            )}
            <DesktopOrgMembersManagerListTable
                memberList={memberList}
                selectIndex={selectIndex}
                setIsNewMember={setIsNewMember}
                setSelectIndex={setSelectIndex}
                setVisible={setVisible}
                pageInfo={pageInfo}
            />

            <DesktopOrgMembersManagerModal
                visible={visible}
                setVisible={setVisible}
                isNewMember={isNewMember}
                codeList={codeList}
                orgId={orgId}
                orgArrList={orgArrList}
                datas={
                    !isNewMember &&
                    typeof memberList !== 'undefined' &&
                    memberList.length > 0 &&
                    typeof memberList[selectIndex] !== 'undefined'
                        ? memberList[selectIndex]
                        : {
                              id: null,
                              member_email: '',
                              member_hp: '',
                              member_name: '',
                              member_pic: '',
                              member_pos: '',
                              member_roll: '',
                              project_id: projectId,
                              rank_cd: '',
                              roll_desc: '',
                              organ_id: orgId,
                              key: null,
                              role: [
                                  {
                                      id: null,
                                      organ_name: isSelected
                                          ? orgArrList.filter(
                                                item => item.id === orgId
                                            )[0].organ_name
                                          : '',
                                      roll_desc: '',
                                      member_id: null,
                                      organ_id: orgId,
                                  },
                              ],
                          }
                }
                pageName="managerList"
            />
        </Container>
    );
};

export default DesktopOrgMembersManagerList;
