import { Button, Empty, Form, Tabs } from 'antd';
import { IGroupProps, IPaneProps } from './type';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import DesktopAdminAssetsGroupAddModal from './desktop-admin-assets-group-add-modal';
import DesktopAssetsGroupContent from './desktop-admin-assets-group-content';
import { IAdminMenuPermissionProps } from '@/common/type';
import { ICodeData } from '../Code/type';
import Loading from '@/components/loading';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import { openNotification } from '@/common/util';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface IDesktopAdminAssetsGroup {
    pageInfo: IAdminMenuPermissionProps;
}

interface IContainerProps {
    isWrite: boolean;
}

const Container = styled.div<IContainerProps>`
    ${props =>
        !props.isWrite &&
        `
    .ant-tabs-tab-remove{
      display: none;
    }
  `}
`;

const AddButtonContainer = styled.div`
    margin-bottom: 10px;
    .ant-form-item {
        margin: 0;
    }
`;

const ContainerEmpty = styled.div`
    padding: 150px 0;
`;

const getAssetGroups = async ({ projectId }) => {
    const result = await api.getAssetGroups({ project_id: projectId });

    return result.data;
};

const setAssetGroup = async ({ projectId, data }) => {
    const result = await api.setAssetGroup({ project_id: projectId, data });

    return result.data;
};

const deleteAssetGroup = async ({ id }) => {
    const result = await api.deleteAssetGroup({ id });

    return result.data;
};

const getCodes = async ({ projectId, id }) => {
    const result = await api.getCodes({ project_id: projectId, id });

    return result.data;
};

const DesktopAdminAssetsGroup: React.FunctionComponent<
    IDesktopAdminAssetsGroup
> = ({ pageInfo }) => {
    const { t } = useTranslation();
    const { projectId } = useContext(ProjectContext);

    const [panes, setPanes] = useState<IPaneProps[]>([]);
    const [assetsTypeList, setAssetsTypeList] = useState<ICodeData[]>([]);
    const [selectTabId, setSelectTabId] = useState(1);
    const [activeKey, setActiveKey] = useState(
        panes.length > 0 ? panes[0].key : '0'
    );
    const [isRemove, setIsRemove] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isCreateGroupModalVisible, setIsCreateGroupModalVisible] =
        useState(false);

    const [form] = Form.useForm();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const isDuplicationCheck = (name: string) =>
        panes.filter(pane => pane.group_name === name).length > 0;

    const getId = targetKey =>
        panes.filter(pane => pane.key === targetKey)[0].id;

    const remove = targetKey => {
        let lastIndex = panes.length;

        panes.forEach((pane, i) => {
            if (pane.key === targetKey) {
                lastIndex = i - 1;
            }
        });

        setPanes(prev => prev.filter(pane => pane.key !== targetKey));
        setIsRemove(true);
        if (panes.length && activeKey === targetKey) {
            setActiveKey(lastIndex >= 0 ? panes[lastIndex].key : panes[0].key);
        }
    };

    const onChange = activeKey => {
        setActiveKey(activeKey);
    };

    const onEdit = (targetKey, action) => {
        if (action === 'remove') {
            if (window.confirm(t("삭제 하시겠습니까?"))) {
                const id = getId(targetKey);

                deleteAssetGroup({ id }).then(() => {
                    const notiText = {
                        description: t("완료"),
                    };

                    openNotification(notiText);
                    remove(targetKey);
                });
            }
            return;
        }
    };

    const onFinish = ({ assetstype, newGroupName }: any) => {
        if (typeof assetstype === 'undefined' || assetstype === '') {
            alert(t('자산유형을 선택해주세요.'));
            return;
        }
        if (typeof newGroupName === 'undefined' || newGroupName === '') {
            alert(t('그룹명을 입력해주세요.'));
            return;
        }

        const addData: IGroupProps = {
            id: null,
            group_name: newGroupName,
            base_cd: assetstype,
            group_sort:
                panes.length > 0
                    ? Number(panes[panes.length - 1].group_sort) + 1
                    : 1,
            project_id: projectId,
            fields: [],
        };

        setAssetGroup({ projectId, data: addData }).then(data => {
            const activeKey = `newTab${panes.length - 1}`;
            const notiText = {
                description: t("완료"),
            };

            setPanes(prev => [
                ...prev,
                {
                    ...data,
                    group_name: newGroupName,
                    content: DesktopAssetsGroupContent,
                    key: activeKey,
                },
            ]);
            openNotification(notiText);
            setSelectTabId(data.id);
            setActiveKey(activeKey);
            setIsCreateGroupModalVisible(false);
            form.resetFields();
        });
    };

    useEffect(() => {
        getCodes({ projectId, id: 'AS' }).then(data => {
            setAssetsTypeList(data);
        });
    }, [projectId]);

    const addButton = (
        <AddButtonContainer>
            {pageInfo.perm_write && (
                <Button
                    type="primary"
                    onClick={() => setIsCreateGroupModalVisible(true)}
                >
                    {t("그룹추가")}
                </Button>
            )}
            <DesktopAdminAssetsGroupAddModal
                isCreateGroupModalVisible={isCreateGroupModalVisible}
                setIsCreateGroupModalVisible={setIsCreateGroupModalVisible}
                assetsTypeList={assetsTypeList}
                onFinish={onFinish}
                isEdit={false}
            />
        </AddButtonContainer>
    );

    const tabClickHandler = key => {
        const id = getId(key);

        setIsRemove(false);
        setSelectTabId(id);
    };

    useEffect(() => {
        getAssetGroups({ projectId }).then(data => {
            const groupData = data.map((item, index) => {
                return {
                    ...item,
                    content: DesktopAssetsGroupContent,
                    key: `${index}`,
                };
            });
            setSelectTabId(data[0]?.id);
            setPanes(groupData);
            setIsLoading(false);
        });
    }, [projectId]);

    const items = useMemo(() => {
        return panes.map(pane => {
            return {
                label: pane.group_name,
                key: pane.key,
                children: (
                    <>
                        {(selectTabId === pane.id || isRemove) && (
                            <pane.content
                                pane={pane}
                                setPanes={setPanes}
                                isDuplicationCheck={isDuplicationCheck}
                                pageInfo={pageInfo}
                            />
                        )}
                    </>
                ),
            };
        });
    }, [isDuplicationCheck, isRemove, pageInfo, panes, selectTabId]);

    if (isLoading) return <Loading />;

    return (
        <Container isWrite={pageInfo.perm_write}>
            <Tabs
                hideAdd
                onChange={onChange}
                activeKey={activeKey}
                type="editable-card"
                onEdit={onEdit}
                tabBarExtraContent={addButton}
                onTabClick={tabClickHandler}
                items={items}
            />

            {panes.length === 0 && pageInfo.perm_write && (
                <ContainerEmpty>
                    <Empty description={t("그룹을 추가하세요.")} />
                </ContainerEmpty>
            )}
        </Container>
    );
};

export default DesktopAdminAssetsGroup;
