const darkTheme = {
  theme: {
    primaryColor: '#8992BD'
  }
};

const lightTheme = {
  theme: {
    primaryColor: '#5E6481'
  }
};

export { darkTheme, lightTheme };
