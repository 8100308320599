import { Button, Dropdown, List } from 'antd';
import React, { useContext, useEffect, useState } from 'react';

import DesktopCertificationAdminModal from '@/panel/Certification/CertAdmin/desktop-certification-admin-modal';
import { Link } from 'react-router-dom';
import { NotificationOutlined } from '@ant-design/icons';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import styled from 'styled-components';
import { defaultColor } from '@/styles/color';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IIndexProps {}

const Container = styled.div`
  position: relative;
  margin-left: -8px;
`;

const RedDot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background: red;
  position: absolute;
  right: 0%;
  top: 50%;
  transform: translate(0px, -13px);
`;

const StyledButton = styled(Button)``;

const ListContainer = styled.div`
  min-width: 200px;
  padding: 0px 15px 0 15px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  border: 1px solid ${props => props.theme.layout.searchBorderColor};
  background-color: ${props => props.theme.layout.dashboardBg};
  margin-top: -13px;
  .ant-list-split .ant-list-header {
    border: none;
  }
`;

const StyledDropdown = styled(Dropdown)``;

const Header = styled.h3`
  font-size: 15px;
`;

const Footer = styled.div`
  text-align: center;
`;

const OutstandingList = styled.div`
  position: relative;
`;
const ListName = styled.div`
  text-align: left;
  padding-right: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: keep-all;
`;
const LinkArea = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const getDashboardAlarm = async ({ project_id }) => {
  const result = await api.getDashboardAlarm({ project_id });

  return result.data;
};

const getCertificationTrace = async ({ project_id, category_id }) => {
  const result = await api.getCertificationTrace({
    project_id,
    category_id,
  });

  return result.data;
};

const StyledNotificationOutlined = styled(NotificationOutlined)`
  color: ${defaultColor.lightGray};
`;

const Index: React.FunctionComponent<IIndexProps> = () => {

  const { t } = useTranslation();
  
  const { projectId } = useContext(ProjectContext);
  const [alarmList, setAlarmList] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setRecordDatas] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectDocId, setSelectDocId] = useState(0);

  useEffect(() => {
    getDashboardAlarm({ project_id: projectId }).then(data => {
      setAlarmList(data);
    });

    getCertificationTrace({
      project_id: projectId,
      category_id: null,
    }).then(datas => {
      setRecordDatas(datas.map(data => ({ ...data, key: data.id })));
    });
  }, [projectId]);

  const alarmListElement = (
    <ListContainer>
      <List
        size="small"
        header={<Header>{t("미처리목록")}</Header>}
        footer={
          <Footer>
            <Button type="link">
              <Link to="/Certification/CertAdmin">{t("증적관리 바로가기")}</Link>
            </Button>
          </Footer>
        }
        dataSource={alarmList}
        renderItem={item => (
          <List.Item>
            <OutstandingList>
              <ListName>{item.docname}</ListName>
              <LinkArea>
                <Button size="small" onClick={() => viewHandler(item.id)}>
                  View
                </Button>
              </LinkArea>
            </OutstandingList>
          </List.Item>
        )}
      />
    </ListContainer>
  );

  const viewHandler = id => {
    setIsModalVisible(true);
    setSelectDocId(id);
  };

  return (
    <Container>
      <StyledDropdown overlay={alarmListElement} placement="bottom">
        <div>
          <StyledButton type="text" icon={<StyledNotificationOutlined />} />
          {alarmList.length > 0 && <RedDot />}
        </div>
      </StyledDropdown>
      <DesktopCertificationAdminModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        selectDocId={selectDocId}
        setRecordDatas={setRecordDatas}
        recordTitle={''}
        pageInfo={{ perm_view: true, perm_write: true }}
      />
    </Container>
  );
};

export default Index;
