import api from '@/api/api';
import { ProjectContext } from '@/common/project-provider';
import defaultInfo from '@/common/site-info';
import { Button, Popconfirm, Table, Tag } from 'antd';
import React, { useContext } from 'react';
import { IDataSourceProps } from './type';
import dayjs from 'dayjs';
import styled from 'styled-components';
import Modal from '@/components/modal';
import { useTranslation } from 'react-i18next';

interface IDesktopDangersRiskLevelHistoryModalProps {
    isHistoryModalVisible: boolean;
    setIsHistoryModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    dataSource: IDataSourceProps[];
    setDataSource: React.Dispatch<React.SetStateAction<IDataSourceProps[]>>;
}

const StyledTag = styled(Tag)`
    font-size: 15px;
`;

const removeRiskYmLevel = async ({ projectId, ym }) => {
    const result = await api.removeRiskYmLevel({ project_id: projectId, ym });

    return result.data;
};

const DesktopDangersRiskLevelHistoryModal: React.FunctionComponent<
    IDesktopDangersRiskLevelHistoryModalProps
> = ({
    isHistoryModalVisible,
    setIsHistoryModalVisible,
    dataSource,
    setDataSource,
}) => {
    const { t } = useTranslation();
    const { projectId } = useContext(ProjectContext);
    const handleCancel = () => {
        setIsHistoryModalVisible(false);
    };

    const columns: any = [
        {
            title: t('기준년월'),
            dataIndex: 'level_ym',
            align: 'center',
            key: 'level_ym',
        },
        {
            title: t('위험관리수준'),
            dataIndex: 'level',
            align: 'center',
            key: 'level',
            render: text => {
                return <StyledTag color="default">{text}</StyledTag>;
            },
        },
        {
            title: t('저장일시'),
            dataIndex: 'created_date',
            align: 'center',
            key: 'created_date',
            render: (_, record) => {
                return (
                    <>
                        {dayjs(
                            record.modified_date ?? record.created_date
                        ).format('YYYY-MM-DD HH:mm')}
                    </>
                );
            },
        },
        {
            title: t('삭제'),
            dataIndex: 'delete',
            align: 'center',
            key: 'delete',
            render: (_, record) => {
                return (
                    <Popconfirm
                        title={`${record.level_ym} ${t("삭제 하시겠습니까?")}`}
                        onConfirm={() => removeHandler(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button>{t("삭제")}</Button>
                    </Popconfirm>
                );
            },
        },
    ];

    const removeHandler = ({ level_ym: ym }) => {
        removeRiskYmLevel({ projectId, ym }).then(datas => {
            setDataSource(
                datas.map(item => {
                    return {
                        ...item,
                        key: item.id,
                        created_date: dayjs(item.created_date).format(
                            'YYYY-MM-DD HH:mm'
                        ),
                    };
                })
            );
        });
        // setIsHistoryModalVisible(false);
    };

    return (
        <Modal
            title={t("위험관리수준 목록")}
            open={isHistoryModalVisible}
            width={600}
            onCancel={handleCancel}
            footer={[
                <Button
                    type="primary"
                    onClick={handleCancel}
                    key="historyConfirmKey"
                >
                    {t("확인")}
                </Button>,
            ]}
        >
            <Table
                dataSource={dataSource}
                columns={columns}
                size={defaultInfo.table_size}
            />
        </Modal>
    );
};

export default DesktopDangersRiskLevelHistoryModal;
