import api from "@/api/api";
import { ProjectContext } from "@/common/project-provider";
import defaultInfo from "@/common/site-info";
import { Button, Popconfirm, Select, Table, Tag, Input } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import { IRkskAnalysisDataProps } from "./type";
import DesktopDangersRiskAnalysisTableModal from "./desktop-dangers-risk-analysis-table-modal";
import { IAdminMenuPermissionProps } from "@/common/type";
import { openNotification } from "@/common/util";
import DeleteButton from "@/components/deletebutton";
import CustomButton from "@/components/button";
import { useTranslation } from "react-i18next";

const { TextArea } = Input;

interface IDesktopDangersRiskAnalysisTableProps {
  pageInfo: IAdminMenuPermissionProps;
}
interface ITextProps {
  align: string;
}
const Text = styled.div<ITextProps>`
  text-align: ${(props) => props.align || "left"};
`;
const ButtonArea = styled.div`
  text-align: right;
  padding-bottom: 20px;
  position: sticky;
  right: 0;
  top: 70px;
  z-index: 6;
`;

const Container = styled.div`
  .ant-table-cell {
    vertical-align: top;
  }
`;
const StyledTag = styled(Tag)`
  font-size: 15px;
  padding: 5px 10px;
`;

const getRiskAnal = async (props) => {
  const result = await api.getRiskAnal({ ...props });

  return result.data;
};

const setRiskAnal = async (props) => {
  const result = await api.setRiskAnal({ ...props });

  return result.data;
};

const removeRiskAnal = async (props) => {
  const result = await api.removeRiskAnal({ ...props });

  return result.data;
};

let timer;

const DesktopDangersRiskAnalysisTable: React.FunctionComponent<
  IDesktopDangersRiskAnalysisTableProps
> = ({ pageInfo }) => {
  
  const { t } = useTranslation();
  
  const addData = useMemo(
    () => ({
      id: null,
      category_id: null,
      category_num: "",
      category_name: "",
      category_desc: "",
      threat: null,
      threat_desc: null,
      vulner_desc: null,
      action_desc: null,
      impact: 0,
      generatability: 0,
      vulnerability: 0,
      manage_risk: 0,
      law_risk: 0,
      law_desc: null,
      del_yn: false,
    }),
    []
  );

  const { projectId } = useContext(ProjectContext);
  // const [mergeNum, setMergeNum] = useState([]);
  // const [singleNum, setSingleNum] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [overlapArr, setOverlabArr] = useState([]); // 중복되는 값
  const [defaultArr, setDetaultArr] = useState([]); // 중복되지 않는 기본 값
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataSource, setDataSource] = useState<IRkskAnalysisDataProps[]>([]);
  const [selectData, setSelectData] = useState<IRkskAnalysisDataProps>();
  const [saveDataSource, setSaveDataSource] = useState<
    IRkskAnalysisDataProps[]
  >([]);

  const apiCall = useCallback(() => {
    setIsLoading(true);
    setDataSource([]);
    getRiskAnal({ project_id: projectId }).then(
      (datas: IRkskAnalysisDataProps[]) => {
        const result = datas
          .sort((a, b) => a.category_id - b.category_id)
          .map((item, index) => ({ ...item, key: index }));

        setDataSource(result);
        setIsLoading(false);
      }
    );
  }, [projectId]);

  useEffect(() => {
    apiCall();
  }, [apiCall, projectId]);

  const addHandler = useCallback(
    ({ category_name, category_id, category_desc }) => {
      if (dataSource.length > 0) {
        setIsLoading(true);

        const index =
          dataSource.map((item) => item.category_id).lastIndexOf(category_id) +
          1;
        const insertData = {
          ...addData,
          category_name,
          category_id,
          category_desc,
          key: dataSource.length + 1,
        };

        const addDataSource = [
          ...dataSource.slice(0, index),
          insertData,
          ...dataSource.slice(index),
        ];

        setDataSource(addDataSource);
        setSaveDataSource((prev) => [...prev, insertData]);
      }
    },
    [addData, dataSource]
  );

  const saveHandler = useCallback(() => {
    // 관리 취약도 계산 추가
    const result = saveDataSource.map((item) => {
      const score = Math.ceil(
        (Number(item.impact) * Number(item.generatability)) / 3
      );

      return {
        ...item,
        vulnerability: score,
        manage_risk: score * 2 + Number(item.law_risk),
      };
    });

    setRiskAnal({ project_id: projectId, datas: result }).then(() => {
      const notiText = {
        description: t("완료"),
      };
      openNotification(notiText);
      apiCall();
      setSaveDataSource([]);
    });
  }, [apiCall, projectId, saveDataSource, t]);

  const removeHandler = useCallback(
    (id) => {
      removeRiskAnal({ project_id: projectId, id }).then(() => {
        apiCall();
      });
    },
    [apiCall, projectId]
  );

  const dataSync = useCallback((insertData, record, name) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      setDataSource((prev) => {
        return prev.map((item) => {
          if (item.key === record.key) {
            return { ...item, [name]: insertData };
          } else {
            return item;
          }
        });
      });
      setSaveDataSource((prev) => {
        const dataFilter = prev.filter((item) => item.key === record.key);
        const isData = dataFilter.length > 0;

        if (isData) {
          return prev.map((item) => {
            if (item.key === dataFilter[0].key) {
              return { ...item, [name]: insertData };
            }
            return item;
          });
        } else {
          return [...prev, { ...record, [name]: insertData }];
        }
      });
    }, 300);
  }, []);

  useEffect(() => {
    if (dataSource.length > 0) {
      const idList = dataSource.map((item) => item.category_id);

      const filterList = idList.filter(
        (item, index) => idList.indexOf(item) === index
      );
      const overlapArr = [];
      const defaultArr = [];

      filterList.forEach((item) => {
        if (idList.indexOf(item) !== idList.lastIndexOf(item)) {
          overlapArr.push([idList.indexOf(item), idList.lastIndexOf(item)]);
        } else {
          defaultArr.push(idList.indexOf(item));
        }
      });
      setOverlabArr(overlapArr);
      setDetaultArr(defaultArr);
      setIsLoading(false);
    }
  }, [dataSource, t]);

  const onCellMerge = useCallback(
    (index) => {
      if (overlapArr.length > 0) {
        const target = overlapArr.filter((arr) => index === arr[0]);
        const singleTarget = defaultArr.filter((arr) => index === arr);

        if (target.length > 0) {
          if (index === target[0][0]) {
            return { rowSpan: target[0][1] - target[0][0] + 1 };
          }
        }
        if (index !== singleTarget[0]) {
          return { rowSpan: 0 };
        }
      }
    },
    [defaultArr, overlapArr]
  );

  const columns: ColumnsType<IRkskAnalysisDataProps> = useMemo(() => {
    return [
      {
        title: "No",
        dataIndex: "no",
        align: "center",
        fixed: "left",
        width: "3%",
        render: (_, __, index) => <>{index + 1}</>,
      },
      {
        title: t("항목"),
        align: "center",
        fixed: "left",
        width: "8%",
        dataIndex: "category_name",
        onCell: (_, index) => {
          return onCellMerge(index);
        },
        render: (_, record) => {
          return (
            <div>
            <Text>{record.category_num}</Text>
            <Text>{record.category_name}</Text>
            </div>
          );
        },
      },
      {
        title: t("상세내용"),
        align: "center",
        width: "20%",
        dataIndex: "category_desc",
        render: (text) => {
          return <Text>{text}</Text>;
        },
        onCell: (_, index) => {
          return onCellMerge(index);
        },
      },
      {
        title: t("위협코드"),
        align: "center",
        width: "7%",
        dataIndex: "threat",
        render: (value, record) =>
          pageInfo.perm_write ? (
            <Button
              type="text"
              size="small"
              onClick={() => {
                setSelectData(record);
                setIsModalOpen(true);
              }}
            >
              {value === null ? (
                <>{t("위협코드 선택")}</>
              ) : (
                <Tag color="error">{value.threat_code}</Tag>
              )}
            </Button>
          ) : (
            <>
              {value === null ? (
                <>-</>
              ) : (
                <Tag color="error">{value.threat_code}</Tag>
              )}
            </>
          ),
      },
      {
        title: t("위협내용"),
        align: "center",
        width: "12%",
        dataIndex: "threat_desc",
        render: (text, record) => {
          return pageInfo.perm_write ? (
            <TextArea
              rows={2}
              defaultValue={text}
              onChange={(event) => {
                dataSync(event.target.value, record, "threat_desc");
              }}
            />
          ) : (
            <Text>{text}</Text>
          );
        },
      },
      {
        title: t("취약점"),
        align: "center",
        width: "12%",
        dataIndex: "vulner_desc",
        render: (text, record) => {
          return pageInfo.perm_write ? (
            <TextArea
              rows={2}
              defaultValue={text}
              onChange={(event) => {
                dataSync(event.target.value, record, "vulner_desc");
              }}
            />
          ) : (
            <Text>{text}</Text>
          );
        },
      },
      {
        title: t("개선방향"),
        align: "center",
        width: "12%",
        dataIndex: "action_desc",
        render: (text, record) => {
          return pageInfo.perm_write ? (
            <TextArea
              rows={2}
              defaultValue={text}
              onChange={(event) => {
                dataSync(event.target.value, record, "action_desc");
              }}
            />
          ) : (
            <Text>{text}</Text>
          );
        },
      },
      {
        title: t("영향도"),
        align: "center",
        width: "4%",
        dataIndex: "impact",
        render: (text, record) =>
          pageInfo.perm_write ? (
            <Select
              defaultValue={text === null || text === 0 ? 0 : text}
              onSelect={(value) => {
                dataSync(value, record, "impact");
              }}
              options={[
                {
                  value: 1,
                  label: "1",
                },
                {
                  value: 2,
                  label: "2",
                },
                {
                  value: 3,
                  label: "3",
                },
              ]}
            />
          ) : (
            <Text align="center">{text}</Text>
          ),
      },
      {
        title: t("발생가능성"),
        align: "center",
        width: "4%",
        dataIndex: "generatability",
        render: (text, record) =>
          pageInfo.perm_write ? (
            <Select
              defaultValue={text === null || text === 0 ? 0 : text}
              onSelect={(value) => {
                dataSync(value, record, "generatability");
              }}
              options={[
                {
                  value: 1,
                  label: "1",
                },
                {
                  value: 2,
                  label: "2",
                },
                {
                  value: 3,
                  label: "3",
                },
              ]}
            />
          ) : (
            <Text align="center">{text}</Text>
          ),
      },
      {
        title: t("관리취약도"),
        align: "center",
        width: "4%",
        dataIndex: "vulnerability",
        render: (_, record) => {
          const score = Math.ceil(
            (Number(record.impact) * Number(record.generatability)) / 3
          );

          return (
            <>
              {score !== 0 ? (
                <StyledTag color="orange">{score}</StyledTag>
              ) : (
                "-"
              )}
            </>
          );
        },
      },
      {
        title: t("법률위험도"),
        align: "center",
        width: "4%",
        dataIndex: "law_risk",
        render: (text, record) =>
          pageInfo.perm_write ? (
            <Select
              defaultValue={text === null || text === 0 ? 0 : text}
              onSelect={(value) => {
                dataSync(value, record, "law_risk");
              }}
              options={[
                {
                  value: 1,
                  label: "1",
                },
                {
                  value: 2,
                  label: "2",
                },
                {
                  value: 3,
                  label: "3",
                },
              ]}
            />
          ) : (
            <Text align="center">{text}</Text>
          ),
      },
      {
        title: t("위험도"),
        align: "center",
        width: "4%",
        dataIndex: "manage_risk",
        render: (_, record) => {
          const score =
            Math.round(
              (Number(record.impact) * Number(record.generatability)) / 3
            ) *
              2 +
            Number(record.law_risk);

          return (
            <>
              {record.law_risk === null || record.law_risk === 0 ? (
                "-"
              ) : (
                <StyledTag color="red">{score}</StyledTag>
              )}
            </>
          );
        },
      },
      {
        title: t("법률"),
        align: "center",
        width: "12%",
        dataIndex: "law_desc",
        render: (text, record) =>
          pageInfo.perm_write ? (
            <TextArea
              rows={2}
              defaultValue={text}
              onChange={(event) => {
                dataSync(event.target.value, record, "law_desc");
              }}
            />
          ) : (
            <Text>{text}</Text>
          ),
      },
    ];
  }, [dataSync, onCellMerge, pageInfo.perm_write, t]);

  const editColumns: ColumnsType<IRkskAnalysisDataProps> = useMemo(() => {
    return [
      {
        title: `${t("추가")} / ${t("삭제")} `,
        align: "center",
        width: "5%",
        fixed: "right",
        render: (_, record) => {
          return (
            <>
              <CustomButton
                onClick={() => {
                  addHandler(record);
                }}
              >
                {t("추가")}
              </CustomButton>
              {record.id !== null && (
                <Popconfirm
                  title={`${record.category_name} ${t("삭제 하시겠습니까?")}`}
                  onConfirm={() => removeHandler(record.id)}
                  onCancel={() => false}
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteButton type="default" size="small">
                  {t("삭제")}
                  </DeleteButton>
                </Popconfirm>
              )}
            </>
          );
        },
      },
    ];
  }, [addHandler, removeHandler, t]);

  return (
    <Container>
      {pageInfo.perm_write && (
        <ButtonArea>
          <Button type="primary" onClick={() => saveHandler()}>
          {t("저장")}
          </Button>
        </ButtonArea>
      )}
      <Table
        dataSource={dataSource}
        columns={
          pageInfo.perm_write ? [...columns, ...editColumns] : [...columns]
        }
        scroll={{ x: 2700, y: 550 }}
        bordered
        loading={isLoading}
        pagination={false}
        size={defaultInfo.table_size}
      />
      <DesktopDangersRiskAnalysisTableModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        dataSync={dataSync}
        selectData={selectData}
        setDataSource={setDataSource}
      />
    </Container>
  );
};

export default DesktopDangersRiskAnalysisTable;
