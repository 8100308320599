import React, { useCallback, useContext, useEffect, useState } from 'react';

import { IAdminMenuPermissionProps } from '@/common/type';
import styled from 'styled-components';
import DesktopDangersTechProtectionHeader from './desktop-dangers-techProtection-header';
import DesktopDangersTechProtectionTable from './desktop-dangers-techProtection-table';
import { ITechPlanParamsProps, ITechProtectionDataSource } from './type';
import api from '@/api/api';
import { ProjectContext } from '@/common/project-provider';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopDangersTechProtectionProps {
  pageInfo: IAdminMenuPermissionProps;
}

const Container = styled.div`
  .ant-form-item {
    margin: 0;
  }
`;

const getRiskTechPlan = async props => {
  const result = await api.getRiskTechPlan({ ...props });

  return result.data;
};

const getRiskYm = async ({ projectId }) => {
  const result = await api.getRiskYm({
    project_id: projectId,
  });

  return result.data;
};

const DesktopDangersTechProtection: React.FunctionComponent<
  IDesktopDangersTechProtectionProps
> = ({ pageInfo }) => {
  const { projectId } = useContext(ProjectContext);
  const [dataSource, setDataSource] = useState<ITechProtectionDataSource>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [standardDateList, setStandardDateList] = useState([]);
  const [selectDate, setSelectDate] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [searchParam, setSearchParam] = useState<ITechPlanParamsProps>({
    ym: '',
    col: '',
    keyword: '',
    asset_group: '',
    check_code: '',
    risk_value: '',
    status: '',
  });

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 5,
  });
  // console.log(pageInfo);
  // console.log(standardDateSelect, setStandardDateSelect, projectId);

  useEffect(() => {
    getRiskYm({ projectId }).then(date => {
      setStandardDateList(date);
      setSelectDate(date[0]);
      fetch(pagination, date[0]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {}, []);

  const fetch = useCallback(
    (page, ym, data = {}) => {
      getRiskTechPlan({
        project_id: projectId,
        ...searchParam,
        ...data,
        ym,
        size: page.pageSize || 100,
        page: page.current || 1,
      }).then(datas => {
        setIsLoading(false);
        setDataSource({
          ...datas,
          content: datas.content.map(item => ({
            ...item,
            key: item.id,
          })),
        });
        setPagination(prev => {
          return {
            ...prev,
            pageSize: page.pageSize,
            current: page.current,
            total: datas.total_elements,
          };
        });
        window.scrollTo(0, 0);
      });
    },
    [projectId, searchParam]
  );

  return (
    <Container>
      <DesktopDangersTechProtectionHeader
        standardDateList={standardDateList}
        setSelectDate={setSelectDate}
        riskTechApi={fetch}
        pagination={pagination}
        pageInfo={pageInfo}
        isEdit={isEdit}
        dataSource={dataSource}
        selectDate={selectDate}
        searchParam={searchParam}
        setSearchParam={setSearchParam}
      />
      <DesktopDangersTechProtectionTable
        pageInfo={pageInfo}
        dataSource={dataSource}
        setDataSource={setDataSource}
        pagination={pagination}
        isLoading={isLoading}
        selectDate={selectDate}
        riskTechApi={fetch}
        setIsEdit={setIsEdit}
      />
    </Container>
  );
};

export default DesktopDangersTechProtection;
