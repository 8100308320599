import { Button, Empty, Input, Space, Tree } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import DesktopOrgManagerInput from '../components/desktop-org-manager-input';
import DesktopOrgManagerTitle from '../components/desktop-org-manager-title';
import { DownOutlined } from '@ant-design/icons';
import { IAdminMenuPermissionProps } from '@/common/type';
import { IOrgGroupData } from '../type';
import Loading from '@/components/loading';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import { openNotification } from '@/common/util';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface IDesktopOrgManagerList {
  datas: IOrgGroupData[];
  setOrgId: React.Dispatch<React.SetStateAction<number>>;
  isLoading: boolean;
  pageInfo: IAdminMenuPermissionProps;
}

const Container = styled.div`
  position: relative;
`;

const StyledTree = styled(Tree)`
  background: transparent;
  .ant-tree-title > div {
    padding: 3px 3px 3px 0;
  }
  .ant-tree-draggable-icon {
    line-height: 29px;
  }
  .ant-tree-switcher {
    background: transparent;
    line-height: 29px;
  }
  .ant-tree-node-content-wrapper:hover {
    .dark & {
      background-color: #434343;
    }
  }
  .ant-tree-node-content-wrapper.ant-tree-node-selected {
    .dark & {
      background-color: #434343;
    }
  }
  .ant-tree-switcher {
    .dark & {
      background: transparent;
    }
  }
`;
const EmptyContainer = styled.div`
  padding: 130px 0;
`;

// 초기데이터
let item: IOrgGroupData = {
  id: null,
  organ_id: '',
  key: '',
  organ_name: '',
  parent_id: '',
  organ_level: '',
  project_id: '',
  organ_sort: '',
  children: [],
};

const setOrgList = async ({ projectId, orgData }) => {
  const result = await api.setOrgList({
    projectId,
    data: orgData,
  });

  return result.data;
};

const deleteOrgList = async ({ id }) => {
  const result = await api.deleteOrgList({
    id,
  });

  return result.data;
};

const DesktopOrgManagerList: React.FunctionComponent<
  IDesktopOrgManagerList
> = ({ datas, setOrgId, isLoading, pageInfo }) => {
  const { t } = useTranslation();

  const [orgData, setOrgData] = useState<IOrgGroupData[]>([]);
  const [isAddMode, setIsAddMode] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [useDraggable, setUseDraggable] = useState(true);
  const inputRef = React.useRef<any>(null);

  const { projectId } = useContext(ProjectContext);

  useEffect(() => {
    if (isEditMode || isAddMode) {
      if (inputRef.current !== null) {
        setTimeout(() => {
          inputRef.current!.focus({
            cursor: 'end',
          });
        }, 500);
      }
    }
  }, [isEditMode, inputRef, isAddMode]);

  let compareKey = '';

  useEffect(() => {
    setOrgData([...datas]);
  }, [datas]);

  // 드래그 로직
  const onDrop = info => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    const data = [...orgData];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 && // Has children
      info.node.props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar;
      let i;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }

    setOrgData(data);
  };

  // 입력 취소
  const removeNodeHandler = () => {
    // targetItem.children.slice(-1);
    const data: IOrgGroupData[] = updateTreeData({
      list: orgData,
      compareKey: '',
      newData: [],
    });

    setIsAddMode(false);
    setIsEditMode(false);
    setOrgData(data);
  };

  // 추가
  const insertNodeHandler = (targetItem, flag) => {
    if (targetItem.organ_name === '') {
      alert(t('부서를 입력해주세요'));
      return;
    }

    const insertData: IOrgGroupData[] = updateTreeData({
      list: orgData,
      compareKey,
      newData: { ...targetItem, id: null, children: [] },
    });

    const editData: IOrgGroupData[] = editTreeData({
      list: orgData,
      compareKey,
      newData: { ...targetItem, children: [...targetItem.children] },
    });

    const datas = flag ? editData : insertData;

    setReRender(false);
    setOrgList({ projectId, orgData: datas }).then(data => {
      const notiText = {
        message: `${t("조직")} ${flag ? t("수정") : t("추가")}`,
        description: `${t("조직")} ${flag ? t("수정") : t("추가")} ${t("완료")}`,
      };

      openNotification(notiText);
      setIsAddMode(false);
      setIsEditMode(false);
      setOrgData(data);
      setReRender(true);
    });
  };

  // 입력폼 추가
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const addInputFormHandler = (targetItem: IOrgGroupData) => {
    compareKey = targetItem.key;

    const data: IOrgGroupData[] = updateTreeData({
      list: orgData,
      compareKey,
      newData: {
        key: createNodeKey(targetItem),
        id: null,
        organ_name: DesktopOrgManagerInput({
          targetItem,
          isEditMode: false,
          item,
          createNodeKey,
          insertNodeHandler,
          removeNodeHandler,
          inputRef,
        }),
        parent_id: targetItem.organ_id,
        organ_level: '',
        project_id: targetItem.project_id,
        organ_sort: '',
        children: [],
      },
    });

    setIsAddMode(true);
    setOrgData(data);
  };

  // 삭제
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const removeNodeItemHandler = (targetItem: IOrgGroupData) => {
    if (window.confirm(`[ ${targetItem.organ_name} ] ${t("삭제 하시겠습니까?")}`)) {
      const list = deleteOrgList({ id: targetItem.id });

      list.then(() => {
        const notiText = {
          description: `${targetItem.organ_name} ${t("삭제")} ${t("완료")}`,
        };

        openNotification(notiText);
        setOrgData(
          removeTreeData({ list: orgData, compareKey: targetItem.key })
        );
      });
    }
  };

  // 수정
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const editNodeItemHandler = (targetItem: IOrgGroupData) => {
    compareKey = targetItem.key;

    const data: IOrgGroupData[] = editTreeData({
      list: orgData,
      compareKey,
      newData: {
        organ_name: DesktopOrgManagerInput({
          targetItem,
          isEditMode: true,
          item,
          createNodeKey,
          insertNodeHandler,
          removeNodeHandler,
          inputRef,
        }),
      },
    });

    setIsEditMode(true);
    setOrgData(data);
  };

  // 키 생성
  const createNodeKey = (targetItem: IOrgGroupData) => {
    if (targetItem.children.length === 0) {
      return `${targetItem.key}-0`;
    }

    const lastSliceKey = targetItem.children[
      targetItem.children.length - 1
    ].key.slice(0, -1);

    return `${lastSliceKey}${targetItem.children.length}`;
  };

  // 추가 로직
  const updateTreeData = ({ list, compareKey, newData }) => {
    return list.map(node => {
      if (node.key === compareKey) {
        return {
          ...node,
          children: [...node.children, newData],
        };
      }

      if (node.children) {
        return {
          ...node,
          children: updateTreeData({
            list: node.children,
            compareKey,
            newData,
          }),
        };
      }

      return node;
    });
  };

  // 삭제 로직
  const removeTreeData = ({ list, compareKey }) => {
    return list
      .filter(node => node.key !== compareKey)
      .map(node => {
        if (node.children) {
          return {
            ...node,
            children: removeTreeData({ list: node.children, compareKey }),
          };
        }

        return node;
      });
  };

  // 수정 로직
  const editTreeData = ({ list, compareKey, newData }) => {
    return list.map(node => {
      if (node.key === compareKey) {
        return {
          ...node,
          ...newData,
        };
      }

      if (node.children) {
        return {
          ...node,
          children: editTreeData({ list: node.children, compareKey, newData }),
        };
      }

      return node;
    });
  };

  // 부서 + 사원 데이터로 변경
  const dataReFactoring = useCallback(
    (data: IOrgGroupData[]) => {
      return data.map(item => {
        const title = (
          <DesktopOrgManagerTitle
            item={item}
            isAddMode={isAddMode}
            isEditMode={isEditMode}
            addInputFormHandler={addInputFormHandler}
            removeNodeItemHandler={removeNodeItemHandler}
            editNodeItemHandler={editNodeItemHandler}
            pageInfo={pageInfo}
          />
        );

        if (item.children) {
          return {
            ...item,
            title,
            children: dataReFactoring(item.children),
          };
        }

        return item;
      });
    },
    [
      addInputFormHandler,
      editNodeItemHandler,
      isAddMode,
      isEditMode,
      pageInfo,
      removeNodeItemHandler,
    ]
  );

  const onSelectHandler = (_, event) => {
    const {
      node: { id },
    } = event;

    setOrgId(id);
  };

  const onDragEnd = () => {
    const list = setOrgList({ projectId, orgData });

    list.then(data => {
      setOrgData(data);
    });
  };

  // 데이터 변화시 초기화
  useEffect(() => {
    item = {
      id: null,
      organ_id: '',
      key: '',
      organ_name: '',
      parent_id: '',
      organ_level: '',
      project_id: '',
      organ_sort: '',
      children: [],
    };
    setUseDraggable(!isAddMode && !isEditMode);
  }, [isAddMode, isEditMode, orgData]);

  const [createGroupName, setCreateGroupName] = useState('');

  const createGroupNameOnChange = event => {
    const { value } = event.target;

    setCreateGroupName(value);
  };

  const createGroupNameOnClick = useCallback(() => {
    const datas = [
      {
        id: null,
        key: '0-0',
        organ_level: 1,
        organ_name: createGroupName,
        organ_sort: 0,
        project_id: projectId,
        children: [],
      },
    ];

    setOrgList({ projectId, orgData: datas }).then(data => {
      setOrgData(data);
    });
  }, [createGroupName, projectId]);

  const [reRender, setReRender] = useState(true);

  if (isLoading) return <Loading />;

  return orgData.length > 0 ? (
    <>
      {reRender ? (
        <StyledTree
          treeData={dataReFactoring(orgData)}
          showLine
          switcherIcon={<DownOutlined />}
          defaultExpandAll={true}
          draggable={useDraggable}
          onDragEnd={onDragEnd}
          onDrop={onDrop}
          blockNode
          autoExpandParent={false}
          onSelect={(_, e) => onSelectHandler(_, e)}
          defaultSelectedKeys={['0-0']}
        />
      ) : (
        <Loading />
      )}
    </>
  ) : (
    <Container>
      <EmptyContainer>
        <Empty description={t("생성된 데이터가 없습니다.")}>
          <Space style={{ marginTop: '20px' }}>
            <Input
              placeholder={t("조직명")}
              value={createGroupName}
              onChange={createGroupNameOnChange}
            />
            <Button type="primary" onClick={createGroupNameOnClick}>
              생성
            </Button>
          </Space>
        </Empty>
      </EmptyContainer>
    </Container>
  );
};

export default DesktopOrgManagerList;
