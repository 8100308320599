import { Button, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { SettingOutlined, OrderedListOutlined } from '@ant-design/icons';
import { IAdminMenuPermissionProps } from '@/common/type';
import api from '@/api/api';
import { ProjectContext } from '@/common/project-provider';
import DesktopDangersRiskLevelSetup from './desktop-dangers-risk-level-setup';
import DesktopDangersRiskLevelHistory from './desktop-dangers-risk-level-history';
import { IDataSourceProps } from './type';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

interface IDesktopDangersRiskLevelProps {
    pageInfo: IAdminMenuPermissionProps;
}

const Container = styled.div`
    border: 1px solid ${props => props.theme.layout.searchBorderColor};
    display: inline-block;
    width: 250px;
    height: 250px;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 2px 0 8px 0 rgb(29 35 41 / 5%);
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    h1 {
        font-size: 17px;
        line-height: 18px;
        span {
            font-size: 12px;
            color: #666666;
        }
    }
`;
const Number = styled.div`
    text-align: center;
    font-size: 90px;
`;
const Date = styled.div`
    text-align: center;
    font-size: 17px;
`;
const BtnArea = styled.div`
    position: absolute;
    right: 10px;
    top: 12px;
`;

const getRiskYmLevel = async ({ projectId }) => {
    const result = await api.getRiskYmLevel({ project_id: projectId });

    return result.data;
};

const DesktopDangersRiskLevel: React.FunctionComponent<
    IDesktopDangersRiskLevelProps
> = ({ pageInfo }) => {
    const { t } = useTranslation();
    const { projectId } = useContext(ProjectContext);

    const [isToolTipView, setIsToolTipView] = useState({
        setup: true,
        list: false,
    });
    const [isSetupModalVisible, setIsSetupModalVisible] = useState(false);
    const [isHistoryModalVisible, setIsHistoryModalVisible] = useState(false);

    const [dataSource, setDataSource] = useState<IDataSourceProps[]>([]);

    useEffect(() => {
        if (pageInfo.perm_write) {
            setTimeout(() => {
                setIsToolTipView({ setup: false, list: true });
            }, 2000);
            setTimeout(() => {
                setIsToolTipView({ setup: false, list: false });
            }, 4000);
        }
    }, [pageInfo.perm_write]);

    useEffect(() => {
        getRiskYmLevel({ projectId }).then(datas => {
            setDataSource(
                datas.map(item => ({
                    ...item,
                    key: item.id,
                    created_date: dayjs(item.created_date),
                }))
            );
        });
    }, [projectId, isSetupModalVisible]);

    return (
        <Container>
            <h1>
                {t("위험관리수준")}
                <br />
                <span>Degree of Acceptance (DoA)</span>
            </h1>
            {dataSource.length > 0 && dataSource[0].hasOwnProperty('level') ? (
                <>
                    <Number>{dataSource[0].level}</Number>
                    <Date>{dataSource[0].level_ym} {t("설정")}</Date>
                </>
            ) : (
                <Number>-</Number>
            )}
            {pageInfo.perm_write && (
                <BtnArea>
                    <Tooltip
                        title={t("위험관리수준 설정")}
                        open={isToolTipView.setup}
                    >
                        <Button
                            type="text"
                            icon={<SettingOutlined />}
                            onClick={() => setIsSetupModalVisible(true)}
                        />
                    </Tooltip>
                    <Tooltip
                        title={t("위험관리수준 목록")}
                        open={isToolTipView.list}
                    >
                        <Button
                            type="text"
                            icon={<OrderedListOutlined />}
                            onClick={() => setIsHistoryModalVisible(true)}
                        />
                    </Tooltip>
                </BtnArea>
            )}

            <DesktopDangersRiskLevelSetup
                isSetupModalVisible={isSetupModalVisible}
                setIsSetupModalVisible={setIsSetupModalVisible}
                dataSource={dataSource}
            />
            <DesktopDangersRiskLevelHistory
                isHistoryModalVisible={isHistoryModalVisible}
                setIsHistoryModalVisible={setIsHistoryModalVisible}
                dataSource={dataSource}
                setDataSource={setDataSource}
            />
        </Container>
    );
};

export default DesktopDangersRiskLevel;
