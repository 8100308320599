import { Button, Space, Tooltip } from 'antd';
import { IGroupProps, IPaneProps } from './type';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import DesktopAdminAssetsGroupAddModal from './desktop-admin-assets-group-add-modal';
import DesktopAdminAssetsGroupContentCategory from './desktop-admin-assets-group-content-category';
import { EditOutlined } from '@ant-design/icons';
import { IAdminMenuPermissionProps } from '@/common/type';
import { ICodeData } from '../Code/type';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import { openNotification } from '@/common/util';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface IDesktopAdminAssetsGroupContent {
  pane: IPaneProps;
  setPanes: React.Dispatch<React.SetStateAction<IPaneProps>>;
  isDuplicationCheck: (name: string) => boolean;
  pageInfo: IAdminMenuPermissionProps;
}

const TabContent = styled.div``;
const ContentTitle = styled.h5`
  font-size: 18px;
`;

const setAssetGroup = async ({ projectId, data }) => {
  const result = await api.setAssetGroup({ project_id: projectId, data });

  return result.data;
};

const getCodes = async ({ projectId, id }) => {
  const result = await api.getCodes({ project_id: projectId, id });

  return result.data;
};

const DesktopAdminAssetsGroupContent: React.FunctionComponent<
  IDesktopAdminAssetsGroupContent
> = ({ pane, setPanes, pageInfo }) => {
  const { t } = useTranslation();
  const { projectId } = useContext(ProjectContext);

  const [assetsTypeList, setAssetsTypeList] = useState<ICodeData[]>([]);
  const [isCreateGroupModalVisible, setIsCreateGroupModalVisible] =
    useState(false);

  useEffect(() => {
    getCodes({ projectId, id: 'AS' }).then(data => {
      setAssetsTypeList(data);
    });
  }, [projectId, t]);

  const titleEditEventListener = useCallback(
    ({ newGroupName, assetstype }) => {
      if (typeof assetstype === 'undefined' || assetstype === '') {
        alert(t('자산유형을 선택해주세요.'));
        return;
      }
      if (typeof newGroupName === 'undefined' || newGroupName === '') {
        alert(t('그룹명을 입력해주세요.'));
        return;
      }

      const editData: IGroupProps = {
        id: pane.id,
        group_name: newGroupName,
        base_cd: assetstype,
        group_sort: pane.group_sort,
        project_id: projectId,
        fields: [],
      };

      setAssetGroup({ projectId, data: editData }).then(data => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setPanes((prev: any) => {
          return prev.map((item: IPaneProps) =>
            item.id === data.id
              ? {
                  ...data,
                  content: DesktopAdminAssetsGroupContent,
                  key: pane.key,
                }
              : item
          );
        });
        setIsCreateGroupModalVisible(false);
        const notiText = {
          description: t("완료"),
        };
        openNotification(notiText);
      });
    },
    [pane.id, pane.group_sort, pane.key, projectId, setPanes, t]
  );

  return (
    <TabContent>
      <ContentTitle>
        <Space>
          <span>{t("자산그룹")} : {pane.group_name}</span>
          {pageInfo.perm_write && (
            <Tooltip placement="right" title={t("그룹명")}>
              <Button
                type="text"
                icon={<EditOutlined />}
                onClick={() => setIsCreateGroupModalVisible(true)}
              />
            </Tooltip>
          )}
        </Space>
      </ContentTitle>
      <DesktopAdminAssetsGroupContentCategory
        id={pane.id}
        pageInfo={pageInfo}
      />
      <DesktopAdminAssetsGroupAddModal
        isCreateGroupModalVisible={isCreateGroupModalVisible}
        setIsCreateGroupModalVisible={setIsCreateGroupModalVisible}
        assetsTypeList={assetsTypeList}
        onFinish={titleEditEventListener}
        isEdit={true}
        datas={{ newGroupName: pane.group_name, assetstype: pane.base_cd }}
      />
    </TabContent>
  );
};

export default DesktopAdminAssetsGroupContent;
