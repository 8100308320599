const defaultColor = {
  white: '#ffffff',
  black: '#000000',
  lightGray: '#f0f2f5',
  veryLightGray: '#fafafa',
  blueGray: '#a3a4a8',
  gray: '#3d3d3d',
  darkGray: '#292a2d',
  veryDarkGray: '#1F1F20',
  lightBlue: '#384BAB',
  darkBlue: '#001628',
  purple: '#A361D7',
  blue: '#1990FF',
  purpleOpacity: '#30242F',
  blueOpacity: '#E6F7FF',
};

export { defaultColor };
