import {
    ApartmentOutlined,
    DashboardOutlined,
    FileProtectOutlined,
    FolderViewOutlined,
    HddOutlined,
    RadarChartOutlined,
    ReadOutlined,
    SettingOutlined,
    SnippetsOutlined,
} from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { IAdminMenuProps } from '@/common/type';
import Loading from '@/components/loading';
import { Menu } from 'antd';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import CustomLink from '@/common/components/customLink/customLink';

// import api from '@/api/api';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IMenuListProps {
    collapsed?: boolean | null;
}

const getAdminMenuMy = async ({ projectId }) => {
    const result = await api.getAdminMenuMy({ project_id: projectId });

    return result.data;
};

let arr = [];

const MenuList: React.FunctionComponent<IMenuListProps> = () => {
    const { projectId, setUserInfo, selectTab } = useContext(ProjectContext);
    const [menuList, setMenuList] = useState<IAdminMenuProps[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const { pathname } = useLocation();
    const defaultKey = pathname.split('/').slice(1);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [selectKey, setSelectKey] = useState<any>({
        keyPath: defaultKey,
        selectedKeys:
            defaultKey.length > 1
                ? defaultKey[0] + '/' + defaultKey[1]
                : defaultKey[0],
    });

    const setIcons = useCallback(key => {
        if (key === 'dashboard') {
            return <DashboardOutlined />;
        } else if (key === 'Org') {
            return <ApartmentOutlined />;
        } else if (key === 'Assets') {
            return <HddOutlined />;
        } else if (key === 'Policy') {
            return <FileProtectOutlined />;
        } else if (key === 'Diagnosis') {
            return <ReadOutlined />;
        } else if (key === 'Dangers') {
            return <RadarChartOutlined />;
        } else if (key === 'Certification') {
            return <FolderViewOutlined />;
        } else if (key === 'Admin') {
            return <SettingOutlined />;
        } else if (key === 'Spec') {
            return <SnippetsOutlined />;
        }
    }, []);

    const MenuListItem = item => {
        if (item.url === null) {
            return item.name;
        } else {
            return <CustomLink url={item.url} name={item.name} />;
        }
    };

    const setPermissionArray = useCallback((datas, arr) => {
        datas.forEach(item => {
            if (
                typeof item.children !== 'undefined' &&
                item.children.length > 0
            ) {
                setPermissionArray(item.children, arr);
            }
            arr.push(item);
        });

        return arr;
    }, []);

    const setMenuDatas = useCallback(
        datas => {
            return datas.map(item => {
                if (!item.perm_view) return false;

                if (
                    typeof item.children !== 'undefined' &&
                    item.children.length > 0
                ) {
                    return {
                        ...item,
                        label: MenuListItem(item),
                        icon: setIcons(item.key),
                        children: setMenuDatas(item.children),
                        perm_view: item.perm_view ? 'true' : 'false', // warning 방지
                        perm_write: item.perm_write ? 'true' : 'false',
                    };
                }
                return {
                    ...item,
                    label: MenuListItem(item),
                    icon: setIcons(item.key),
                    perm_view: item.perm_view ? 'true' : 'false', // warning 방지
                    perm_write: item.perm_write ? 'true' : 'false',
                };
            });
        },
        [setIcons]
    );

    useEffect(() => {
        // 배열 초기화 필수
        arr = [];
        getAdminMenuMy({ projectId }).then(datas => {
            setUserInfo(prev => ({
                ...prev,
                permission: setPermissionArray(datas, arr),
            }));
            setMenuList([...setMenuDatas(datas)]);
            setIsLoading(false);
        });
    }, [projectId, setMenuDatas, setPermissionArray, setUserInfo]);

    // 탭 변경시 사이드 메뉴 변경
    useEffect(() => {
        setSelectKey(() => {
            return {
                selectedKeys: selectTab.slice(1),
                keyPath: selectTab.split('/').slice(1),
            };
        });
    }, [selectTab]);

    if (isLoading) return <Loading />;

    return menuList.length > 0 ? (
        <Menu
            items={menuList}
            mode="inline"
            defaultOpenKeys={selectKey.keyPath}
            defaultSelectedKeys={selectKey.selectedKeys}
            openKeys={selectKey.keyPath}
            selectedKeys={selectKey.selectedKeys}
            onOpenChange={event => {
                setSelectKey(prev => ({
                    keyPath: event,
                    selectedKeys: prev.selectedKeys,
                }));
            }}
        />
    ) : (
        <></>
    );
};

export default MenuList;
