import React, { createContext, useEffect, useMemo, useState } from 'react';
import { dark, light } from '../styles/theme';
import { ConfigProvider, theme } from 'antd';

import { ThemeProvider as TProvider } from 'styled-components';

interface IThemeContextProps {
  isDarkMode: boolean;
  setIsDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ThemeContext = createContext<IThemeContextProps>({
  isDarkMode: false,
  setIsDarkMode: () => false,
});

const ThemeProvider: React.FunctionComponent = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const value = useMemo(
    () => ({ isDarkMode, setIsDarkMode }),
    [isDarkMode, setIsDarkMode]
  );

  useEffect(() => {
    const getLocalData = sessionStorage.getItem('theme');

    if (getLocalData) {
      setIsDarkMode(getLocalData === 'dark' ? true : false);
    }
  }, []);

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.remove('light');
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
      document.body.classList.add('light');
    }
  }, [isDarkMode]);

  return (
    <ConfigProvider
      theme={{
        token: {
          borderRadius: 3,
          colorPrimary: '#476ca0',
          colorSuccess: '#4c8ee6',
        },
        algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
      }}
    >
      <ThemeContext.Provider value={value}>
        <TProvider theme={isDarkMode ? dark : light}>{children}</TProvider>
      </ThemeContext.Provider>
    </ConfigProvider>
  );
};

export default ThemeProvider;
