import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Tabs } from 'antd';
import { IHistoryDataProps } from '@/panel/panels';
import { ProjectContext } from '@/common/project-provider';
import { useTranslation } from 'react-i18next';

interface IHistoryTabProps {
    pathName: string;
    historyTab: any;
    currentPageItem: IHistoryDataProps[];
    setHistoryTab: any;
}

const Container = styled.div`
    min-height: 60px;
    box-sizing: border-box;
    padding: 16px 24px 0 24px;
    background-color: ${props => props.theme.layout.contentBg};
`;
const StyledTabs = styled(Tabs)`
    &.ant-tabs {
        position: relative;
        > .ant-tabs-nav {
            margin-bottom: 0;
            position: relative;
            .ant-tabs-nav-wrap {
                z-index: 9;
                .ant-tabs-tab {
                    padding: 0;
                    margin-left: 1px;
                    border-radius: 10px 10px 0 0;
                    background-color: ${props => props.theme.layout.tabBg};
                    border-color: ${props => props.theme.layout.tabBorderColor};
                    border-bottom: none;
                    font-size: 15px;
                    position: relative;
                    z-index: 1;
                    padding: 10px 20px;
                    display: block;
                    color: ${props => props.theme.font.color};
                    text-align: center;
                    .ant-tabs-tab-btn {
                        display: inline-block;
                    }
                    .ant-tabs-tab-remove {
                        display: inline-block;
                    }
                    &:first-child {
                        margin: 0;
                    }
                    &.ant-tabs-tab-active {
                        background-color: ${props =>
                            props.theme.layout.tabActiveBg};
                        border-color: ${props =>
                            props.theme.layout.pageHeaderBorderColor};
                        a {
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
`;

const HistoryTab: React.FunctionComponent<IHistoryTabProps> = ({
    pathName,
    historyTab = [],
    currentPageItem,
    setHistoryTab,
}) => {
    const { t } = useTranslation();

    const { setSelectTab } = useContext(ProjectContext);
    const [activeKey, setActiveKey] = useState('');
    const [pageItem, setPageItem] = useState(currentPageItem);

    const remove = (targetKey: string) => {
        let newActiveKey = activeKey;
        let lastIndex = -1;
        let historyData = [];

        historyTab.forEach((item, i) => {
            if (item.key === targetKey) {
                lastIndex = i - 1;
            }
        });
        const newPanes = historyTab.filter(item => item.key !== targetKey);
        if (newPanes.length && newActiveKey === targetKey) {
            if (lastIndex >= 0) {
                newActiveKey = newPanes[lastIndex].key;
            } else {
                newActiveKey = newPanes[0].key;
            }
        }

        historyData =
            newPanes.length === 1
                ? newPanes.map(item => ({ ...item, closable: false }))
                : newPanes;

        const menuItemStorage = JSON.parse(sessionStorage.getItem('history'));
        menuItemStorage.splice(lastIndex + 1, 1);

        sessionStorage.setItem('history', JSON.stringify(menuItemStorage));
        setHistoryTab(historyData);
        setActiveKey(newActiveKey);
    };

    const onEdit = (targetKey: string, action: 'add' | 'remove') => {
        if (action === 'remove') {
            remove(targetKey);
        }
    };

    const onChange = (newActiveKey: string) => {
        const tabPath = historyTab.filter(item => item.key === newActiveKey);
        window.history.pushState('', '', tabPath[0].pathName);
        setPageItem([
            {
                pageName: tabPath[0].pageName,
                pathName: tabPath[0].pathName,
            },
        ]);
        setActiveKey(newActiveKey);

        // 사이드 메뉴 로직
        setSelectTab(tabPath[0].pathName);
    };

    useEffect(() => {
        const tabPath = historyTab.filter(item => item.key === activeKey);

        if (tabPath.length > 0) {
            setSelectTab(tabPath[0].pathName);
        }

        return () => {
            setSelectTab('');
        };
    }, [activeKey, historyTab, setSelectTab]);

    useEffect(() => {
        if (historyTab.length > 0) {
            historyTab.forEach(item => {
                if (item.pathName === pathName) {
                    setActiveKey(item.key);
                }
            });
        }
    }, [pathName, historyTab]);

    return (
        <Container>
            <StyledTabs
                hideAdd
                onChange={onChange}
                items={historyTab}
                onEdit={onEdit}
                activeKey={activeKey}
                type="editable-card"
                tabBarExtraContent={
                    <Button
                        disabled={historyTab.length === 1}
                        onClick={() => {
                            sessionStorage.setItem(
                                'history',
                                JSON.stringify(pageItem)
                            );
                            setHistoryTab(prev =>
                                prev
                                    .filter(
                                        item =>
                                            item.pageName ===
                                            pageItem[0].pageName
                                    )
                                    .map(item => ({ ...item, closable: false }))
                            );
                        }}
                    >
                        {t("전체 탭 닫기")}
                    </Button>
                }
            />
        </Container>
    );
};

export default HistoryTab;
