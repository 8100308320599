import {
    Button,
    Drawer,
    Empty,
    Popconfirm,
    Space,
    Tag,
    Timeline,
    Upload,
} from 'antd';
import {
    DeleteOutlined,
    DownloadOutlined,
    FileTextOutlined,
    FolderOpenOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import { formatDate, openNotification } from '@/common/util';
import { IAdminMenuPermissionProps } from '@/common/type';
import { ICertificationList } from './type';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import styled from 'styled-components';
import Viewer from '@/pages/Viewer';
import Modal from '@/components/modal';
import { useTranslation } from 'react-i18next';

interface IDesktopCertificationAdminModalProps {
    isModalVisible: boolean;
    setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setRecordDatas?: React.Dispatch<React.SetStateAction<any[]>>;
    selectDocId: number;
    selectId?: number;
    recordTitle: string;
    isDeletBtn?: boolean;
    pageInfo: IAdminMenuPermissionProps;
}

const MoreContainer = styled.div`
    position: absolute;
    left: 0;
    bottom: 53px;
    padding: 20px;
    width: 100%;
    text-align: center;
    line-height: 32px;
    border-top: 1px solid ${props => props.theme.layout.searchBorderColor};
    background-color: ${props => props.theme.layout.innerContentBg};
    .ant-upload-list-text-container,
    .ant-upload-list-item-container {
        display: none !important;
    }
`;

interface IDateProps {
    isCurrent: boolean;
}

const FileHead = styled.div<IDateProps>`
    opacity: ${props => (props.isCurrent ? '1' : '0.3')};
`;

const Date = styled.span`
    font-size: 12px;
`;
const Vol = styled.span`
    font-size: 11px;
    vertical-align: top;
    display: inline-block;
    padding-bottom: 5px;
    display: -webkit-box;
`;
const FileName = styled.span`
    font-size: 13px;
    display: inline-block;
    /* border: 1px solid red; */
    max-width: 450px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`;
const ButtonArea = styled.div`
    position: absolute;
    right: 0;
    top: 2px;
    button {
        font-size: 12px;
    }
`;

const Title = styled.h1`
    padding-bottom: 20px;
    padding-right: 130px;
    position: relative;
`;

const StyledButton = styled(Button)`
    position: absolute;
    right: 0;
    top: -5px;
`;

interface IInnerProps {
    isWrite: boolean;
}

const Inner = styled.div<IInnerProps>`
    overflow-y: auto;
    max-height: 50vh;
    margin: ${props =>
        props.isWrite ? '-24px -24px 50px 0' : '-24px -24px 0 0'};
    padding: 24px 24px 24px 0;
`;

const getCertificationTraceDetail = async ({ project_id, id }) => {
    const result = await api.getCertificationTraceDetail({
        project_id,
        id,
    });

    return result.data;
};

const setCertificationTraceFileUpload = async ({ projectId, id, formData }) => {
    const result = await api.setCertificationTraceFileUpload({
        project_id: projectId,
        id,
        formData,
    });

    return result.data;
};

const delCertificationTraceFile = async ({ projectId, file_id, id }) => {
    const result = await api.delCertificationTraceFile({
        project_id: projectId,
        file_id,
        id,
    });

    return result.data;
};

const getCertificationTraceFileDownload = async ({ projectId, trace_file }) => {
    const result = await api.getCertificationTraceFileDownload({
        project_id: projectId,
        trace_file,
    });

    return result;
};

const deletePolicyGroupList = async ({ projectId, id }) => {
    const result = await api.deletePolicyGroupList({
        project_id: projectId,
        id,
    });

    return result.data;
};

const DesktopCertificationAdminModal: React.FunctionComponent<
    IDesktopCertificationAdminModalProps
> = ({
    isModalVisible,
    setIsModalVisible,
    selectDocId,
    setRecordDatas,
    recordTitle,
    isDeletBtn = false,
    pageInfo,
    selectId,
}) => {
    const { t } = useTranslation();
    const { projectId } = useContext(ProjectContext);

    const [list, setList] = useState<ICertificationList[]>([]);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [selectItem, setSelectItem] = useState<ICertificationList | null>(
        null
    );

    const deleteFile = file_id => {
        delCertificationTraceFile({ projectId, file_id, id: selectDocId }).then(
            data => {
                typeof setRecordDatas !== 'undefined' &&
                    setRecordDatas(prev => {
                        return prev.map(item => {
                            if (item.id === selectDocId) {
                                return {
                                    ...item,
                                    last_date:
                                        data.length > 0
                                            ? data[0].created_dt
                                            : null,
                                };
                            }
                            return item;
                        });
                    });

                setList(prev => {
                    return prev.filter(item => item.id !== file_id);
                });
            }
        );
    };

    const handleChange = info => {
        if (info.file.status === 'uploading') {
            return;
        }
        const formData = new FormData();
        setUploadLoading(true);

        formData.append('file', info.file.originFileObj);

        setCertificationTraceFileUpload({
            projectId,
            id: selectDocId,
            formData,
        }).then(data => {
            typeof setRecordDatas !== 'undefined' &&
                setRecordDatas(prev => {
                    return prev.map(item => {
                        if (item.id === selectDocId) {
                            return {
                                ...item,
                                last_date: formatDate(data[0].created_dt),
                                key: `edit_${item.key}`,
                            };
                        }
                        return item;
                    });
                });
            const notiText = {
                description: t('완료'),
            };

            openNotification(notiText);
            setList(data);
            setUploadLoading(false);
        });
    };

    const downloadFile = item => {
        getCertificationTraceFileDownload({
            projectId,
            trace_file: item.fileurl,
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${item.filename}.${item.file_ext}`);
            document.body.appendChild(link);
            link.click();
        });
    };

    const fileTypeCheck = fileExt => {
        if (fileExt.includes('xlsx') || fileExt.includes('xls')) return 'EXCEL';
        if (
            fileExt.includes('JPG') ||
            fileExt.includes('jpg') ||
            fileExt.includes('jpeg')
        )
            return 'JPG';
        if (fileExt.includes('png')) return 'PNG';
        if (fileExt.includes('pdf')) return 'PDF';
        if (fileExt.includes('doc') || fileExt.includes('docx')) return 'WORD';
        if (fileExt.includes('ppt') || fileExt.includes('pptx')) return 'PPT';
        if (fileExt.includes('hwp')) return 'HWP';

        return 'ETC';
    };

    useEffect(() => {
        getCertificationTraceDetail({
            project_id: projectId,
            id: selectDocId,
        }).then(datas => {
            setList(datas);
        });
    }, [projectId, selectDocId]);

    const removeCertHandler = id => {
        deletePolicyGroupList({ projectId, id }).then(data => {
            const notiText = {
                description: t('완료'),
            };

            openNotification(notiText);
            setRecordDatas(data);
            setIsModalVisible(false);
        });
    };

    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Modal
                width="750px"
                title={pageInfo.perm_write ? t('증적등록') : t('증적목록')}
                open={isModalVisible}
                // onOk={handleOk}
                onCancel={() => setIsModalVisible(false)}
                footer={
                    <Button
                        onClick={() => {
                            setIsModalVisible(false);
                        }}
                    >"
                        {t("닫기")}
                    </Button>
                }
            >
                <Inner isWrite={pageInfo.perm_write}>
                    <Title>
                        <FileTextOutlined /> [{recordTitle}] {t("증적자료")}
                        {isDeletBtn && pageInfo.perm_write && (
                            <Popconfirm
                                title={t("삭제 하시겠습니까?")}
                                onConfirm={() => {
                                    // onConfirm(record);
                                    removeCertHandler(selectId);
                                }}
                                // onCancel={cancel}
                                okText={t("삭제")}
                                cancelText={t("취소")}
                            >
                                <StyledButton icon={<DeleteOutlined />}>
                                    {t("정책삭제")}
                                </StyledButton>
                            </Popconfirm>
                        )}
                    </Title>
                    {list.length === 0 && (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                    <Timeline>
                        {list.map((item, index) => {
                            return (
                                <Timeline.Item
                                    key={item.id}
                                    color={index === 0 ? 'green' : 'gray'}
                                    style={{ position: 'relative' }}
                                >
                                    <FileName title={item.filename}>
                                        {item.filename}
                                    </FileName>
                                    <Vol>
                                        (
                                        {Math.ceil(
                                            (item.filesize / 1024) * 10
                                        ) / 10}{' '}
                                        KB)
                                    </Vol>
                                    <FileHead isCurrent={index === 0}>
                                        <Tag>
                                            {fileTypeCheck(item.file_ext)}
                                        </Tag>
                                        <Date>
                                            {formatDate(item.created_dt)}
                                        </Date>
                                    </FileHead>
                                    <ButtonArea>
                                        <Space size="small">
                                            <Button
                                                key="list-loadmore-open"
                                                size="small"
                                                onClick={() => {
                                                    setSelectItem(item);
                                                    showDrawer();
                                                }}
                                                icon={<FolderOpenOutlined />}
                                            >
                                                {t("미리보기")}
                                            </Button>

                                            {pageInfo.perm_write && (
                                                <>
                                                    <Button
                                                        key="list-loadmore-download"
                                                        size="small"
                                                        onClick={() => {
                                                            downloadFile(item);
                                                        }}
                                                        icon={
                                                            <DownloadOutlined />
                                                        }
                                                    >
                                                        {t("다운로드")}
                                                    </Button>
                                                    <Popconfirm
                                                        title={`${item.filename} ${t('삭제 하시겠습니까?')}`}
                                                        onConfirm={() =>
                                                            deleteFile(item.id)
                                                        }
                                                        onCancel={() => false}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            key="list-loadmore-delete"
                                                            size="small"
                                                            icon={
                                                                <DeleteOutlined />
                                                            }
                                                        >
                                                            {t("삭제")}
                                                        </Button>
                                                    </Popconfirm>
                                                </>
                                            )}
                                        </Space>
                                    </ButtonArea>
                                </Timeline.Item>
                            );
                        })}
                    </Timeline>
                    {pageInfo.perm_write && (
                        <MoreContainer>
                            <Upload
                                name="file"
                                maxCount={1}
                                onChange={handleChange}
                            >
                                <Button
                                    icon={<UploadOutlined />}
                                    loading={uploadLoading}
                                >
                                    {t("증적업로드")}
                                </Button>
                            </Upload>
                        </MoreContainer>
                    )}
                </Inner>
            </Modal>
            <Drawer
                title={selectItem?.filename || 'FILE PREVIEW'}
                placement="right"
                onClose={onClose}
                open={open}
                width="80%"
            >
                <Viewer selectItem={selectItem} />
            </Drawer>
        </>
    );
};

export default DesktopCertificationAdminModal;
