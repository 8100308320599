import React, { useContext, useEffect, useState } from 'react';

import DesktopOrgManagerList from './desktop-org-manager-list';
import { IAdminMenuPermissionProps } from '@/common/type';
import api from '@/api/api';
import styled from 'styled-components';
import DesktopOrgMemberManager from '@/panel/Org/Manager/desktop-org-members-manager';
import { ProjectContext } from '@/common/project-provider';

interface IDesktopOrgManager {
    pageInfo: IAdminMenuPermissionProps;
}

const Container = styled.div``;

const ContainerManager = styled.div`
    /* min-height: 75vh; */
    position: sticky;
    left: 0;
    top: 140px;
    width: 35%;
    height: calc(100vh - 200px);
    padding: 20px;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    overflow-y: auto;
    border-radius: 10px;

    border: 1px solid ${props => props.theme.layout.searchBorderColor};
    border-radius: 10px;
    box-shadow: 2px 0 8px 0 rgb(29 35 41 / 5%);
    &::-webkit-scrollbar {
        width: 2px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #9f9f9f;
        background-clip: padding-box;
    }
    &::-webkit-scrollbar-track {
        background-color: #eeeeee;
    }
`;

const ContainerMember = styled.div`
    display: inline-block;
    /* padding: 20px; */
    box-sizing: border-box;
    vertical-align: top;
    width: 65%;
    padding-left: 20px;
`;

const getOrgList = async ({ projectId }) => {
    const result = await api.getOrgList({ project_id: projectId });

    return result.data;
};

const DesktopOrgManager: React.FunctionComponent<IDesktopOrgManager> = ({
    pageInfo,
}) => {
    const { projectId } = useContext(ProjectContext);
    const [orgList, setOrgList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [orgId, setOrgId] = useState<number | null>(1);

    useEffect(() => {
        getOrgList({ projectId }).then(datas => {
            setOrgList(datas);
            setIsLoading(false);
        });
    }, [projectId]);

    return (
        <Container>
            <ContainerManager>
                <DesktopOrgManagerList
                    datas={orgList}
                    setOrgId={setOrgId}
                    isLoading={isLoading}
                    pageInfo={pageInfo}
                />
            </ContainerManager>
            <ContainerMember>
                <DesktopOrgMemberManager
                    orgId={orgId}
                    projectId={projectId}
                    pageInfo={pageInfo}
                />
            </ContainerMember>
        </Container>
    );
};

export default DesktopOrgManager;
