import { AppConsumer } from '@/common/app-provider';
import DesktopSider from './desktop-sider';
import { IMenuFoldingProps } from './type';
import MobileSider from './mobile-sider';
import React from 'react';

type IIndexProps = IMenuFoldingProps;

const index: React.FunctionComponent<IIndexProps> = props => {
  return (
    <AppConsumer>
      {({ config }) => {
        if (config.isMobile) {
          return <MobileSider {...props} />;
        }
        return <DesktopSider {...props} />;
      }}
    </AppConsumer>
  );
};

export default index;
