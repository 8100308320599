import { Col, Descriptions, Row } from 'antd';
import React, { useEffect, useMemo, useState, useContext } from 'react';

import { IVulnerabilityType } from '../type';
import { Pie } from '@ant-design/plots';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import styled from 'styled-components';
import Loading from '@/components/loading';
import { graphColor } from '@/common/util';
import { ThemeContext } from '@/common/theme-provider';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopDashboardVulnerabilityTabItemProps {
  id: number;
}

const Container = styled.div``;
const StyledCol = styled(Col)`
  padding: 10px 10px 0 10px;
`;
const Effect = styled.span`
  color: #bd0f0f;
  font-weight: bold;
`;
const Nodata = styled.div`
  text-align: center;
  padding-top: 120px;
  font-size: 15px;
`;

const getDashboarVulnerability = async ({ projectId, id }) => {
  const result = await api.getDashboarVulnerability({
    project_id: projectId,
    group_id: id,
  });

  return result.data;
};

const DesktopDashboardVulnerabilityTabItem: React.FunctionComponent<
  IDesktopDashboardVulnerabilityTabItemProps
> = ({ id }) => {
  const { t } = useTranslation();
  
  const { isDarkMode } = useContext(ThemeContext);
  const { projectId } = useContext(ProjectContext);
  const [vulnerabilityData, setVulnerabilityData] = useState<
    IVulnerabilityType[]
  >([]);

  useEffect(() => {
    getDashboarVulnerability({ projectId, id }).then(data => {
      setVulnerabilityData(data);
    });
  }, [projectId, id]);

  const pieData = useMemo(() => {
    if (vulnerabilityData.length > 0) {
      return vulnerabilityData[0].pie_data.map(item => {
        return {
          type: item.type,
          value: item.value,
        };
      });
    }
    return [];
  }, [vulnerabilityData]);

  const config: any = {
    appendPadding: 10,
    data: pieData,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    color: graphColor,
    innerRadius: 0.4,
    theme: isDarkMode ? 'dark' : 'default',
    legend: {
      layout: 'vertical',
      position: 'right',
      offsetX: 0,
    },
    style: {
      height: '300px',
      width: '100%',
      margin: '0 auto',
    },
    label: {
      type: 'inner',
      offset: '-50%',
      content: '{value}',
      style: {
        textAlign: 'center',
        fontSize: 11,
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: '16px',
        },
        content: t('취약점 구분별'),
      },
    },
  };

  return vulnerabilityData.length > 0 ? (
    <Container>
      <Row>
        <StyledCol span={7}>
          <Descriptions title={t("취약점 점검 이행 현황")} bordered>
            <Descriptions.Item
              label={t("이행대상")}
              span={3}
              contentStyle={{ width: '50%' }}
            >
              {vulnerabilityData[0].trans_target}
            </Descriptions.Item>
            <Descriptions.Item label={t("미이행")} span={3}>
              {vulnerabilityData[0].trans_done}
            </Descriptions.Item>
            <Descriptions.Item label={t("이행률")} span={3}>
              <Effect>{vulnerabilityData[0].trans_rate}%</Effect>
            </Descriptions.Item>
          </Descriptions>
        </StyledCol>
        <StyledCol span={7}>
          <Descriptions title={t("취약점 점검 현황")} bordered>
            <Descriptions.Item
              label={t("취약점대상")}
              span={3}
              contentStyle={{ width: '50%' }}
            >
              {vulnerabilityData[0].check_target}
            </Descriptions.Item>
            <Descriptions.Item label={t("점검완료")} span={3}>
              {vulnerabilityData[0].check_done}
            </Descriptions.Item>
            <Descriptions.Item label={t("처리율")} span={3}>
              <Effect>{vulnerabilityData[0].check_rate}%</Effect>
            </Descriptions.Item>
          </Descriptions>
        </StyledCol>
        <StyledCol span={10}>
          {pieData.length > 0 ? (
            <Pie {...config} />
          ) : (
            <Nodata>{t("데이터가 없습니다.")}</Nodata>
          )}
        </StyledCol>
      </Row>
    </Container>
  ) : (
    <Loading />
  );
};

export default DesktopDashboardVulnerabilityTabItem;
