import { ICacheLike } from 'axios-extensions';
import api from './api';
import axios from 'axios';
import { cacheAdapterEnhancer } from 'axios-extensions';
import { openNotification } from '@/common/util';

declare module 'axios' {
    interface AxiosRequestConfig {
        // if your cacheFlag was setting to 'useCache'
        useCache?: boolean | ICacheLike<any>;
    }
}

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60000,
    adapter: cacheAdapterEnhancer(axios.defaults.adapter, {
        enabledByDefault: false,
        cacheFlag: 'useCache',
    }),
    

    // withCredentials: true
});

let isTokenRefreshing = false;
const refreshSubscribers = [];

const onTokenRefreshed = accessToken => {
    refreshSubscribers.map(callback => callback(accessToken));
};

const addRefreshSubscriber = callback => {
    refreshSubscribers.push(callback);
};

instance.interceptors.request.use(
    config => {
        // 요청 바로 직전
        // axios 설정값에 대해 작성
        // console.log(config);

        return { ...config, url: config.url.replace('.', '-') };
    },
    error => {
        // 요청 에러 처리를 작성
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    response => {
        /*
        http status가 200인 경우
        응답 바로 직전에 대해 작성합니다. 
        .then() 으로 이어집니다.
        */
        return response;
    },
    async error => {
        /*
        http status가 200이 아닌 경우
        응답 에러 처리를 작성합니다.
        .catch() 으로 이어집니다.    
        */
        const {
            config,
            response: { status },
        } = error;

        const originalRequest = config;

        if (status === 401) {
            if (!isTokenRefreshing) {
                isTokenRefreshing = true;
                // const refresh_token = sessionStorage.getItem('refreshToken');
                const refresh_token = sessionStorage.getItem('refreshToken');

                api.setRefreshToken({
                    refresh_token,
                }).then(response => {
                    const accessTokens = response.data.token;

                    isTokenRefreshing = false;

                    instance.defaults.headers.common['Authorization'] =
                        'Bearer ' + accessTokens;

                    onTokenRefreshed(accessTokens);
                });
            }
            const retryOriginalRequest = new Promise(resolve => {
                addRefreshSubscriber(accessToken => {
                    originalRequest.headers.Authorization =
                        'Bearer ' + accessToken;
                    resolve(axios(originalRequest));
                });
            });
            return retryOriginalRequest;
        } else {
            
            if (status !== 200 || status === 400) {
                openNotification({
                    type: 'error',
                    description: error.response.data.message,
                });
                console.log( error.response.status );
            }
            if (status === 403) {
                window.location.href = '/Login';
            }
        }

        return Promise.reject(error);
    }
);

export default instance;
