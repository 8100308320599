import { Button, Col, Input, Row } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import { IOrgGroupData } from '../type';
import React from 'react';
import styled from 'styled-components';

interface IDesktopOrgManagerInput {
  targetItem: IOrgGroupData;
  isEditMode: boolean;
  item: IOrgGroupData;
  createNodeKey: (targetItem: IOrgGroupData) => string;
  insertNodeHandler: (targetItem: IOrgGroupData, flag: boolean) => void;
  removeNodeHandler: () => void;
  inputRef?: any;
}

const AddButton = styled.div`
  margin-left: 5px;
  button {
    font-size: 12px;
    display: inline-block;
  }
`;

const CancleButton = styled(AddButton)``;

const DesktopOrgManagerInput: React.FunctionComponent<
  IDesktopOrgManagerInput
> = ({
  targetItem,
  isEditMode,
  item,
  createNodeKey,
  insertNodeHandler,
  removeNodeHandler,
  inputRef,
}) => {
  if (isEditMode) {
    item.organ_name = targetItem.organ_name;
  }

  return (
    <Row gutter={8} key={Math.random()}>
      <Col span={3.5}>
        <Input
          size="small"
          ref={inputRef}
          placeholder="부서명 입력"
          name="organ_name"
          defaultValue={item.organ_name}
          // eslint-disable-next-line react/no-string-refs
          onChange={event => {
            const {
              target: { name, value },
            } = event;

            item = {
              ...targetItem,
              [name]: value,
              parent_id: targetItem.organ_id,
              key: createNodeKey(targetItem),
            };
          }}
          onKeyPress={event => {
            if (targetItem.organ_name === item.organ_name) return;
            if (event.key === 'Enter') {
              insertNodeHandler(item, isEditMode);
            }
          }}
          onKeyUp={event => {
            if (event.keyCode === 27) {
              removeNodeHandler();
            }
          }}
        />
      </Col>
      <AddButton>
        <Button
          type="text"
          icon={<CheckOutlined />}
          size="small"
          onClick={() => {
            if (targetItem.organ_name === item.organ_name) {
              removeNodeHandler();
              return;
            }
            insertNodeHandler(item, isEditMode);
          }}
        />
      </AddButton>
      <CancleButton>
        <Button
          type="text"
          icon={<CloseOutlined />}
          size="small"
          onClick={removeNodeHandler}
        />
      </CancleButton>
    </Row>
  );
};

export default DesktopOrgManagerInput;
