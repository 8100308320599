import { Button, Form, Input, Select, Space } from 'antd';
import React, { useContext } from 'react';

import { IAccountDatas } from './type';
import { ProjectContext } from '@/common/project-provider';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopAdminAccountSearchProps {
  setUserList: React.Dispatch<React.SetStateAction<IAccountDatas[]>>;
  getUsersAPI: ({ projectId, datas }) => any;
}

const Container = styled.div`
  text-align: left;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  border: 1px solid ${props => props.theme.layout.searchBorderColor};
  box-sizing: border-box;
  .ant-form-item {
    margin: 0;
  }
`;
const Label = styled.label`
  font-size: 15px;
  margin-left: 15px;
`;

const DesktopAdminAccountSearch: React.FunctionComponent<
  IDesktopAdminAccountSearchProps
> = ({ setUserList, getUsersAPI }) => {
  const { t } = useTranslation();
  const { projectId } = useContext(ProjectContext);

  const onFinish = (values: any) => {
    const { username: user_id, fullname: user_name, appr_yn } = values;

    getUsersAPI({
      projectId,
      datas: {
        user_name,
        user_id,
        appr_yn: appr_yn === '' ? '' : appr_yn === '0' ? 'false' : 'true',
      },
    }).then(datas => {
      setUserList(
        datas.map((item, index) => ({
          ...item,
          no: index,
          key: index,
          rank_cd: item.rank_cd,
          dept_cd: item.dept_cd,
        }))
      );
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const [form] = Form.useForm();

  const resetHandler = () => {
    getUsersAPI({
      projectId,
      datas: {
        user_name: '',
        user_id: '',
        appr_yn: '',
      },
    }).then(datas => {
      form.resetFields();
      setUserList(
        datas.map((item, index) => ({
          ...item,
          no: index,
          key: index,
          rank_cd: item.rank,
          dept_cd: item.dept,
        }))
      );
    });
  };

  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  return (
    <Container>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
      >
        <Space>
          <Label>{t("아이디")}</Label>
          <Form.Item name="username" initialValue="">
            <Input />
          </Form.Item>
          <Label>{t("이름")}</Label>
          <Form.Item name="fullname" initialValue="">
            <Input />
          </Form.Item>
          <Label>{t("승인여부")}</Label>
          <Form.Item name="appr_yn" initialValue="">
            <Select
              style={{ width: 90, textAlign: 'left' }}
              onChange={handleChange}
            >
              <Option value="">{t("전체")}</Option>
              <Option value="0">{t("미승인")}</Option>
              <Option value="1">{t("승인")}</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t("조회")}
            </Button>
          </Form.Item>

          <Form.Item>
            <Button onClick={resetHandler}>{t("초기화")}</Button>
          </Form.Item>
        </Space>
      </Form>
    </Container>
  );
};

export default DesktopAdminAccountSearch;
