import React from 'react';
import styled from 'styled-components';

import { IAdminMenuPermissionProps } from '@/common/type';
import DesktopDangersRiskAnalysisTable from './desktop-dangers-risk-analysis-table';

interface IDesktopDangersRiskAnalysisProps {
  pageInfo: IAdminMenuPermissionProps;
}

const Container = styled.div`
  position: relative;
  min-height: 500px;
  .ant-form-item {
    margin: 0;
  }
`;

const DesktopDangersRiskAnalysis: React.FunctionComponent<
  IDesktopDangersRiskAnalysisProps
> = ({ pageInfo }) => {
  return (
    <Container>
      <DesktopDangersRiskAnalysisTable pageInfo={pageInfo} />
    </Container>
  );
};

export default DesktopDangersRiskAnalysis;
